import React from "react";
import usecase1 from "../../assets/media/Landing/usecase1.svg";
import usecase2 from "../../assets/media/Landing/usecase2.svg";
import usecase3 from "../../assets/media/Landing/usecase3.svg";
import usecase4 from "../../assets/media/Landing/usecase4.svg";

const UseCases = () => {
  const useCaseData = [
    {
      icon: usecase1,
      title: "Job Readiness",
      descr:
        "Navigating the job market can be a daunting task, but Nexa is here to simplify the process and maximize your chances of success.",
    },
    {
      icon: usecase2,
      title: "Scalable Career Services",
      descr:
        "Nexa partners with  training institutions to enhance their career services, offering at scale professional guidance, tools, and resources",
    },
    {
      icon: usecase3,
      title: "Diverse Career Support",
      descr:
        "Nexa offers robust career support services designed to empower all walks of individuals at every stage of their professional journey.",
    },
    {
      icon: usecase4,
      title: "Talent Screening",
      descr:
        "Nexa will source, screen and interview candidates on your behalf and bring you only the best talent for your hiring needs. You just press a button.",
    },
  ];
  return (
    <div className="section text-center">
      <div className="gradient-right"></div>
      <h1 className="section-heading color-dark">How NEXA HELPS</h1>
      <div className="container my-5">
        <div className="row usecase-row">
          {useCaseData.map((useCase, index) => (
            <div
              key={index}
              className="col col-12 col-sm-6 col-lg-3 mb-3 mb-lg-0"
            >
              <div className="use_case-card">
                <div className="profileImage-div ">
                  <img src={useCase.icon} alt="" className="profileImage" />
                </div>
                <div className="textContainer">
                  <p className="title">{useCase?.title}</p>
                  <p className="description">{useCase?.descr}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UseCases;
