import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  ElementsConsumer,
} from "@stripe/react-stripe-js";
import { error } from "../../../Utilities/toast";
import { useDispatch } from "react-redux";
import {
  addNewPaymentMethod,
  getAllPaymentCards,
} from "../../../Redux/PaymentsSlice";

const stripeKeys =
  process.env.REACT_APP_ENVIRONMENT === "test"
    ? "pk_test_51Ot01YC6xSXKGdgmo8VM7GBORwlXrRHqsX8DiRmpNpAd4ufhzHF79L4RtvZUOw5nSHcTipLiLr6WMDk1yhrt05bi00Pq3wPwfq"
    : "pk_live_51Ot01YC6xSXKGdgmVwoZGN4gdVaGjaWkzf5hQ3stTphEDI2rHG5EzjdLOajBI96xHALSHtbHQ1Yx4PyiYzra1skK00Go2drHWm";

const AddCardModal = ({ addModal, toggleModal }) => {
  const disptach = useDispatch();
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    const loadStripeScript = async () => {
      const stripe = await loadStripe(stripeKeys);
      setStripePromise(stripe);
    };
    loadStripeScript();
  }, []);

  const handleSubmit = async (e, elements, stripe) => {
    // console.log("submitttttt", elements, stripe);
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    const { token, error } = await stripe.createToken(
      elements.getElement(CardNumberElement)
    );
    if (error) {
      return console.error(error.message);
    }

    if (token) {
      let payload = {
        token: token?.id,
      };
      disptach(addNewPaymentMethod(payload))
        .unwrap()
        .then((res) => {
          toggleModal();
          disptach(getAllPaymentCards());
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  return (
    <div>
      <div
        class={`fixed z-10 overflow-y-auto top-0 w-full left-0 ${
          !addModal ? "hidden" : ""
        }`}
        id="modal"
      >
        <div class="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div class="fixed inset-0 transition-opacity">
            <div class="absolute inset-0 bg-gray-900 opacity-75" />
          </div>
          <span class="hidden sm:inline-block sm:align-middle sm:h-screen">
            &#8203;
          </span>
          <div
            class="inline-block py-4 align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <h1 class="text-2xl font-bold text-center my-2">Add New Card</h1>
            {stripePromise && (
              <Elements stripe={stripePromise}>
                <ElementsConsumer>
                  {({ elements, stripe }) => (
                    <form onSubmit={(e) => handleSubmit(e, elements, stripe)}>
                      <div class="bg-white px-4 py-2">
                        <label style={{ fontSize: "14px", marginTop: "10px" }}>
                          Card Number
                        </label>
                        <div
                          style={{
                            border: "1px solid #d1d5db",
                            borderRadius: "5px",
                            padding: "10px",
                          }}
                        >
                          <CardNumberElement
                            required
                            options={{
                              placeholder: "XXXX XXXX XXXX XXXX",
                              style: {
                                base: {
                                  fontSize: "16px",
                                },
                                invalid: {
                                  color: "#9e2146",
                                },
                              },
                            }}
                          />
                        </div>
                        <label style={{ fontSize: "14px", marginTop: "10px" }}>
                          Expiry Date
                        </label>
                        <div
                          style={{
                            border: "1px solid #d1d5db",
                            borderRadius: "5px",
                            padding: "10px",
                          }}
                        >
                          <CardExpiryElement
                            required
                            options={{
                              placeholder: "MM/YY",
                              style: {
                                base: {
                                  fontSize: "16px",
                                },
                                invalid: {
                                  color: "#9e2146",
                                },
                              },
                            }}
                          />
                        </div>
                        <label style={{ fontSize: "14px", marginTop: "10px" }}>
                          CVC/CVV
                        </label>
                        <div
                          style={{
                            border: "1px solid #d1d5db",
                            borderRadius: "5px",
                            padding: "10px",
                          }}
                        >
                          <CardCvcElement
                            required
                            options={{
                              placeholder: "...",
                              style: {
                                base: {
                                  fontSize: "16px",
                                },
                                invalid: {
                                  color: "#9e2146",
                                },
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div class="bg-gray-200 px-4 py-3 mt-3 text-right">
                        <button
                          type="button"
                          class="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2"
                          onClick={toggleModal}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          class="py-2 px-4 bg-blue-500 text-white rounded font-medium hover:bg-blue-700 mr-2 transition duration-500"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  )}
                </ElementsConsumer>
              </Elements>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCardModal;
