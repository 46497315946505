import React, { useState, useEffect, useRef } from "react";
import {
  FaMicrophoneAlt,
  FaMicrophoneAltSlash,
  FaPhone,
  FaVideo,
  FaVideoSlash,
} from "react-icons/fa";
import Dictaphone from "./Dictaphone";
import Loader from "../../../Components/main-loader";
import { stopMachineSpeaks } from "./MachineSpeaks";
import CircularLoader from "../../../Components/loader";
import { LoaderOverlay } from "../../../Components/QuestionList/index.style";
import { useSelector } from "react-redux";

const MeetScreen = ({
  videoStream,
  handleMute,
  handleCamera,
  handleEndCall,
  muted,
  setMuted,
  sessionId,
  jobDescription,
  contactDetails,
}) => {
  const [loading, setLoading] = useState(false);
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const [quickQuit, setQuickQuit] = useState(false);
  const { singleResumeLoading } = useSelector((state) => state.ResumeBuilder);

  const videoRef = useRef(null); // Create a reference for the video element

  // Use useEffect to only set the video stream when it changes
  useEffect(() => {
    if (videoRef.current && videoStream) {
      videoRef.current.srcObject = videoStream;
    }
  }, [videoStream]);

  return (
    <div className="mt-32 p-5">
      {feedbackLoading && (
        <LoaderOverlay>
          <CircularLoader
            title="Nexa is hard at work to analyze your results, give us a minute"
            icon={true}
          />
        </LoaderOverlay>
      )}
      <div className="p-4 my-16 bg-gray-800 rounded-lg relative">
        <div className="flex w-full h-[80vh] justify-between gap-10 items-center relative">
          <div className="w-full h-[90%] rounded-lg overflow-hidden relative">
            {(loading || singleResumeLoading) && <Loader />}
            <video
              autoPlay
              playsInline
              ref={videoRef} // Attach the video element to the ref
              className="w-full h-full object-cover"
              id="video_stream"
              // style={{ transform: "scaleX(-1)" }} // Optional, you can enable this if you want to mirror the video
            ></video>
            <div className="absolute bottom-10 left-1/2 transform -translate-x-1/2 flex space-x-4">
              <button
                onClick={handleMute}
                className="flex items-center justify-center w-12 h-12 bg-gray-900 text-white rounded-full"
              >
                {!muted ? <FaMicrophoneAlt /> : <FaMicrophoneAltSlash />}
              </button>
              <button
                onClick={() => {
                  setQuickQuit(true);
                  stopMachineSpeaks();
                }}
                className="flex items-center justify-center w-14 h-14 bg-red-500 rounded-full text-white shadow-lg"
              >
                <FaPhone />
              </button>
              <button
                onClick={handleCamera}
                className="flex items-center justify-center w-12 h-12 bg-gray-900 text-white rounded-full"
              >
                {videoStream === null ? <FaVideoSlash /> : <FaVideo />}
              </button>
            </div>
          </div>
          <div>
            {!singleResumeLoading && (
              <div>
                <Dictaphone
                  jobDescription={jobDescription}
                  muted={muted}
                  setMuted={setMuted}
                  handleEndCall={handleEndCall}
                  loading={loading}
                  sessionId={sessionId}
                  setLoading={setLoading}
                  feedbackLoading={feedbackLoading}
                  setFeedbackLoading={setFeedbackLoading}
                  quickQuit={quickQuit}
                  setQuickQuit={setQuickQuit}
                  contactDetails={contactDetails}
                  handleCamera={handleCamera}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeetScreen;
