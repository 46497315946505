import Repository from "./Repository";
const GET_CARDS = "/payment/cards";
const ADD_CARD = "/payment/add_card";
const UPDATE_DEFAULT_CARD = "/payment/set_default_card";
const DELETE_CARD = "/payment/delete-card";
const INVOICES = "/payment/invoices";
const GET_DECLINE_ERROR = "payment/subscription_status";
const REACTIVATE_PACKAGE = "es/onboard/customer/retry_payment";

const paymentRepository = {
  getInvoices() {
    return Repository.get(`${INVOICES}`);
  },
  getAllCards() {
    return Repository.get(`${GET_CARDS}`);
  },
  addNewCard(payload) {
    return Repository.post(`${ADD_CARD}`, payload);
  },
  updateDefaultCard(ID) {
    return Repository.put(`${UPDATE_DEFAULT_CARD}?payment_method_id=${ID}`);
  },
  deleteCard(ID) {
    return Repository.delete(`${DELETE_CARD}/${ID}`);
  },
  getDeclinedError() {
    return Repository.get(`${GET_DECLINE_ERROR}`);
  },
  reActivatePackage(payload) {
    return Repository.post(`${REACTIVATE_PACKAGE}?invoice_id=${payload}`);
  },
};

export default paymentRepository;
