import { useState, useEffect, useRef } from "react";
import Header from "../../Components/header";
import MeetScreen from "./Components/MeetScreen";
import { stopMachineSpeaks } from "./Components/MachineSpeaks";
import { useDispatch, useSelector } from "react-redux";
import { getResume } from "../../Redux/ResumeBuilderSlice";
import { error } from "../../Utilities/toast";
import speech, { useSpeechRecognition } from "react-speech-recognition";

const SimulatorPro = () => {
  const canvasRef = useRef(null);
  const dispatch = useDispatch();
  const { allResume } = useSelector((state) => state.ResumeBuilder);
  const [jobDescription, setJobDescription] = useState("");
  const [videoStream, setVideoStream] = useState(null);
  const [audioStream, setAudioStream] = useState(null);
  const [startMeet, setStartMeet] = useState(false);
  const [muted, setMuted] = useState(false);
  const [selectedResumeID, setSelectedResumeID] = useState("");
  const { listening } = useSpeechRecognition();

  useEffect(() => {
    if (startMeet) {
      requestVideo();
      requestAudio();
    }
  }, [startMeet]);

  const handleEndCall = () => {
    if (videoStream) {
      const videoTracks = videoStream.getTracks();
      videoTracks.forEach((track) => track.stop());
      setVideoStream(null);
    }
    if (audioStream) {
      const audioTracks = audioStream.getTracks();
      audioTracks.forEach((track) => track.stop());
      setAudioStream(null);
      setMuted(false);
    }
    setSelectedResumeID("");
    setJobDescription("");
    setStartMeet(false);
    stopMachineSpeaks();
  };

  useEffect(() => {
    if (audioStream && canvasRef.current) {
      const audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
      const analyser = audioContext.createAnalyser();
      const source = audioContext.createMediaStreamSource(audioStream);
      source.connect(analyser);

      analyser.fftSize = 256;
      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      const canvas = canvasRef.current;
      const canvasCtx = canvas.getContext("2d");
      const drawVisualizer = () => {
        requestAnimationFrame(drawVisualizer);
        analyser.getByteFrequencyData(dataArray);

        const width = canvas.width;
        const height = canvas.height;
        const barWidth = 10;
        canvasCtx.clearRect(0, 0, width, height);
        let x = 0;

        dataArray.forEach((item) => {
          const barHeight = (item / 255) * height * 1.1;
          canvasCtx.strokeStyle = `gray`;
          x += barWidth;
          canvasCtx.beginPath();
          canvasCtx.lineCap = "round";
          canvasCtx.lineWidth = 2;
          canvasCtx.moveTo(x, height);
          canvasCtx.lineTo(x, height - barHeight);
          canvasCtx.stroke();
        });
      };

      drawVisualizer();

      return () => {
        audioContext.close();
      };
    }
  }, [audioStream]);

  const requestVideo = () => {
    if (navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          setVideoStream(stream);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const requestAudio = () => {
    if (navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          setAudioStream(stream);
          setMuted(true);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handleMute = () => {
    if (audioStream && listening) {
      audioStream.getTracks().forEach((track) => {
        track.enabled = !track.enabled;
      });
      speech.stopListening();
      setMuted(!muted);
    } else {
      requestAudio();
    }
  };

  const handleSubmitJD = (e) => {
    e.preventDefault();
    if (jobDescription.length > 0 && selectedResumeID) {
      setStartMeet(true);
    } else {
      error("Please enter your Job Description and Select Resume");
    }
  };
  const handleCamera = () => {
    if (videoStream) {
      const videoTracks = videoStream.getTracks();
      videoTracks.forEach((track) => track.stop());
      setVideoStream(null);
    } else {
      requestVideo();
    }
  };

  useEffect(() => {
    dispatch(getResume({ onSuccess: () => {} }));
  }, []);

  return (
    <div>
      <Header />
      <div
        className="ic-interview-full-body"
        style={{ backgroundImage: `url(${"/images/interview-banner.png"})` }}
      >
        {startMeet ? (
          <MeetScreen
            videoStream={videoStream}
            audioStream={audioStream}
            handleMute={handleMute}
            handleCamera={handleCamera}
            handleEndCall={handleEndCall}
            setStartMeet={setStartMeet}
            muted={muted}
            jobDescription={jobDescription}
            selectedResumeID={selectedResumeID}
          />
        ) : (
          <div className="d-flex align-items-center justify-content-center">
            <div className="ic-mockInterview">
              <div className="ic-mock-interview">
                <h4>Tell us about your Job Description</h4>
                <div className="text-area">
                  <textarea
                    placeholder="Type your Job Description..."
                    required
                    value={jobDescription}
                    onChange={(e) => setJobDescription(e.target.value)}
                  />
                </div>
                <div class="relative border border-gray-300 text-gray-800 bg-white shadow-lg">
                  <select
                    class="appearance-none w-full py-2 bg-white rounded leading-tight focus:outline-none focus:shadow-outline"
                    name="whatever"
                    value={selectedResumeID}
                    onChange={(e) => setSelectedResumeID(e.target.value)}
                    id="frm-whatever"
                  >
                    <option value="" className="py-2">
                      Select A Resume&hellip;
                    </option>
                    {allResume?.map(
                      (resume, key) =>
                        resume?.contact !== null && (
                          <option value={resume?.id} key={key} className="py-2">
                            {resume?.contact?.first_name}{" "}
                            {resume?.contact?.last_name}
                          </option>
                        )
                    )}
                  </select>
                  <div class="pointer-events-none absolute right-0 top-0 bottom-0 flex items-center px-2 text-gray-700 border-l">
                    <svg
                      class="h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
                <button onClick={handleSubmitJD} className="ic-btn mt-3">
                  Start Interview <i className="ri-file-list-line"></i>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SimulatorPro;
