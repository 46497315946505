import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../../../Components/main-loader";
import techStacks from "../../../DummyData/techStacks.json";

const TechStacks = ({ developerRole, setTechStack, techStackIcon }) => {
  const { isLoading } = useSelector((state) => state.dashboard);

  useEffect(() => {
    // if (developerRole === "qa-engineer") {
    //   setTechStack("qa-engineer");
    // }
  }, []);

  const getTechStackCard = (item, index) => {
    return (
      <>
        <Link
          onClick={() => {
            setTechStack(item.url_param);
          }}
          key={index}
          to={`/interview/role/developer/${developerRole}`}
          className="ic-interview-items"
          data-aos="fade-up"
          data-aos-delay="50"
          data-aos-duration={index * 200 + 500}
        >
          <div className="ic-icons">
            <img src={techStackIcon} className="img-fluid" alt={item.name} />
          </div>
          <p>{item.name}</p>
          <i
            className="ri-arrow-right-line ic-right-arrow"
            style={{ fontSize: "22px" }}
            size="30"
       
       />
 </Link>
      </>
    );
  };

  return (
    <div className="ic-interviews">
      <div className="container">
        <div className="ic-interview-container">
          <h3 className="text-center text-capitalize">
            {developerRole === "qa-engineer"
              ? "Ready to practice for your QA Automation Engineer Role?"
              : " Which Tech Stack are you interested in practicing?"}
          </h3>
          {isLoading ? (
            <Loader />
          ) : (
            <div className="ic-interview-card-container">
              {techStacks[developerRole]?.tech_stacks
                .sort((a, b) => {
                  if (a.name < b.name) {
                    return -1;
                  }
                  if (a.name > b.name) {
                    return 1;
                  }
                  return 0;
                })
                .map((item, i) => {
                  return getTechStackCard(item, i);
                })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TechStacks;
