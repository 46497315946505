import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { success, error } from "../../Utilities/toast";
import {
  addResumeLanguages,
  getResumeLanguages,
  updateResumeLanguages,
} from "../../Redux/ResumeBuilderSlice";
const LanguagesForm = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { languages } = useSelector((state) => state.ResumeBuilder);
  console.log(languages, "languages>>>>>>>>>>>>>>");
  const [newLanguages, setNewLanguages] = useState([]);
  console.log(newLanguages, "languages>>>>>>>>>>>>>>>>>>>>>>>");
  const [languageId, setlanguageId] = useState("");
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  useEffect(() => {
    if (languages?.languages?.length > 0 || languages?.id) {
      setNewLanguages(languages?.languages);
      setlanguageId(languages?.id);
    }
  }, [languages]);
  const handleKeyDown = (e) => {
    if (e.key !== "Enter") return;
    const value = e.target.value;
    if (!value.trim()) return;
    setNewLanguages([...newLanguages, value]);
    e.target.value = "";
    setShowPlaceholder(false);
  };

  const handleRemoveChip = (index) => {
    setNewLanguages(newLanguages.filter((_, i) => i !== index));
  };
  const fetchInterests = () => {
    const resumeId = id;
    dispatch(getResumeLanguages({ resumeId, onSuccess: () => {} }));
  };
  const update = () => {
    const inputField = document.querySelector(".tags-input");
    const value = inputField.value.trim();

    if (value) {
      setNewLanguages([...newLanguages, value]);
      inputField.value = "";
    }

    if (newLanguages.length === 0 && !value) {
      error("Please add at least one language and press enter to add");
      return;
    }

    const payload = {
      languages: [...newLanguages, value].filter(Boolean), // Ensure the new value is added only once
    };

    if (languageId) {
      dispatch(
        updateResumeLanguages({
          languageId,
          payload,
          onSuccess: (res) => {
            success(res);
            fetchInterests();
          },
        })
      );
    } else {
      const resumeId = id;
      dispatch(
        addResumeLanguages({
          resumeId,
          payload,
          onSuccess: (res) => {
            success(res);
            fetchInterests();
          },
        })
      );
    }
  };

  return (
    <div className="mydetails">
      <div className="row mt-4 pb-4">
        <div className="col-md-12">
          <div
            className="tags-input-container"
            style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
          >
            {newLanguages.length > 0 &&
              newLanguages?.map((chip, index) => (
                <div
                  key={index}
                  className="chip"
                  style={{
                    margin: "5px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {chip}
                  <button
                    className="remove-button"
                    //   style={{ marginLeft: '5px' }}
                    onClick={() => handleRemoveChip(index)}
                  >
                    &times;
                  </button>
                </div>
              ))}
            <input
              type="text"
              className="tags-input"
              onKeyDown={handleKeyDown}
              placeholder={
                newLanguages?.length === 0
                  ? "Type Language and press Enter"
                  : ""
              }
              style={{ flex: "1", margin: "5px", minWidth: "150px" }}
            />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end  py-2 px-2   border-bottom border-primary  ">
        <button
          onClick={() => update()}
          className="save-btn mr-1"
          style={{
            fontSize: "14px",
            fontWeight: "500",
            width: "100px",
            height: "40px",
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default LanguagesForm;
