// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.parent-container-wrapper {
  background-color: #f5f5f5; 
  width: 100%; 
  min-height: 100vh; 
  box-sizing: border-box; 
}
.parent-container {
  display: flex;
  flex-direction: column;
  max-width: 1200px; 
  width: 100%; 
  margin: 0 auto; 
  background-color: #ffffff; 
  border-radius: 10px; 
}
.border-black {
  border-color: black !important;
}
* {
  font-family: "Roobert", -apple-system, BlinkMacSystemFont, "Fira Sans", sans-serif;
}


`, "",{"version":3,"sources":["webpack://./src/Pages/AutoApply/style.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,WAAW;EACX,iBAAiB;EACjB,sBAAsB;AACxB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB;EACjB,WAAW;EACX,cAAc;EACd,yBAAyB;EACzB,mBAAmB;AACrB;AACA;EACE,8BAA8B;AAChC;AACA;EACE,kFAAkF;AACpF","sourcesContent":[".parent-container-wrapper {\n  background-color: #f5f5f5; \n  width: 100%; \n  min-height: 100vh; \n  box-sizing: border-box; \n}\n.parent-container {\n  display: flex;\n  flex-direction: column;\n  max-width: 1200px; \n  width: 100%; \n  margin: 0 auto; \n  background-color: #ffffff; \n  border-radius: 10px; \n}\n.border-black {\n  border-color: black !important;\n}\n* {\n  font-family: \"Roobert\", -apple-system, BlinkMacSystemFont, \"Fira Sans\", sans-serif;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
