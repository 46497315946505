import React, { useEffect } from "react";
import Header from "../../Components/header";
import Chats from "../../Components/chat/Chats";
import { useDispatch, useSelector } from "react-redux";
import { getAllChatAction } from "../../Redux/ChatSlice";
import Loader from "../../Components/main-loader";

const ChatPage = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.chat);

  useEffect(() => {
    dispatch(getAllChatAction());
  }, []);
  return (
    <div>
      <Header />
      {loading && <Loader />}
      <div className="pt-40">
        <Chats />
      </div>
    </div>
  );
};

export default ChatPage;
