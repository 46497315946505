import Select from "react-select";
import { useState, useEffect } from "react";

const options = [
  { value: "FullTime", label: "Full-Time" },
  { value: "PartTime", label: "Part-Time" },
  { value: "Remote", label: "Remote" },
  { value: "Hybrid", label: "Hybrid" },
];

const SelectComponent = ({ onSelect, selectedValue }) => {
  const [selectedOption, setSelectedOption] = useState(null); // Initialize with null

  // Update selected option when selectedValue prop changes
  useEffect(() => {
    setSelectedOption(options.find((option) => option.value === selectedValue));
  }, [selectedValue]);

  const [selectShow, setSelectShow] = useState(false);

  function toggleSelect() {
    setSelectShow(!selectShow);
  }

  const handleSelectedOption = (option) => {
    setSelectedOption(option);
    onSelect(option.value);
    setSelectShow(false);
  };

  return (
    <>
      <div
        className="w-200 position-relative cursor-pointer"
        onClick={toggleSelect}
      >
        <div className="workcontrol d-flex justify-content-between  ">
          <p className="py-2 ">
            {selectedOption ? selectedOption.label : "Select"}
          </p>

          <button
            onClick={toggleSelect}
            className="transparent_button"
            type="button"
          >
            <i
              className={`ri-arrow-${selectShow ? "up" : "down"}-s-line`}
              style={{ fontSize: "26px" }}
            />{" "}
          </button>
        </div>
        {selectShow && (
          <ul className="customized-dropdown">
            {options.map((option) => (
              <li
                key={option.value}
                onClick={() => handleSelectedOption(option)}
              >
                {option.label}
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

export default SelectComponent;
