import { animated, useSpring } from '@react-spring/web';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../Components/header';

const Techstacks = () => {
  const [state, setState] = useState();

  const error = null;
  const uploadFile = () => {};
  const submitPrompt = () => {};
  const messages = [];

  const handleIconChange = (message) => {
    console.log(message);
  };

  const cards = [
    { label: "Frontend", delay: 300, image: "/images/icon.png", link: "/intervieworcustom" },
    { label: "Backend", delay: 500, image: "/images/icon.png", link: "/intervieworcustom" },
    { label: "Fullstack", delay: 700, image: "/images/icon.png", link: "/intervieworcustom" },
    { label: "Mobile", delay: 900, image: "/images/icon.png", link: "/intervieworcustom" },
  ];

  const animationProps = useSpring({
    from: { opacity: 0, transform: 'translateY(20px)' },
    to: { opacity: 1, transform: 'translateY(0px)' },
    config: { duration: 500 },
    delay: 200,
  });

  return (
    <div className="h-screen flex flex-col">
      <Header />
      <div className="flex-1 overflow-hidden">
        <div className="ic-interviews voyce py-0 h-full">
          <div className="container pt-32">
            <h1 className="text-center text-bold text-2xl mb-8">Choose your Area of Focus</h1>
            {messages.length === 0 ? (
              <div className="ic-interview-card-container flex flex-wrap justify-center">
                {cards.map((card, index) => (
                  <animated.div
                    key={index}
                    style={{ ...animationProps, transitionDelay: `${card.delay}ms` }}
                    className="ic-interview-items m-6 p-8 flex flex-col items-center justify-center bg-gray-100 shadow-lg rounded-lg hover:text-white transition-all duration-300 transform hover:scale-105"
                  >
                    <Link to={card.link} onClick={() => handleIconChange(card.label)} className="flex flex-col items-center justify-center w-full h-full">
                      <div className="ic-icons h-32 w-32 flex items-center justify-center mb-4 bg-white rounded-full">
                        <img src={card.image} className="img-fluid h-24 w-24 object-contain" alt={card.label} />
                      </div>
                      <div className="text-center text-lg font-semibold mt-4">{card.label}</div>
                    </Link>
                  </animated.div>
                ))}
              </div>
            ) : (
              <div className="h-[720px] w-full lg:w-[900px] mx-auto">
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Techstacks;
