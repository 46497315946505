import React from "react";
import Header from "../../Components/header";
import TableRow from "./components/TableRow";
import { Link } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";

const Autopilot = () => {
  const rows = [
    { name: "Ahsan", createdAt: "2024-09-25", jobPosition: "React" },
    { name: "codebyahsan", createdAt: "2024-09-20", jobPosition: "Next" },
    { name: "Brian", createdAt: "2024-09-15", jobPosition: "Vue" },
  ];

  return (
    <>
      <Header />
      <div className="relative p-4 mt-14">
        <div className="w-full grid-margin stretch-card my-5">
          <div
            className="card border-rounded-card card-padding-profile2 !overflow-scroll"
            style={{ boxShadow: "10px 10px 40px gray" }}
          >
            <div className="card-body text-center justify-content-between card-body-mobile">
              <div className="flex justify-between items-center w-full mb-3">
                <h2 className="font-bold text-2xl">Your Auto Pilots</h2>
                <Link
                  to="/nexa-autopilot/create-new"
                  className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition duration-500"
                >
                  Create Auto Pilot
                </Link>
              </div>
            </div>
            <div className="mt-1">
              <div className="flex flex-col">
                <div className="overflow-x-auto sm:mx-0.5 lg:mx-0.5">
                  <div className="py-2 inline-block min-w-full">
                    <div className="overflow-hidden">
                      <table className="min-w-full">
                        <thead className="bg-gray-200 border-b">
                          <tr>
                            <th
                              scope="col"
                              className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                            >
                              Name
                            </th>
                            <th
                              scope="col"
                              className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                            >
                              Created At
                            </th>
                            <th
                              scope="col"
                              className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                            >
                              Job Position
                            </th>
                            <th
                              scope="col"
                              className="text-sm font-medium text-gray-900 px-6 py-4 text-center"
                            >
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {rows?.length > 0 ? (
                            rows?.map((item) => {
                              return (
                                <tr className="bg-white  text-capitalize border-b text-start transition duration-300 ease-in-out hover:bg-gray-100">
                                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    {item?.name}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    {item?.createdAt}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                    {item?.jobPosition}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 flex justify-center gap-3">
                                    <button className="w-8 h-8 rounded-full flex justify-center items-center border hover:bg-gray-100 hover:scale-110 transition-all transform duration-200">
                                      <FaEdit />
                                    </button>
                                    <button className="w-8 h-8 rounded-full flex justify-center items-center border hover:bg-gray-100 hover:scale-110 transition-all transform duration-200">
                                      <RiDeleteBin6Line />
                                    </button>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr className="bg-white border-b text-start transition duration-300 ease-in-out hover:bg-gray-100">
                              <td
                                colSpan="6"
                                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center"
                              >
                                No Data Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="overflow-x-auto">
          <table className="min-w-full text-left text-sm font-light">
            <thead className="border-b bg-gray-800 text-white">
              <tr>
                <th scope="col" className="px-6 py-3">
                name
                </th>
                <th scope="col" className="px-6 py-3">
                  Created At
                </th>
                <th scope="col" className="px-6 py-3">
                  Job Position
                </th>
                <th scope="col" className="px-6 py-3 text-center">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {rows.map((row) => (
              <TableRow key={row.id} row={row} />
              ))}
            </tbody>
          </table>
        </div> */}
      </div>
    </>
  );
};

export default Autopilot;
