import OpenAI from "openai";
let currentAudio = null;

export const stopMachineSpeaksGlobal = () => {
  if (currentAudio) {
    currentAudio.pause();
    currentAudio.currentTime = 0;
    currentAudio.src = "";
    currentAudio = null;
  }
};
export default async function textToSpeech(
  text,
  onPlayCallback,
  onProgressCallback,
  onEndCallback
) {
  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true,
  });

  const textChunks = splitTextIntoChunks(text, 160); // Adjust chunk size as needed
  let audioCache = [];

  async function fetchAudioChunk(index) {
    try {
      const response = await openai.audio.speech.create({
        model: "tts-1",
        voice: "alloy",
        input: textChunks[index],
      });

      const blob = new Blob([await response.arrayBuffer()], {
        type: "audio/mp3",
      });
      return window.URL.createObjectURL(blob);
    } catch (err) {
      console.error("Error converting text to speech:", err);
      throw err;
    }
  }

  async function playNextChunk(index = 0) {
    if (index >= textChunks.length) {
      onEndCallback && onEndCallback();
      return;
    }

    // Play current chunk from cache
    const audio = new Audio();
    audio.src = audioCache[index];

    // Preload the next chunk if available
    if (index + 1 < textChunks.length) {
      audioCache[index + 1] = await fetchAudioChunk(index + 1);
    }
    currentAudio = audio; // Set the current audio to the newly created audio element

    audio.play();

    audio.addEventListener("playing", async () => {
      // onPlayCallback && (await onPlayCallback(audio));
      if (onPlayCallback) {
        await onPlayCallback(audio); // Pass the audio object to the callback
        return; // Resolve the promise with the result from onPlayCallback
      }
    });

    audio.addEventListener("timeupdate", async () => {
      const percentage =
        ((index + audio.currentTime / audio.duration) / textChunks.length) *
        100;
      onProgressCallback && (await onProgressCallback(Math.round(percentage)));
    });

    audio.addEventListener("ended", () => {
      URL.revokeObjectURL(audio.src);
      currentAudio = null; // Clear the current audio reference when playback ends
      playNextChunk(index + 1);
    });
  }

  // Preload the first chunk and start playing
  audioCache[0] = await fetchAudioChunk(0);
  playNextChunk();
}

function splitTextIntoChunks(text, chunkSize) {
  const chunks = [];
  let currentIndex = 0;

  while (currentIndex < text.length) {
    let endIndex = currentIndex + chunkSize;
    if (endIndex > text.length) {
      endIndex = text.length;
    }

    // Ensure chunks are split at spaces where possible
    if (text[endIndex] !== " " && endIndex < text.length) {
      const lastSpaceIndex = text.lastIndexOf(" ", endIndex);
      if (lastSpaceIndex > currentIndex) {
        endIndex = lastSpaceIndex;
      }
    }

    chunks.push(text.slice(currentIndex, endIndex).trim());
    currentIndex = endIndex;
  }

  return chunks;
}
