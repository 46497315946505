import React, { useEffect } from "react";
import admin from "../../assets/media/images/person_four.jpg";
import moment from "moment";
import { useSelector } from "react-redux";
const FeedbackMessageComponent = ({ key, feedback }) => {
  const { singleTask, loading } = useSelector((state) => state.tasks);
  useEffect(() => {
    if (feedback) {
      const element = document.getElementById(feedback.created_at);
      if (element) {
        element.parentElement.scrollTo({
          top: element.offsetTop,
          behavior: "smooth",
        });
      }
    }
  }, [feedback]);
  return (
    <div
      id={feedback?.created_at}
      className="bg-white py-2 px-4 rounded-xl my-6"
      key={key + 100}
    >
      <div className="flex my-1 gap-3">
        <div
          className="flex justify-center items-center gap-3"
          style={{ width: "7%" }}
        >
          <img
            src={singleTask?.task_details?.organization_logo || admin}
            alt="Profile Image"
            style={{ width: "60px", height: "60px" }}
            className="rounded-full"
          />
        </div>
        <div
          className="flex font-medium justify-center flex-col"
          style={{ width: "93%" }}
        >
          <div className="flex items-center">
            <h2>{singleTask?.task_details?.organization_name} &nbsp;</h2>{" "}
            <span>
              {" "}
              -&nbsp;{moment(feedback?.created_at).format("hh:mm A")}
            </span>
          </div>
          <div>
            <h2 className="mb-0 text-1xl font-medium mt-0 md:-mt-4 leading-6">
              {feedback?.feedback}
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedbackMessageComponent;
