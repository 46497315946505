import React, { useEffect, useState } from "react";
import Header from "../../Components/header";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineDelete, MdEdit } from "react-icons/md";
import {
  addNewResume,
  deleteResume,
  getResume,
  udpateResumeProfile,
  updateResume,
  // getSingleResume,
} from "../../Redux/ResumeBuilderSlice";
import { error, success } from "../../Utilities/toast";
import { useNavigate } from "react-router-dom";
import Loader from "../../Components/main-loader";
import { addStep } from "../../features/companyinformation/StepsSlice";
import { PlusSignIcon } from "../../Components/Resume/svgimages";
import ReactModal from "react-modal";
import CVminTemplate from "../../Components/Resume/CVMinTemplate"; // Import CVTemplate
import ResumePreview from "../../Components/Resume/ResumePreview";

function Index() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { allResume, isLoading } = useSelector((state) => state.ResumeBuilder);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [resumeState, setResumeState] = useState("");
  const [selectedResumeId, setSelectedResumeId] = useState("");
  const initialState = {
    name: "",
    person_name: "",
    company_name: "",
    job_role: "",
  };
  const [createResume, setCreateResume] = useState(initialState);
  const [currentResume, setCurrentResume] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  // useEffect(() => {
  //   allResume.forEach(async (resume) => {
  //     if (resume.contact) {
  //       try {
  //         const resultAction = await dispatch(
  //           getSingleResume({
  //             resumeId: resume.id,
  //             onSuccess: (data) => {
  //               console.log(`Data for resume ID ${resume.id}:`, data);
  //             },
  //           })
  //         );

  //         if (getSingleResume.rejected.match(resultAction)) {
  //           console.error(
  //             `Failed to fetch data for resume ID ${resume.id}:`,
  //             resultAction.payload
  //           );
  //         }
  //       } catch (error) {
  //         console.error(
  //           `Error fetching data for resume ID ${resume.id}:`,
  //           error.message
  //         );
  //       }
  //     }
  //   });
  // }, [allResume, dispatch]);

  const handleEdit = (resume) => {
    console.log("Editing resume:", resume);
    setCurrentResume(resume);
    setFirstName(resume.contact.first_name);
    setLastName(resume.contact.last_name);
    setModalIsOpen(true);
  };

  const handleSave = async () => {
    console.log("saving ..");
    console.log({ first_name: firstName, last_name: lastName });

    const { id, ...existingContactData } = currentResume.contact;

    const userInfo = {
      contactId: id,
      data: {
        ...existingContactData,
        first_name: firstName,
        last_name: lastName,
      },
    };

    console.log("userInfo :", userInfo);

    try {
      const resultAction = await dispatch(
        udpateResumeProfile({
          userInfo,
          onSuccess: (data) => {
            success("Contact information updated successfully");
            dispatch(getResume({ onSuccess: () => {} }));
            setModalIsOpen(false);
          },
        })
      );

      if (udpateResumeProfile.rejected.match(resultAction)) {
        error(resultAction.payload || "Failed to update contact information");
      }
    } catch (err) {
      error("An unexpected error occurred: " + err.message);
    }
  };

  const handleNewResume = () => {
    dispatch(
      addNewResume({
        createResume,
        onSuccess: (toast, id) => {
          navigate(`/resume-builder/create/${id?.id}`);
        },
        onError: (err) => {
          console.log(err);
          error(err);
        },
      })
    );
  };

  const handleDelete = (resumeId) => {
    dispatch(
      deleteResume({
        resumeId,
        onSuccess: (res) => {
          success(res);
          dispatch(getResume({ onSuccess: () => {} }));
        },
      })
    );
  };
  const handleUpdateResume = () => {
    dispatch(
      updateResume({
        createResume,
        resumeId: selectedResumeId,
        onSuccess: () => {
          setCreateModal(false);
          dispatch(getResume({ onSuccess: () => {} }));
        },
        onError: (err) => {
          console.log(err);
          error(err);
        },
      })
    );
  };
  const handleResumeClick = (id) => {
    setSelectedResumeId(id);
    const selectedResume = allResume.find((resume) => resume.id === id);
    if (selectedResume) {
      setCreateResume({
        name: selectedResume.resume_name,
        person_name: selectedResume?.person_name,
        company_name: selectedResume?.company_name,
        job_role: selectedResume?.job_role,
      });
      setResumeState("edit");
      setCreateModal(true);
    }
  };
  useEffect(() => {
    dispatch(addStep(1));
  }, [dispatch]);
  useEffect(() => {
    dispatch(getResume({ onSuccess: () => {} }));
  }, []);
  return (
    <>
      <Header />
      {isLoading && <Loader />}
      <div
        className="ic-interview-full-body overflow-y-scroll"
        style={{
          backgroundImage: `url(${"/images/background.svg"})`,
          paddingTop: "6em",
        }}
      >
        <main
          role="main"
          className="ml-sm-auto col-lg-12 px-4 bg-cover"
          style={{
            backgroundImage: `url(${"/images/background.svg"})`,
          }}
        >
          <div className="content pb-5 pt-14">
            <div className="career_slide-cover mt-10 md:!mt-2">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "50px",
                }}
              >
                <p
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "24px",
                    fontWeight: "700",
                    lineHeight: "30px",
                    textAlign: "center",
                  }}
                >
                  Nexa Resume
                </p>
                <button
                  onClick={() => {
                    const filteredResumes = allResume.filter(
                      (resume) => resume.contact === null
                    );

                    if (filteredResumes.length === 0) {
                      setCreateResume(initialState);
                      setCreateModal(true);
                      setResumeState("add");
                    } else {
                      const resumeFilterId = filteredResumes[0].id;
                      navigate(`/resume-builder/view/${resumeFilterId}`);
                    }
                  }}
                  style={{
                    backgroundColor: "#2B6BE2",
                    color: "white",
                    borderRadius: "8px",
                    padding: "6px 25px",
                    border: "none",
                    cursor: "pointer",
                    fontWeight: "400",
                    fontSize: "14px",
                    display: "flex",
                    gap: "8px",
                    alignItems: "center",
                    justifyContent: "center",
                    fontFamily: "Inter",
                  }}
                >
                  <PlusSignIcon />
                  Create Resume
                </button>
              </div>
            </div>
          </div>
          <div className="w-full flex flex-wrap gap-6 pb-14">
            {(allResume?.length < 1 ||
              (allResume?.length === 1 && !allResume?.[0]?.contact)) && (
              <h2 className="no-resume">No Resume Found</h2>
            )}
            {allResume?.map(
              (item, index) =>
                item?.contact !== null && (
                  <ResumePreview
                    item={item}
                    handleResumeClick={(id) => handleResumeClick(id)}
                    handleDelete={(id) => handleDelete(id)}
                  />
                )
            )}
          </div>
          <ReactModal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
              content: {
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
                padding: "20px",
                maxWidth: "500px",
                width: "90%",
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              },
            }}
          >
            <h2>Edit Resume</h2>
            <div className="modal-body">
              <div className="modal-row">
                <label className="modal-label">
                  <span>First Name:</span>
                  <input
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    className="modal-input"
                  />
                </label>
                <label className="modal-label">
                  <span>Last Name:</span>
                  <input
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    className="modal-input"
                  />
                </label>
              </div>
            </div>
            <div className="modal-footer">
              <button onClick={handleSave} className="modal-button save-button">
                Save
              </button>
              <button
                onClick={() => setModalIsOpen(false)}
                className="modal-button cancel-button"
              >
                Cancel
              </button>
            </div>
          </ReactModal>
          <ReactModal
            isOpen={createModal}
            onRequestClose={() => setCreateModal(false)}
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
              content: {
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
                padding: "20px",
                maxWidth: "500px",
                width: "90%",
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              },
            }}
          >
            <h2>{resumeState === "edit" ? "Edit" : "Start"} Resume</h2>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (resumeState === "add") {
                  handleNewResume();
                } else {
                  handleUpdateResume();
                }
              }}
            >
              <div className="modal-body">
                <div className="modal-row">
                  {" "}
                  <label className="modal-label">
                    <div className="flex">
                      <span>Resume Name</span>
                      <span className="text-red-600">*</span>
                    </div>
                    <input
                      type="text"
                      value={createResume?.name}
                      onChange={(e) =>
                        setCreateResume((prev) => ({
                          ...prev,
                          name: e.target.value,
                        }))
                      }
                      required
                      className="modal-input"
                    />
                  </label>
                  <label className="modal-label">
                    <span>Your Name:</span>
                    <input
                      type="text"
                      value={createResume?.person_name}
                      onChange={(e) =>
                        setCreateResume((prev) => ({
                          ...prev,
                          person_name: e.target.value,
                        }))
                      }
                      className="modal-input"
                    />
                  </label>
                </div>
                <div className="modal-row">
                  <label className="modal-label">
                    <span>Company Name:</span>
                    <input
                      type="text"
                      value={createResume?.company_name}
                      onChange={(e) =>
                        setCreateResume((prev) => ({
                          ...prev,
                          company_name: e.target.value,
                        }))
                      }
                      className="modal-input"
                    />
                  </label>
                  <label className="modal-label">
                    <div className="flex">
                      <span>job Role</span>
                      <span className="text-red-600">*</span>
                    </div>
                    <input
                      type="text"
                      value={createResume?.job_role}
                      onChange={(e) =>
                        setCreateResume((prev) => ({
                          ...prev,
                          job_role: e.target.value,
                        }))
                      }
                      required
                      className="modal-input"
                    />
                  </label>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="modal-button save-button">
                    {resumeState === "edit" ? "Save" : "Start"}
                  </button>
                  <button
                    type="button"
                    onClick={() => setCreateModal(false)}
                    className="modal-button cancel-button"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </ReactModal>
        </main>
      </div>
    </>
  );
}

export default Index;
