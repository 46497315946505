import React from "react";

const HeroSection = () => {
  return (
    <div className="aboutUs__hero-section-container">
      <div className="container">
        <div className="aboutUs__hero-section-content">
          <h1 className="aboutUs__hero-section-heading section-heading">
            INTRODUCTION
          </h1>
          <p className="aboutUs__hero-section-description">
            At Nexa, we believe in more than just providing top-tier career
            services; we’re committed to sparking a worldwide movement that
            reshapes the landscape of career development, mentorship, and
            lifelong learning. “Empower to Elevate” isn’t just our motto—it’s
            our mission to create a future where every individual has the tools
            and community support to reach their highest professional potential.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
