import Repository from "./Repository";

const TicketRepository = {
  addNewTicket(payload) {
    return Repository.post(`/ticket/create`, payload);
  },
  getAllTickets() {
    return Repository.get(`/ticket/read?sort_by=id_desc`);
  },
  deleteTicket(payload) {
    return Repository.delete(`/ticket/delete/${payload}`);
  },
  getSingleTicket(payload) {
    return Repository.get(`/ticket/get/${payload}`);
  },
  udpateTicket(payload, id) {
    return Repository.put(`/ticket/update/${id}`, payload);
  },
};

export default TicketRepository;
