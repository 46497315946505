import { useEffect } from "react";
import Navbar from "./Navbar";

import { Routes, Route, Outlet } from "react-router-dom";
import DashboardHome from "./dashboardHome";
import Sidebar from "./Sidebar";
import UsersComponent from "./Users";
import ReportingComponent from "./Reporting";
import AnalyticsComponent from "./Analytics";
// import Login from "../Login";


const Dashboard = () => {
    useEffect(() => {
        // Load dashboard JavaScript assets dynamically
        const script1 = document.createElement('script');
        script1.src = '/dashboard/assets/vendors/js/vendor.bundle.base.js';
        document.head.appendChild(script1);

        const link = document.createElement('link');
        link.href = '/dashboard/assets/css/dashboard.css';
        link.rel = 'stylesheet';
        document.head.appendChild(link);
        // Remove the dynamically injected scripts when the component unmountdf
        return () => {
            document.head.removeChild(script1);
        };
    }, []);
    return (


        <>

            <link rel="stylesheet" href="/dashboard/assets/vendors/mdi/css/materialdesignicons.min.css" />

            <link rel="stylesheet" href="assets/css/style.css" />
            <div className="container-scroller dashboard">
                {/* <!-- partial:partials/_navbar.html --> */}
                <Navbar></Navbar>
                {/* <!-- partial --> */}


                <div className="container-fluid page-body-wrapper" style={{ paddingLeft: '15%', paddingRight: '0' }}>
                    {/* <!-- partial:partials/_sidebar.html --> */}
                    <Sidebar></Sidebar>
                    {/* <!-- partial --> */}
                    <div className="main-panel" style={{ width: '100%' }}>

                        <Routes>
                            <Route path="/home" element={<DashboardHome />} />
                            <Route path="/**" element={<DashboardHome />} />
                            <Route path="users" element={<UsersComponent />} />
                            <Route path="reporting" element={<ReportingComponent />} />
                            <Route path="analytics" element={<AnalyticsComponent />} />

                            {/* <Route path="start-interview" element={<DashboardHome2 />} /> */}

                        </Routes>

                        {/* <!-- partial --> */}
                    </div>

                    {/* <!-- main-panel ends --> */}
                </div>





                {/* <!-- page-body-wrapper ends --> */}
            </div>
            {/* <!-- container-scroller -->
    <!-- plugins:js --> */}

            {/* <!-- End custom js for this page --> */}




        </>
    )
}

export default Dashboard;
