import React from "react";
import { useNavigate } from "react-router-dom";
import fourteen from "../../assets/media/Landing/14dayimg.png";
import dots from "../../assets/media/Landing/dots.svg";
import featuremain from "../../assets/media/Landing/featuremain.svg";
import featurecardbg from "../../assets/media/Landing/featurescards.svg";
import interview from "../../assets/media/Landing/interview.gif";
import ipad from "../../assets/media/Landing/ipad.svg";
import lines from "../../assets/media/Landing/lines.svg";
import mobile from "../../assets/media/Landing/mobile.svg";
import realopsimg from "../../assets/media/Landing/realops.png";
import scanner from "../../assets/media/Landing/scanner.gif";

const FeaturesSection = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="section text-center">
        <div className="gradient-right"></div>
        <h1 className="section-heading color-dark">
          Focus ON Training, LET NEXA HELP WITH THE CAREER STUFF.{" "}
        </h1>
        <div className="container">
          <div className="feature-card row flex-column-reverse flex-md-row align-items-center justify-content-between my-5 py-5">
            <div className="col col-10 col-md-5 card-images position-relative">
              <img
                src={fourteen}
                className="card-main-img"
                style={{ width: "120%", maxWidth: "150%" }}
              />
            </div>

            <div className="col col-10 col-md-5 mb-4 mb-md-0 col-md-5 feature-card-content">
              <h1 className="feature-card-heading">
                An All-in-One Platform for the Entire Career Center Workflow
              </h1>
              <p className="feature-card-subheading">
                At Nexa, we understand the challenges that come with managing
                multiple tools for career advisors, job seekers, and employers.
                The frustration of juggling different platforms can be
                overwhelming, not to mention costly and damaging to your brand’s
                integrity. Nexa brings everything you need into one cohesive,
                easy-to-use platform. Whether you’re handling student
                engagement, job placements, employer partnerships, or internal
                operations, Nexa simplifies the entire process, allowing you to
                focus on what truly matters—helping your students succeed. Say
                goodbye to the headaches of disjointed tools and experience the
                seamless efficiency of Nexa.
              </p>
              <button
                className="feature-card-btn mt-4"
                onClick={() =>
                  (window.location.href = "https://tally.so/r/3yDKdg")
                }
              >
                Learn more
              </button>
            </div>
          </div>
          <div className="feature-card row flex-column flex-md-row align-items-center justify-content-between my-5 py-5">
            <div className="col col-12 col-md-6 feature-card-content">
              <h1 className="feature-card-heading">
                STREAMLINE OPERATIONS & INCREASE YOUR ORGANIZATION'S EFFICIENCY
              </h1>
              <p className="feature-card-subheading">
                Enhance your organization's efficiency with Nexa. Automate
                administrative tasks, optimize career services, and provide
                personalized support, ensuring your students’ success while
                saving time and resources. Put your students at the center with
                streamlined operations designed for maximum impact.
              </p>
              <button
                className="feature-card-btn mt-4"
                onClick={() => navigate("/start-interview")}
              >
                Learn more
              </button>
            </div>
            <div className="col col-10 col-md-5 card-images position-relative">
              <img src={realopsimg} className="card-main-img" />
            </div>
          </div>

          <div className="feature-card row flex-column-reverse flex-md-row align-items-center justify-content-between my-5 py-5">
            <div className="col col-10 col-md-5 card-images position-relative">
              <img src={dots} className=" ipad-dots" />
              <img src={ipad} className="card-main-img" />
            </div>
            <div className="col col-12 col-md-6 feature-card-content">
              <h1 className="feature-card-heading">
                SEAMLESS ANALYTICS FOR ORGANIZATIONS
              </h1>
              <p className="feature-card-subheading">
                Enhance your educational programs or corporate training with
                Nexa’s comprehensive analytics dashboard. Track user progress,
                measure success rates, and gain valuable insights into job
                placement effectiveness.
              </p>
              <button
                className="feature-card-btn mt-4"
                onClick={() => navigate("/start-interview")}
              >
                Learn more
              </button>
            </div>
          </div>
          <div className="features-main-card my-4">
            <div className="main-card-content">
              <h1 className="main-card-heading">
                LIGHTNING-FAST RESUME BUILDS
              </h1>
              <p className="main-card-subheading">
                Create polished, professional resumes in seconds with Nexa’s
                AI-powered Resume Builder. Tailored to specific job
                descriptions, our tool ensures your resume stands out to
                recruiters and passes ATS filters with ease.
              </p>
            </div>
            <div className="d-flex justify-content-center justify-content-lg-end position-relative">
              <img src={featuremain} className="main-card-img" />
              <img src={scanner} className="main-card-scanner" />
            </div>
            <button
              className="feature-btn"
              onClick={() => navigate("/start-interview")}
            >
              Learn more
            </button>
          </div>

          {/* Features Cards */}
          {/* 1st Card */}
          <div className="feature-card row flex-column-reverse flex-md-row align-items-center justify-content-between my-5 py-5">
            <div className="col col-12 col-md-6 card-images position-relative">
              <img src={featurecardbg} className="card-img" />
              <img src={interview} className="feature-interview" />
            </div>
            <div className="col col-10 col-md-5 mb-4 mb-md-0 col-md-5 feature-card-content">
              <h1 className="feature-card-heading">
                LIFELIKE INTERVIEW SIMULATIONS
              </h1>
              <p className="feature-card-subheading">
                Prepare for any interview scenario with Nexa’s realistic
                AI-driven simulations. Receive real-time feedback on your
                answers, body language, and engagement to refine your approach
                and boost your confidence
              </p>
              <button
                className="feature-card-btn mt-4"
                onClick={() => navigate("/start-interview")}
              >
                Learn more
              </button>
            </div>
          </div>
          {/* 2nd Card */}
          <div className="feature-card row flex-column flex-md-row align-items-center justify-content-between my-5 pb-5">
            <div className="col col-12 col-md-6 feature-card-content">
              <img src={lines} className="lines_img" />
              <img src={dots} className="dots_img" />
              <h1 className="feature-card-heading">AI CAREER COUNSELING</h1>
              <p className="feature-card-subheading">
                Navigate your career path with guidance from Nexa Voyce, your
                personal AI career counselor. From exploring new career options
                to identifying skills gaps, Nexa Voyce provides personalized
                advice every step of the way.
              </p>
              <button
                className="feature-card-btn mt-4"
                onClick={() => navigate("/start-interview")}
              >
                Learn more
              </button>
            </div>

            <div className="col col-10 col-md-5 card-images position-relative">
              <img src={mobile} className="card-main-img" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeaturesSection;
