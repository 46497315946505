import React, { useEffect, useState } from "react";
import Header from "../../Components/header";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineDelete, MdEdit } from "react-icons/md";
import {
  addNewResume,
  deleteResume,
  getResume,
  getResumeFeedback,
  udpateResumeProfile,
  updateResume,
  uploadExistingResume,
  // getSingleResume,
} from "../../Redux/ResumeBuilderSlice";
import { error, success } from "../../Utilities/toast";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../Components/main-loader";
import { addStep } from "../../features/companyinformation/StepsSlice";
import { PlusSignIcon } from "../../Components/Resume/svgimages";
import ReactModal from "react-modal";
import CVminTemplate from "../../Components/Resume/CVMinTemplate"; // Import CVTemplate
import ResumePreview from "../../Components/Resume/ResumePreview";
import Modal from "../../Components/modal";
import { FaUpload } from "react-icons/fa";

function Index() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { allResume, isLoading } = useSelector((state) => state.ResumeBuilder);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [resumeState, setResumeState] = useState("");
  const [selectedResumeId, setSelectedResumeId] = useState("");
  const [isModal, setIsModal] = useState(false);
  const initialState = {
    name: "",
    person_name: "",
    company_name: "",
    job_role: "",
  };
  const [createResume, setCreateResume] = useState(initialState);
  const [selectedResume, setSelectedResume] = useState(null);
  const [currentResume, setCurrentResume] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [storeResumeFeedback, setStoreResumeFeedback] = useState(null);
  const [resumeType, setResumeType] = useState("");

  const location = useLocation();
  console.log("location: ", location);

  const handleEdit = (resume) => {
    console.log("Editing resume:", resume);
    setCurrentResume(resume);
    setFirstName(resume.contact.first_name);
    setLastName(resume.contact.last_name);
    setModalIsOpen(true);
  };

  const handleSave = async () => {
    console.log("saving ..");
    console.log({ first_name: firstName, last_name: lastName });

    const { id, ...existingContactData } = currentResume.contact;

    const userInfo = {
      contactId: id,
      data: {
        ...existingContactData,
        first_name: firstName,
        last_name: lastName,
      },
    };

    console.log("userInfo :", userInfo);

    try {
      const resultAction = await dispatch(
        udpateResumeProfile({
          userInfo,
          onSuccess: (data) => {
            success("Contact information updated successfully");
            dispatch(getResume({ onSuccess: () => {} }));
            setModalIsOpen(false);
          },
        })
      );

      if (udpateResumeProfile.rejected.match(resultAction)) {
        error(resultAction.payload || "Failed to update contact information");
      }
    } catch (err) {
      error("An unexpected error occurred: " + err.message);
    }
  };

  const handleCloseModal = () => {
    setIsModal(false);
  };

  const getModal = () => {
    const header = (
      <div className="mockInterview-modal-header">
        <button className="modal-close" onClick={() => handleCloseModal()}>
          <i className="ri-close-line"></i>
        </button>
      </div>
    );

    const content = (
      <div className="row">
        <div className="col-12 order-md-1 order-2">
          <div className="cardBody">
            <h5 className="mx-3 my-3 text-primary">Feedback:</h5>
            <hr />
            <h6 className="mx-3 my-3 text-primary">
              {storeResumeFeedback?.feedback}
            </h6>
          </div>
        </div>
      </div>
    );

    const footer = (
      <div className="footer_scrore d-flex">
        <h5 className="my-0">Nexa Feedback Score:</h5>
        <span className="score">{storeResumeFeedback?.score}.0</span>
      </div>
    );

    return (
      <Modal show={isModal} header={header} content={content} footer={footer} />
    );
  };

  const handleNewResume = () => {
    if (resumeType === "new") {
      dispatch(
        addNewResume({
          createResume,
          onSuccess: (toast, id) => {
            navigate(`/resume-builder/create/${id?.id}`);
          },
          onError: (err) => {
            // console.log("...", err);
            error("Quota Limit exhausted, please upgrade the plan");
          },
        })
      );
    } else {
      let payload = new FormData();

      if (selectedResume) {
        payload.append("file", selectedResume);
      } else {
        error("Please upload your existing Resume PDF file");
        return;
      }
      Object.entries(createResume).forEach(([key, val]) => {
        payload.append(key, val);
      });

      dispatch(
        uploadExistingResume({
          payload,
          onSuccess: () => {
            setCreateModal(false);
            setResumeType("");
          },
        })
      );
    }
  };

  const handleDelete = (resumeId) => {
    dispatch(
      deleteResume({
        resumeId,
        onSuccess: (res) => {
          success(res);
          dispatch(getResume({ onSuccess: () => {} }));
        },
      })
    );
  };

  const handleGetFeedback = (id) => {
    dispatch(
      getResumeFeedback({
        id,
        onSuccess: (res) => {
          setIsModal(true);
          setStoreResumeFeedback(res?.data);
          // fixed_feedback = res?.data["feedback"]
          //   .replace("'", '"')
          //   .replace('"The candidate', '"The candidate');
          // parsed_feedback = json.loads(fixed_feedback);
          console.log(res?.data, "responsebyahsan");
        },
      })
    );
  };
  const handleUpdateResume = () => {
    dispatch(
      updateResume({
        createResume,
        resumeId: selectedResumeId,
        onSuccess: () => {
          success("Resume updated successfully");
          setCreateModal(false);
          setResumeType("");
          dispatch(getResume({ onSuccess: () => {} }));
        },
        onError: (err) => {
          console.log(err);
          error(err);
        },
      })
    );
  };
  const handleResumeClick = (id) => {
    setSelectedResumeId(id);
    const selectedResume = allResume.find((resume) => resume.id === id);
    if (selectedResume) {
      setCreateResume({
        name: selectedResume.resume_name,
        person_name: selectedResume?.person_name,
        company_name: selectedResume?.company_name,
        job_role: selectedResume?.job_role,
      });
      setResumeState("edit");
      setResumeType("existing");
      setCreateModal(true);
    }
  };
  const groupedResumes = allResume?.reduce((acc, resume) => {
    const { parent_resume_id, contact } = resume;
    if (contact !== null) {
      if (!acc[parent_resume_id]) {
        acc[parent_resume_id] = [];
      }
      acc[parent_resume_id].push(resume);
    }
    return acc;
  }, {});

  useEffect(() => {
    dispatch(addStep(1));
  }, [dispatch]);
  useEffect(() => {
    dispatch(getResume({ onSuccess: () => {} }));
  }, []);
  return (
    <>
      <Header />
      {isLoading && <Loader />}
      <div
        className="ic-interview-full-body overflow-y-scroll"
        style={{
          backgroundImage: `url(${"/images/background.svg"})`,
          paddingTop: "6em",
        }}
      >
        <main
          role="main"
          className="ml-sm-auto col-lg-12 px-4 bg-cover"
          style={{
            backgroundImage: `url(${"/images/background.svg"})`,
          }}
        >
          <div className="content pb-5 pt-14">
            <div className="career_slide-cover mt-10 md:!mt-2">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "50px",
                }}
              >
                <p
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "24px",
                    fontWeight: "700",
                    lineHeight: "30px",
                    textAlign: "center",
                  }}
                >
                  Nexa Resume
                </p>
                {location.pathname !== "/resume" && (
                  <div className="flex items-center justify-end gap-3">
                    <button
                      onClick={() => {
                        const filteredResumes = allResume.filter(
                          (resume) => resume.contact === null
                        );

                        if (filteredResumes.length === 0) {
                          setCreateResume(initialState);
                          setCreateModal(true);
                          setResumeType("new");
                          setResumeState("add");
                        } else {
                          const resumeFilterId = filteredResumes[0].id;
                          navigate(`/resume-builder/view/${resumeFilterId}`);
                        }
                      }}
                      className="bg-[#2B6BE2] text-white rounded-lg py-2 px-6 border-none text-sm flex items-center gap-2"
                    >
                      <PlusSignIcon />
                      Create New
                    </button>
                    {/* <button
                      onClick={() => {
                        setCreateResume(initialState);
                        setCreateModal(true);
                        setResumeState("add");
                        setResumeType("existing");
                      }}
                      className="bg-[#2B6BE2] text-white rounded-lg py-2 px-6 border-none text-sm flex items-center gap-2"
                    >
                      <FaUpload />
                      Upload Existing
                    </button> */}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="w-full pb-14">
            {(allResume?.length < 1 ||
              (allResume?.length === 1 && !allResume?.[0]?.contact)) && (
              <h2 className="no-resume">No Resume Found</h2>
            )}
            {Object.entries(groupedResumes)?.map(
              ([parent_resume_id, resumes]) => (
                <div key={parent_resume_id} className="w-full mb-4">
                  {resumes
                    ?.filter((item) => !item?.is_copy && item?.contact)
                    .map((item) => (
                      <h2
                        key={item.id}
                        className="text-base underline text-capitalize text-slate-500	"
                      >
                        {item?.job_role}
                      </h2>
                    ))}
                  <div className="flex flex-wrap gap-4 justify-start mt-2">
                    {resumes
                      .filter((item) => item?.contact !== null)
                      .map((item) => (
                        <ResumePreview
                          key={item.id}
                          item={item}
                          handleResumeClick={(id) => handleResumeClick(id)}
                          handleDelete={(id) => handleDelete(id)}
                          handleGetFeedback={handleGetFeedback}
                        />
                      ))}
                  </div>
                  <hr className="my-4 border-2 border-blue-500 " />
                </div>
              )
            )}
          </div>
          <ReactModal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
              content: {
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
                padding: "20px",
                maxWidth: "500px",
                width: "90%",
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              },
            }}
          >
            <h2>Edit Resume</h2>
            <div className="modal-body">
              <div className="modal-row">
                <label className="modal-label">
                  <span>First Name:</span>
                  <input
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    className="modal-input"
                  />
                </label>
                <label className="modal-label">
                  <span>Last Name:</span>
                  <input
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    className="modal-input"
                  />
                </label>
              </div>
            </div>
            <div className="modal-footer">
              <button onClick={handleSave} className="modal-button save-button">
                Save
              </button>
              <button
                onClick={() => setModalIsOpen(false)}
                className="modal-button cancel-button"
              >
                Cancel
              </button>
            </div>
          </ReactModal>
          <ReactModal
            isOpen={createModal}
            onRequestClose={() => {
              setResumeType("");
              setCreateModal(false);
            }}
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              },
              content: {
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
                padding: "20px",
                maxWidth: "500px",
                width: "90%",
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              },
            }}
          >
            <h2>{resumeState === "edit" ? "Edit" : "Start"} Resume</h2>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (resumeState === "add") {
                  handleNewResume();
                } else {
                  handleUpdateResume();
                }
              }}
            >
              <div className="modal-body">
                <div className="modal-row w-full">
                  {" "}
                  <label className="modal-label">
                    <div className="flex">
                      <span>Resume Name</span>
                      <span className="text-red-600">*</span>
                    </div>
                    <input
                      type="text"
                      value={createResume?.name}
                      onChange={(e) =>
                        setCreateResume((prev) => ({
                          ...prev,
                          name: e.target.value,
                        }))
                      }
                      required
                      className="modal-input"
                    />
                  </label>
                  <label className="modal-label">
                    <span>Your Name:</span>
                    <input
                      type="text"
                      value={createResume?.person_name}
                      onChange={(e) =>
                        setCreateResume((prev) => ({
                          ...prev,
                          person_name: e.target.value,
                        }))
                      }
                      className="modal-input"
                    />
                  </label>
                </div>
                <div className="modal-row w-full">
                  <label className="modal-label">
                    <span>Company Name:</span>
                    <input
                      type="text"
                      value={createResume?.company_name}
                      onChange={(e) =>
                        setCreateResume((prev) => ({
                          ...prev,
                          company_name: e.target.value,
                        }))
                      }
                      className="modal-input"
                    />
                  </label>
                  <label className="modal-label">
                    <div className="flex">
                      <span>Job Role</span>
                      <span className="text-red-600">*</span>
                    </div>
                    <input
                      type="text"
                      value={createResume?.job_role}
                      onChange={(e) =>
                        setCreateResume((prev) => ({
                          ...prev,
                          job_role: e.target.value,
                        }))
                      }
                      required
                      className="modal-input"
                    />
                  </label>
                </div>
                {resumeType === "existing" && (
                  <label className="modal-label">
                    <div className="flex">
                      <span>Upload Resume</span>
                      <span className="text-red-600">*</span>
                    </div>
                    <input
                      type="file"
                      accept="application/pdf"
                      onChange={(e) => setSelectedResume(e.target.files[0])}
                      required
                      className="modal-input !border-none"
                    />
                  </label>
                )}
                <div className="modal-footer w-full justify-center pb-0">
                  <button type="submit" className="modal-button save-button">
                    {resumeState === "edit"
                      ? "Save"
                      : resumeType === "new"
                      ? "Start"
                      : "Upload"}
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setCreateModal(false);
                      setResumeType("");
                    }}
                    className="modal-button cancel-button"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </ReactModal>
        </main>
      </div>
      {getModal()}
    </>
  );
}

export default Index;
