import React, { useEffect, useState } from "react";
import dummy from "../../assets/media/images/person_three.jpg";
import { useSelector } from "react-redux";
import moment from "moment";
import MessageComponent from "../MessageComponent";

const TaskChatSection = ({ setqueryLength }) => {
  const { singleTask, loading } = useSelector((state) => state.tasks);
  const [modifiedComments, setModifiedComments] = useState([]);

  useEffect(() => {
    setqueryLength(singleTask?.comments?.length);
    const restructuredData = singleTask?.comments?.reduce((acc, comment) => {
      const messageDate = moment(comment?.created_at).format("MM/DD/YYYY");
      console.log(messageDate, "messageDate");
      const existingDateIndex = acc?.findIndex(
        (item) => item.date === messageDate
      );
      if (existingDateIndex !== -1) {
        acc[existingDateIndex]?.comments.push(comment);
      } else {
        acc.push({
          date: messageDate,
          comments: [comment],
        });
      }
      return acc;
    }, []);
    setModifiedComments(restructuredData);
    console.log(restructuredData, "restructuredData");
  }, [singleTask]);

  return (
    <div style={{ maxHeight: "70vh", overflow: "auto" }}>
      {singleTask?.comments?.length <= 0 && (
        <div className="text-center my-6">
          <h2 className="text-2xl font-semibold">No Comments Found</h2>
        </div>
      )}
      {modifiedComments?.map((item, index) => (
        <>
          <div class="d-flex justify-content-center sticky-top" key={index}>
            <div class="border bg-white border-black text-black border-2 font-weight-bold rounded-lg p-2 px-4">
              {moment(item?.date).format("DD-MM-YYYY")}
            </div>
          </div>
          {item?.comments?.map((comment, index) => (
            <MessageComponent
              key={index}
              comment={comment}
            />
          ))}
        </>
      ))}
    </div>
  );
};

export default TaskChatSection;
