import Repository from "./Repository";

const GET_PROMPT_BY_ID = "/admin/prompt";
const SIMULATOR_PRO_BEGIN = "/dashboard/interview-simulator-pro/begin";
const SAVE_QUESTIONS = "/dashboard/interview-simulator-pro/save-questions";

const SimulatorProRepository = {
  getPromptById(id) {
    return Repository.get(`${GET_PROMPT_BY_ID}/${id}`);
  },
  simulatorProBegin(payload) {
    return Repository.post(`${SIMULATOR_PRO_BEGIN}`, payload);
  },
  simulatorProSave(payload) {
    return Repository.post(`${SAVE_QUESTIONS}`, payload);
  },
};

export default SimulatorProRepository;
