import React from "react";

const SkillsButton = ({ children, onClick }) => {
  return (
    <button className="skill-button" onClick={onClick}>
      {children}
    </button>
  );
};

export default SkillsButton;
