import React, { useContext } from "react";
import backIcon from "../assets/icons/backIcon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setInterviewSession, setinterviewMode } from "../Redux/GlobalStates";
import { MobileSidebarContext } from "../context/mobilesidemenuContext";

const BackButton = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { interViewMode, previousInterviewMode, interviewSession } =
    useSelector((state) => state.globalState);

  const { isMobileSidebarActive } = useContext(MobileSidebarContext);

  const isInterviewRolePath = /^\/interview\/role\/[^/]+\/[^/]+$/.test(
    location.pathname
  );

  const handleGoBack = () => {
    if (isInterviewRolePath && previousInterviewMode !== interViewMode) {
      dispatch(setinterviewMode(previousInterviewMode));
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <img
        src={backIcon}
        className={`back_button`}
        style={isMobileSidebarActive ? { display: "none" } : {}}
        onClick={() => {
          if (interviewSession) {
            const confirmLeave = window.confirm(
              "You have an ongoing interview session. Are you sure you want to leave?"
            );
            if (!confirmLeave) {
              return;
            } else {
              handleGoBack();
              dispatch(setInterviewSession(false));
            }
          } else {
            handleGoBack();
          }
        }}
      />
      {/* {!isInterviewRolePath && (
      )} */}
    </>
  );
};

export default BackButton;
