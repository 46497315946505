import React, { useEffect, useRef, useState } from "react";
import Header from "../../Components/header";
import Modal from "../../Components/modal";
import { error } from "../../Utilities/toast";
import { useDispatch, useSelector } from "react-redux";
import Interview from "./components/interview";
import Loader from "../../Components/main-loader";
import { Link, useNavigate } from "react-router-dom";
import { getSimulationsAction } from "../../Redux/DashboardSlice";
import { getResume } from "../../Redux/ResumeBuilderSlice";
import { stopMachineSpeaksGlobal } from "../../Utilities/textToSpeech";
import { toast } from "react-toastify";

const InterviewSimulation = () => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.dashboard);
  const { allResume } = useSelector((state) => state.ResumeBuilder);
  const { user } = useSelector((state) => state.authenticate);
  const [openDetails, setOpenDetails] = useState("upload");
  const [isOpenAccordion, setIsOpenAccordion] = useState("upload");
  const [isQuestionShow, setIsQuestionShow] = useState(true);
  const [isRole, setIsRole] = useState(false);
  const [step, setStep] = useState(1);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [description, setDescription] = useState("");
  const [isModal, setIsModal] = useState(false);
  const [isFirstQ, setIsFirstQ] = useState(false);
  const [loading, setLoading] = useState(false);
  const [interviewTimer, setInterviewTimer] = useState(false);
  const [storeResponse, setStoreResponse] = useState(null);
  const [audio, setAudio] = useState(false);
  const [startTimer, setStartTimer] = useState(false);
  const [question, setQuestion] = useState([]);
  const [selectedResumeID, setSelectedResumeID] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [interviewSimulationSessionId, setInterviewSimulationSessionId] =
    useState(null);
  const [userAgent, setUserAgent] = useState("");
  const [showInterviewStartButton, setShowInterviewStartButton] =
    useState(false);
  const [conversationLogId, setConversationLogId] = useState(null);

  const navigate = useNavigate();

  // useEffect(() => {
  //   if (!user?.activeBundle) {
  //     navigate("/pricing");
  //   }
  // }, [user?.activeBundle]);

  const fileInput = useRef(null);

  const getFile = (e) => {
    const file = e.target.files[0];
    if (file.type === "application/pdf") {
      if (!file) return;
      const fileName =
        file.name.length >= 50
          ? file.name.substring(0, 51) + "..." + file.name.split(".")[1]
          : file.name;

      if (file && file.size > 2.5 * 1024 * 1024) {
        error("File size exceeds 2.5MB, please select a smaller file.");
        return;
      }

      setUploadedFile(file);
    } else {
      error("Only PDF file Supported");
    }
  };

  useEffect(() => {
    // Detect user agent
    const ua = navigator.userAgent;

    if (/iPhone|iPad|iPod/i.test(ua)) {
      console.log("iOS device detected");
      setUserAgent("iOS");
    } else if (/Android/i.test(ua)) {
      console.log("Android device detected");
      setUserAgent("Android");
    } else if (/Chrome/i.test(ua)) {
      console.log("Chrome browser detected");
      setUserAgent("Chrome");
    } else if (/Firefox/i.test(ua)) {
      console.log("Firefox browser detected");
      setUserAgent("Firefox");
    } else if (/Safari/i.test(ua) && !/Chrome/i.test(ua)) {
      console.log("Safari browser detected");
      setUserAgent("Safari");
    }
    // Add more checks as needed
  }, []);

  const submit = (type) => {
    if (!description) {
      error("Job Description is required!");
      return;
    }
    let payload = {
      jobDescription: description,
    };
    if (uploadedFile) {
      payload.resume_file = uploadedFile;
    } else if (selectedResumeID) {
      payload.resumeId = selectedResumeID;
    }
    // console.log({ payload });

    if (uploadedFile && uploadedFile.size > 2.7 * 1024 * 1024) {
      console.error("The file size exceeds the limit of 2.7 MB.");
      error("The file size exceeds the limit of 2.7 MB.");
    } else {
      if (type == "timer") {
        setInterviewTimer(true);
      }
      dispatch(getSimulationsAction(payload))
        .unwrap()
        .then((resolvedData) => {
          setIsQuestionShow(false);

          setStoreResponse(resolvedData);
          setQuestion(resolvedData?.question);
          setIsFirstQ(true);
          setInterviewSimulationSessionId(
            resolvedData?.interviewSimulationSessionId
          );
          setConversationLogId(resolvedData?.conversationLogId);
          if (userAgent === "iOS" || userAgent == "Safari") {
            setShowInterviewStartButton(true);
          }
          setIsModal(true);
        })
        .catch((rejectedValueOrSerializedError) => {
          console.error(rejectedValueOrSerializedError);
        });
    }
  };

  const getModal = () => {
    const header = (
      <div className="mockInterview-modal-header">
        <button className="modal-close" onClick={() => setIsModal(false)}>
          <i className="ri-close-line"></i>
        </button>
      </div>
    );

    const content = (
      <div className="row">
        <div className="col-lg-7 col-md-7 order-md-1 order-2">
          <div>
            <h4 className="mb-4">Realistic Expectations:</h4>
          </div>
          <p>
            {" "}
            Friendly reminder that perfection isn't expected in interview. It's
            about showing Your potential and fit for the role. Regardless of the
            outcome, you just got better at interviewing from yesterday!
            Congrats.
          </p>
        </div>
        <div className="col-lg-5 col-md-5 order-md-2 order-1 text-center">
          <img
            src="/images/message-img.webp"
            className="img-fluid ic-message-vector"
            alt="message image"
          />
        </div>
      </div>
    );

    const footer = (
      <div>
        <button
          className="ic-btn"
          onClick={() => {
            setIsModal(false);
            setIsRole(true);
          }}
        >
          Okay
        </button>
      </div>
    );

    return (
      <Modal
        show={isModal}
        // header={header}
        content={content}
        footer={footer}
        type="begin"
      />
    );
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setDescription(value);

    if (value.length >= 1000) {
      setErrorMessage(true);
    } else {
      setErrorMessage(false);
    }
  };

  const handleDetailsOpen = (type) => {
    setOpenDetails(type);
    if (type !== "upload") {
      // setUploadedFile(null);
    } else {
      setSelectedResumeID("");
    }
  };

  useEffect(() => {
    dispatch(getResume({ onSuccess: () => {} }));
  }, []);

  useEffect(() => {
    setIsOpenAccordion(openDetails);
  }, [openDetails]);

  useEffect(() => {
    return () => {
      if (audio) {
        stopMachineSpeaksGlobal();
        setAudio(false);
      }
    };
  }, [audio]);
  return (
    <div>
      <Header />
      {isLoading && <Loader />}

      <div
        className="ic-interview-full-body"
        style={{ backgroundImage: `url(${"/images/interview-banner.png"})` }}
      >
        {isQuestionShow && (
          <div className="d-flex align-items-center justify-content-center">
            {step === 1 && (
              <>
                <div className="ic-mockInterview">
                  <div className="ic-mock-interview">
                    <div className="grid divide-y divide-neutral-200 max-w-xl mx-auto">
                      <div className="py-4">
                        <details
                          className="group"
                          open={isOpenAccordion === "upload"}
                          onClick={() => handleDetailsOpen("upload")}
                        >
                          <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                            <span>
                              Upload Your Resume For Personal Interview Coming
                              Up
                            </span>
                            <span className="transition group-open:rotate-180">
                              <svg
                                fill="none"
                                height="24"
                                shape-rendering="geometricPrecision"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                                viewBox="0 0 24 24"
                                width="24"
                              >
                                <path d="M6 9l6 6 6-6"></path>
                              </svg>
                            </span>
                          </summary>
                          <div className="pt-4  group-open:animate-fadeIn">
                            <form action="#">
                              <input
                                type="file"
                                name="file"
                                ref={fileInput}
                                accept=".pdf"
                                hidden
                                onChange={(e) => getFile(e)}
                              />
                              <div
                                className="ic-file-upload"
                                onClick={() => {
                                  fileInput.current.click();
                                }}
                              >
                                <div className="ic-icon">
                                  <img
                                    src="/images/document-upload.png"
                                    alt=""
                                    style={{ width: "auto" }}
                                  />
                                </div>
                                <p className="support-text">
                                  <span className="primary">
                                    Click to Upload{" "}
                                  </span>
                                  or drag and drop
                                </p>
                                <span className="ic-file-size">
                                  {" "}
                                  (Max. File size: 2.5 MB)
                                </span>
                              </div>
                            </form>

                            {uploadedFile && (
                              <div className="ic-loading-area">
                                <ul>
                                  <li>
                                    <div className="file-name">
                                      <div className="file-icon">
                                        <img
                                          src="/images/document-text-area.png"
                                          alt=""
                                        />
                                      </div>
                                      <div className="file-size">
                                        <div>
                                          <p>{uploadedFile.name}</p>
                                          {uploadedFile.size && (
                                            <span>{uploadedFile.size}</span>
                                          )}
                                        </div>
                                        {uploadedFile.loading === 100 && (
                                          <i className="ri-checkbox-circle-fill"></i>
                                        )}
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            )}

                            {uploadedFile && (
                              <div
                                className="mt-2 ic-btn"
                                onClick={() => setStep(2)}
                              >
                                Next
                              </div>
                            )}
                          </div>
                        </details>
                      </div>
                      <div className="py-4">
                        <details
                          className="group"
                          open={isOpenAccordion === "noUpload"}
                          onClick={() => handleDetailsOpen("noUpload")}
                        >
                          <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                            <span>Select From Existing Resumes?</span>
                            <span className="transition group-open:rotate-180">
                              <svg
                                fill="none"
                                height="24"
                                shape-rendering="geometricPrecision"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                                viewBox="0 0 24 24"
                                width="24"
                              >
                                <path d="M6 9l6 6 6-6"></path>
                              </svg>
                            </span>
                          </summary>
                          {/* <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
                            Dropdown to be added
                          </p> */}
                          <form className="w-full py-4 px-2 mt-3 group-open:animate-fadeIn">
                            <fieldset>
                              <div className="relative border border-gray-300 text-gray-800 bg-white shadow-lg">
                                {allResume?.filter((item) => item?.isCompleted)
                                  .length > 0 ? (
                                  <>
                                    <select
                                      className="appearance-none w-full py-2 bg-white rounded leading-tight focus:outline-none focus:shadow-outline"
                                      name="whatever"
                                      value={selectedResumeID}
                                      onChange={(e) =>
                                        setSelectedResumeID(e.target.value)
                                      }
                                      id="frm-whatever"
                                    >
                                      <option value="" className="py-2">
                                        Select A Resume&hellip;
                                      </option>
                                      {allResume
                                        ?.filter((item) => item?.isCompleted)
                                        ?.map((resume) => {
                                          return (
                                            <option
                                              value={resume?.id}
                                              className="py-2"
                                            >
                                              {resume?.resume_name
                                                ? `${resume.resume_name}(${
                                                    resume?.iterations
                                                  })${
                                                    resume.company_name
                                                      ? ` - ${resume.company_name}`
                                                      : ""
                                                  }`
                                                : `${resume?.contact?.first_name} ${resume?.contact?.last_name}`}
                                            </option>
                                          );
                                        })}
                                    </select>
                                    <div className="pointer-events-none absolute right-0 top-0 bottom-0 flex items-center px-2 text-gray-700 border-l">
                                      <svg
                                        className="h-4 w-4"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                      >
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                      </svg>
                                    </div>
                                  </>
                                ) : (
                                  <p className="text-yellow-600 p-1">
                                    You currently don't have any completed
                                    resumes in your profile. Please visit the{" "}
                                    <Link
                                      to="/resumes"
                                      className="text-yellow-600 underline"
                                    >
                                      Resume Page
                                    </Link>{" "}
                                    to create and complete a resume for use in
                                    Nexa.
                                  </p>
                                )}
                              </div>
                            </fieldset>
                          </form>
                          {selectedResumeID !== "" && (
                            <div
                              className="mt-2 ic-btn"
                              onClick={() => setStep(2)}
                            >
                              Next
                            </div>
                          )}
                        </details>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            {step === 2 && (
              <div className="ic-mockInterview">
                <div className="ic-mock-interview">
                  <h4>Tell us about your Job Description</h4>
                  <div className="text-area">
                    <textarea
                      placeholder="Type your Job Description..."
                      required
                      value={description}
                      onChange={handleChange}
                      maxLength={1000}
                    />
                    {errorMessage && (
                      <p style={{ color: "red" }}>
                        You have reached the maximum character limit of 1000!
                      </p>
                    )}
                  </div>

                  {uploadedFile && (
                    <div className="ic-loading-area">
                      <ul className="position-relative">
                        <li>
                          <div className="file-name">
                            <div className="file-icon">
                              <img
                                src="/images/document-text-area.png"
                                alt=""
                              />
                            </div>
                            <div className="file-size ">
                              <div>
                                <p>{uploadedFile.name}</p>
                                <span v-if="uploadedFile.size">
                                  {uploadedFile.size}
                                </span>
                              </div>
                              <i className="ri-checkbox-circle-fill"></i>

                              <i
                                className="ri-close-circle-fill"
                                style={{
                                  position: "absolute",
                                  right: "-6px",
                                  top: "-17px",
                                  fontSize: "24px",
                                  color: "red",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setStep(1);
                                  setUploadedFile(null);
                                }}
                              ></i>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  )}
                  <div className="flex justify-left mt-3 items-center">
                    <input
                      type="checkbox"
                      checked={startTimer}
                      className="h-4 w-4  text-white rounded border-gray-300 "
                      id="timerCheckbox"
                      name="checkbox"
                      disabled={loading}
                      onChange={(e) => setStartTimer(!startTimer)}
                    />
                    <label
                      className="font-light text-sm ml-2"
                      for="timerCheckbox"
                    >
                      {" "}
                      Start with Timer <i className="ri-time-line"></i>
                    </label>
                  </div>
                  <div className="flex">
                    <button
                      onClick={() =>
                        !startTimer ? submit("no_timer") : submit("timer")
                      }
                      disabled={loading}
                      className="ic-btn mt-3"
                    >
                      Submit Application <i className="ri-file-list-line"></i>
                    </button>
                    {/* <button
                      onClick={() => submit("timer")}
                      disabled={loading}
                      className="ic-btn mt-3 mx-3"
                    >
                      Start Interview with Timer{" "}
                      <i className="ri-time-line"></i>
                    </button> */}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {isRole && (
          <Interview
            allQuestions={question && question}
            audio={audio}
            setAudio={setAudio}
            setIsFirstQ={setIsFirstQ}
            isFirstQ={isFirstQ}
            conversationLogId={conversationLogId}
            setConversationLogId={setConversationLogId}
            interviewSimulationSessionId={interviewSimulationSessionId}
            setInterviewTimer={setInterviewTimer}
            interviewTimer={interviewTimer}
          />
        )}
      </div>

      {getModal()}
    </div>
  );
};

export default InterviewSimulation;
