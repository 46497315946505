import React, { useEffect, useState } from "react";
import { convertToHTML } from "draft-convert";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";

const RichTextEditor = ({ setEditorContent, defaultContent }) => {
  console.log(defaultContent, "defaultContent");
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  };

  useEffect(() => {
    if (defaultContent) {
      setEditorState(
        EditorState?.createWithContent(
          ContentState?.createFromBlockArray(htmlToDraft(defaultContent))
        )
      );
    }
  }, [defaultContent]);

  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setEditorContent(currentContentAsHTML);
  };
  return (
    <Editor
      editorState={editorState}
      editorStyle={{
        // border: "2px solid #C0C0C0",
        padding: "2px 8px",
        height: "100%",
      }}
      toolbar={{
        options: [
          "inline",
          "blockType",
          "fontSize",
          "fontFamily",
          "list",
          "textAlign",
          "history",
        ],
        inline: {
          inDropdown: false,
          options: [
            "bold",
            "italic",
            "underline",
            "strikethrough",
            "monospace",
          ],
        },
        fontFamily: {
          options: [
            "Arial",
            "Georgia",
            "Impact",
            "Tahoma",
            "Times New Roman",
            "Verdana",
            "Barlow",
          ],
        },
      }}
      onEditorStateChange={handleEditorChange}
    />
  );
};

export default RichTextEditor;
