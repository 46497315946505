import Repository from "./Repository";

const ChatRepository = {
  startChat(payload) {
    return Repository.post(`/chats/`, payload);
  },
  sendMessage(payload) {
    return Repository.post(`/chats/messages`, payload);
  },
  getAllChats() {
    return Repository.get(`/chats/user/chats`);
  },
  getSingleChat(id) {
    return Repository.get(`/chats/${id}`);
  },
};

export default ChatRepository;
