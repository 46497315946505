import React from "react";
import card1Image from "../../assets/media/aboutUs/01.png";
import card2Image from "../../assets/media/aboutUs/02.png";
import card3Image from "../../assets/media/aboutUs/03.png";
import card4Image from "../../assets/media/aboutUs/04.png";

const BlogsSection = () => {
  const diversityCards = [
    {
      image: card1Image,
      title: "DIVERSITY AND INCLUSION AT NEXA",
      description:
        "Diversity and Inclusion at Nexa At Nexa, we believe that diversity, equity, and inclusion (DEI) are fundamental to our mission of empowering job seekers and creating a supportive, innovative community. Our commitment to DEI is reflected in our internal policies, our platform’s features, and our broader community engagement. Here’s how we promote diversity, equity, and inclusion within Nexa and beyond.",
    },
    {
      image: card2Image,
      title: "INCLUSIVE WORKPLACE CULTURE",
      description:
        "Hiring Practices: We actively seek diverse talent and ensure our hiring processes are free from bias. We use structured interviews, diverse hiring panels, and blind resume reviews to promote fairness and inclusivity. Employee Resource Groups (ERGs): Our ERGs provide a platform for employees from underrepresented groups to share their experiences, support one another, and advocate for positive change within the company. Continuous Learning: We offer regular DEI training and workshops for all employees to foster a culture of respect, understanding, and allyship.",
    },
    {
      image: card3Image,
      title: "DIVERSE LEADERSHIP",
      description:
        "Representation at All Levels: We are committed to having diverse representation at all levels of the company, including our leadership team. This ensures that a variety of perspectives are included in decision-making processes. Mentorship Programs: Our mentorship programs are designed to support the career growth of employees from underrepresented backgrounds, helping them to advance into leadership positions.",
    },
    {
      image: card4Image,
      title: "PLATFORM ACCESSIBILITY",
      description:
        "User-Friendly Design: Nexa is designed to be accessible to users of all backgrounds and abilities. We continuously work to ensure our platform is easy to use and meets the highest accessibility standards. Language Support: We offer multilingual support to cater to our diverse user base, ensuring that language is not a barrier to accessing our career services. Tailored Content: Our AI-driven tools provide personalized recommendations and feedback that consider the unique needs and backgrounds of each user.",
    },
  ];
  return (
    <div className="aboutUs__blog-container">
      <div className="container">
        <div className="aboutUs__blog-content">
          <h2 className="aboutUs__blog-heading section-heading mt-2">
            Diversity and Inclusion
          </h2>
          <div className="diversity_section-cover">
            {diversityCards?.map((item, index) => (
              <div className="diversity_section-card" key={index}>
                <div className="diversity_section-card_header">
                  <img src={item?.image} alt="card1Image" />
                </div>
                <h3 className="diversity_section-card_heading">
                  {item?.title}
                </h3>
                <p className="diversity_section-card_description">
                  {item?.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogsSection;
