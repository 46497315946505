import React, { useEffect, useState } from "react";
import Header from "../../Components/header";
import { IoArrowBack } from "react-icons/io5";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getBlogs } from "../../Redux/BlogSlice";
import Loader from "../../Components/main-loader";

const BlogDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { blogs, loading } = useSelector((state) => state.blog);
  const [singleBlog, setsingleBlog] = useState(null);
  console.log(singleBlog, "singleBlog");
  useEffect(() => {
    const currentBlog = blogs.filter((blog) => blog.id == id)[0];
    setsingleBlog(currentBlog);
  }, [blogs]);
  useEffect(() => {
    dispatch(getBlogs());
  }, []);
  return (
    <>
      {loading && <Loader />}
      <Header />
      <div
        className="ic-interview-full-body"
        style={{
          backgroundImage: `url(${"/images/interview-banner.png"})`,
          marginTop: "6em",
        }}
      >
        <main
          role="main"
          className="ml-sm-auto col-lg-12 px-4 bg-cover"
          style={{
            backgroundImage: `url(${"/images/interview-banner.png"})`,
          }}
        >
          <div className="py-5 px-4">
            <div className="flex items-center w-full py-3 mb-5 border-b border-black">
              <div className="flex w-full flex-col md:flex-row">
                <div className="w-full md:w-full flex justify-between">
                  <Link to="/news">
                    <IoArrowBack size={30} />
                  </Link>
                  <div>
                    <p className="text-sm text-gray-500 font-semibold">
                      <span className="font-bold text-primary">
                        Posted on:{" "}
                      </span>
                      {moment(singleBlog?.created_on).format("DD/MM/YYYY ")}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="mb-4 w-full mx-auto relative">
              <div className="px-4 lg:px-0 text-center">
                <h2 className="text-2xl md:text-3xl font-bold leading-tight mb-5">
                  {singleBlog?.title}
                </h2>
              </div>

              <img
                src={singleBlog?.image}
                className="w-full object-cover lg:rounded-lg h-[15em] md:h-[28em]"
              />
            </div>

            <div className="px-4 md:px-40 mb-4">
              <button className="bg-primary text-white rounded-xl px-2 py-1 text-xs uppercase cursor-not-allowed">
                {singleBlog?.category_name}
              </button>
            </div>

            <div className="flex flex-col gap-4 px-4 md:px-40">
              <div className="whitespace-normal break-words flex flex-col item-center leading-relaxed">
                <p
                  dangerouslySetInnerHTML={{ __html: singleBlog?.description }}
                />
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default BlogDetail;
