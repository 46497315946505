import React, { useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useRef } from "react";

const Modal = ({ show = false, header, content, footer, type }) => {
  const printRef = useRef();
  const [loader, setLoader] = useState(false);

  const downloadFeedback = async () => {
    setLoader(true);
    const element = printRef.current;
    const canvas = await html2canvas(element, { scale: 2 });
    const data = canvas.toDataURL("image/png");

    const pdf = new jsPDF("p", "mm", "a4");
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    let heightLeft = pdfHeight;
    let position = 0;

    pdf.addImage(data, "PNG", 0, position, pdfWidth, pdfHeight);
    heightLeft -= pdf.internal.pageSize.getHeight();

    while (heightLeft > 0) {
      position = heightLeft - pdfHeight;
      pdf.addPage();
      pdf.addImage(data, "PNG", 0, position, pdfWidth, pdfHeight);
      heightLeft -= pdf.internal.pageSize.getHeight();
    }
    pdf.save("InterviewFeedback.pdf");
    setLoader(false);
  };

  return (
    <div className={`ic-modal ${show ? "show" : ""}`}>
      <div className="ic-modal-dialog">
        <div className="modal-main-content w-100">
          {header && <div className="ic-modal-header">{header}</div>}

          <div className="ic-modal-body" ref={printRef}>
            {content && <div className="ic-modal-content">{content}</div>}
            {footer && <div className="ic-footer-modal">{footer}</div>}
          </div>
          {type && type == "feedback" && (
            <div className="ic-modal-body pt-0">
              <div className="ic-footer-modal p-0 d-flex justify-content-end">
                <button onClick={downloadFeedback} disabled={loader}>
                  {loader ? "Downloading..." : "Download Feedback"}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
