import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../../../Components/main-loader";

const Roles = ({
  roles,
  selectedRole,
  setRoleCategoryId,
  setTechStack,
  setTechStackIcon,
}) => {
  const { isLoading } = useSelector((state) => state.dashboard);
  console.log(roles, selectedRole, "HAHAHAHAHA");

  setTechStack && setTechStack(null);

  const getUrl = (name) => {
    let url = "/interview/role/";
    if (selectedRole && selectedRole.name) {
      url += selectedRole.name.replace(" ", "-").toLowerCase();
    }
    if (!name || typeof name !== "string") return "";
    return name.replace(" ", "-").toLowerCase();
  };

  const getRoleCard = (id, name, icon, index) => {
    setTechStackIcon(icon);

    return (
      <>
        <Link
          onClick={() => setRoleCategoryId(id)}
          key={index}
          to={`${getUrl(name)}`}
          className="ic-interview-items"
          data-aos="fade-up"
          data-aos-delay="50"
          data-aos-duration={index * 200 + 500}
        >
          <div className="ic-icons">
            <img src={icon} className="img-fluid" alt={name} />
          </div>
          <p>{name}</p>
          <i
            className="ri-arrow-right-line ic-right-arrow"
            style={{ fontSize: "22px" }}
            size="30"
          />
        </Link>
      </>
    );
  };

  return (
    <div className="ic-interviews">
      <div className="container">
        <div className="ic-interview-container">
          <h1 className="text-center text-capitalize text-bold">
            Which Career are you interested in practicing?
          </h1>
          {isLoading ? (
            <Loader />
          ) : (
            <div className="ic-interview-card-container">
              {selectedRole
                ? selectedRole?.categories
                    ?.slice()
                    .sort((a, b) => {
                      if (a.name < b.name) {
                        return -1;
                      }
                      if (a.name > b.name) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((item, index) =>
                      getRoleCard(
                        item.id,
                        item.name,
                        selectedRole.iconUrl,
                        index
                        // setRoleCategoryId
                      )
                    )
                : roles
                    ?.slice()
                    .sort((a, b) => {
                      if (a.name < b.name) {
                        return -1;
                      }
                      if (a.name > b.name) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((item, index) =>
                      getRoleCard(
                        item.id,
                        item.name,
                        item.iconUrl,
                        index
                        // setRoleCategoryId
                      )
                    )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Roles;
