import React, { useEffect, useState } from "react";
import DatePicker from "../../Components/Resume/DatePicker";
import { useDispatch, useSelector } from "react-redux";
import {
  AddPublications,
  RemovePublication,
  UpdatePublications,
  addResumePublication,
  deleteResumePublication,
} from "../../Redux/ResumeBuilderSlice";
// import {
//   setActiveField,
//   showPublicationForm,
// } from "../education/EducationFormSlice";
import {
  getResumePublication,
  updateResumePublication,
} from "../../Redux/ResumeBuilderSlice";
import { error, success } from "../../Utilities/toast";
import { useParams } from "react-router-dom";

const PublicationForm = () => {
  const { id } = useParams();
  console.log(id, "resumeID>>>>>>>>>>>>>>>>>>>");
  const dispatch = useDispatch();
  const { publications } = useSelector((state) => state.ResumeBuilder);
  const [selectedpubDate, setSelectedPubDate] = useState(new Date());
  const [Publications, setPublications] = useState([]);
  useEffect(() => {
      setPublications(publications);
  }, [publications]);
  const fetchPublications = () => {
    const ID = id;
    console.log("inside get publications");
    dispatch(getResumePublication({ ID, onSuccess: () => {} }));
  };
  const [EditingIndex, setEditingIndex] = useState(null);
  console.log(EditingIndex, "EditingIndex>>>>>>>>>>>>>>>.");
  const [FormVisible, setFormVisible] = useState(false);
  const [HoveredIndex, sethoveredIndex] = useState(null);
  const [Newpublication, setNewPublication] = useState("");
  const [NewPublisher, setNewPublisher] = useState("");
  const [publicationID, setpublicationID] = useState("");
  console.log(publicationID, "publicationID");
  function onPublicationCancel() {
    setFormVisible(!FormVisible);
    setEditingIndex(null);
    setNewPublication("");
    setNewPublisher("");
    setSelectedPubDate(new Date());
  }
  const toggleNewPublication = () => {
    setFormVisible(!FormVisible);
    setEditingIndex(null);
    setNewPublication("");
    setNewPublisher("");
    setSelectedPubDate(new Date());
  };
  const handlePlusPublication = (event) => {
    event.preventDefault();
    if (Newpublication.trim() !== "" && NewPublisher.trim() !== "") {
      if (new Date(selectedpubDate) > new Date()) {
        error("Start date cannot be greater than Current Date");
        return;
      }
      const ID = id;
      const payload = {
        title: Newpublication,
        publisher: NewPublisher,
        date: selectedpubDate,
      };
      console.log(payload, "payload>>>>>>>>>>>>>");
      dispatch(
        addResumePublication({
          ID,
          payload,
          onSuccess: (res) => {
            success(res);
            fetchPublications();
          },
        })
      );
      setNewPublication("");
      setNewPublisher("");
      setSelectedPubDate(new Date());
      setFormVisible(false);
    } else {
      error("All Fields are mandatory");
    }
  };
  const handleRemove = (ID) => {
    dispatch(
      deleteResumePublication({
        ID,
        onSuccess: (res) => {
          success(res);
          fetchPublications();
        },
      })
    );
  };

  const handleEdit = (ID, index) => {
    setpublicationID(ID);
    setEditingIndex(index);
    setNewPublication(Publications[index].title);
    setNewPublisher(Publications[index].publisher);
    setSelectedPubDate(new Date());
    setFormVisible(true);
  };
  const handleSaveUpdatedPublication = (event) => {
    event.preventDefault();
    if (Newpublication !== "") {
      if (new Date(selectedpubDate) > new Date()) {
        error("Start date cannot be greater than Current Date");
        return;
      }
      const ID = publicationID;
      const payload = {
        title: Newpublication,
        publisher: NewPublisher,
        date: selectedpubDate,
      };
      dispatch(
        updateResumePublication({
          ID,
          payload,
          onSuccess: (res) => {
            success(res);
            fetchPublications();
          },
        }),
        setPublications("")
      );
      setEditingIndex(null);
      setNewPublication("");
      setNewPublisher("");
      setSelectedPubDate(new Date());
      setFormVisible(false);
    }else{
      error("All Fields are mandatory");
    }
  };

  return (
    <div className="mydetails">
      {FormVisible && (
        <form
          onSubmit={(e) =>
            EditingIndex !== null
              ? handleSaveUpdatedPublication(e)
              : handlePlusPublication(e)
          }
        >
          <div className="d-flex flex-column  mb-1   ">
            <div className="form-group  w-100 ">
              <label htmlFor="publication">Publication</label>
              <input
                type="text"
                id="publication"
                className="form-control"
                placeholder=""
                value={Newpublication}
                // onFocus={() => dispatch(showPublicationForm())}
                onChange={(e) => setNewPublication(e.target.value)}
              />
            </div>
          </div>
          <div className="d-flex mb-1 flex-col md:flex-row">
            <div className="form-group mr-2 w-full md:w-50">
              <label htmlFor="publisher">Publisher</label>
              <input
                type="text"
                id="publisher"
                className="form-control"
                placeholder="Meta"
                value={NewPublisher}
                // onFocus={() => dispatch(showPublicationForm())}
                onChange={(e) => setNewPublisher(e.target.value)}
              />
            </div>
            <div className="form-group ml-0 md:ml-3 w-full md:w-50">
              <label htmlFor="date">Date</label>
              <DatePicker
                selectedDate={selectedpubDate}
                onSelect={setSelectedPubDate}
              />
            </div>
          </div>

          <div className="d-flex justify-content-end  py-2 px-2   border-bottom border-primary  ">
            <button
              type="button"
              className="cancel-btn mr-4"
              style={{
                fontSize: "14px",
                fontWeight: "500",
                width: "100px",
                height: "40px",
              }}
              onClick={onPublicationCancel}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="save-btn"
              style={{
                fontSize: "14px",
                fontWeight: "500",
                width: "100px",
                height: "40px",
              }}
            >
              {EditingIndex !== null ? "Update" : "Save"}
            </button>
          </div>
        </form>
      )}
      {!FormVisible && (
        <div className="professionalsummary-item">
          <button
            className="professionalitem-button"
            onClick={toggleNewPublication}
          >
            <i className="ri-add-circle-line"></i>
            <p className="ml-2">Add Publication</p>
          </button>
        </div>
      )}
      {Publications &&
        Publications.filter((publication, index) => EditingIndex !== index).map(
          (publication, index) => (
            <div
              key={index}
              className="professionalsummary-item"
              onMouseEnter={() => sethoveredIndex(index)}
              onMouseLeave={() => sethoveredIndex(null)}
            >
              <div className="d-flex">
                <p className="mr-2">{publication.title}</p>
                <p>{publication.publisher}</p>
              </div>
              {HoveredIndex === index && (
                <div className="professionalitem-actions">
                  <button
                    className="professionalitem-button"
                    onClick={() => handleEdit(publication.id, index)}
                  >
                    <i className="ri-edit-line"></i>
                  </button>
                  <button
                    className="professionalitem-button"
                    onClick={() => handleRemove(publication.id)}
                  >
                    <i className="ri-delete-bin-line"></i>
                  </button>
                </div>
              )}
            </div>
          )
        )}
    </div>
  );
};

export default PublicationForm;
