
const ReportingComponent = () => {

    return (
        <>
         <h6>Reporting</h6>
        </>
    )
};

export default ReportingComponent;
