import Repository from "./Repository";
const TRAINING_SESSIONS = "/analytics/interview_training_sessions";

const SIMULATION_SESSIONS = "/analytics/interview_simulation_sessions";

const profileRepository = {
  trainingSessions() {
    return Repository.get(`${TRAINING_SESSIONS}`);
  },
  simulationSessions() {
    return Repository.get(`${SIMULATION_SESSIONS}`);
  },
};

export default profileRepository;
