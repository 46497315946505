import React from "react";
import ellipseLeft from "../../assets/media/aboutUs/ellipse-vector-1.png";
import rightRide from "../../assets/media/aboutUs/about_secound_section.png";
import leftSide from "../../assets/media/aboutUs/about_secound_secound.png";
import leftRidesecound from "../../assets/media/aboutUs/about_secound_third.png";
import JoinUSImg from "../../assets/media/aboutUs/joinUsImg.png";
import MissionImg from "../../assets/media/aboutUs/mission.png";

const TeamAndCultureSection = () => {
  return (
    <div className="aboutUs__team-and-culture-container">
      <img
        className="aboutUs__team-and-culture-left-ellipse"
        src={ellipseLeft}
        alt=""
      />
      <div className="container">
        <div className="aboutUs__team-and-culture-content">
          <h2 className="aboutUs__team-and-culture-heading section-heading">
            Our Story
          </h2>
          <p className="aboutUs__story-section-description">
            The inception of Nexa is rooted in a profound journey of resilience,
            passion, and an unwavering commitment to making a difference in the
            world of career preparation.
          </p>
          <div className="aboutUs__story-section-main">
            <div className="aboutUs__story-section-left">
              <h2 className="aboutUs__story-section-left_heading">
                THE SPARK OF INSPIRATION
              </h2>
              <p className="aboutUs__story-section-left_description">
                Nexa’s story begins with <b>our founder, Divy Nayyar,</b> a
                cybersecurity and data engineer who spent seven years navigating
                the complexities of the tech industry. During this time, Divy
                saw firsthand the struggles that job seekers faced—talented
                individuals who, despite their potential, were often unprepared
                for the rigorous demands of the job market. This challenge hit
                even closer to home when he founded InTech Institute, a
                vocational center for technology education. There, he
                encountered students brimming with skills but lacking the
                confidence and guidance needed to secure their dream jobs.
              </p>
            </div>
            <div className="aboutUs__story-section-right">
              <img
                className="aboutUs__team-and-culture-right_image"
                src={rightRide}
                alt=""
              />
            </div>
          </div>
          {/* //////section 2 */}
          <div className="aboutUs__story-section-main">
            <div className="aboutUs__story-section_two-right">
              <img
                className="aboutUs__team-and-culture-left_image"
                src={leftSide}
                alt=""
              />
            </div>
            <div className="aboutUs__story-section_two-left">
              <h2 className="aboutUs__story-section-left_heading">
                THE HARDSHIP
              </h2>
              <p className="aboutUs__story-section-left_description">
                It wasn’t just the students’ struggles that fueled Divy's drive;
                it was his own personal journey. Growing up in a modest family,
                Divy had to overcome numerous obstacles to achieve his own
                career goals. The financial constraints, the countless
                rejections, and the persistent feeling of being underprepared
                were constant hurdles. He vividly remembers the sleepless
                nights, the endless job applications, and the crushing
                disappointments that almost made him give up. But through sheer
                determination and the support of mentors, he persevered. This
                journey instilled in him a deep empathy for job seekers and a
                burning desire to ease their path.
              </p>
            </div>
          </div>
          {/* //////section 3 */}
          <div className="aboutUs__story-section-main">
            <div className="aboutUs__story-section-left">
              <h2 className="aboutUs__story-section-left_heading">
                THE BIRTH OF NEXA
              </h2>
              <p className="aboutUs__story-section-left_description">
                Motivated by these experiences, Divy envisioned a platform that
                could bridge the gap between raw talent and job market
                readiness.
                <b>
                  {" "}
                  In 2023, Nexa was born—a revolutionary career services
                  platform designed to provide personalized interview
                  simulations, tailored resume building, and comprehensive
                  career coaching.
                </b>{" "}
              </p>
              <p className="aboutUs__story-section-left_description mt-5">
                Joining Divy in this mission was
                <b> Prithvi , Co-Founder and CTO of Nexa.</b> With a background
                as an AI Developer at Scale AI and a dual degree in Computer
                Science and Business from USC, Prithvi brought a wealth of
                technical expertise and a deep for career and HR tech. Divy and
                Prithvi met at a tech conference in LA and after sharing some
                ideas on business and life their mindsets for creation synced
                faster than google drive! His experience in building a
                successful freelance staffing and recruiting business gave him
                unique insights into the hiring process and the challenges faced
                by both job seekers and employers. Together, Divy and Prithvi
                combined their skills and experiences to create a platform that
                would empower individuals with the tools and confidence they
                need to succeed in their careers.
              </p>
              <p className="aboutUs__story-section-left_description mt-5">
                Their collaboration was the perfect blend of technical prowess
                and a deep understanding of the career landscape, ensuring that
                Nexa was not just another career services tool, but a
                comprehensive, AI-driven solution that truly met the needs of
                its users.
              </p>
            </div>
            <div className="aboutUs__story-section-right">
              <img
                className="aboutUs__team-and-culture-right_image-secound"
                src={leftRidesecound}
                alt=""
              />
            </div>
          </div>

          <div className="aboutUs__story-section-main">
            <div className="aboutUs__story-section_two-right">
              <img
                className="aboutUs__team-and-culture-left_image"
                src={MissionImg}
                alt=""
              />
            </div>
            <div className="aboutUs__story-section_two-left">
              <h2 className="aboutUs__story-section-left_heading">
                OUR MISSION
              </h2>
              <p className="aboutUs__story-section-left_description">
                At Nexa, we believe that every individual deserves a fair chance
                at success. We are driven by a mission to democratize career
                preparation, making it accessible, effective, and personalized.
                Our commitment is to stand by our users, providing them not just
                with tools, but with hope and confidence.
              </p>
            </div>
          </div>
          <div className="aboutUs__story-section-main">
            <div className="aboutUs__story-section-left">
              <h2 className="aboutUs__story-section-left_heading">JOIN US</h2>
              <p className="aboutUs__story-section-left_description">
                As we continue to grow and evolve, we invite you to be a part of
                Nexa’s story. Whether you’re a job seeker, an educational
                institution, or a corporate partner, together we can build a
                future where everyone has the opportunity to succeed.
              </p>
            </div>
            <div className="aboutUs__story-section-right">
              <img
                className="aboutUs__team-and-culture-right_image-secound"
                src={JoinUSImg}
                alt=""
              />
            </div>
          </div>
          {/* <div className="mt-8">
       
            <p className="mt-1 aboutUs__story-section-left_description text-center">
              <b>Our Mission: </b>
              At Nexa, we believe that every individual deserves a fair chance
              at success. We are driven by a mission to democratize career
              preparation, making it accessible, effective, and personalized.
              Our commitment is to stand by our users, providing them not just
              with tools, but with hope and confidence.
            </p>
            <p className="mt-1 aboutUs__story-section-left_description text-center">
              <b>Join Us: </b>
              As we continue to grow and evolve, we invite you to be a part of
              Nexa's story. Whether you're a job seeker, an educational
              institution, or a corporate partner, together we can build a
              future where everyone has the opportunity to succeed.
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default TeamAndCultureSection;
