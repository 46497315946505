import { useDispatch, useSelector } from "react-redux";
import Header from "../../Components/header";
import "./user.css";
import { useEffect, useRef, useState } from "react";
import {
  generateSkillsGapAnalysis,
  getOnBoardingAction,
  getSkillsAnalysis,
  onBoardingAction,
  updateOnboarding,
} from "../../Redux/authenticate/authenticate.action";
import { error, success } from "../../Utilities/toast";
import Loader from "../../Components/main-loader";
import { SkillsGapConvertion } from "../../Utilities/SkillsGapConvertion";
import TypingEffect from "../../Utilities/TypingEffect";
import CircularLoader from "../../Components/loader";
const UserDashboard2 = () => {
  const dispatch = useDispatch();
  const { user, onboardingData, loading, isloading, skillsAnalysis } =
    useSelector((state) => state.authenticate);
  console.log(loading, "loading");
  const [isOpen, setIsOpen] = useState(false);
  const [storeFetchValue, setStoreFetchValue] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const fileInput = useRef(null);
  const [resume, setResume] = useState(null);

  const localSlugs = [
    {
      slug: "career-goals",
      value: "Career Goals",
    },
    {
      slug: "current-role-and-experience",
      value: "Current Role & Experience",
    },
    {
      slug: "new-skill-goals",
      value: "New Skill Goals",
    },
    {
      slug: "additional-information",
      value: "Additional Information",
    },
    {
      slug: "your-resume",
      value: "Your Resume",
    },
  ];

  const updateAnswer = (payload) => {
    dispatch(updateOnboarding(payload))
      .unwrap()
      .then((response) => {
        if (response) {
          dispatch(getOnBoardingAction());
          setSelectedItem(null);
          setResume(null);
          success("Updated Successfully");
          setIsOpen(false);
        }
      });
  };
  const createAnswer = (payload) => {
    dispatch(onBoardingAction(payload))
      .unwrap()
      .then((response) => {
        if (response) {
          dispatch(getOnBoardingAction());
          setSelectedItem(null);
          setResume(null);
          success("Updated Successfully");
          setIsOpen(false);
        }
      });
  };

  useEffect(() => {
    dispatch(getOnBoardingAction());
    // dispatch(getSkillsAnalysis());
  }, []);
  const handleUpdate = () => {
    let previousData = onboardingData?.find(
      (onboarding) => onboarding?.question_slug === selectedItem?.slug
    );
    let prevAnswer =
      previousData?.answer || previousData?.fileUrl ? true : false;

    if (selectedItem.answer || resume !== null) {
      let payload = {
        question_slug: selectedItem.slug,
      };
      if (selectedItem?.slug == "your-resume") {
        payload.file = resume;
      } else {
        payload.answer = selectedItem.answer;
      }
      if (prevAnswer) {
        updateAnswer(payload);
      } else {
        createAnswer(payload);
      }
    } else {
      error("Answer Cannot be Empty");
    }
  };

  return (
    <>
      {loading && <Loader />}
      <Header />
      <div
        style={{
          position: "fixed",
          top: "10vh",
          bottom: "55vh",
          left: "15vw",
          right: "10vw",
          backgroundColor: "white",
          border: "1px solid",
          borderColor: "gray",
          borderRadius: "20px",
          zIndex: 10,
          boxShadow: "10px 10px 40px gray",
          padding: "30px",
          minHeight: "20rem",
          height: "fit-content",
          visibility: isOpen ? "visible" : "hidden",
        }}
      >
        <h3 className="inline text-bold text-primary text-md w-[100vw]">
          Edit Details
        </h3>
        <span
          onClick={() => {
            setIsOpen(false);
          }}
          style={{
            position: "absolute",
            right: "4vw",
            fontSize: "30px",
            fontWeight: "800",
            top: "20px",
          }}
          className="inline text-primary font-bold ml-auto"
        >
          X
        </span>

        <br />
        {selectedItem?.slug == "your-resume" ? (
          <>
            {resume ? (
              <p>{resume?.name}</p>
            ) : (
              <div className="ic-mockInterview d-flex justify-content-center align-items-center">
                <div className="ic-mock-interview mt-3">
                  <h4>Please Upload your Resume to Tailor your Experience</h4>
                  <form action="#">
                    <input
                      type="file"
                      name="file"
                      ref={fileInput}
                      accept=".pdf"
                      hidden
                      onChange={(e) => {
                        setResume(e.target.files[0]);
                      }}
                    />
                    <div
                      className="ic-file-upload"
                      onClick={() => {
                        fileInput.current.click();
                      }}
                    >
                      <div className="ic-icon">
                        <img
                          src="/images/document-upload.png"
                          alt=""
                          style={{ width: "auto" }}
                        />
                      </div>
                      <p className="support-text">
                        <span className="primary">Click to Upload </span>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </>
        ) : (
          <textarea
            className="w-full p-1"
            placeholder="Change contents here!"
            rows={6}
            value={selectedItem?.answer}
            onChange={(e) => {
              setSelectedItem({
                ...selectedItem,
                answer: e.target.value,
              });
            }}
          ></textarea>
        )}
        <br />
        <br />
        <button
          className="btn btn-primary"
          onClick={() => {
            handleUpdate();
          }}
        >
          Save
        </button>
      </div>
      <div
        className="ic-interview-full-body"
        style={{
          backgroundImage: `url(${"/images/interview-banner.png"})`,
          marginTop: "6em",
        }}
      >
        <main
          role="main"
          className=" ml-sm-auto col-lg-12 px-4 bg-cover"
          style={{
            backgroundImage: `url(${"/images/interview-banner.png"})`,
          }}
        >
          <div className="pt-3 pb-2 mb-3">
            <h1 className="h2">Career Goals!</h1>
          </div>

          <div className="grid-margin stretch-card my-3">
            <div className="card border-rounded-card card-padding-profile">
              <div className="card-body py-0">
                <h3 className="skills-heading">Skills Gap Assessment</h3>
                <TypingEffect storeFetchValue={storeFetchValue} />
                {/* <div
                  dangerouslySetInnerHTML={SkillsGapConvertion(
                    skillsAnalysis && skillsAnalysis.analysis
                  )}
                ></div> */}
                {/* <p>skills gap response </p> */}
                <div className="d-flex justify-content-end align-items-center mt-4">
                  <button
                    className="ic-btn"
                    onClick={() =>
                      dispatch(
                        generateSkillsGapAnalysis({
                          onSuccess: () => {
                            setStoreFetchValue(true);
                          },
                        })
                      )
                    }
                  >
                    Refresh
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="content pb-5">
            {/* {localSlugs?.map((item, index) => {
                let answer = onboardingData?.find(
                  (onboarding) => onboarding?.question_slug === item?.slug
                )?.answer;
                let file = onboardingData?.find(
                  (onboarding) => onboarding?.question_slug === item?.slug
                )?.file_url;

                return (
                  <>
                    {item?.question_slug !== "nexa-usage-plan" && (
                      <div className="col-xl-12 col-lg-12 col-sm-12 grid-margin stretch-card mb-5 position-relative">
                        <div
                          className="card border-rounded-card card-padding-profile"
                          style={{ boxShadow: "10px 10px 40px gray" }}
                        >
                          <div className="card-body text-center d-flex justify-content-between ">
                            <div className="d-flex flex-column justify-content-left  ">
                              <h3 className="text-dark font-weight-normal text-left">
                                {item?.value}
                              </h3>
                              {item?.slug == "your-resume" ? (
                                <button
                                  className="btn btn-primary mx-0 my-3"
                                  disabled={file == null}
                                  onClick={() => window.open(file, "_blank")}
                                >
                                  Open PDF
                                </button>
                              ) : (
                                <p className="mb-2 text-dark font-weight-normal mt-4">
                                  {answer}
                                </p>
                              )}
                            </div>
                            <div>
                              <i
                                class="ri-pencil-line h1 text-primary"
                                onClick={() => {
                                  setSelectedItem({ ...item, answer });
                                  setIsOpen(true);
                                }}
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                );
              })} */}
            {localSlugs?.map((item, index) => {
              let answer = onboardingData?.find(
                (onboarding) => onboarding?.question_slug === item?.slug
              )?.answer;
              let file = onboardingData?.find(
                (onboarding) => onboarding?.question_slug === item?.slug
              )?.file_url;
              return (
                <div className="career_slide-cover">
                  <div className="ic-question-start border-0">
                    <div className="question-header">
                      <span className="question-tags">
                        <i className="ri-error-warning-line"></i>
                        {item?.value}
                      </span>
                      <div className="edit-arrow">
                        <i
                          class="ri-pencil-line h1 text-primary"
                          onClick={() => {
                            setSelectedItem({ ...item, answer });
                            setIsOpen(true);
                          }}
                        ></i>
                      </div>
                    </div>
                    {item?.slug == "your-resume" ? (
                      <button
                        className="btn btn-primary mx-0 my-3"
                        disabled={file == null}
                        onClick={() => window.open(file, "_blank")}
                      >
                        Open PDF
                      </button>
                    ) : (
                      <h4>{answer}</h4>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </main>
      </div>
    </>
  );
};

export default UserDashboard2;
