import { io } from "socket.io-client";

const socket = io("https://api.stagingnexa.com", {
  autoConnect: false,
  reconnectionAttempts: 5, // Limits reconnection attempts
  reconnectionDelayMax: 5000, // Maximum delay between reconnections
});

socket.on("connect", () => {
  console.log("Socket connected:", socket.id);
});

socket.on("disconnect", (reason) => {
  console.log("Socket disconnected:", reason);
});

socket.on("connect_error", (error) => {
  console.log("Connection error:", error);
});

export default socket;
