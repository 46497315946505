import React, { useEffect, useState } from "react";
import Header from "../../Components/header/index";

function SalesOptions() {
  const [callType, setCallType] = useState("");
  const [product, setProduct] = useState("");
  const [difficulty, setDifficulty] = useState("");
  const [industry, setIndustry] = useState("");
  const [situation, setSituation] = useState("");
  const [progress, setProgress] = useState(0);
  const [animateCard, setAnimateCard] = useState(false);

  useEffect(() => {
    let filledFields = 0;
    if (callType) filledFields++;
    if (product) filledFields++;
    if (difficulty) filledFields++;
    if (industry) filledFields++;
    if (situation) filledFields++;
    setProgress((filledFields / 5) * 100);
  }, [callType, product, difficulty, industry, situation]);

  useEffect(() => {
    setAnimateCard(true);
  }, []);

  useEffect(() => {
    if (progress === 100) {
      setTimeout(() => {
        setProgress(100);
      }, 300); // Slight delay to simulate smooth loading
    }
  }, [progress]);

  return (
    <>
      <Header />
      <div style={styles.container}>
        <div style={styles.progressBarContainer}>
          <div style={{ ...styles.progressBar, width: `${progress}%` }}></div>
        </div>
        <div
          style={{
            ...styles.card,
            opacity: animateCard ? 1 : 0,
            transform: animateCard ? "translateY(0)" : "translateY(20px)",
          }}
        >
          <h2 style={styles.title}>Create your Live Sales Call</h2>

          <div style={styles.inputGroup}>
            <label style={styles.label}>Call Type</label>
            <select
              value={callType}
              onChange={(e) => setCallType(e.target.value)}
              style={styles.select}
            >
              <option value="">Select...</option>
              <option value="1">Cold Call</option>
              <option value="2">Warm Call</option>
              <option value="3">Gatekeeper Call</option>
              <option value="4">Closing Call</option>
              {/* Add more options as needed */}
            </select>
          </div>

          <div style={styles.inputGroup}>
            <label style={styles.label}>
              What Product/Service Are You Selling?
            </label>
            <input
              type="text"
              value={product}
              onChange={(e) => setProduct(e.target.value)}
              placeholder="Enter Your Product/Service"
              style={styles.input}
            />
          </div>

          <div style={styles.inputGroup}>
            <label style={styles.label}>Difficulty Level</label>
            <select
              value={difficulty}
              onChange={(e) => setDifficulty(e.target.value)}
              style={styles.select}
            >
              <option value="">Select...</option>
              <option value="1">Easy</option>
              <option value="2">Medium</option>
              <option value="3">Hard</option>
              {/* Add more options as needed */}
            </select>
          </div>

          <div style={styles.inputGroup}>
            <label style={styles.label}>Prospect's Industry</label>
            <select
              value={industry}
              onChange={(e) => setIndustry(e.target.value)}
              style={styles.select}
            >
              <option value="">Select...</option>
              <option value="1">Technology</option>
              <option value="2">Healthcare</option>
              <option value="3">Financial Services</option>
              <option value="4">Manufacturing</option>
              <option value="5">Retail & Ecommerce</option>
              <option value="6">Automotive</option>
              <option value="7">Real Estate</option>
              <option value="8">Telecommunications</option>
              <option value="9">Insurance</option>
              <option value="10">Government</option>
              {/* Add more options as needed */}
            </select>
          </div>

          <div style={styles.inputGroup}>
            <label style={styles.label}>
              Any Specific Situational Parameters?
            </label>
            <input
              type="text"
              value={situation}
              onChange={(e) => setSituation(e.target.value)}
              placeholder="Add a standard"
              style={styles.input}
            />
          </div>

          <button style={styles.button}>Generate</button>
        </div>
      </div>
    </>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    backgroundColor: "#f0f2f5",
    paddingTop: "100px",
    boxSizing: "border-box",
  },
  progressBarContainer: {
    width: "100%",
    height: "8px",
    backgroundColor: "#e0e0e0",
    borderRadius: "4px",
    marginBottom: "20px",
  },
  progressBar: {
    height: "100%",
    backgroundColor: "#0077cc",
    borderRadius: "4px",
    transition: "width 0.3s ease",
  },
  card: {
    opacity: 0,
    transform: "translateY(20px)",
    transition: "opacity 0.5s ease-out, transform 0.5s ease-out",
    padding: "20px",
    maxWidth: "600px",
    width: "90%",
    margin: "20px auto",
    fontFamily: "Arial, sans-serif",
    backgroundColor: "white",
    borderRadius: "15px",
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
    boxSizing: "border-box",
  },
  title: {
    textAlign: "center",
    color: "#0077cc",
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "20px",
  },
  inputGroup: {
    marginBottom: "15px",
  },
  label: {
    display: "block",
    marginBottom: "5px",
    fontWeight: "bold",
  },
  select: {
    width: "100%",
    padding: "10px",
    borderRadius: "5px",
    border: "1px solid #ccc",
  },
  input: {
    width: "100%",
    padding: "10px",
    borderRadius: "5px",
    border: "1px solid #ccc",
  },
  button: {
    backgroundColor: "#0077cc",
    color: "white",
    padding: "10px 20px",
    border: "none",
    borderRadius: "5px",
    width: "100%",
    fontSize: "16px",
    fontWeight: "bold",
  },
};

export default SalesOptions;
