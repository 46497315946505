import Repository from "./Repository";

const THEMING = "/customer/get_theming";

const customerRepository = {
  getTheming() {
    return Repository.get(`${THEMING}`);
  },
};

export default customerRepository;
