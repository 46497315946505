import { axiosLib } from "../../config/axios";

export const AuthenticateApi = async (payload) => {
  try {
    const res = await axiosLib.get(
      "https://www.googleapis.com/oauth2/v3/userinfo",
      {
        headers: {
          Authorization: `Bearer ${payload}`,
        },
      }
    );
    return res;
  } catch (err) {
    throw err;
  }
};
