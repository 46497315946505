import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Header from "../../Components/header";
import Footer from "../../Components/footer";
import ThankYou from "../thank-you";

const StartInterview = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    company: "",
    organizationType: "",
    useCase: "",
    agree: false,
  });

  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const validate = () => {
    let tempErrors = {};
    tempErrors.firstName = formData.firstName ? "" : "First name is required.";
    tempErrors.lastName = formData.lastName ? "" : "Last name is required.";
    tempErrors.email = formData.email ? "" : "Professional Email is required.";
    tempErrors.company = formData.company
      ? ""
      : "Company/Organization is required.";
    tempErrors.organizationType = formData.organizationType
      ? ""
      : "Organization Type is required.";
    tempErrors.useCase = formData.useCase ? "" : "Nexa Use Case is required.";
    tempErrors.agree = formData.agree
      ? ""
      : "You must agree to the terms and conditions.";
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === "");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        const response = await axios.post(
          "https://api.hubapi.com/integrations/v1/46448620/timeline/event-types?hapikey=5cd41d50-b757-42f5-8c30-6a450ee6c90b",
          {
            properties: [
              { property: "firstname", value: formData.firstName },
              { property: "lastname", value: formData.lastName },
              { property: "email", value: formData.email },
              { property: "company", value: formData.company },
              {
                property: "organization_type",
                value: formData.organizationType,
              },
              { property: "use_case", value: formData.useCase },
            ],
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer 5cd41d50-b757-42f5-8c30-6a450ee6c90b`,
            },
          }
        );
        if (response.status === 200) {
          setIsSubmitted(true);
        }
      } catch (error) {
        console.error(
          "There was an error submitting the form to HubSpot",
          error
        );
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          portalId: "46093892",
          formId: "224c07e7-fdcc-4a63-8b71-fc2f254d6096",
          target: "#hubspotForm",
        });
      }
    });
  }, []);
  {
    /* <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/embed/v2.js"></script>
<script>
  hbspt.forms.create({
    region: "na1",
    portalId: "46093892",
    formId: "224c07e7-fdcc-4a63-8b71-fc2f254d6096"
  });
</script> */
  }
  if (isSubmitted) {
    return <ThankYou />;
  }

  return (
    <div className="interveiw">
      <Header />
      <div
        className="ic-banner"
        style={{ backgroundImage: `url(${"/images/ic-banner.jpg"})` }}
      >
        <div
          className="container-fluid position-relative px-5 py-4 "
          style={{ maxWidth: "auto" }}
        >
          <div className="row">
            <div className="col-md-6 mt-0 py-4 ml-40px px-6 text-black interview-text">
              <h1>
                <b>
                  Nexa streamlines career management, upskilling, and coaching
                  throughout your organization
                </b>
              </h1>
              <br />
              <p className="support-text mt-2">
                Contact us{" "}
                <u>
                  <b>today</b>
                </u>{" "}
                to unlock:
                <br />
                <ul
                  className="mt-1 mx-2"
                  style={{ listStyleType: "disc", marginLeft: "80px" }}
                >
                  <br />
                  <li>Advanced Analytics and Reporting</li>
                  <br />
                  <li>AI-Driven Career Preparation Tool Suite</li>
                  <br />
                  <li>Tailored Career Pathways and Skill Gap Analysis</li>
                  <br />
                  <li>Unlimited Nexa seats across your organization</li>
                  <br />
                  <li>Enterprise grade SSO and security</li>
                  <br />
                  <li>Enterprise Dashboard & SmartScreen Vetting</li>
                  <br />
                  <li>Custom onboarding & 24/7 support</li>
                </ul>
              </p>
            </div>
            <div className="mt-0 px-4 py-2 col-md-6">
              <div className="row">
                <div className="col-md-11 col-sm-4 bg-light shadow-2xl start-interview-form">
                  <div>
                    <div id="hubspotForm"></div>
                  </div>
                  {/* <form name="salesform" onSubmit={handleSubmit}>
                    <div className="form-row">
                      <div className="col-md-12 mb-3">
                        <label htmlFor="firstName">First name *</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="First name"
                          name="firstName"
                          value={formData.firstName}
                          onChange={handleInputChange}
                        />
                        {errors.firstName && (
                          <div className="text-danger">{errors.firstName}</div>
                        )}
                      </div>
                      <div className="col-md-12 mb-3">
                        <label htmlFor="lastName">Last name *</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Last name"
                          name="lastName"
                          value={formData.lastName}
                          onChange={handleInputChange}
                        />
                        {errors.lastName && (
                          <div className="text-danger">{errors.lastName}</div>
                        )}
                      </div>

                      <div className="col-md-12 mb-3">
                        <label htmlFor="email">Professional Email *</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Email"
                          name="email"
                          value={formData.email}
                          onChange={handleInputChange}
                        />
                        {errors.email && (
                          <div className="text-danger">{errors.email}</div>
                        )}
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="col-md-12 mb-3">
                        <label htmlFor="company">
                          Name of Company/Organization *
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Company/Organization"
                          name="company"
                          value={formData.company}
                          onChange={handleInputChange}
                        />
                        {errors.company && (
                          <div className="text-danger">{errors.company}</div>
                        )}
                      </div>

                      <div className="col-md-12 mb-3">
                        <label htmlFor="organizationType">
                          Organization Type *
                        </label>
                        <div className="input-group">
                          <select
                            className="form-control"
                            name="organizationType"
                            value={formData.organizationType}
                            onChange={handleInputChange}
                          >
                            <option value="">Select...</option>
                            <option value="Enterprise">Enterprise</option>
                            <option value="Training center">
                              Training center
                            </option>
                            <option value="Career center">Career center</option>
                          </select>
                        </div>
                        {errors.organizationType && (
                          <div className="text-danger">
                            {errors.organizationType}
                          </div>
                        )}
                      </div>

                      <div className="col-md-12 mb-3">
                        <label htmlFor="useCase">Nexa Use Case *</label>
                        <div className="input-group">
                          <textarea
                            rows={8}
                            className="form-control"
                            name="useCase"
                            value={formData.useCase}
                            onChange={handleInputChange}
                          />
                        </div>
                        {errors.useCase && (
                          <div className="text-danger">{errors.useCase}</div>
                        )}
                      </div>
                    </div>
                    <div className="form-group submit-button"></div>
                    <button
                      className="btn btn-primary btn-rounded"
                      type="submit"
                    >
                      Submit
                    </button>
                  </form> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default StartInterview;
