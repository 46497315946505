import React, { useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useRef } from "react";

const Modal = ({ show = false, header, content, footer, onCreate }) => {
  const printRef = useRef();
  const [loader, setLoader] = useState(false);

  const downloadFeedback = async () => {
    setLoader(true);
    // Placeholder for any additional actions, like downloading feedback as PDF
  };

  return (
    <div className={`ic-modal ${show? "show" : ""}`}>
      <div className="ic-modal-dialog">
        <div className="modal-main-content w-100">
          {header && <div className="ic-modal-header">{header}</div>}
          <div className="ic-modal-body" ref={printRef}>
            {content}
          </div>
          <div className="ic-modal-body pt-0">
            <div className="ic-footer-modal p-0 d-flex justify-content-end">
              {footer}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;