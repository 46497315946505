import React, { useEffect, useState } from "react";
import Modal from "../tasks/createTaskModal";
import { MdDelete, MdFileUpload } from "react-icons/md";
import { error, success } from "../../Utilities/toast";
import {
  AddAttachmentFile,
  getSingleTaskDetail,
  udpateTaskStatus,
} from "../../Redux/TaskSlice";
import { useDispatch } from "react-redux";

const AttachmentModal = ({ modal, handleAttachmentModal, paramId }) => {
  const dispatch = useDispatch();
  console.log(paramId, "idbyahsan");
  const [attachment, setAttachment] = useState(null);
  const handleProfileChange = (e) => {
    const { files } = e.target;
    const file = files[0];
    const maxSizeInMB = 1;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

    if (file.type !== "application/pdf") {
      error("Only PDF files are allowed.");
      return;
    }

    if (file.size > maxSizeInBytes) {
      error(`File size must be less than ${maxSizeInMB}MB.`);
      return;
    }
    setAttachment(file);
  };

  const handleSubmit = () => {
    if (!attachment) {
      error("Please Upload a file");
      return;
    }
    const payload = new FormData();
    payload.append("task_file", attachment);
    dispatch(
      AddAttachmentFile({
        payload,
        paramId,
        onSuccess: (res) => {
          dispatch(
            udpateTaskStatus({
              taskID: paramId,
              status: "COMPLETED",
              onSuccess: () => {
                dispatch(
                  getSingleTaskDetail({ taskID: paramId, onSuccess: () => {} })
                );
                success("Task status updated successfully");
              },
            })
          );
        },
      })
    );
    handleAttachmentModal();
  };

  useEffect(() => {
    setAttachment(null);
  }, [handleAttachmentModal]);

  return (
    <Modal
      show={modal}
      header=""
      content={
        <>
          <div className="image-upload-container">
            {!attachment && (
              <>
                <input
                  accept=".pdf"
                  style={{ display: "none" }}
                  id="image-upload-input"
                  type="file"
                  name="image"
                  onChange={handleProfileChange}
                />
                <label htmlFor="image-upload-input">
                  <div className="image-upload-box">
                    <span className="upload-icon text-center">
                      <MdFileUpload className="m-auto" />
                      <p>Upload you Attachment</p>
                    </span>
                  </div>
                </label>
              </>
            )}

            {attachment && (
              <div className="image-preview-container">
                {/* <img
                  src={
                    typeof attachment === "object"
                      ? URL.createObjectURL(attachment)
                      : attachment
                  }
                  alt="Selected"
                  className="image-preview"
                /> */}
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQoT8tDa8lvdqVywMTlFsFhvINbgNFB48FCzA&s"
                  alt="Selected PDF"
                  className="image-preview"
                />
                <div
                  className="image-overlay"
                  onClick={() => setAttachment(null)}
                >
                  <button className="delete-icon">
                    <MdDelete />
                  </button>
                </div>
              </div>
            )}
          </div>
        </>
      }
      footer={
        <>
          <button onClick={handleAttachmentModal}>Close</button>
          <button onClick={() => handleSubmit()}>Add Attachment</button>
        </>
      }
    />
  );
};

export default AttachmentModal;
