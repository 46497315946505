import React, { useEffect } from "react";
import Header from "../../Components/header";
import { Link } from "react-router-dom";
import { getBlogs } from "../../Redux/BlogSlice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Loader from "../../Components/main-loader";

const Blogs = () => {
  const dispatch = useDispatch();
  const { blogs, loading } = useSelector((state) => state.blog);

  useEffect(() => {
    dispatch(getBlogs());
  }, []);
  return (
    <>
      {loading && <Loader />}
      <Header />
      <div
        className="ic-interview-full-body"
        style={{
          backgroundImage: `url(${"/images/interview-banner.png"})`,
        }}
      >
        <div className="blog-card_cover flex gap-5 flex-wrap justify-center py-32">
          {blogs?.map((blog) => (
            <div
              key={blog.id}
              className="w-[320px] md:w-[400px] shadow-lg overflow-hidden rounded-lg cursor-pointer"
            >
              <Link to={`/blog/view/${blog.id}`}>
                <img
                  src={blog.image}
                  alt="featured image"
                  className="h-[250px] w-full"
                />
                <div>
                  <div className="flex justify-between items-center px-3 mt-3">
                    <span className="bg-primary text-white rounded-xl px-2 py-1 text-xs uppercase">
                      {blog.category_name}
                    </span>
                    <p className="text-gray-400 text-sm">
                      {moment(blog.created_on).format("DD/MM/YYYY ")}
                    </p>
                  </div>
                  <div className="px-3 py-2">
                    <h2 className="font-bold line-clamp-1">{blog.title}</h2>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Blogs;