import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeveloperInterview from "../../Components/QuestionList";
import Header from "../../Components/header";
import CircularLoader from "../../Components/loader";
import DeveloperQuestions from "../../DummyData/questionDeveloper.json";
import { withRouter } from "../../Utilities";
import AllQuestions from "./components/allQuestions";
import DeveloperType from "./components/developerType";
import Interview from "./components/interview";
import QuestionInfo from "./components/questionsInfo";
import QuestionType from "./components/questionsType";
import Roles from "./components/roles";
import { useLocation } from "react-router-dom";
import { generateQuestions, getJobRoles } from "../../Redux/DashboardSlice";
// import BackButton from "../../Components/BackButton";
import {
  setPreviousMode,
  setinterviewMode,
  setTechStack,
} from "../../Redux/GlobalStates";
import TechStacks from "./components/techStacks";
// import Modal from "../../Components/modal";
import { stopMachineSpeaksGlobal } from "../../Utilities/textToSpeech";

const tabs = [
  { label: "Recommended Categories", value: "visible" },
  { label: "Archived Categories", value: "hidden" },
];

const InterviewRole = ({ params }) => {
  const dispatch = useDispatch();
  const { roles } = useSelector((state) => state.dashboard);
  const { interViewMode, techStack } = useSelector(
    (state) => state.globalState
  );
  const location = useLocation();
  const [techStackIcon, setTechStackIcon] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [roleCategoryId, setRoleCategoryId] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [interviewTrainingSessionId, setInterviewTrainingSessionId] =
    useState(null);
  const [selectedQuestionType, setSelectedQuestionType] = useState(null);
  const [interviewTimer, setInterviewTimer] = useState(false);
  const [loader, setLoader] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [audio, setAudio] = useState(true);

  const [collectedPayload, setCollectedPayload] = useState({
    roleCategoryId: "",
    interviewTrainingSessionId: "",
  });
  const [activeTab, setActiveTab] = useState(tabs[0].value);

  const handleMode = (currentMode) => {
    console.log({ interViewMode, currentMode }, "codebyahsaniqbal");
    dispatch(setPreviousMode(interViewMode));
    dispatch(setinterviewMode(currentMode));
  };

  useEffect(() => {
    if (params) {
      if (params.category) {
        setSelectedCategory(params.category);
      }
      if (params.role) {
        const selectedRole = roles?.jobRoles?.filter(
          (dRole) => dRole.name.replace(" ", "-").toLowerCase() === params.role
        );
        if (selectedRole && selectedRole.length) {
          setSelectedRole(selectedRole[0]);
        }
      }
      if (params.role === undefined) {
        setSelectedRole(null);
      }
      if (!params.category) {
        setSelectedCategory(null);
      }
      if (!params.role || !params.category) {
        handleMode(null);
        setQuestions([]);
        setInterviewTrainingSessionId(null);
      }
    }
  }, [params]);

  const getQuestions = async (params) => {
    console.log("params: ", params);
    console.log(selectedQuestionType, "selectedQuestionType");
    if (
      params.category &&
      params.role &&
      selectedQuestionType === "Round Robin"
    ) {
      setLoader(true);
      console.log(roleCategoryId);

      const payload = {
        roleCategoryId,
        questionCount: 5,
        random: true,
      };
      dispatch(generateQuestions(payload))
        .unwrap()
        .then((resolvedData) => {
          setCollectedPayload((prev) => ({
            ...prev,
            roleCategoryId: roleCategoryId,
            interviewTrainingSessionId: resolvedData.interviewTrainingSessionId,
          }));
          setQuestions(resolvedData?.questions);
          setInterviewTrainingSessionId(
            resolvedData?.interviewTrainingSessionId
          );
          setLoader(false);
        })
        .catch((rejectedValueOrSerializedError) => {
          console.error("Register failed", rejectedValueOrSerializedError);
        });
    }
  };

  useEffect(() => {
    dispatch(getJobRoles(activeTab));
  }, [dispatch, activeTab]);

  useEffect(() => {
    if (location.pathname === "/interview/role/") {
      setSelectedQuestionType(null);
    }
  }, [location]);

  useEffect(() => {
    console.log("selectedQuestionType: ", selectedQuestionType);
    getQuestions(params);
  }, [selectedQuestionType]);

  console.log("selectedRole>", selectedRole);
  useEffect(() => {
    return () => {
      if (audio) {
        stopMachineSpeaksGlobal();
        setAudio(false);
      }
    };
  }, [audio]);
  return (
    <div
      style={{
        backgroundImage: `url(${"/images/interview-banner.png"})`,
        backgroundSize: "cover",
        position: "relative",
      }}
      className="h-screen overflow-y-scroll"
    >
      <Header
        sound={interViewMode === "interview_start"}
        audio={audio}
        setAudio={setAudio}
      />
      {!selectedCategory && (
        <Roles
          roles={roles?.jobRoles}
          selectedRole={selectedRole}
          setRoleCategoryId={setRoleCategoryId}
          setTechStack={(data) => dispatch(setTechStack(data))}
          setTechStackIcon={setTechStackIcon}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabs={tabs}
        />
      )}
      {selectedCategory && !interViewMode && params.role !== "developer" && (
        <QuestionType
          handleMode={handleMode}
          roleCategoryId={roleCategoryId}
          setQuestions={setQuestions}
          setInterviewTrainingSessionId={setInterviewTrainingSessionId}
          setLoader={setLoader}
          setSelectedQuestionType={setSelectedQuestionType}
          developer={false}
          setInterviewTimer={setInterviewTimer}
        />
      )}
      {selectedCategory &&
        interViewMode === "Behavioural" &&
        params.role === "developer" && (
          <QuestionType
            handleMode={handleMode}
            roleCategoryId={roleCategoryId}
            setQuestions={setQuestions}
            setInterviewTrainingSessionId={setInterviewTrainingSessionId}
            setLoader={setLoader}
            setSelectedQuestionType={setSelectedQuestionType}
            developer={false}
            setInterviewTimer={setInterviewTimer}
          />
        )}
      {selectedCategory &&
        interViewMode === "Technical" &&
        params.role === "developer" && (
          <QuestionType
            handleMode={handleMode}
            roleCategoryId={roleCategoryId}
            setQuestions={setQuestions}
            setInterviewTrainingSessionId={setInterviewTrainingSessionId}
            setLoader={setLoader}
            setSelectedQuestionType={setSelectedQuestionType}
            developer={true}
            title={"Choose random number of questions of custom. Enjoy!"}
            setInterviewTimer={setInterviewTimer}
          />
        )}
      {selectedCategory &&
        !interViewMode &&
        params.role === "developer" &&
        techStack && (
          <DeveloperType
            handleMode={handleMode}
            setSelectedQuestionType={setSelectedQuestionType}
          />
        )}
      {selectedCategory &&
        !interViewMode &&
        params.role === "developer" &&
        !techStack && (
          <TechStacks
            developerRole={selectedCategory}
            setTechStack={(data) => {
              dispatch(setTechStack(data));
            }}
            techStackIcon={techStackIcon}
          />
        )}
      {loader && (
        <CircularLoader title="Our Nexa engine has started to find you the best questions for your session, please wait while we make you the best candidate! " />
      )}
      {!loader &&
        (interViewMode === "question_info" ||
          interViewMode === "question_info_developer") && (
          <QuestionInfo
            handleMode={handleMode}
            developer={
              interViewMode === "question_info_developer" ? true : false
            }
            role={params?.category}
            setInterviewTimer={setInterviewTimer}
          />
        )}
      {interViewMode === "all_questions" && (
        <AllQuestions
          questions={questions && questions}
          handleMode={handleMode}
          role={"Developer"}
        />
      )}
      {interViewMode === "interview_start" && (
        <Interview
          allQuestions={questions && questions}
          // allQuestions={demoQuestions}
          audio={audio}
          type={params?.category}
          interviewTrainingSessionId={interviewTrainingSessionId}
          setInterviewTimer={setInterviewTimer}
          interviewTimer={interviewTimer}
        />
      )}
      {interViewMode === "interview_start_developer" && (
        <DeveloperInterview
          techStack={techStack}
          collectedPayload={collectedPayload}
          interviewTimer={interviewTimer}
          setInterviewTimer={setInterviewTimer}
        />
      )}
    </div>
  );
};

export default withRouter(InterviewRole);
