import React, { memo } from "react";
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";

const TableRow = memo(({ row }) => {
  return (
    <tr className="border-b">
      <td className="px-6 py-3">{row.id}</td>
      <td className="px-6 py-3">{row.createdAt}</td>
      <td className="px-6 py-3">{row.jobPosition}</td>
      <td className="px-6 py-3 flex justify-center gap-3">
        <button className="w-8 h-8 rounded-full flex justify-center items-center border hover:bg-gray-100 hover:scale-110 transition-all transform duration-200">
          <FaEdit />
        </button>
        <button className="w-8 h-8 rounded-full flex justify-center items-center border hover:bg-gray-100 hover:scale-110 transition-all transform duration-200">
          <RiDeleteBin6Line />
        </button>
      </td>
    </tr>
  );
});

export default TableRow;
