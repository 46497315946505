import React, { useEffect, useRef, useState } from "react";
import { FaRegFile } from "react-icons/fa6";
import MDX from "../../Components/MDX";
import Header from "../../Components/header";
import { error } from "../../Utilities/toast";
import userIcon from "../../assets/icons/userIcon.svg";
import useChatLogs, { openDB } from "../../hook/chatStream";

export const getData = (db, storeName, key) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(storeName, "readonly");
    const store = transaction.objectStore(storeName);
    const request = store.get(key);

    request.onsuccess = () => {
      resolve(request.result);
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
};

export const getAllData = (db, storeName) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(storeName, "readonly");
    const store = transaction.objectStore(storeName);
    const request = store.getAll();

    request.onsuccess = () => {
      resolve(request.result);
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
};

const BottomIcons = () => {
  return (
    <div className="bottom-icons">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
        className="icon-md-heavy"
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M7 5a3 3 0 0 1 3-3h9a3 3 0 0 1 3 3v9a3 3 0 0 1-3 3h-2v2a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3v-9a3 3 0 0 1 3-3h2zm2 2h5a3 3 0 0 1 3 3v5h2a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1h-9a1 1 0 0 0-1 1zM5 9a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h9a1 1 0 0 0 1-1v-9a1 1 0 0 0-1-1z"
          clipRule="evenodd"
        ></path>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
        className="icon-md-heavy"
      >
        <path
          fill="currentColor"
          d="M3.07 10.876C3.623 6.436 7.41 3 12 3a9.15 9.15 0 0 1 6.012 2.254V4a1 1 0 1 1 2 0v4a1 1 0 0 1-1 1H15a1 1 0 1 1 0-2h1.957A7.15 7.15 0 0 0 12 5a7 7 0 0 0-6.946 6.124 1 1 0 1 1-1.984-.248m16.992 1.132a1 1 0 0 1 .868 1.116C20.377 17.564 16.59 21 12 21a9.15 9.15 0 0 1-6-2.244V20a1 1 0 1 1-2 0v-4a1 1 0 0 1 1-1h4a1 1 0 1 1 0 2H7.043A7.15 7.15 0 0 0 12 19a7 7 0 0 0 6.946-6.124 1 1 0 0 1 1.116-.868"
        ></path>
      </svg>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="icon-md-heavy"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.8727 21.4961C11.6725 21.8466 11.2811 22.0423 10.8805 21.9922L10.4267 21.9355C7.95958 21.6271 6.36855 19.1665 7.09975 16.7901L7.65054 15H6.93226C4.29476 15 2.37923 12.4921 3.0732 9.94753L4.43684 4.94753C4.91145 3.20728 6.49209 2 8.29589 2H18.0045C19.6614 2 21.0045 3.34315 21.0045 5V12C21.0045 13.6569 19.6614 15 18.0045 15H16.0045C15.745 15 15.5054 15.1391 15.3766 15.3644L11.8727 21.4961ZM14.0045 4H8.29589C7.39399 4 6.60367 4.60364 6.36637 5.47376L5.00273 10.4738C4.65574 11.746 5.61351 13 6.93226 13H9.00451C9.32185 13 9.62036 13.1506 9.8089 13.4059C9.99743 13.6612 10.0536 13.9908 9.96028 14.2941L9.01131 17.3782C8.6661 18.5002 9.35608 19.6596 10.4726 19.9153L13.6401 14.3721C13.9523 13.8258 14.4376 13.4141 15.0045 13.1902V5C15.0045 4.44772 14.5568 4 14.0045 4ZM17.0045 13V5C17.0045 4.64937 16.9444 4.31278 16.8338 4H18.0045C18.5568 4 19.0045 4.44772 19.0045 5V12C19.0045 12.5523 18.5568 13 18.0045 13H17.0045Z"
          fill="currentColor"
        ></path>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M19.898.855a.4.4 0 0 0-.795 0c-.123 1.064-.44 1.802-.943 2.305-.503.503-1.241.82-2.306.943a.4.4 0 0 0 .001.794c1.047.119 1.801.436 2.317.942.512.504.836 1.241.93 2.296a.4.4 0 0 0 .796 0c.09-1.038.413-1.792.93-2.308.515-.516 1.269-.839 2.306-.928a.4.4 0 0 0 .001-.797c-1.055-.094-1.792-.418-2.296-.93-.506-.516-.823-1.27-.941-2.317Z"
        ></path>
        <path
          fill="currentColor"
          d="M12.001 1.5a1 1 0 0 1 .993.887c.313 2.77 1.153 4.775 2.5 6.146 1.34 1.366 3.3 2.223 6.095 2.47a1 1 0 0 1-.003 1.993c-2.747.238-4.75 1.094-6.123 2.467-1.373 1.374-2.229 3.376-2.467 6.123a1 1 0 0 1-1.992.003c-.248-2.795-1.105-4.754-2.47-6.095-1.372-1.347-3.376-2.187-6.147-2.5a1 1 0 0 1-.002-1.987c2.818-.325 4.779-1.165 6.118-2.504 1.339-1.34 2.179-3.3 2.504-6.118A1 1 0 0 1 12 1.5ZM6.725 11.998c1.234.503 2.309 1.184 3.21 2.069.877.861 1.56 1.888 2.063 3.076.5-1.187 1.18-2.223 2.051-3.094.871-.87 1.907-1.55 3.094-2.05-1.188-.503-2.215-1.187-3.076-2.064-.885-.901-1.566-1.976-2.069-3.21-.505 1.235-1.19 2.3-2.081 3.192-.891.89-1.957 1.576-3.192 2.082Z"
        ></path>
      </svg>
    </div>
  );
};

const Sidebar = ({ label }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <button
        onClick={toggleSidebar}
        className=" p-2 bg-blue-500 text-white rounded-3xl px-4"
      >
        {label || "Toggle"}
      </button>
      <div
        className={`fixed inset-0 bg-black bg-opacity-50 z-40 transition-opacity duration-300 ease-in-out ${
          isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        onClick={toggleSidebar}
      ></div>

      <div
        className={`fixed top-0 right-0 h-full text-black bg-white sm:w-[450px] md:w-[720px] 2xl:w-[960px] pl-3 pr-3 transform z-50 ${
          isOpen ? "translate-x-0" : "translate-x-full"
        } transition-transform duration-300 ease-in-out`}
      >
        <div className="flex justify-start items-center my-3 gap-4">
          <button className="text-4xl" onClick={toggleSidebar}>
            x
          </button>
          <h2 className=" text-lg font-bold">Chat History</h2>
        </div>
        <ChatHistory />
      </div>
    </div>
  );
};

// extracts chats from indexedDB and renders them
const ChatHistory = () => {
  const [messages, setMessages] = useState([]);
  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const db = await openDB("chatDB", 1, (db) => {
          if (!db.objectStoreNames.contains("messages")) {
            db.createObjectStore("messages", {
              keyPath: "id",
              autoIncrement: true,
            });
          }
        });

        const storedMessages = await getAllData(db, "messages");

        if (storedMessages && storedMessages.length > 0) {
          const allMessages = storedMessages.flatMap(
            (item) => item.messages || []
          );
          setMessages(allMessages);
        }
      } catch (error) {
        console.error("Error fetching messages from IndexedDB:", error);
      }
    };

    fetchMessages();
  }, []);

  return <ChatsRenderer messages={messages} />;
};

const ChatsRenderer = ({ messages, handleIconChange }) => {
  const chatContainerRef = useRef(null);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scroll({
        top: chatContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };
  return (
    <div
      className="w-full justify-center overflow-y-scroll pb-40 h-full"
      ref={chatContainerRef}
    >
      {!messages || messages.length === 0 ? (
        <div className="ic-interview-card-container">
          <span className="text-2xl text-center">No chats found</span>
        </div>
      ) : (
        <>
          <div className="flex flex-col w-full mx-auto">
            {messages?.map((message, index) =>
              message.role === "user" ? (
                <div className="flex w-full justify-end" key={index}>
                  <div className="bg-gray-300/75 p-2 px-4 rounded-[20px]">
                    {message.content}
                  </div>
                </div>
              ) : (
                <div key={index} className={"mt-4 justify-start"}>
                  <div className={`my-4`}>
                    <div className="flex items-start gap-4">
                      <img src={userIcon} alt="avatar" className=" size-10" />
                      <span className="w-full">
                        {message?.meta?.loading ? (
                          message.meta.chunks.map((chunk) => (
                            <span className="fade-in">
                              <MDX>{chunk.content}</MDX>
                            </span>
                          ))
                        ) : (
                          <MDX>{message.content}</MDX>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        </>
      )}
    </div>
  );
};

const VoyceSimulation = () => {
  const [input, setInput] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [file, setFile] = useState(null);
  const { messages, submitPrompt, uploadFile, loading } = useChatLogs({
    storeChats: true,
    chatDB: "chatDB",
    url: "/nexavoyce/stream-response",
  });

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleIconChange = (value) => {
    console.log("Clicked icon with value:", value);
    setInput(value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      setInput(e.target.value);
      handleSendMessage();
    }
  };

  const handleSendMessage = async () => {
    if (file) {
      if (!input?.length) {
        error("message cannot be blank");
        return;
      }

      uploadFile(file, input);
      setInput("");
      setFile(null);
      setImagePreview(false);
    } else {
      submitPrompt(input, file);
      setInput("");
    }
  };

  const handleAttachmentClick = () => {
    document.getElementById("file-input").click();
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImagePreview(e.target.result);
      };
      reader.readAsDataURL(selectedFile);
      setFile(selectedFile);
    }
  };

  return (
    <div className="h-screen flex flex-col">
      <Header />

      <div className="flex-1 overflow-hidden">
        <div className="ic-interviews voyce py-0 h-full">
          <div className="container pt-32">
            <h1 className="text-center text-bold"> Voyce</h1>
            {messages.length === 0 ? (
              <div className="ic-interview-card-container">
                <a
                  className="ic-interview-items aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-delay="50"
                  data-aos-duration="500"
                  onClick={() =>
                    handleIconChange("How do I enhance my LinkedIn Profile")
                  }
                >
                  <div className="ic-icons">
                    <img
                      src="/images/icon.png"
                      className="img-fluid"
                      alt="Beauty Services"
                    />
                  </div>
                  <p className="voyce_P-lineheight">
                    How do I enhance my LinkedIn Profile?
                  </p>
                  <i className="size-30" style={{ fontSize: "22px" }}></i>
                </a>
                <a
                  className="ic-interview-items aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-delay="50"
                  data-aos-duration="700"
                  onClick={() => handleIconChange("Create my career journey")}
                >
                  <div className="ic-icons">
                    <img
                      src="/images/icon.png"
                      className="img-fluid"
                      alt="C-Level"
                    />
                  </div>
                  <p className="voyce_P-lineheight">Create my Career Journey</p>
                  <i className="size-30" style={{ fontSize: "22px" }}></i>
                </a>
                <a
                  className="ic-interview-items aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-delay="50"
                  data-aos-duration="900"
                  onClick={() =>
                    handleIconChange(
                      "What are some Effective Strategies for finding job openings in my field?"
                    )
                  }
                >
                  <div className="ic-icons">
                    <img
                      src="/images/icon.png"
                      className="img-fluid"
                      alt="Cloud Computing"
                    />
                  </div>
                  <p className="voyce_P-lineheight">
                    Effective Strategies to find a Job
                  </p>
                  <i className="size-30" style={{ fontSize: "22px" }}></i>
                </a>
                <a
                  className="ic-interview-items aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-delay="50"
                  data-aos-duration="1100"
                  onClick={() =>
                    handleIconChange("What is good workpalce etiquette?")
                  }
                >
                  <div className="ic-icons">
                    <img
                      src="/images/icon.png"
                      className="img-fluid"
                      alt="Confidence Booster"
                    />
                  </div>
                  <p className="voyce_P-lineheight">
                    What is good workplace ettiequte?
                  </p>
                  <i className="size-30" style={{ fontSize: "22px" }}></i>
                </a>
              </div>
            ) : (
              <div className="h-[720px] w-full lg:w-[900px] mx-auto">
                <ChatsRenderer
                  messages={messages}
                  handleIconChange={handleIconChange}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="container absolute bottom-0 w-full left-0 right-0">
        <div className="chat-input-container bg-white p-4 shadow-lg">
          <div className="input-wrapper flex gap-4">
            <button
              className="attachment-btn"
              onClick={handleAttachmentClick}
            ></button>
            <input
              accept="application/pdf, application/json, text/plain, text/html, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              type="file"
              id="file-input"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />

            <input
              type="text"
              placeholder="Type your message..."
              value={input}
              onChange={handleInputChange}
              onKeyUp={handleKeyPress}
              // className={
              //   imagePreview ? "chat-input ms pb-5 mb-4" : "chat-input ms"
              // }
              className="chat-input ms"
              style={imagePreview ? { paddingLeft: 95 } : {}}
            />

            {imagePreview && (
              <div
                id="image-preview-container"
                className="flex items-center row"
              >
                {/* <img
                  id="image-preview"
                  src={imagePreview}
                  alt="Image Preview"
                  style={{ height: 35, width: 35 }}
                /> */}
                <FaRegFile size={18} />
              </div>
            )}

            <button
              disabled={loading}
              className="send-btn"
              onClick={handleSendMessage}
            ></button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VoyceSimulation;
