// Socket-related actions and listeners
import socket from "./socket";

export function addUserSocket(userId) {
  if (!socket.connected) {
    socket.auth = { token: userId };
    socket.connect();
  }
  socket.emit("add_user", { user_id: `Bearer ${userId}`, role: "customer" });
}

export const getMessage = (dispatch) => {
  const handleGetMessage = (message) => {
    console.log("New message received:", message);
    // dispatch(receiveMessageAction(message)); // Uncomment and use if needed
  };

  socket.on("get_message", handleGetMessage);

  return () => {
    socket.off("get_message", handleGetMessage);
  };
};

export const sendMessage = (messageData) => {
  if (socket.connected) {
    console.log("sending=>", socket.id);
    socket.emit("send_message", messageData, () => {
      console.log("Message sent successfully");
    });
  }
};

export function pageRefresh(token) {
  if (!socket.connected) {
    socket.auth = { token: token };
    socket.connect();
    console.log("Refresh==>", socket.id);
  }
  socket.emit("page_refresh", {
    user_id: `Bearer ${token}`,
    role: "customer",
  });

  socket.on("get_message", (message) => {
    console.log("New message received:", message);
  });
}

// save file (mentioned in data) in the backend
export function saveFile(data, callback) {
  if (!socket.connected) {
    socket.connect();
  }
  socket.emit("save_file", data, callback);
}

// fetch contents of a directory (depth 1)
export function fetchDir(path, callback) {
  if (!socket.connected) {
    socket.connect();
  }
  socket.emit("get_dir", { path }, callback);
}

export function fetchFileContent(path, callback) {
  if (!socket.connected) {
    socket.connect();
  }
  socket.emit("get_file", { path }, callback);
}
