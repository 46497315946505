// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.para {
  font-size: 20px;
  font-weight: 400;
}

.heading {
  font-size: 24px;
  font-weight: 700;
}
.layout-header {
  background-color: transparent;
}

/** Onboarding Layout Styles */
.layout-root {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  overflow-y: scroll;
}
@media (max-width: 768px) {
  .onBoard_heading {
    font-size: 20px;
  }
}

.progess-line {
  height: 5px;
  border-radius: 25px;
  width: 120px;
  background-color: #e4e4e4;
}
.completed {
  background-color: var(--green);
}
.content-container {
  padding: 48px;
}

.right-section {
  background-image: url(http://localhost:3000/static/media/ic-banner.557f8f42838fde60c453.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}
.right-section img {
  background-color: #e4e4e4;
  border-radius: 30px;
  object-fit: contain;
}

@media (max-width: 990px) {
  .right-section {
    display: none !important;
  }
  .content-container {
    padding: 0px;
  }
}

.btn-agree {
  text-align: center;
  margin-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/onboarding/onboarding.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,6BAA6B;AAC/B;;AAEA,8BAA8B;AAC9B;EACE,aAAa;EACb,WAAW;EACX,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE;IACE,eAAe;EACjB;AACF;;AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,YAAY;EACZ,yBAAyB;AAC3B;AACA;EACE,8BAA8B;AAChC;AACA;EACE,aAAa;AACf;;AAEA;EACE,4FAA4F;EAC5F,sBAAsB;EACtB,4BAA4B;EAC5B,gBAAgB;AAClB;AACA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE;IACE,wBAAwB;EAC1B;EACA;IACE,YAAY;EACd;AACF;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB","sourcesContent":[".para {\n  font-size: 20px;\n  font-weight: 400;\n}\n\n.heading {\n  font-size: 24px;\n  font-weight: 700;\n}\n.layout-header {\n  background-color: transparent;\n}\n\n/** Onboarding Layout Styles */\n.layout-root {\n  height: 100vh;\n  width: 100%;\n  overflow: hidden;\n  overflow-y: scroll;\n}\n@media (max-width: 768px) {\n  .onBoard_heading {\n    font-size: 20px;\n  }\n}\n\n.progess-line {\n  height: 5px;\n  border-radius: 25px;\n  width: 120px;\n  background-color: #e4e4e4;\n}\n.completed {\n  background-color: var(--green);\n}\n.content-container {\n  padding: 48px;\n}\n\n.right-section {\n  background-image: url(http://localhost:3000/static/media/ic-banner.557f8f42838fde60c453.jpg);\n  background-size: cover;\n  background-repeat: no-repeat;\n  overflow: hidden;\n}\n.right-section img {\n  background-color: #e4e4e4;\n  border-radius: 30px;\n  object-fit: contain;\n}\n\n@media (max-width: 990px) {\n  .right-section {\n    display: none !important;\n  }\n  .content-container {\n    padding: 0px;\n  }\n}\n\n.btn-agree {\n  text-align: center;\n  margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
