// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timer-div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.timer-box {
  width: 50%;
  /* padding: 10px; */
  /* background-color: #3f51b5; */
  /* color: white; */
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  /* border-radius: 10px; */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
  /* cursor: grab; */
  margin-bottom: 1rem;
}

.timer-text {
  font-size: 1rem;
  font-weight: bold;
  /* margin-bottom: 10px; */
}

.progress-bar-container {
  width: 90%;
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  margin: 0 10px;
}

.progress-bar {
  height: 100%;
  transition: width 0.5s ease, background-color 0.5s ease; /* Smooth transition */
  border-radius: 10px;
}

.timer-controls {
  display: flex;
  gap: 5px;
}

.timer-controls button {
  background-color: white;
  color: #3f51b5;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.timer-controls button:disabled {
  background-color: #bdbdbd;
  cursor: not-allowed;
}
`, "",{"version":3,"sources":["webpack://./src/assets/CSS/InterviewTimer.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,UAAU;EACV,mBAAmB;EACnB,+BAA+B;EAC/B,kBAAkB;EAClB,aAAa;EACb,4BAA4B;EAC5B,mBAAmB;EACnB,uBAAuB;EACvB,yBAAyB;EACzB,8CAA8C;EAC9C,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,yBAAyB;AAC3B;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,uDAAuD,EAAE,sBAAsB;EAC/E,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,QAAQ;AACV;;AAEA;EACE,uBAAuB;EACvB,cAAc;EACd,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;AACrB","sourcesContent":[".timer-div {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.timer-box {\n  width: 50%;\n  /* padding: 10px; */\n  /* background-color: #3f51b5; */\n  /* color: white; */\n  display: flex;\n  /* flex-direction: column; */\n  align-items: center;\n  justify-content: center;\n  /* border-radius: 10px; */\n  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */\n  /* cursor: grab; */\n  margin-bottom: 1rem;\n}\n\n.timer-text {\n  font-size: 1rem;\n  font-weight: bold;\n  /* margin-bottom: 10px; */\n}\n\n.progress-bar-container {\n  width: 90%;\n  height: 10px;\n  background-color: #e0e0e0;\n  border-radius: 5px;\n  overflow: hidden;\n  margin: 0 10px;\n}\n\n.progress-bar {\n  height: 100%;\n  transition: width 0.5s ease, background-color 0.5s ease; /* Smooth transition */\n  border-radius: 10px;\n}\n\n.timer-controls {\n  display: flex;\n  gap: 5px;\n}\n\n.timer-controls button {\n  background-color: white;\n  color: #3f51b5;\n  border: none;\n  padding: 5px 10px;\n  border-radius: 5px;\n  cursor: pointer;\n}\n\n.timer-controls button:disabled {\n  background-color: #bdbdbd;\n  cursor: not-allowed;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
