export const plans = {
  individual: {
    month: [
      {
        id: "2lNbMnPO8W",
        title: "Free Tier",
        name: "Nexa Explorer",
        description:
          "Ideal for: Individual job seekers starting their career journey and want to explore how the nexa suite of tools will get them a job offer more effectively.",
        price: "0",
        features: [
          "Recruiter Magnet Resume Builds",
          "Realtime voice and video interview simulation with instant feedback reports",
        ],
      },

      {
        id: "ey3PX9En4R",
        title: "Growth Tier",
        name: "Nexa Career",
        description:
          "Ideal for: Job seekers that are on the hunt to land a job in the next 90 days and NEED access to all career preparation tools to dominate their sector and stand out to hiring managers.",
        price: "22.99",
        features: [
          "Everything in Explorer",
          "Advanced Resume Analytics",
          "Realtime voice and video interview simulation with instant feedback reports",
          "Job Board + Application Automation",
        ],
      },
      // {
      //   id: "custom",
      //   title: "Custom Tier",
      //   name: "Nexa Scale",
      //   price: "Custom",
      //   description: "Ideal for full customization of your career center",
      //   idealFor:
      //     "Ideal For Training Centers & Large Organizations Requiring Tailored Solutions.",
      //   features: [
      //     "Everything in Career",
      //     "Custom integrations",
      //     "White-Labeling",
      //     "Advanced Administrative Controls",
      //     "Dedicated Support",
      //     "Around The Clock Consulting",
      //     "Scalability For Institutional Needs",
      //   ],
      // },
    ],
    year: [
      {
        id: "kdqEYpPVvp",
        title: "Career Tier",
        name: "Nexa Career",
        price: "79.99",
        features: [
          "Daily Access To Interview Training Center",
          "Daily Access To Nexa Interview Simulation With Realtime Feedback",
          "Resume Feedback + Resume Builds Realtime",
          "Career Roadmap Builder",
          "Personal NEXA AI Consultant : Nexa Voyce",
          "Ideal For Job seekers and Students who Are Actively Preparing For Job Interviews And Want To Enhance Their Resumes Use The Real Time feedback to land a Job In Their Desired Career.",
        ],
      },
    ],
  },
  organization: {
    month: [
      {
        id: "QolEraPnrw",
        title: "Free Tier",
        name: "Nexa Explorer",
        price: "0.00",
        description:
          " Ideal For Training organizations that want to pilot how Nexa will increase their career center operations and services which will improve their job placement results while reducing financial overhead.",
        idealFor:
          "Ideal For Training Organizations Trying To Explore The Benefits Of Nexa",
        features: [
          "Recruiter Magnet Resumes",
          "Realtime Voice & Video Interview Simulation With Instant Feedback Reports",
          "Unlock The Entire Experience With No Limits For Up To 5 Users, & Add As Many Admins As You Need",
        ],
      },

      {
        id: "0qXPowPlVW",
        title: "Growth Tier",
        name: "Nexa Career",
        price: "20 / user",
        description:
          "Ideal for organizations seeking to provide a top-tier career center experience that not only enhances job seeker support but also streamlines operational workflows, saving both time and money. This comprehensive solution leads to increased enrollments by offering full access to all career preparation tools, including recruitment and employer networks, alongside robust advisor dashboards.",

        idealFor:
          "Ideal For Organizations Looking To Provide Their Job Seekers Full Access To All Career Preparation Tools",
        features: [
          "Everything In Explorer",
          "For Career Centers: Multi-Admin Roles",
          "Candidate Smart-Screening for Employer Networks",
          "Build Your Own Interview Simulations",
          "Create Sub-Organizations",
          "Skills Gap Analyzer Created To Better Assist Learners",
          "Career Advising Co-Pilot",
          "Context View To Peek Into Job-Seeker Portal",
          "Detailed Job-Seeker Analytics In Realtime",
          "For Job Seekers: Live Analytics to Track Progress & Organize Career Objectives",
          "Linkedin Recruiter Connection Builder",
          "Realtime Resume Feedback Reporting",
          "Nexa Auto-Pilot- Job Application Auto Applier",
          "Nexa IQ- Enhanced Job Board with Real Time Labor Market Analytics In Users Job Search",
          "Nexa Voyce- AI Career Counselling Co-Pilot Dedicated to Answering Any Question for Job-Seekers",
          "Nexa Octagon- Allows users to build experience prior to stepping in the Workforce Simulating Job Tasks",
        ],
      },
      {
        id: "enterprise",
        title: "Enterprise Tier",
        name: "Nexa Scale",
        price: "Custom",
        description: "Ideal for full customization of your career center",
        idealFor:
          "Ideal For Training Centers & Large Organizations Requiring Tailored Solutions.",
        features: [
          "Everything in Career",
          "Custom integrations",
          "White-Labeling",
          "Advanced Administrative Controls",
          "Dedicated Support",
          "Around The Clock Consulting",
          "Scalability For Institutional Needs",
        ],
      },
    ],
    year: [
      {
        id: "enterprise",
        title: "Enterprise Tier",
        name: "Nexa Scale",
        price: "Custom",
        description: "Ideal for full customization of your career center",
        idealFor:
          "Ideal For Educational institutions and large organizations requiring tailored solutions.",
        features: [
          "Everything In Career +",
          "Nexa Voyce Coaching",
          "Custom Integrations",
          "White-Labeling",
          "Advanced Administrative Controls",
          "Dedicated Support",
          "Scalability For Institutional Needs",
        ],
      },
    ],
  },
};
