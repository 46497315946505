import { Link, useNavigate } from "react-router-dom";
import "./onboarding.css";
import YoutubeEmbed from "../youtubeEmbed/YoutubeEmbed";
import logo from "../../../src/assets/media/images/logo.jpeg";

export const OnboardingLayout = ({
  children,
  activeStep,
  handleNext,
  goBack,
  answer,
  submitLater,
}) => {
  const navigate = useNavigate();
  console.log("===========activeStep=============", activeStep);
  console.log("===========children length=============", children.length);
  console.log("===========answer=============", answer);
  console.log("===========answer length=============", answer?.length);
  return (
    <div className="row layout-root">
      <div className="col-lg ">
        <div className="container h-100">
          <div className="header headers layout-header">
            <div className="container">
              <div className="ic-navbar-heads">
                <div className="ic-logos">
                  <Link to="/">
                    <img src="/images/logo.png" alt="" />
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="content-container h-100">
            <div className="d-flex align-items-center">
              {Array(children.length)
                .fill(1)
                .map((_, index) => (
                  <div
                    className={`progess-line ml-2 ${
                      activeStep >= index + 1 && "completed"
                    }`}
                  />
                ))}
            </div>
            {children}
            <div className="d-flex flex-wrap align-items-center justify-content-start">
              {activeStep > 1 && (
                <button className="ic-btn next-btn mr-3 mt-0" onClick={goBack}>
                  Back
                </button>
              )}
              {activeStep <= children.length && (
                <button
                  className="ic-btn next-btn mt-0"
                  onClick={() => handleNext(activeStep + 1)}
                >
                  Next
                </button>
              )}
              {activeStep <= children.length && activeStep >= 2 && (
                <button
                  className="ic-btn chip-btn"
                  onClick={() => submitLater(activeStep)}
                >
                  Save and do later
                </button>
              )}
              {/* {console.log({ activeStep, legnth: children.length })} */}
              {/* {activeStep === children.length && (
                <button
                  type="button"
                  class="ic-btn next-btn"
                  data-toggle="modal"
                  data-target="#exampleModal"
                >
                  Next
                </button>
              )} */}
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg d-flex align-items-center justify-content-center right-section">
        <img
          src={logo}
          className="img-fluid"
          style={{ width: "744px" }}
          alt="."
        />
      </div>

      <div
        className="modal fade onboarding-modal"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="false"
        data-show="true"
      >
        <div className="modal-dialog onboarding-dialog" role="document">
          <div className="modal-content">
            <div class="modal-body">
              <div className="header headers layout-header">
                <div className="container">
                  <div className="ic-navbar-heads">
                    <div className="ic-logos">
                      <button
                        type="button"
                        data-dismiss="modal"
                        onClick={() => navigate("/")}
                      >
                        <img src="/images/logo.png" alt="" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="video-container">
                <YoutubeEmbed embedId="J_S34tZd9Ao" />
              </div>
            </div>
            <div class="p-1">
              <button
                type="button"
                className="ic-btn chip-btn"
                data-dismiss="modal"
                onClick={() => navigate("/home")}
              >
                Skip
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
