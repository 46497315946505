import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
// import { selectCategories } from "../../features/Skills/SkillsSlice";
// import { selectPublications } from "../../features/publications/PublicationSlice";
import Loader from "../main-loader";
import moment from "moment";
import { selectStep } from "../../features/companyinformation/StepsSlice";
import CVTemplate from "./CVTemplate";

const AICVTemplate = ({ tempRef, setSaveButton }) => {
  const containerRef = useRef(null);
  // const { profile } = useSelector((state) => state.profileForm);
  const { AiResume } = useSelector((state) => state.ResumeBuilder);
  const step = useSelector(selectStep);
  // console.log(
  //   workExperience,
  //   professionalSummaries,
  //   "professionalSummariesprofessionalSummaries"
  // );
  const [activePage, setActivePage] = useState(null);
  console.log(activePage, "activePage");
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };

  const GetAITemplate = () => {
    return (
      <>
        <div className="cv-child-container mb-50 border-none" ref={tempRef}>
          {/* Render profile data */}
          {AiResume?.profile && (
            <header className="cv-header">
              <p className="cv-name text-uppercase">
                {AiResume?.profile?.first_name} {AiResume?.profile?.last_name}
              </p>
              <p className="cv-email  text-center">
                {AiResume?.profile?.email && `${AiResume?.profile?.email}`}
                {AiResume?.profile?.email &&
                  (AiResume?.profile?.address ||
                    AiResume?.profile?.city ||
                    AiResume?.profile?.phone ||
                    AiResume?.profile?.linked_in ||
                    AiResume?.profile?.website) &&
                  " | "}

                {(AiResume?.profile?.address || AiResume?.profile?.city) &&
                  `${AiResume?.profile?.address ?? ""}${
                    AiResume?.profile?.address && AiResume?.profile?.city
                      ? ", "
                      : ""
                  }${AiResume?.profile?.city ?? ""}`}
                {(AiResume?.profile?.address || AiResume?.profile?.city) &&
                  (AiResume?.profile?.phone ||
                    AiResume?.profile?.linked_in ||
                    AiResume?.profile?.website) &&
                  " | "}

                {AiResume?.profile?.phone && `${AiResume?.profile?.phone}`}
                {AiResume?.profile?.phone &&
                  (AiResume?.profile?.linked_in ||
                    AiResume?.profile?.website) &&
                  " | "}

                {AiResume?.profile?.linked_in &&
                  `${AiResume?.profile?.linked_in}`}
                {AiResume?.profile?.linked_in &&
                  AiResume?.profile?.website &&
                  " | "}

                {AiResume?.profile?.website && `${AiResume?.profile?.website}`}
              </p>
            </header>
          )}
          {/* <section className="cv-professional_summary"> */}
          {AiResume?.professionalSummaries &&
            AiResume?.professionalSummaries?.length > 0 && (
              <section className="cv-professional_summary">
                <h2 className="cv-section-title">Professional Summary</h2>
                {AiResume?.professionalSummaries?.map((item, index) => (
                  <div
                    key={index}
                    className="cv-professional_summary-item"
                    ref={containerRef}
                  >
                    <p dangerouslySetInnerHTML={{ __html: item?.text }} />
                  </div>
                ))}
              </section>
            )}

          {AiResume?.certificates && AiResume?.certificates?.length > 0 && (
            <section className="cv-certifications">
              <h2 className="cv-section-title">Certifications</h2>

              {AiResume?.certificates.map((certification, index) => (
                <div
                  className="cv-certification-item d-flex justify-content-between align-items-center"
                  key={index}
                >
                  <div>
                    <h3 className="cv-item-title mb-0">{certification.name}</h3>
                    <p className="cv-item-description text-capitalize">
                      {certification?.provider}
                    </p>
                  </div>
                  <p className="cv-item-date">
                    {formatDate(certification?.start_date)} -{" "}
                    {formatDate(certification?.end_date)}
                  </p>
                </div>
              ))}

              {/* Add more certification items as needed */}
            </section>
          )}
          {AiResume?.skills && AiResume.skills?.length > 0 && (
            <section className="cv-skills">
              <h2 className="cv-section-title">Top Skills</h2>
              {AiResume?.skills?.map((category, index) => (
                <React.Fragment key={index}>
                  {category.name == "Top Skills" ? (
                    <>
                      <ul className="cv-skills-list">
                        {category?.skills &&
                          category?.skills.map((skill, index) => (
                            <li className="cv-skill_top" key={index}>
                              <span className="font-bold text-lg">•</span>{" "}
                              {skill?.name}
                            </li>
                          ))}
                      </ul>
                    </>
                  ) : (
                    <>
                      <ul className="cv-skills-list">
                        {category?.skills &&
                          category?.skills.map((skill, index) => (
                            <li className="cv-skill_top" key={index}>
                              <span className="font-bold text-lg">•</span>{" "}
                              {skill?.name}
                            </li>
                          ))}
                      </ul>
                    </>
                  )}
                </React.Fragment>
              ))}
            </section>
          )}

          {AiResume?.workExperience && AiResume?.workExperience?.length > 0 && (
            <section className="cv-professional_summary">
              <h2 className="cv-section-title">Work Experiences</h2>
              {AiResume?.workExperience?.map((experience, index) => (
                <div
                  key={index}
                  className="cv-professional_summary-item d-flex justify-content-between align-items-center"
                >
                  <div className="text-capitalize">
                    <h3 className="cv-item-title mb-0">
                      {experience?.company?.name}
                    </h3>
                    {experience?.roles?.map((item, index) => (
                      <>
                        <p className="cv-item-date">{item?.name}</p>
                      </>
                    ))}
                  </div>
                  <div className="text-capitalize">
                    {experience?.roles?.map((item, index) => (
                      <>
                        <p className="cv-item-date">
                          {formatDate(item?.started_at)}
                          {item?.ended_at == null
                            ? " - present"
                            : `${""} - ${formatDate(item?.ended_at)}`}
                        </p>
                      </>
                    ))}
                  </div>
                </div>
              ))}
            </section>
          )}

          {AiResume?.educations && AiResume?.educations?.length > 0 && (
            <section className="cv-professional_summary">
              <h2 className="cv-section-title">Educations</h2>
              {AiResume?.educations.map((education, index) => (
                <div
                  key={index}
                  className="cv-professional_summary-item d-flex align-items-center justify-content-between"
                  // ref={containerRef}
                >
                  <div>
                    <h3>{education.school_name}</h3>
                    <p className="cv-item-date">
                      {education.degree &&
                        education.degree + " Degree " + " | "}
                      {education?.field && education?.field}
                    </p>
                  </div>
                  <div>
                    <p className="cv-item-date text-capitalize">
                      {education?.ended_at == null
                        ? " present"
                        : `${formatDate(education?.ended_at)}`}
                    </p>
                  </div>
                </div>
              ))}
            </section>
          )}

          {AiResume?.projects && AiResume?.projects?.length > 0 && (
            <section className="cv-projects">
              <h2 className="cv-section-title">Projects</h2>
              {AiResume?.projects?.map((pro, index) => (
                <div className="cv-project-item" key={index}>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h3 className="cv-item-title mb-0">
                        {pro?.project_name}
                      </h3>
                      <p className="cv-item-date">{pro?.organization}</p>
                    </div>
                    <p className="cv-item-date">
                      {formatDate(pro?.start_date)} -{" "}
                      {formatDate(pro?.end_date)}
                    </p>
                  </div>
                  <p
                    className="cv-item-date px-2"
                    dangerouslySetInnerHTML={{
                      __html: pro?.additional_information,
                    }}
                  />
                </div>
              ))}
            </section>
          )}

          {AiResume?.publications && AiResume?.publications?.length > 0 && (
            <section className="cv-certifications">
              <h2 className="cv-section-title">Publications</h2>

              {AiResume?.publications.map((publication, index) => {
                return (
                  <div
                    className="cv-publication-item d-flex justify-content-between align-items-center"
                    key={index}
                  >
                    <div>
                      <h3 className="cv-item-title mb-0">
                        {publication.title}
                      </h3>
                      <p className="cv-item-date">{publication.publisher}</p>
                    </div>
                    <p className="cv-item-description">
                      {formatDate(publication?.date)}
                    </p>
                  </div>
                );
              })}

              {/* Add more certification items as needed */}
            </section>
          )}
          {AiResume?.interests?.interests?.length > 0 && (
            <section className="cv-interests">
              <h2 className="cv-section-title">Interests</h2>
              <ul className="cv-skills-list d-flex justify-content-start">
                {AiResume?.interests?.interests &&
                  AiResume?.interests?.interests.map((interest, index) => (
                    <li className="cv-skill_top" key={index}>
                      <span className="font-bold text-lg">•</span> {interest}
                    </li>
                  ))}
              </ul>
            </section>
          )}

          {AiResume?.softskills?.skills?.length > 0 && (
            <section className="cv-languages">
              <h2 className="cv-section-title">Soft Skills</h2>
              <ul className="cv-skills-list d-flex justify-content-start">
                {AiResume?.softskills?.skills &&
                  AiResume?.softskills?.skills?.map((skill, index) => (
                    <li className="cv-skill_top" key={index}>
                      <span className="font-bold text-lg">•</span> {skill}
                    </li>
                  ))}
              </ul>
            </section>
          )}
          {AiResume?.languages?.languages?.length > 0 && (
            <section className="cv-languages">
              <h2 className="cv-section-title">Languages</h2>
              <ul className="cv-skills-list d-flex justify-content-start">
                {AiResume?.languages?.languages &&
                  AiResume?.languages?.languages.map((language, index) => (
                    <li className="cv-skill_top" key={index}>
                      <span className="font-bold text-lg">•</span> {language}
                    </li>
                  ))}
              </ul>
            </section>
          )}
          {/* Add more sections as needed */}
        </div>
      </>
    );
  };

  return (
    <>
      {step == 3 && (
        <div className="cv-template_main">
          <div className="cv-template_wrapper-left">
            <h2 className="cv-template_title">Before</h2>
            <div
              className={`cv-main-container cv-main-container_select-cover ${
                activePage == 1 ? "active_page" : ""
              }`}
              onClick={() => {
                setActivePage(1);
                setSaveButton({ val: true, page: 1 });
              }}
            >
              <CVTemplate />
            </div>
          </div>
          <div className="cv-template_wrapper-right">
            <h2 className="cv-template_title">After</h2>
            <div
              className={`cv-main-container cv-main-container_select-cover ${
                activePage == 2 ? "active_page" : ""
              }`}
              onClick={() => {
                setActivePage(2);
                setSaveButton({ val: true, page: 2 });
              }}
            >
              {GetAITemplate()}
            </div>
          </div>
        </div>
      )}

      {step == 4 &&
        (activePage == 2 ? (
          <div className="cv-main-container">{GetAITemplate()}</div>
        ) : (
          <div className="cv-main-container">
            <CVTemplate tempRef={tempRef} />
          </div>
        ))}
    </>
  );
};

export default AICVTemplate;
