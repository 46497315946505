import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FeedbackMessageComponent from "../FeedbackMessageComponent";

const TaskFeedbackSection = () => {
  const { singleTask, loading } = useSelector((state) => state.tasks);
  const [modifiedFeedbacks, setModifiedFeedbacks] = useState([]);
  console.log(modifiedFeedbacks, "modifiedFeedbacks");

  useEffect(() => {
    // setqueryLength(singleTask?.feedbacks?.length);
    const restructuredData = singleTask?.feedbacks?.reduce((acc, feedback) => {
      const feedbackDate = moment(feedback?.created_at).format("MM/DD/YYYY");
      const existingDateIndex = acc?.findIndex(
        (item) => item.date === feedbackDate
      );
      if (existingDateIndex !== -1) {
        acc[existingDateIndex]?.feedbacks.push(feedback);
      } else {
        acc.push({
          date: feedbackDate,
          feedbacks: [feedback],
        });
      }
      return acc;
    }, []);
    setModifiedFeedbacks(restructuredData);
  }, [singleTask]);

  return (
    <>
      {singleTask?.feedbacks?.length <= 0 && (
        <div className="text-center my-6">
          <h2 className="text-2xl font-semibold">No feedbacks Found</h2>
        </div>
      )}
      {modifiedFeedbacks?.map((item, index) => (
        <>
          <div className="d-flex justify-content-center sticky-top" key={index}>
            <div className="border bg-white border-black text-black border-2 font-weight-bold rounded-lg p-2 px-4">
              {moment(item?.date).format("DD-MM-YYYY")}
            </div>
          </div>
          {item?.feedbacks?.map((feedback, index) => (
            <FeedbackMessageComponent key={index} feedback={feedback} />
          ))}
        </>
      ))}
    </>
  );
};

export default TaskFeedbackSection;
