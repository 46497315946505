// components/Chats.js
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendMessageAction } from "../../Redux/ChatSlice";
import Message from "./Message";
import Contact from "./Contact";
import { sendMessage } from "../../config/socketActions";
import { Link } from "react-router-dom";

const Chats = () => {
  const chatContainerRef = useRef(null);
  const dispatch = useDispatch();

  const { connectedChats, singleChat, singleChatLoading } = useSelector(
    (state) => state.chat
  );
  const [input, setInput] = useState("");

  const handleSendMessage = useCallback(
    (e) => {
      e.preventDefault();

      if (input.trim()) {
        const messageData = {
          chat_id: singleChat?.id,
          content: input,
        };

        // sendMessage(messageData);
        dispatch(sendMessageAction(messageData));
        setInput("");
      }
    },
    [input, singleChat]
  );

  // Scroll to the bottom whenever messages change
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [singleChat?.messages]);

  // Listen to scroll events
  useEffect(() => {
    const handleScroll = (event) => {
      // console.log("Chat container scrolled", event.target.scrollTop);
    };

    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      chatContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (chatContainer) {
        chatContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <div className="grid md:grid-cols-3 grid-cols-9 gap-4 md:p-4 p-2">
      <div className="md:col-span-1 col-span-4 h-[500px] overflow-y-auto bg-gray-200 p-4 rounded-lg">
        <h2 className="text-2xl font-semibold border-b-2 border-black border-opacity-25 mb-3">
          Chats
        </h2>
        <div className="flex flex-col gap-1 ">
          {connectedChats &&
            connectedChats.map((item, index) => (
              <Contact key={index} item={item} />
            ))}
        </div>
      </div>
      <div className="md:col-span-2 col-span-5 h-[500px] bg-gray-100 rounded-lg shadow-lg flex flex-col py-4 relative">
        {singleChatLoading && (
          <div className="absolute top-8 left-1/2 -translate-x-1/2">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900"></div>
          </div>
        )}
        <div
          className="flex-grow overflow-y-auto mb-4 sm:px-4 px-1 pb-3"
          ref={chatContainerRef}
        >
          {!singleChatLoading && !singleChat && (
            <div>
              <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center gap-1">
                <div className="navbar-logo mb-6">
                  <Link to="/">
                    <img src="/images/logo.png" alt="logo" />
                  </Link>
                </div>
                <h2 className="text-xl font-semibold text-gray-700">
                  No Chat Selected
                </h2>
                <p className="text-gray-500">
                  Please select a chat to start messaging.
                </p>
              </div>
            </div>
          )}
          {singleChat &&
            singleChat.messages.map((message, index) => (
              <Message key={index} message={message} />
            ))}
        </div>
        {singleChat && (
          <form onSubmit={handleSendMessage} className="flex sm:px-4 px-1">
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              className="flex-grow p-2 rounded-l"
              placeholder="Type a message..."
            />
            <button
              type="submit"
              className="bg-blue-500 text-white p-2 rounded-r"
            >
              Send
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default Chats;
