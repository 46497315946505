import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import testimonal1 from "../../assets/media/Landing/testimonial1.png";
import testimonal2 from "../../assets/media/Landing/testimonial2.png";
import testimonal3 from "../../assets/media/Landing/testimonial3.png";
import testimonal4 from "../../assets/media/Landing/testimonial4.jpeg";

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "#000",
        borderRadius: "50%",
      }}
      onClick={onClick}
    />
  );
};

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "#000",
        borderRadius: "50%",
      }}
      onClick={onClick}
    />
  );
};

const Testimonials = () => {
  const TestimonialsData = [
    {
      name: "Kieran",
      designation: "CEO and Founder",
      description:
        "Nexa helped us increase our enrollments by 50% thanks to our robust career services",
      icon: testimonal2,
    },
    {
      name: "Marianna Consultancy",
      designation: "CEO and Founder",
      description:
        "Nexa's career upskilling platform has been invaluable to my learners. I would highly recommend!",
      icon: testimonal1,
    },
    {
      name: "James",
      designation: "CEO and Founder",
      description:
        "Relevant and perfect for our data science bootcamp needs- we are grateful for their quick and timely responses. !",
      icon: testimonal3,
    },
    {
      name: "Eric",
      designation: "CEO and Founder",
      description:
        "Nexa is amazing for our job preparation. Kudos to all the efforts!",
      icon: testimonal4,
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="section text-center">
      <div className="gradient-right"></div>
      <h1 className="section-heading color-dark">What OUR CLIENTS SAY</h1>
      <div className="container my-5  ">
        <Slider {...settings}>
          {TestimonialsData?.map((testimonial, index) => (
            <div key={index} className="col mb-4 mb-lg-0 py-10">
              <div className="testimonial-card">
                <div className="testimonial-upper">
                  <div className="testimonial-img">
                    <img src={testimonial.icon} alt={testimonial.name} />
                  </div>
                  <div>
                    <h3 className="testimonial-name">{testimonial.name}</h3>
                    <p className="testimonial-designation">
                      {testimonial.designation}
                    </p>
                  </div>
                </div>
                <div className="divider"></div>
                <div>
                  <p className="testimonial-description">
                    {testimonial.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Testimonials;
