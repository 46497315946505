// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.skillsgap h1 {
  font-weight: bold;
  margin: 0.5rem 0;
  text-align: center;
}
.skills-heading {
  font-size: 22px;
  font-weight: 700;
  margin: 0.5rem 0;
}
.skillsgap h2 {
  font-size: 22px;
  font-weight: 700;
  margin: 0.5rem 0;
}
.skillsgap h3 {
  font-size: 20px;
  font-weight: 600;
  margin: 1rem 0;
}
.skillsgap h4 {
  font-size: 18px;
  font-weight: 600;
  margin: 1rem 0;
}
.skillsgap p {
  margin: 1rem 0.5rem;
}
.skillsgap p strong {
  font-size: 18px;
}
.skillsgap ul li {
  margin: 0.5rem 0 0.5rem 1.5rem;
  list-style: initial;
}
.skillsgap br {
  display: none;
}
@media (max-width: 525px) {
  .skillsgap h1 {
    font-size: 22px;
    line-height: 30px;
  }
  .skills-heading {
    font-size: 20px;
  }
  .skillsgap h2 {
    font-size: 18px;
  }
  .skillsgap h3 {
    font-size: 16px;
  }
  .skillsgap h4 {
    font-size: 16px;
  }
  .skillsgap p strong {
    font-size: 16px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/assets/CSS/SkillsGap.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;AAChB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;AAChB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,eAAe;AACjB;AACA;EACE,8BAA8B;EAC9B,mBAAmB;AACrB;AACA;EACE,aAAa;AACf;AACA;EACE;IACE,eAAe;IACf,iBAAiB;EACnB;EACA;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;AACF","sourcesContent":[".skillsgap h1 {\n  font-weight: bold;\n  margin: 0.5rem 0;\n  text-align: center;\n}\n.skills-heading {\n  font-size: 22px;\n  font-weight: 700;\n  margin: 0.5rem 0;\n}\n.skillsgap h2 {\n  font-size: 22px;\n  font-weight: 700;\n  margin: 0.5rem 0;\n}\n.skillsgap h3 {\n  font-size: 20px;\n  font-weight: 600;\n  margin: 1rem 0;\n}\n.skillsgap h4 {\n  font-size: 18px;\n  font-weight: 600;\n  margin: 1rem 0;\n}\n.skillsgap p {\n  margin: 1rem 0.5rem;\n}\n.skillsgap p strong {\n  font-size: 18px;\n}\n.skillsgap ul li {\n  margin: 0.5rem 0 0.5rem 1.5rem;\n  list-style: initial;\n}\n.skillsgap br {\n  display: none;\n}\n@media (max-width: 525px) {\n  .skillsgap h1 {\n    font-size: 22px;\n    line-height: 30px;\n  }\n  .skills-heading {\n    font-size: 20px;\n  }\n  .skillsgap h2 {\n    font-size: 18px;\n  }\n  .skillsgap h3 {\n    font-size: 16px;\n  }\n  .skillsgap h4 {\n    font-size: 16px;\n  }\n  .skillsgap p strong {\n    font-size: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
