import React from "react";
import SkillsButton from "./SkillsButton";

const Skill = ({ name, onDelete, onEdit }) => {
  return (
    <div className="skill">
      <p className="skill-name ">{name}</p>
      <SkillsButton onClick={onDelete}>
        <i className="ri-close-circle-line" style={{ fontSize: "18px" }}></i>
      </SkillsButton>
      <SkillsButton onClick={onEdit}>
        <i className="ri-edit-line" style={{ fontSize: "18px" }}></i>
      </SkillsButton>
    </div>
  );
};

export default Skill;
