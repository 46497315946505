import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import "./tailwind.css";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "remixicon/fonts/remixicon.css";
import "./assets/CSS/Landing.css";
import "./assets/CSS/SkillsGap.css";
import "./assets/CSS/aboutUs.css";
import "./assets/CSS/InterviewTimer.css";

import "./Css/bootstrap.min.css";
import "./Css/style.css";
import "./Css/variables.css";

import AOS from "aos";
import "aos/dist/aos.css";
import { useDispatch, useSelector } from "react-redux";
// import { refreshAuthToken } from "./Redux/authenticate/authenticate.action";
import { getDeclinedError } from "./Redux/PaymentsSlice";
import PackageExpireModal from "./Components/PackageExpireModal";
import { error } from "./Utilities/toast";
import Loader from "./Components/main-loader";

function App() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authenticate);
  const { paymentLoading } = useSelector((state) => state.paymentMethods);
  const [openModal, setopenModal] = useState(false);
  // useEffect(() => {
  //   let intervalId;

  //   if (user) {
  //     intervalId = setInterval(() => {
  //       dispatch(refreshAuthToken());
  //       console.log("callll refresh token");
  //     }, 6 * 60 * 60 * 1000);
  //   }

  //   return () => {
  //     if (intervalId) {
  //       clearInterval(intervalId);
  //     }
  //   };
  // }, [user, dispatch]);

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    if (!user?.subscriptionStatus) return;
    if (
      user?.subscriptionStatus == "active" ||
      user?.subscriptionStatus == "trialing"
    )
      return;
    if (user?.organizationId == null) {
      dispatch(
        getDeclinedError({
          onSuccess: () => {
            setopenModal(true);
          },
        })
      )
        .then((res) => {})
        .catch((rejected) => error(rejected?.response?.data?.detail));
    }
  }, [user]);

  return (
    <div>
      {paymentLoading && <Loader />}
      <Outlet />
      <PackageExpireModal openModal={openModal} setopenModal={setopenModal} />
    </div>
  );
}

export default App;
