import React from "react";
import Payments from "../../Pages/Payments";
import { useDispatch, useSelector } from "react-redux";
import { reActivatePackage } from "../../Redux/PaymentsSlice";
import { getUserSubscription } from "../../Redux/authenticate/authenticate.action";
import Methods from "../../Pages/Payments/data/Methods";
import { upgradeSubscription } from "../../Redux/DashboardSlice";

const ChangePaymentModal = ({
  modal,
  setopenModal,
  onClose,
  role,
  activePricingId,
}) => {
  const dispatch = useDispatch();
  const { declineError } = useSelector((state) => state.paymentMethods);
  const handleReactivate = () => {
    if (role) {
      dispatch(upgradeSubscription(activePricingId))
        .unwrap()
        .then((res) => {
          console.log({ res });
          if (res.checkout_url) {
            window.location.href = res.checkout_url;
          } else {
            dispatch(getUserSubscription());
          }
        });
    } else {
      dispatch(
        reActivatePackage({
          payload: declineError?.invoice_id,
          onSuccess: () => {
            onClose();
            setopenModal(false);
            dispatch(getUserSubscription());
          },
        })
      )
        .unwrap()
        .then((res) => {});
    }
  };
  return (
    <div>
      {modal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg w-11/12 sm:w-4/5 md:w-2/3 lg:w-4/5 h-auto relative max-h-[80vh] overflow-scroll">
            <Methods role="udpateSubscriptionModal" />
            <div className="flex justify-end mb-2 gap-5 mt-8 px-2">
              <button
                className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-lg focus:outline-none"
                onClick={handleReactivate}
                // disabled={loading}
              >
                ReActivate Subscription
              </button>
              <button
                className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-lg focus:outline-none"
                onClick={onClose}
                // disabled={loading}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChangePaymentModal;
