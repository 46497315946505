import React from "react";
import { Link } from "react-router-dom";
import Header from "../../Components/header";
import Footer from "../../Components/footer";

const ThankYou = () => {
    return (
        <div className="thank-you">
            <Header />

            <div
                className="ic-banner"
                style={{ backgroundImage: `url(${'/images/ic-banner.jpg'})` }}>
                <main className="text-center">
                    <section class="pb-fluid-lg bg-thd-color-grey-100 flex flex-col items-center justify-center pt-fluid-lg">
                        <div>
                            <button class=" bg-thd-color-white flex items-center gap-[.25em] mx-auto py-fixed-2xl px-fluid-sm rounded-[1.5em] lg:rounded-[1em] thd-heading-xl mb-2xl w-content">
                                <h3><b>Thanks!</b></h3>
                            </button>
                        </div>
                        <div class="text-thd-color-grey-30 thd-body-lg text-center mt-4">
                            <p>Thanks for your submission! We'll share more info soon.</p>
                        </div>
                    </section>

                </main>


            </div>



            <Footer />

        </div >
    )
}

export default ThankYou;
