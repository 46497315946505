import React, { useState } from "react";
import { error } from "../../../Utilities/toast";
import questionType from "../../../DummyData/developerType.json";

const DeveloperType = ({ handleMode, setSelectedQuestionType }) => {
  const [processing, setProcessing] = useState(false);

  const onQuestionTypeSelect = (name) => {
    setSelectedQuestionType(name);
    if (name === "Technical") {
      handleMode("Technical");
    } else {
      handleMode("Behavioural");
    }
  };

  return (
    <div className="ic-interviews">
      <div className="container">
        <div className="ic-interview-container">
          <h3 className="text-center text-3xl">
            What kind of Interview Would you be Interested in Practicing?
          </h3>

          <h6 className="text-center px-5 mt-3">
            Most coding interviews fall under two main categories: Technical &
            Behavioral. Here you can have us pick a bunch randomly for you or
            you can use the custom option to create your own practice set.
            Enjoy!
          </h6>
          <div className="ic-interview-card-container">
            {questionType.map((item, index) => (
              <button
                key={index}
                onClick={() => onQuestionTypeSelect(item.name)}
                className={`ic-interview-items`}
                data-aos="fade-up"
                data-aos-delay="50"
                data-aos-duration={index * 200 + 500}
              >
                <div className="ic-icons">
                  <img
                    src={`/images/${item.icon}`}
                    className="img-fluid"
                    alt={item.name}
                    style={{ width: "36px", height: "36px" }}
                  />
                </div>
                <p>{item.name}</p>
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeveloperType;
