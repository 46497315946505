import React from "react";
import CVTemplate from "./CVTemplate";

const Preview = () => {
  return (
    <div className="py-3">
      <div className="cv-main-container">
        <CVTemplate />
      </div>
    </div>
    // <div className="companyinformation">
    //   <div className="mydetails w-100 md:w-50">
    //   </div>
    // </div>
  );
};

export default Preview;
