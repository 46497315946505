import React from "react";
function CircularLoader({ title, icon }) {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      {icon ? (
        <div className="d-flex flex-column justify-content-center align-items-center feedback_loader">
          <i class="ri-ai-generate text-primary"></i>
          <div className="spinner-border text-primary m-3" role="status">
            <span className="visually-hidden">{title}</span>
          </div>
        </div>
      ) : (
        <div className="spinner-border text-primary m-3" role="status">
          <span className="visually-hidden">{title}</span>
        </div>
      )}
    </div>
  );
}

export default CircularLoader;
