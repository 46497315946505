import { memo } from "react";
import ReactMarkdown from "react-markdown";

const MDX = ({ children }) => {
  return (
    <ReactMarkdown
      className="markdown leading-relaxed text-[15px] w-full"
      components={{
        code(props) {
          const { children, className, node, ...rest } = props;

          return (
            <code
              {...rest}
              className={`${className} dark:text-yellow-200/50 text-yellow-800 dark:bg-neutral-900 bg-neutral-200 text-[13px] rounded-md p-[2px] px-1 font-mono w-full`}
            >
              {children}
            </code>
          );
        },
      }}
    >
      {children}
    </ReactMarkdown>
  );
};

// const Codeblock = ({ code, language, theme }) => {
//   return (
//     <div
//       className="w-full my-4 overflow-x-auto rounded-lg p-0 drop-shadow-lg"
//       style={{ fontFamily: "monospace" }}
//     >
//       <div className="flex bg-neutral-900 rounded-t-md justify-between w-[720px] items-center p-2 px-4">
//         <div className="text-xs dark:text-neutral-50 text-neutral-50">
//           {language}
//         </div>
//         <div className="">
//           <CopyIcon
//             className="cursor-pointer text-neutral-100"
//             onClick={async () => {
//               await copyContent(code);
//             }}
//           />
//         </div>
//       </div>
//       <SyntaxHighlighter
//         lineNumberStyle={{ fontFamily: "monospace" }}
//         style={theme}
//         showLineNumbers
//         // wrapLongLines
//         // wrapLines
//         PreTag={pretag}
//         CodeTag={codetag}
//         language={language}
//       >
//         {code}
//       </SyntaxHighlighter>
//     </div>
//   );
// };

export default MDX;
