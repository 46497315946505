import { iconsImgs, personsImgs } from "../utils/images";
// import { ReactComponent as Human } from "../../../assets/icons/image_2024_06_06T06_05_35_355Z.svg";
// import Human from "../../../assets/media/images/human.png";
// import adow from "../../../assets/media/images/adow.png";
// import octagon from "../../../assets/media/images/octagon.png";
// import blogs from "../../../assets/media/images/blogs.png";
// import TaskImg from "../../../assets/media/images/tasksImg.png";

const {
  Budget,
  Home,
  Plane,
  Wallet,
  adows,
  octagon,
  Human,
  TaskImg,
  blogs,
  Gears,
  search,
  Voyceicon,
  announcements,
} = iconsImgs;

export const navigationLinks = [
  { id: 1, title: "Home", Image: Home, link: "/home" },
  // { id: 2, title: "Career Goals", Image: Home, link: "/career-goals" },
  { id: 6, title: "Nexa Resume", Image: Wallet, link: "/resumes" },
  {
    id: 3,
    title: "Interview Practice",
    Image: Budget,
    link: "/interview/role/",
  },
  {
    id: 4,
    title: "Interview Simulator",
    Image: adows,
    link: "/mock-interview",
  },
  {
    id: 5,
    title: "Simulator Pro",
    Image: adows,
    link: "/simulator-pro",
    visible: process.env.REACT_APP_ENVIRONMENT,
  },
  // { id: 4, title: "Nexa Smart Screen", Image: Wallet, link: "/coming-soon" },
  // { id: 5, title: 'Loans', image: iconsImgs.bills }afdasfasf
  // { id: 6, title: 'Reports', image: iconsImgs.report },afsdfasff
  // { id: 7, title: 'Savings', image: iconsImgs.wallet },
  // { id: 8, title: 'Financial Advice', image: iconsImgs.wealth },
  // { id: 9, title: 'Account', image: iconsImgs.user },

  {
    id: 7,
    title: "Nexa Octagon (Beta)",
    Image: octagon,
    link: "/octagon",
  },
  { id: 8, title: "Nexa Voyce", Image: Human, link: "/nexa-voyce" },
  {
    id: 9,
    title: "Nexa IQ (Coming Soon)",
    Image: Plane,
    link: "/nexa-iq",
    visible: process.env.REACT_APP_ENVIRONMENT,
  },
  {
    id: 11,
    title: "Nexa Autopilot (Coming Soon)",
    Image: Plane,
    link: "/nexa-autopilot",
    visible: process.env.REACT_APP_ENVIRONMENT,
  },
  {
    id: 10,
    title: "Tasks",
    Image: TaskImg,
    link: "/tasks",
    // visible: process.env.REACT_APP_ENVIRONMENT,
  },
  // {
  //   id: 11,
  //   title: "Tickets",
  //   Image: TaskImg,
  //   link: "/tickets",
  //   // visible: process.env.REACT_APP_ENVIRONMENT,
  // },
];

export const navigationHomeLinks = [
  { id: 1, title: "Home", Image: "ri-home-line", link: "/user/home" },
  {
    id: 2,
    title: "My Profile",
    Image: "ri-dashboard-2-line",
    link: "/user/home",
  },
  {
    id: 3,
    title: "Career Goals",
    Image: "ri-user-line",
    link: "/career-goals",
  },
  {
    id: 4,
    title: "Resume",
    Image: "ri-draft-line",
    link: "/resume",
  },

  {
    id: 5,
    title: "News",
    Image: "ri-news-line",
    link: "/news",
    // visible: process.env.REACT_APP_ENVIRONMENT,
  },
  {
    id: 6,
    title: "Chats",
    Image: "ri-message-2-line",
    link: "/chats",
    // visible: process.env.REACT_APP_ENVIRONMENT,
  },
  { id: 7, title: "Billing", Image: "ri-bank-card-line", link: "/pricing" },
  {
    id: 8,
    title: "payments",
    Image: "ri-settings-line",
    link: "/payments",
    visible: process.env.REACT_APP_ENVIRONMENT,
    isIndividual: true,
  },
];

export const transactions = [
  {
    id: 11,
    name: "Sarah Parker",
    image: personsImgs.person_four,
    date: "23/12/04",
    amount: 22000,
  },
  {
    id: 12,
    name: "Krisitine Carter",
    image: personsImgs.person_three,
    date: "23/07/21",
    amount: 20000,
  },
  {
    id: 13,
    name: "Irene Doe",
    image: personsImgs.person_two,
    date: "23/08/25",
    amount: 30000,
  },
];

export const reportData = [
  {
    id: 14,
    month: "Jan",
    value1: 45,
    value2: null,
  },
  {
    id: 15,
    month: "Feb",
    value1: 45,
    value2: 60,
  },
  {
    id: 16,
    month: "Mar",
    value1: 45,
    value2: null,
  },
  {
    id: 17,
    month: "Apr",
    value1: 45,
    value2: null,
  },
  {
    id: 18,
    month: "May",
    value1: 45,
    value2: null,
  },
];

export const budget = [
  {
    id: 19,
    title: "Subscriptions",
    type: "Automated",
    amount: 22000,
  },
  {
    id: 20,
    title: "Loan Payment",
    type: "Automated",
    amount: 16000,
  },
  {
    id: 21,
    title: "Foodstuff",
    type: "Automated",
    amount: 20000,
  },
  {
    id: 22,
    title: "Subscriptions",
    type: null,
    amount: 10000,
  },
  {
    id: 23,
    title: "Subscriptions",
    type: null,
    amount: 40000,
  },
];

export const subscriptions = [
  {
    id: 24,
    title: "LinkedIn",
    due_date: "23/12/04",
    amount: 20000,
  },
  {
    id: 25,
    title: "Netflix",
    due_date: "23/12/10",
    amount: 5000,
  },
  {
    id: 26,
    title: "DSTV",
    due_date: "23/12/22",
    amount: 2000,
  },
];

export const savings = [
  {
    id: 27,
    image: personsImgs.person_one,
    saving_amount: 250000,
    title: "Pay kid bro’s fees",
    date_taken: "23/12/22",
    amount_left: 40000,
  },
];
