import Repository from "./Repository";

const TaskRepository = {
  getAllTasks() {
    return Repository.get(`/organization/task/customer/get_tasks?sort_by=task_status`);
  },
  getSingleTaskDetail(payload) {
    return Repository.get(`/organization/task/customer/${payload}`);
  },
  udpateTaskStatus(taskID, status) {
    const form = new FormData();
    form.append("task_status", status);
    return Repository.put(`/organization/task/update_status/${taskID}`, form);
  },
  addComment(taskID, comment) {
    const form = new FormData();
    form.append("comment", comment);
    return Repository.post(`/organization/task/${taskID}/comments`, form);
  },
  AddAttachmentFile(payload, paramId) {
    return Repository.post(`/organization/task/${paramId}/add_attachment`, payload);
  },
};

export default TaskRepository;
