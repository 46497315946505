import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reActivatePackage } from "../../Redux/PaymentsSlice";
import { getUserSubscription } from "../../Redux/authenticate/authenticate.action";
import ChangePaymentModal from "../ChangePaymentModal";

const PackageExpireModal = ({ openModal, setopenModal }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.authenticate);
  const { declineError } = useSelector((state) => state.paymentMethods);
  const [modal, setModal] = useState(false);

  const onClose = () => {
    setModal(!modal);
  };

  const handleReactivate = () => {
    dispatch(
      reActivatePackage({
        payload: declineError?.invoice_id,
        onSuccess: () => {
          setopenModal(false);
          dispatch(getUserSubscription());
        },
      })
    )
      .unwrap()
      .then((res) => {
      });
  };

  return (
    <div>
      {openModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg w-11/12 sm:w-4/5 md:w-2/3 lg:w-2/5 px-6 py-8 relative min-h-[200px]">
            <h3 className="text-center text-4xl font-medium mt-2">
              {declineError?.last_payment_error || "Your Card Was Declined"}
            </h3>
            <div className="mt-10 mb-1 flex gap-4 justify-center">
              <button
                className={`${
                  loading
                    ? "bg-blue-500 text-white cursor-not-allowed"
                    : "bg-blue-600 hover:bg-blue-700 text-white"
                } py-2 px-4 rounded-lg focus:outline-none`}
                onClick={handleReactivate}
                disabled={loading}
              >
                Retry with same payment method
              </button>
              <button
                className={`${
                  loading
                    ? "bg-blue-500 text-white cursor-not-allowed"
                    : "bg-blue-600 hover:bg-blue-700 text-white"
                } py-2 px-4 rounded-lg focus:outline-none`}
                onClick={onClose}
                disabled={loading}
              >
                Update Payment Method
              </button>
            </div>
          </div>
        </div>
      )}
      {modal && (
        <ChangePaymentModal
          modal={modal}
          onClose={onClose}
          setopenModal={setopenModal}
        />
      )}
    </div>
  );
};

export default PackageExpireModal;
