import React, { useEffect, useRef, useState } from "react";
import { LiveAudioVisualizer } from "react-audio-visualize";
import { FaRegCopy } from "react-icons/fa";
import { FaRegFile } from "react-icons/fa6";
import { IoMicOutline, IoMicSharp, IoSendSharp } from "react-icons/io5";
import MDX from "../../Components/MDX";
import Header from "../../Components/header";
import { error, info } from "../../Utilities/toast";
import useChatLogs from "../../hook/chatStream";
import {
  AttachmentButton,
  InputContainer,
  MessageItem,
  MicContainer,
  SendButton,
  VoyaceStaticMsgs,
} from "./voyace.style";
const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;
const mic = new SpeechRecognition();
mic.continuous = true;
mic.interimResults = true;
mic.lang = "en-US";
let voyace_static_messages = [
  "How do I enhance my LinkedIn Profile",
  "Create my career journey",
  "Effective Strategies for finding job",
  "What is good workplace etiquette?",
  "⁠How can I improve my resume to land an interview ?",
  "What networking strategies should I adapt to expand my professional network ?",
  "⁠What career resources are there in the United States Labor Department for professionals ?",
  "⁠What are some effective strategies for negotiating a salary ?",
  "How can I handle career setbacks, layoffs, or job changes, effectively ?",
];
const VoyceSimulation = () => {
  const [input, setInput] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [file, setFile] = useState(null);
  const { messages, submitPrompt, uploadFile, loading } = useChatLogs({
    storeChats: true,
    chatDB: "chatDB",
    url: "/nexavoyce/stream-response",
  });
  const [isListening, setIsListening] = useState(false);
  const [note, setNote] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const chatContainerRef = useRef(null);
  useEffect(() => {
    // Update body styles when the component mounts
    document.body.style.overflow = "hidden"; // Example: Disable scrolling
    // Clean up styles when the component unmounts
    return () => {
      document.body.style.overflow = ""; // Reset scrolling
    };
  }, []);
  useEffect(() => {
    scrollToBottom();
  }, [messages]);
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scroll({
        top: chatContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };
  useEffect(() => {
    if (isListening) {
      startRecording();
      handleListen();
    } else {
      stopRecording();
      mic.stop(); // Ensure the mic stops listening when isListening is false
    }
  }, [isListening]);
  const startRecording = async () => {
    try {
      if (mediaRecorder && mediaRecorder.state === "recording") {
        console.log("Recording is already started.");
        return;
      }
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      recorder.ondataavailable = (e) => {
        console.log("Data available: ", e.data);
      };
      setMediaRecorder(recorder);
      recorder.start();
      console.log("Recording started...");
    } catch (error) {
      console.error("Error accessing microphone: ", error);
    }
  };
  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      console.log("Recording stopped...");
      mediaRecorder.stream.getTracks().forEach((track) => track.stop()); // Ensure all tracks are stopped
      setMediaRecorder(null);
    }
  };
  const handleListen = () => {
    if (isListening) {
      mic.start();
      mic.onend = () => {
        console.log("continue..");
      };
      mic.onstart = () => {
        console.log("Mic's on");
      };
      mic.onresult = (event) => {
        const transcript = Array.from(event.results)
          .map((result) => result[0])
          .map((result) => result.transcript)
          .join("");
        setNote(transcript);
        setInput(transcript);
        mic.onerror = (event) => {
          console.log(event.error);
        };
      };
    } else {
      mic.stop();
      mic.onend = () => {
        console.log("Stopped Mic on Click");
      };
    }
  };
  const handleInputChange = (e) => {
    setInput(e.target.value);
  };
  const handleIconChange = (value) => {
    setInput(value);
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      setInput(e.target.value);
      handleSendMessage();
    }
  };
  const handleSendMessage = async (value) => {
    if (file) {
      if (!input?.length) {
        error("message cannot be blank");
        return;
      }
      uploadFile(file, input);
      setInput("");
      setFile(null);
      setImagePreview(false);
    } else if (value?.length) {
      submitPrompt(value, file);
      setInput("");
      setNote("");
    } else if (input?.length) {
      submitPrompt(input, file);
      setInput("");
    }
  };
  const handleAttachmentClick = () => {
    document.getElementById("file-input").click();
    console.log("enter hete>>");
  };
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    console.log("selectedFile>.", selectedFile);
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImagePreview(e.target.result);
      };
      reader.readAsDataURL(selectedFile);
      setFile(selectedFile);
    }
  };
  return (
    <div className="h-screen flex flex-col">
      <Header />
      <div className="flex-1 overflow-hidden">
        <div
          className="ic-interviews voyce py-0 h-full"
          // style={{ overflow: "scroll" }}
        >
          <div className="container pt-32">
            <h1 className="text-center text-bold flex justify-center items-center">
              <img
                src="/images/logo.png"
                alt="Nexa Logo"
                style={{ width: "150px", marginBottom: "0px" }}
              />
              <span
                style={{
                  marginLeft: "15px",
                  fontSize: "28px",
                  fontWeight: "bold",
                }}
              >
                Voyce
              </span>
            </h1>
            <p
              className="text-center text-bold max-w-md mx-auto font-serif"
              style={{ fontSize: "14px" }}
            >
              Nexa Voyce is your Dedicated Career Copilot. Ask her{" "}
              <span className="italic">anything</span> you need help with to
              achieve your career goals!
            </p>
            <br />
            <br />
            {/* <div className="flex-row border height-[50px] p-2 rounded-[5px] "></div> */}
            <InputContainer>
              <div className="flex">
                <AttachmentButton htmlFor="file-input" />
                <input
                  accept="application/pdf, application/json, text/plain, text/html, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  type="file"
                  id="file-input"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                {imagePreview && (
                  <FaRegFile size={18} className="mt-1.5 mr-1" />
                )}
                <MicContainer
                  isListening
                  // onMouseDown={startListening}
                  // onMouseUp={stopListening}
                  // onMouseLeave={stopListening}
                  onClick={(e) => {
                    e.preventDefault();
                    // if (!isSmallScreen) return;
                    if (isListening) {
                      handleSendMessage(note);
                      setTimeout(() => {
                        setInput("");
                      }, 200);
                    }
                    setIsListening(!isListening);
                  }}
                  className={`${
                    isListening
                      ? "h-[30px] w-[30px] rounded-full bg-red-500 flex justify-center items-center"
                      : ""
                  } `}
                >
                  {isListening ? (
                    <IoMicSharp size={20} color="#fff" />
                  ) : (
                    <IoMicOutline size={25} />
                  )}
                </MicContainer>
                {mediaRecorder && isListening ? (
                  <div className="flex ml-3">
                    <LiveAudioVisualizer
                      mediaRecorder={mediaRecorder}
                      width={150}
                      height={35}
                      barColor="#f76565"
                    />
                  </div>
                ) : null}
              </div>
              <input
                type="text"
                placeholder="Type your message..."
                value={input}
                onChange={handleInputChange}
                onKeyUp={handleKeyPress}
                className="bg-transparent"
                style={{ paddingRight: 10 }}
              />
              <SendButton disabled={loading} onClick={handleSendMessage}>
                <IoSendSharp size={20} color={loading ? "#ccc" : "#017bfe"} />
              </SendButton>
            </InputContainer>
            {messages.length === 0 ? (
              // <div className="ic-interview-card-container">
              <VoyaceStaticMsgs className="hide_scrollbar">
                {voyace_static_messages?.map((e, i) => (
                  <MessageItem
                    key={i}
                    className="ic-interview-items aos-init aos-animate"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="500"
                    onClick={() => handleIconChange(e)}
                  >
                    <div className="ic-icons">
                      <img
                        src="/images/icon.png"
                        className="img-fluid"
                        alt="Beauty Services"
                      />
                    </div>
                    <p className="voyce_P-lineheight line-clamp-2">{e}</p>
                    <i className="size-30" style={{ fontSize: "22px" }}></i>
                  </MessageItem>
                ))}
              </VoyaceStaticMsgs>
            ) : (
              <div
                ref={chatContainerRef}
                className="w-full lg:w-[900px] mx-auto overflow-y-scroll"
                style={{ height: "55vh" }}
              >
                <ChatsRenderer
                  messages={messages}
                  handleIconChange={handleIconChange}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default VoyceSimulation;
export const getData = (db, storeName, key) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(storeName, "readonly");
    const store = transaction.objectStore(storeName);
    const request = store.get(key);
    request.onsuccess = () => {
      resolve(request.result);
    };
    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
};
export const getAllData = (db, storeName) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(storeName, "readonly");
    const store = transaction.objectStore(storeName);
    const request = store.getAll();
    request.onsuccess = () => {
      resolve(request.result);
    };
    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
};
// extracts chats from indexedDB and renders them
const ChatsRenderer = ({ messages, handleIconChange }) => {
  const chatContainerRef = useRef(null);
  // useEffect(() => {
  //   scrollToBottom();
  // }, [messages]);
  // const scrollToBottom = () => {
  //   if (chatContainerRef.current) {
  //     chatContainerRef.current.scroll({
  //       top: chatContainerRef.current.scrollHeight,
  //       behavior: "smooth",
  //     });
  //   }
  // };
  const onCopy = (message, index) => {
    navigator.clipboard.writeText(
      `${message?.content}\n\n${messages?.[index + 1]?.content}`
    );
    info("copied!");
  };
  return (
    <div className="w-full justify-center  mt-11" ref={chatContainerRef}>
      {!messages || messages.length === 0 ? (
        <div className="ic-interview-card-container">
          <span className="text-2xl text-center">No chats found</span>
        </div>
      ) : (
        <>
          <div className="flex flex-col w-full mx-auto">
            {messages?.map((message, index) =>
              message.role === "user" ? (
                <div
                  className="flex w-full items-center justify-between"
                  key={index}
                >
                  <div
                    className="font-bold text-base"
                    // className="bg-gray-300/75 p-2 px-4 rounded-[20px]"
                  >
                    {message.content}
                  </div>
                  <FaRegCopy
                    size={18}
                    className="cursor-pointer"
                    onClick={() => onCopy(message, index)}
                  />
                </div>
              ) : (
                <div key={index} className={"mt-2 justify-start"}>
                  <div className={`my-2`}>
                    <div className="flex items-start gap-4">
                      {/* <img src={userIcon} alt="avatar" className=" size-10" /> */}
                      <span className="w-full">
                        {/* {message?.meta?.loading ? (
                          message.meta.chunks.map((chunk) => (
                            <span className="fade-in">
                              <MDX>{chunk.content}</MDX>
                            </span>
                          ))
                        ) : ( */}
                        <MDX>{message?.content}</MDX>
                        {/* )} */}
                        {/* {message.meta.chunks.map((chunk) => (
                          <span className="fade-in">
                            <MDX>{chunk.content}</MDX>
                          </span>
                        ))} */}
                      </span>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        </>
      )}
    </div>
  );
};
