import React from "react";
import Header from "../../Components/header";
const dummyAnnouncementData = [
  {
    announcement:
      "The start date for your job . Kindly update the start date to a future date.",
    announcementFrom: "Aqib Siddique",
    announcementDate: "5/5/2023",
    read: true,
  },
  {
    announcement: "Your job has been approved.",
    announcementFrom: "Ahsan Iqbal",
    announcementDate: "6/5/2023",
    read: false,
  },
  {
    announcement: "Your order has been shipped.",
    announcementFrom: "Munir Malik",
    announcementDate: "16/5/2023",
    read: false,
  },
];
export default function index() {
  return (
    <>
      <Header />
      <div
        className="ic-interview-full-body"
        style={{
          backgroundImage: `url(${"/images/interview-banner.png"})`,
          marginTop: "6em",
        }}
      >
        <main
          role="main"
          className="ml-sm-auto col-lg-12 px-4 bg-cover"
          style={{
            backgroundImage: `url(${"/images/interview-banner.png"})`,
          }}
        >
          <div
            style={{ marginTop: "50px", position: "relative", top: "6rem" }}
            className="w-full  stretch-card"
          >
            <div
              className="card border-rounded-card card-padding-profile  !overflow-scroll"
              style={{ boxShadow: "10px 10px 40px gray" }}
            >
              <div className="d-flex justify-between  items-center  mb-4 gap-x-1">
                <h1 className="font-bold d-flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    viewBox="0 0 448 512"
                  >
                    <path
                      fill="#007bff"
                      d="M224 0c-17.7 0-32 14.3-32 32V51.2C119 66 64 130.6 64 208v18.8c0 47-17.3 92.4-48.5 127.6l-7.4 8.3c-8.4 9.4-10.4 22.9-5.3 34.4S19.4 416 32 416H416c12.6 0 24-7.4 29.2-18.9s3.1-25-5.3-34.4l-7.4-8.3C401.3 319.2 384 273.9 384 226.8V208c0-77.4-55-142-128-156.8V32c0-17.7-14.3-32-32-32zm45.3 493.3c12-12 18.7-28.3 18.7-45.3H224 160c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7z"
                    />
                  </svg>
                  &nbsp; Announcements
                </h1>
                <button className="py-2 px-6 bg-primary border-none rounded-lg text-white">
                  Mark as all read
                </button>
              </div>
              <hr />
              <div>
                <div className="d-flex justify-between items-center mb-4 gap-x-1">
                  <h3 className="font-bold d-flex items-center">
                    All Announcements ({dummyAnnouncementData.length})
                  </h3>
                  <h3 className="font-bold d-flex items-center">
                    Unread Announcements (
                    {dummyAnnouncementData.filter((a) => !a.read).length})
                  </h3>
                </div>
                <div
                  style={{ marginTop: "20px" }}
                  className="w-full grid-margin stretch-card"
                >
                  <div
                    className="card border-rounded-card card-padding-profile !overflow-scroll"
                    style={{ boxShadow: "10px 10px 40px gray" }}
                  >
                    <div className="card-body  card-body-mobile">
                      <table
                        border={1}
                        style={{ width: "100%" }}
                        className="table table-hover w-full"
                      >
                        <thead>
                          <tr>
                            <th className="py-2 px-4 border-b">Announcement</th>
                            <th className="py-2 px-4 border-b">From</th>
                            <th className="py-2 px-4 border-b">Date</th>
                            <th className="py-2 px-4 border-b">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dummyAnnouncementData.map((announcement, index) => (
                            <tr
                              key={index}
                              className={`my-5 ${
                                announcement.read ? "bg-white" : "bg-gray-200"
                              }`}
                            >
                              <td className="py-2 px-4 border-b">
                                {announcement.announcement}
                              </td>
                              <td className="py-2 px-4 border-b">
                                {announcement.announcementFrom}
                              </td>
                              <td className="py-2 px-4 border-b">
                                {announcement.announcementDate}
                              </td>
                              <td className="py-2 px-4 border-b">
                                {announcement.read ? (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                    width="20"
                                    height="20"
                                  >
                                    <path d="M255.4 48.2c.2-.1 .4-.2 .6-.2s.4 .1 .6 .2L460.6 194c2.1 1.5 3.4 3.9 3.4 6.5v13.6L291.5 355.7c-20.7 17-50.4 17-71.1 0L48 214.1V200.5c0-2.6 1.2-5 3.4-6.5L255.4 48.2zM48 276.2L190 392.8c38.4 31.5 93.7 31.5 132 0L464 276.2V456c0 4.4-3.6 8-8 8H56c-4.4 0-8-3.6-8-8V276.2zM256 0c-10.2 0-20.2 3.2-28.5 9.1L23.5 154.9C8.7 165.4 0 182.4 0 200.5V456c0 30.9 25.1 56 56 56H456c30.9 0 56-25.1 56-56V200.5c0-18.1-8.7-35.1-23.4-45.6L284.5 9.1C276.2 3.2 266.2 0 256 0z" />
                                  </svg>
                                ) : (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                    width="20"
                                    height="20"
                                  >
                                    <path d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z" />
                                  </svg>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
