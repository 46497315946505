import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../Components/header";
import octoStack from "../../DummyData/octoStack.json";
import { OctaCatCards, OctagonCards } from "./octagonCards";

const Octagon = () => {
  const navigate = useNavigate();
  const { category, subcategory } = useParams();

  // State to hide/show the intro text
  const [showIntro, setShowIntro] = useState(true);

  const cards = [
    {
      label: "Coding Dojo",
      delay: 300,
      image: "/images/icon.png",
      link: "/codingdojo",
    },
    {
      label: "Sales Simulations",
      delay: 500,
      image: "/images/icon.png",
      link: "/salesoptions",
    },
    {
      label: "Customer Hospitality",
      delay: 700,
      image: "/images/icon.png",
      link: "/hospitality",
    },
    {
      label: "Management & Communications",
      delay: 900,
      image: "/images/icon.png",
      link: "/management",
    },
  ];

  useEffect(() => {
    document.body.style.overflow = "hidden"; // Disable scrolling
    return () => {
      document.body.style.overflow = ""; // Reset scrolling on unmount
    };
  }, []);

  // Effect to reset intro text when no category or subcategory is selected
  useEffect(() => {
    if (!category && !subcategory) {
      setShowIntro(true); // Show intro when no card is selected
    }
  }, [category, subcategory]);

  return (
    <div className="h-screen flex flex-col pb-20 md:pb-0">
      <Header />
      <div className="flex-1 overflow-y-scroll pb-2">
        <div className="ic-interviews voyce py-0 h-full">
          <div className="container pt-32">
            <h1 className="text-center text-bold text-2xl mb-8">
              Enter Nexa's Octagon & Face Real World Scenarios
            </h1>

            {/* Intro text */}
            {showIntro && (
              <div className="text-center text-sm mb-6 px-2 max-w-md mx-auto font-light text-gray-700 leading-relaxed italic">
                Nexa Octagon allows you to earn experience in a specific role to
                make you stand out as a better candidate for a position. Our
                engine uses AI to help simulate 100s of on-the-job scenarios
                based on specific companies. Let's get to work!
              </div>
            )}

            {/* Initial Options */}
            {!category && !subcategory && (
              <div className="ic-interview-card-container">
                {cards.map((card, index) => (
                  <OctagonCards
                    index={index}
                    key={index}
                    card={card}
                    onCardClick={() => {
                      setShowIntro(false); // Hide the intro text on card click
                      navigate(
                        { pathname: `/octagon${card.link}` },
                        { replace: true }
                      );
                    }}
                  />
                ))}
              </div>
            )}

            {/* Category Options */}
            {category && !subcategory && (
              <div className="ic-interview-card-container">
                {octoStack[category].map((card, index) => (
                  <OctaCatCards
                    index={index}
                    key={index}
                    card={card}
                    onCardClick={() => {
                      setShowIntro(false); // Hide the intro text on card click
                      navigate(
                        { pathname: `/octagon/${category}${card.link}` },
                        { replace: true }
                      );
                    }}
                  />
                ))}
              </div>
            )}

            {/* Subcategory Options */}
            {category && subcategory && (
              <div className="ic-interview-card-container flex flex-wrap justify-center">
                {octoStack["techstacks"].map((card, index) => (
                  <OctagonCards
                    index={index}
                    key={index}
                    card={card}
                    onCardClick={() => {
                      setShowIntro(false); // Hide the intro text on card click
                      navigate(
                        `/intervieworcustom/${category}/${subcategory}${card.link}`
                      );
                    }}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Octagon;
