import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  addResumeProfile,
  getResumeProfile,
  udpateResumeProfile,
} from "../../Redux/ResumeBuilderSlice";
import { success } from "../../Utilities/toast";

const Frozendetails = () => {
  const { id } = useParams();
  const { profile, contactId } = useSelector((state) => state.ResumeBuilder);
  const dispatch = useDispatch();

  const [editing, setIsEditing] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm();

  const getUserInfo = () => {
    const resumeId = id;
    dispatch(getResumeProfile({ resumeId, onSuccess: () => {} }));
  };

  const onSubmit = (data) => {
    data.zip = null;
    if (editing) {
      const userInfo = {
        contactId,
        data,
      };
      dispatch(
        udpateResumeProfile({
          userInfo,
          onSuccess: (res) => {
            success(res);
            getUserInfo();
          },
        })
      );
    } else {
      const userInfo = data;
      dispatch(
        addResumeProfile({
          userInfo,
          id,
          onSuccess: (res) => {
            success(res);
            getUserInfo();
          },
        })
      );
    }
    reset();
    setIsEditing(false);
  };

  useEffect(() => {
    if (profile) {
      // Populate form fields with profile data when editing
      Object.keys(profile).forEach((key) => {
        setValue(key, profile[key]);
      });
    }
  }, [profile, setValue]);

  return (
    <div className="mydetails">
      <div className="profile-data">
        <div className="profile-data-item">
          <i className="ri-user-shared-2-line"></i>
          <span>{profile?.first_name || "N/A"}</span>
        </div>
        <div className="profile-data-item">
          <i className="ri-user-received-2-line"></i>
          <span>{profile?.last_name || "N/A"}</span>
        </div>
        <div className="profile-data-item">
          <i className="ri-mail-open-line"></i>
          <span>{profile?.email || "N/A"}</span>
        </div>
        <div className="profile-data-item">
          <i className="ri-phone-line"></i>
          <span>{profile?.phone || "N/A"}</span>
        </div>
        <div className="profile-data-item">
          <i className="ri-linkedin-box-line"></i>
          <span>{profile?.linked_in ? profile.linked_in : "---"}</span>
        </div>
        <div className="profile-data-item">
          <i className="ri-twitter-x-line"></i>
          <span>{profile?.twitter ? profile.twitter : "---"}</span>
        </div>
        <div className="profile-data-item">
          <i className="ri-home-8-line"></i>
          <span>{profile?.address || "N/A"}</span>
        </div>
        <div className="profile-data-item">
          <i className="ri-building-2-line"></i>
          <span>{profile?.city || "N/A"}</span>
        </div>
        <div className="profile-data-item">
          <i className="ri-earth-line"></i>
          <span>{profile?.state || "N/A"}</span>
        </div>
        <div className="profile-data-item">
          <i className="ri-links-line"></i>
          <span>{profile?.website ? profile?.website : "---"}</span>
        </div>
      </div>
    </div>
  );
};

export default Frozendetails;
