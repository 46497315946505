import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { removeToken } from "../../Redux/authenticate/authenticate.slicer";
import { logoutUser } from "../../Redux/authenticate/authenticate.action";
import { personsImgs } from "../dashboardComponents/utils/images";
import userIcon from "../../assets/icons/userIcon.svg";
import { navData } from "../../DummyData/NavData";
import { setInterviewCompleted } from "../../Redux/GlobalStates";
import { IoClose } from "react-icons/io5";
import {
  navigationLinks,
  navigationHomeLinks,
} from "../dashboardComponents/data/data";
import { MobileSidebarContext } from "../../context/mobilesidemenuContext";
import "./Popupmenu.css";
import { getMessage, pageRefresh } from "../../config/socketActions";
import { success } from "../../Utilities/toast";
import { SidebarContext } from "../../context/sidebarContext";
import { stopMachineSpeaksGlobal } from "../../Utilities/textToSpeech";
import { stopMachineSpeaks } from "../../Pages/SimulatorPro/Components/MachineSpeaks";

const Header = ({ outside, sound = false, audio, setAudio, onOpenPopup }) => {
  // const [isMobileSidebarActive, setIsMobileSidebarActive] = useState(false);
  const {
    file_url,
    interViewMode,
    interviewCompleted,
    sidebar_colorBtn,
    sidebar_colorBg,
  } = useSelector((state) => state.globalState);

  const { user } = useSelector((state) => state.authenticate);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    activeLinkIdx,
    setActiveLinkIdx,
    isMobileSidebarActive,
    setIsMobileSidebarActive,
  } = useContext(MobileSidebarContext);
  const { isSidebarOpen } = useContext(SidebarContext);

  /* const user = {
    name: 'meghraj',
    avatar_url: '',
  } */
  // const user = null;

  const sidebarClick = () => {
    setIsMobileSidebarActive(!isMobileSidebarActive);
  };

  const AudioController = () => {
    setAudio(!audio);
  };

  const handleLogout = () => {
    dispatch(logoutUser())
      .unwrap()
      .then((res) => {
        // success(res.detail);
        dispatch(removeToken());
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const location = useLocation();
  const serializedUid = localStorage.getItem("auth");

  // Check if the current location matches "/user/home"
  const isUserHome =
    location.pathname === "/user/home" ||
    location.pathname === "/career-goals" ||
    location.pathname === "/resume" ||
    location.pathname === "/pricing" ||
    location.pathname === "/payments";
  // console.log("isUserHome: ", isUserHome);

  const [menuItems, setMenuItems] = useState(navData);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  const toggleMenu = (index) => {
    const updatedMenuItems = [...menuItems];
    updatedMenuItems[index].isOpen = !updatedMenuItems[index].isOpen;
    setMenuItems(updatedMenuItems);
  };
  const linksToDisplay = !isUserHome
    ? navigationLinks.filter(
        (link) => !(link.id === 10 && !user?.organizationId)
      )
    : navigationHomeLinks;

  useEffect(() => {
    const handleResize = () => {
      setInnerWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // useEffect(() => {
  //   if (user) {
  //     pageRefresh(localStorage.getItem("auth"));
  //     getMessage();
  //   }
  // }, []);

  if (interViewMode === "interview_start_developer" && !interviewCompleted)
    return;

  return (
    <div>
      <div
        className={`ic-overlay ${outside ? "Outside" : ""} ${
          isMobileSidebarActive ? "active" : ""
        }`}
        onClick={sidebarClick}
      ></div>
      <div
        className={`header transition-all ease-in ${outside ? "Outside" : ""} ${
          isSidebarOpen ? "!w-full" : ""
        }`}
        // style={{ zIndex: 999 }}
      >
        <div className={"container header-content"}>
          <div className="ic-navbar-heads">
            <div className="navbar-logo">
              <Link to="/">
                <img
                  src={`${
                    file_url && file_url != "" ? file_url : "/images/logo.png"
                  }`}
                  alt="logo"
                />
              </Link>
            </div>
            {/* {!isUserHome ? ( */}
            <div
              className={`ic-navbar-nav ${
                isMobileSidebarActive ? "active" : ""
              }`}
              style={{ backgroundColor: sidebar_colorBg }}
            >
              {/* <div className="ic-mobile-nav">
                <a href="#">
                  <img src="/images/logo.png" alt="" />
                </a>
              </div> */}
              {/* Mobile Drawer */}
              {user && (
                <ul
                  className={`mobile-side-bar mt-3 ${
                    isMobileSidebarActive ? "block" : "none"
                  }`}
                >
                  {linksToDisplay.map(({ Image, ...navigationLink }) => {
                    return (
                      <li
                        key={navigationLink.id}
                        className={`${
                          navigationLink.visible !== "test" &&
                          navigationLink.visible !== undefined
                            ? "hidden"
                            : "block"
                        } ${
                          navigationLink.isIndividual === undefined
                            ? "block"
                            : navigationLink.isIndividual &&
                              !user.organizationId
                            ? "block"
                            : "hidden"
                        }`}
                      >
                        <div
                          onClick={() => {
                            setActiveLinkIdx(navigationLink.id);
                            sidebarClick();
                          }}
                        >
                          <Link
                            to={navigationLink?.link}
                            className={`mobile-nav-link ${
                              navigationLink.id === activeLinkIdx
                                ? `active  activeLink-${sidebar_colorBtn} text-white`
                                : sidebar_colorBg == "#000000"
                                ? "text-white"
                                : ""
                            }`}
                          >
                            {!isUserHome ? (
                              typeof Image === "string" ? (
                                <img
                                  src={Image}
                                  className={`mobile-nav-link-icon ${
                                    navigationLink.id === activeLinkIdx
                                      ? `active bg-transparent
                                        `
                                      : sidebar_colorBg == "#000000"
                                      ? "text-white"
                                      : ""
                                  }  ${
                                    sidebar_colorBg == "#000000"
                                      ? "icon_white"
                                      : ""
                                  }`}
                                  alt={navigationLink.title}
                                />
                              ) : (
                                <Image
                                  className={`mobile-nav-link-icon ${
                                    navigationLink.id === activeLinkIdx
                                      ? `active bg-transparent`
                                      : sidebar_colorBg == "#000000"
                                      ? "text-white"
                                      : ""
                                  } ${
                                    sidebar_colorBg == "#000000"
                                      ? "icon_whiteSvg"
                                      : ""
                                  }`}
                                />
                              )
                            ) : (
                              <i
                                className={`${Image} mobile-nav-link-icon ${
                                  navigationLink.id === activeLinkIdx
                                    ? `active bg-transparent`
                                    : null
                                } `}
                              ></i>
                            )}
                            <span>{navigationLink.title}</span>
                          </Link>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              )}
              {user ? (
                <></>
              ) : (
                <ul className="ic-navbar-nav-ul">
                  {menuItems.map((item, index) => (
                    <li key={index}>
                      <button
                        type="button"
                        onClick={() => {
                          toggleMenu(index);
                          if (item.route) {
                            navigate(item.route);
                          }
                        }}
                      >
                        {item.title}
                        {item.icon && (
                          <div>
                            <img src={item.icon} alt="icon" />
                          </div>
                        )}
                      </button>
                      {(item.isOpen || innerWidth >= 1199) && item.subMenu && (
                        <ul className="ic-navbar-dropdown">
                          {item.subMenu.map((subItem, subIndex) => (
                            <li key={subIndex}>
                              <button
                                type="button"
                                onClick={() =>
                                  subItem?.route && navigate(subItem.route)
                                }
                              >
                                {subItem.icon && (
                                  <div>
                                    <img src={subItem.icon} alt="icon" />
                                  </div>
                                )}{" "}
                                {subItem.title}
                              </button>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                  <div className="flex flex-col gap-2 mt-2 md:hidden">
                    <div className="header-contact-sales-btn-wrapper w-100">
                      <Link to="/login" className="header-contact-sales-btn">
                        Sign In
                      </Link>
                    </div>
                    <div className="header-contact-sales-btn-wrapper w-100">
                      <Link
                        to="/start-interview"
                        className="header-contact-sales-btn"
                      >
                        Contact Sales
                      </Link>
                    </div>
                  </div>
                </ul>
              )}
            </div>
            <div className="ic-navbar-right">
              {!user ? (
                <div className="flex flex-row space-x-2 ">
                  <div className="header-contact-sales-btn-wrapper hidden md:flex">
                    <Link to="/login" className="header-contact-sales-btn ">
                      Sign In
                    </Link>
                  </div>
                  <div className="header-contact-sales-btn-wrapper">
                    <Link
                      to="/start-interview"
                      className="header-contact-sales-btn"
                    >
                      Contact Sales
                    </Link>
                  </div>
                </div>
              ) : (
                <div className="ic-profile-dropdown">
                  <div className="ic-avatar-profile">
                    <img src={user?.picture || userIcon} alt="" />
                  </div>
                  <div className="ic-dropdown-menu">
                    <div className="ic-profiles-dropdown">
                      <div>
                        <img src={user?.picture || userIcon} alt="" />
                      </div>
                      <div>
                        <p>{user.name || user?.fullName}</p>
                        {user?.organizationId !== null && (
                          <div className="d-flex mt-2">
                            <span>org: </span>
                            &nbsp;<span>{user?.organizationName}</span>
                          </div>
                        )}
                        {user?.organizationId == null && <span>Admin</span>}
                      </div>
                    </div>
                    <ul className="ic-drop-menu curved-edges">
                      <li>
                        {/* {user.is_admin == 1 ? ( */}
                        <Link to="/home">
                          <i className="ri-dashboard-line mr-4"></i>
                          Dashboard
                        </Link>
                        {/* ) : null} */}
                        <Link to="/user/home">
                          <i className="ri-user-line mr-4"></i>
                          My Profile
                        </Link>
                        <Link to="/user2/settings">
                          <i className="ri-dashboard-line mr-4"></i>
                          Settings
                        </Link>
                        <Link
                          onClick={() => {
                            handleLogout();
                            stopMachineSpeaksGlobal();
                            stopMachineSpeaks();
                          }}
                          as="button"
                        >
                          <i className="ri-logout-circle-r-line mr-4"></i>
                          Logout
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              )}

              <div
                className={`humbarger-menu ${isMobileSidebarActive ? "" : ""}`}
                onClick={sidebarClick}
              >
                {isMobileSidebarActive ? (
                  <IoClose color="#000" size={25} style={{ margin: "auto" }} />
                ) : (
                  <>
                    <span className="bar1"></span>
                    <span className="bar2"></span>
                    <span className="bar3"></span>
                  </>
                )}
              </div>

              {sound ? (
                <div
                  className="sound-click me-2 me-lg-3"
                  onClick={AudioController}
                >
                  {audio ? (
                    <i className="ri-volume-up-line"></i>
                  ) : (
                    <i className="ri-volume-mute-line"></i>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
