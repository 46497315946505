import React, { useState, useEffect, memo, useRef, useCallback } from "react";
import MonacoEditor from "@monaco-editor/react";
import questionsData from "./questions.json";
import {
  TestContainer,
  QuestionContainer,
  QuestionCard,
  CodeEditorContainer,
  TimerContainer,
  TimerIcon,
  Timer,
  ButtonContainer,
  SelectContainer,
  LoaderOverlay,
  Button,
  PreFormattedText,
  ApiResponseContainer,
  ApiResponseHeader,
  ApiResponseContent,
  FileButton,
} from "./index.style";
import "../../assets/CSS/index.css";
import techStackQuestions from "../../DummyData/newQuestions.json";
import dsaCareerQuestions from "../../DummyData/dsa-questions-data-free.json";
import Select, { SingleValue } from "react-select";
import { files, themes } from "./data";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  setInterviewCompleted,
  setinterviewMode,
} from "../../Redux/GlobalStates";
import { useDispatch } from "react-redux";
import { useChatCompletion } from "openai-streaming-hooks";
import Markdown from "react-markdown";
import useChatLogs from "../../hook/chatStream";
import MDX from "../MDX";
import Modal from "../modal";
import { Tabs } from "antd";
import { saveAs } from "file-saver";

let currentAudio = null;

// function convertToObjects(summary) {
//   const lines = summary.split("\n");
//   const questions = [];
//   let currentQuestion = {};

//   lines.forEach((line) => {
//     if (line.startsWith("**QUESTION") || line.startsWith("### QUESTION")) {
//       if (Object.keys(currentQuestion).length) {
//         questions.push(currentQuestion);
//       }
//       currentQuestion = {
//         question: line
//           .replace(/^\*\*QUESTION \d+: /, "")
//           .replace(/\*\*$/, "")
//           .trim(),
//       };
//     } else if (line.startsWith("- **Score:")) {
//       currentQuestion.score = line.replace("- **Score:**", "").trim();
//     } else if (line.startsWith("- **Evaluation:")) {
//       currentQuestion.score = line.replace("- **Evaluation:**", "").trim();
//     } else if (line.startsWith("- **Reasoning")) {
//       currentQuestion.reasoning = line.replace("- **Reasoning", "").trim();
//     }
//   });

//   console.log("currentQuestion>>", currentQuestion);

//   if (Object.keys(currentQuestion).length) {
//     questions.push(currentQuestion);
//   }

//   return questions;
// }

function convertToObjects(summary) {
  const questions = summary.split(/### QUESTION \d+/);

  return questions
    .slice(1) // Skip the initial text before the first question
    .map((question) => {
      const [codeSection, evaluationSection] =
        question.split("**Evaluation:**");

      const questionMatch = codeSection?.match(
        /\*\*Code:\*\*\s*```javascript([\s\S]*?)```/
      );
      const code = questionMatch ? questionMatch[1].trim() : "";

      const scoreMatch = evaluationSection?.match(/\*\*Score:\s*(\d+)\/5\*\*/);
      const score = scoreMatch ? scoreMatch[1] : "0";

      const evaluation = evaluationSection?.replace(/\*\*Score:.*/, "").trim();

      return {
        question: `QUESTION ${questions?.indexOf(question)}`,
        answer: code,
        feedback: evaluation,
        score: score,
      };
    });
}

const fetchAndPlayTTS = async (text, playTTSRef) => {
  if (!playTTSRef) return;
  if (!playTTSRef.current) return;

  try {
    if (currentAudio) {
      currentAudio.pause();
      currentAudio = null;
    }

    const response = await axios.post(
      "https://trynexa.com/api/api/tts",
      { text },
      { responseType: "blob" }
    );

    const audioUrl = URL.createObjectURL(response.data);
    currentAudio = new Audio(audioUrl);
    currentAudio.volume = 1.0;
    currentAudio.play();
    currentAudio.addEventListener("ended", () => {
      currentAudio = null;
    });
  } catch (error) {
    console.error("Failed to fetch TTS audio:", error);
  }
};
function getRandomNumbersInRange(min, max, count) {
  // Ensure the range can accommodate the requested count of unique numbers
  if (max - min + 1 < count) {
    throw new Error(
      "Range too small to accommodate the count of unique numbers."
    );
  }

  const numbers = new Set();

  while (numbers.size < count) {
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    numbers.add(randomNumber);
  }

  return Array.from(numbers);
}

function getRandomQuestions(techStack) {
  if (localStorage.getItem("dev_questions")) {
    return JSON.parse(localStorage.getItem("dev_questions"));
  } else {
    let randomQuestions;
    if (techStack === "dsa-career") {
      let questions = Object.keys(dsaCareerQuestions).map((key) => ({
        slug: key,
        ...dsaCareerQuestions[key],
      }));
      randomQuestions = getRandomNumbersInRange(0, questions.length - 1, 5).map(
        (i) => questions[i]
      );
    } else {
      randomQuestions = getRandomNumbersInRange(
        0,
        techStackQuestions[techStack].length - 1,
        5
      )?.map((i) => techStackQuestions?.[techStack]?.[i]);
    }
    localStorage.setItem("dev_questions", JSON.stringify(randomQuestions));
    return randomQuestions;
  }
}

const RealTimeResponseBox = ({
  questions,
  answers,
  techStack,
  currentQuestionIndex,
}) => {
  // const {
  //   messages,
  //   loading: evalLoading,
  //   submitPrompt,
  //   setMessages,
  //   abortResponse,
  // } = useChatCompletion({
  //   apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  //   model: "gpt-4o",
  //   temperature: 0.9,
  // });
  const {
    messages,
    forceSetMessages,
    abortResponse,
    setMessages,
    submitPrompt,
    loading,
  } = useChatLogs({ storeChats: false, url: "/dashboard/hint" });

  let promptMessage = "";

  if (questions?.length) {
    promptMessage = `TECH STACK: ${techStack} \n QUESTION: ${
      questions[currentQuestionIndex].question
    } \n CODE: ${answers[currentQuestionIndex + 1]}`;
  }

  // const promptMessages = [
  //   {
  //     role: "system",
  //     content:
  //       "In a few sentences, please give feedback on the written coding question and answer on the tech stack chosen.",
  //   },
  //   {
  //     role: "user",
  //     content: `TECH STACK: ${techStack} \n QUESTION: ${
  //       questions?.[currentQuestionIndex]?.question
  //     } \n CODE: ${answers[currentQuestionIndex + 1]}`,
  //   },
  // ];
  useEffect(() => {
    forceSetMessages([]);
  }, [currentQuestionIndex]);

  // useEffect(() => {
  //   const intervalId = setInterval(async () => {
  //     setMessages([]);
  //     submitPrompt(promptMessages);
  //   }, 60000);
  //   return () => clearInterval(intervalId);
  // }, [currentQuestionIndex]);
  // console.log(messages?.at(1)?.content);

  return (
    <ApiResponseContainer>
      <button
        className="next-button get-hint-button"
        onClick={() => {
          submitPrompt(promptMessage);
        }}
      >
        Get hint
      </button>
      <ApiResponseContent className="overflow-y-scroll">
        {loading && messages?.at(1)?.content === "" ? (
          <div className="flex justify-center mt-10">
            <img
              className="w-32"
              src="https://cdn.dribbble.com/users/3742211/screenshots/9195657/media/6796a544d6f9ef1293d8d8d9e60d38d5.gif"
              alt="loader"
            />
          </div>
        ) : (
          <div className="w-full h-[270px] text-wrap overflow-y-scroll">
            <MDX
              children={
                messages.at(1) &&
                messages.at(1).content.replaceAll("\n\n", "\n")
              }
            />
          </div>
        )}
      </ApiResponseContent>
    </ApiResponseContainer>
  );
};

// Convert remaining seconds to mm:ss format
const formatTime = (seconds) => {
  if (seconds <= 0) {
    return "00:00";
  }

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
};

const DSAQuestion = memo(({ question }) => {
  return (
    <div className="h-96 overflow-y-scroll">
      <h4 className="text-2xl my-1 font-semibold">{question?.title ?? ""}</h4>
      <div dangerouslySetInnerHTML={{ __html: question?.content }}></div>
    </div>
  );
});

const DeveloperInterview = memo(({ techStack }) => {
  const playTTSRef = useRef(true);

  const [modalIsOpen, setIsOpen] = useState(false);
  // const {
  //   messages,
  //   loading: evalLoading,
  //   submitPrompt,
  // } = useChatCompletion({
  //   apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  //   model: "gpt-4o",
  //   temperature: 0.9,
  // });

  const {
    messages,
    forceSetMessages,
    abortResponse,
    setMessages,
    submitPrompt,
    getFeedback,
  } = useChatLogs({ storeChats: false, url: "/dashboard/evaluate" });
  // console.log(messages);
  const dispatch = useDispatch();
  const [realTimeResponse, setRealTimeResponse] = useState("");
  const questions = getRandomQuestions(techStack);
  const [answers, setAnswers] = useState({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [fileName, setFileName] = useState("javascript");
  const [theme, setTheme] = useState("vs");
  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const navigate = useNavigate();
  const file = files.filter((ele) => ele.language === fileName)[0];
  const [selectedFileType, setSelectedFileType] = useState("js");
  const [fileList, setFileList] = useState([
    {
      key: "0",
      label: "question1.js",
      type: "js",
      value: ``,
      language: "javascript",
      closable: false,
    },
  ]);

  const [selectedFile, setSelectedFile] = useState(fileList[0]);

  const [feedback, setFeeback] = useState([]);
  const [feedbackLoading, setFeedbackloading] = useState(false);

  console.log("feedback>>", feedback);

  const handleFeedback = async (summary) => {
    try {
      setFeedbackloading(true);
      const resposne = await getFeedback(
        `${summary}\nconvert above summary to array of object with key name question(its inlcudes question), answer(includes code), feedback(inlcudes Evaluation or Reasoning ). Please retrun result in json format with array named "results"`
      );

      console.log(
        "feeback response>>>",
        resposne,
        resposne && JSON.parse(resposne)
      );

      if (resposne) {
        let results = JSON.parse(resposne);
        console.log("results>>", results);
        if (results?.results) {
          setFeeback(results?.results);
        }
        if (results?.summary) {
          setFeeback(results?.summary);
        }
        if (results?.responses) {
          setFeeback(results?.responses);
        }
      }
      setFeedbackloading(false);
    } catch (error) {
      setFeedbackloading(false);
    }
  };

  useEffect(() => {
    if (modalIsOpen && !loading) {
      handleFeedback(messages.at(1)?.content);
    }
  }, [modalIsOpen, loading]);

  useEffect(() => {
    // Update File Extension
    if (selectedFileType) {
      let isExistFile = files.find((e) => e.sign === selectedFileType);

      if (isExistFile) {
        let fileArr = [...fileList];
        fileArr[Number(selectedFile.key)].type = isExistFile.sign;
        fileArr[Number(selectedFile.key)].label = `question${
          Number(selectedFile.key) + 1
        }.${isExistFile.sign}`;
        fileArr[Number(selectedFile.key)].language = isExistFile.language;

        setFileList(fileArr);
      }
    }
  }, [selectedFileType]);

  const handleChangeFile = (e) => {
    setFileName(e?.value);
  };

  const handleChangeTheme = (e) => {
    setTheme(e?.value);
  };
  useEffect(() => {
    dispatch(setInterviewCompleted(false));
    window.addEventListener("beforeunload", handleBeforeUnload);

    window.addEventListener("unload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("unload", handleUnload);
      // dispatch(setinterviewMode("interview_exited"));
      // dispatch(setInterviewCompleted(true));
    };
  }, []);

  const handleBeforeUnload = (evt) => {
    evt.preventDefault();
    let msg = "You cannot exit the interview.";
    evt.returnValue = msg;
    return msg;
  };
  const handleUnload = (evt) => {
    evt.preventDefault();
    localStorage.removeItem("dev_questions");
  };

  // useEffect(() => {
  //   console.log("main useeffect");
  //   // Fetch questions from JSON data
  //   const fetchData = async () => {
  //     try {
  //       setLoading(true); // Set loading to true when fetching data
  //       // setQuestions(techStackQuestions[techStack].slice(0, 5));
  //       if (!timer) {
  //         startTimer(); // Move startTimer call here
  //       }
  //     } catch (error) {
  //       console.error("Error fetching questions:", error);
  //     } finally {
  //       setLoading(false); // Set loading to false after fetching data
  //     }
  //   };

  //   fetchData();

  //   // Cleanup function to stop the timer when component unmounts
  //   return () => {
  //     if (timer) {
  //       clearInterval(timer);
  //     }
  //   };
  // }, []); // Listen to changes in the category prop

  const handleAnswerChange = (questionId, answer) => {
    setAnswers({ ...answers, [questionId]: answer });
    setSelectedFile((s) => ({ ...s, value: answer }));

    let fileArr = [...fileList];

    fileArr[Number(selectedFile.key)].value = answer;
    setFileList(fileArr);
  };

  const submitTest = async () => {
    playTTSRef.current = false; // Disable TTS playback
    setLoading(true);
    const answersArray = Object.keys(answers).map((questionId) => ({
      [questionId]: answers[questionId],
    }));
    localStorage.removeItem("dev_questions");

    const promptMessage = questions
      ?.map((item, i) => {
        return `QUESTION ${i + 1}: ${item?.question}
      CODE: ${answers[item.id] || ""}`;
      })
      .join("\n-------------------------------\n");
    try {
      setIsOpen(true);

      await submitPrompt(promptMessage);
    } catch (err) {
      setResponseData("Could not evaluate the test. Some error occured");
      throw err;
    } finally {
      setLoading(false); // Ensure loading state is set to false after submission
    }
  };
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current && questions.length > 0) {
      const playTTSOnLoad = async () => {
        await fetchAndPlayTTS(questions[0].question, playTTSRef);
      };
      playTTSOnLoad();
      isInitialMount.current = false; // Ensure this runs only once
    }
  }, [questions]);
  const moveToNextQuestion = async () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);

      let newFileInfo = {
        key: String(currentQuestionIndex + 1),
        label: `question${String(currentQuestionIndex + 2)}.js`,
        type: "js",
        value: ``,
        language: "javascript",
        closable: false,
      };
      setFileList([...fileList, newFileInfo]);

      setSelectedFile(newFileInfo);

      setRealTimeResponse("");
      await fetchAndPlayTTS(
        questions[currentQuestionIndex + 1].question,
        playTTSRef
      );
      // setAnswers((prevAnswers) => ({
      //   ...prevAnswers,
      //   [questions[currentQuestionIndex].id]: "", // Reset the answer for the current question
      // }));
    } else {
      playTTSRef.current = false; // Disable TTS playback
      await submitTest();
    }
  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "8px",
      border: state.isFocused ? "2px solid #007bff" : "2px solid #ced4da",
      boxShadow: "none",
      "&:hover": {
        border: "2px solid #007bff",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#007bff" : "white",
      color: state.isFocused ? "white" : "black",
      "&:hover": {
        backgroundColor: "#007bff",
        color: "white",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "black",
    }),
  };

  const customModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      width: "1000px",
      height: "720px",
      // bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const editorRef = useRef(null);
  const containerRef = useRef(null);

  const handleEditorDidMount = useCallback((editor) => {
    editorRef.current = editor;
  }, []);

  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (e.message.startsWith("ResizeObserver loop")) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.remove("style", "display: none");
        }
      }
    });
  }, []);
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (editorRef.current) {
        editorRef.current.layout();
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  const onChangeTab = (key) => {
    let idx = Number(key);

    setSelectedFile(fileList[idx]);
  };

  const monoEditor = useCallback(() => {
    return (
      <>
        <Tabs
          type="editable-card"
          onChange={onChangeTab}
          activeKey={selectedFile.key}
          items={fileList}
          hideAdd
        />

        <div
          className={`editor-wrapper ${theme === "vs-dark" ? "dark" : ""}`}
          style={{ flexGrow: 1 }}
        >
          <div className={`top-bar ${theme === "vs-dark" ? "dark" : ""}`}>
            <div className="icon text-sd-green-500">
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="far"
                data-icon="code"
                className="svg-inline--fa fa-code"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 640 512"
                style={{ width: "1em", height: "1em" }}
              >
                <path
                  fill="#38a169"
                  d="M399.1 1.1c-12.7-3.9-26.1 3.1-30 15.8l-144 464c-3.9 12.7 3.1 26.1 15.8 30s26.1-3.1 30-15.8l144-464c3.9-12.7-3.1-26.1-15.8-30zm71.4 118.5c-9.1 9.7-8.6 24.9 1.1 33.9L580.9 256 471.6 358.5c-9.7 9.1-10.2 24.3-1.1 33.9s24.3 10.2 33.9 1.1l128-120c4.8-4.5 7.6-10.9 7.6-17.5s-2.7-13-7.6-17.5l-128-120c-9.7-9.1-24.9-8.6-33.9 1.1zm-301 0c-9.1-9.7-24.3-10.2-33.9-1.1l-128 120C2.7 243 0 249.4 0 256s2.7 13 7.6 17.5l128 120c9.7 9.1 24.9 8.6 33.9-1.1s8.6-24.9-1.1-33.9L59.1 256 168.4 153.5c9.7-9.1 10.2-24.3 1.1-33.9z"
                ></path>
              </svg>
            </div>
            <div className="text">Code</div>
          </div>
          <div className="code-editor-container">
            <MonacoEditor
              height="100%"
              width="100%"
              theme={theme}
              path={selectedFile.language}
              defaultLanguage={selectedFile.language}
              defaultValue={selectedFile.value}
              // value={answers[questions?.[currentQuestionIndex]?.id] || ""}
              value={selectedFile.value}
              onChange={(value) => {
                handleAnswerChange(
                  questions?.[currentQuestionIndex]?.id || 0,
                  value || ""
                );
              }}
              options={{
                wordWrap: "on",
                minimap: { enabled: false },
              }}
              onMount={handleEditorDidMount}
            />
          </div>
        </div>
      </>
    );
  }, [
    answers,
    currentQuestionIndex,
    file.language,
    theme,
    handleAnswerChange,
    questions,
    selectedFile,
  ]);

  const renderModal = useCallback(() => {
    if (!modalIsOpen || loading) return <></>;

    let info = convertToObjects(messages.at(1)?.content);

    // console.log("messages.at(1)?.content", messages.at(1)?.content);

    const header = (
      <div className="mockInterview-modal-header">
        <button
          className="modal-close"
          onClick={() => {
            forceSetMessages([]);
            dispatch(setinterviewMode("interview_exit"));
            dispatch(setInterviewCompleted(true));
            localStorage.removeItem("dev_questions");
            navigate("/interview/role/developer");
          }}
        >
          <i className="ri-close-line"></i>
        </button>
      </div>
    );

    const content = (
      <div className="row">
        <div className="col-12 order-md-1 order-2">
          {feedback?.map((item, index) => (
            <div className="cardBody" key={index + 100}>
              <h5 className="mx-3 my-3 ">Question:</h5>
              <hr />
              <p className="mx-3 my-3">{item?.question}</p>
              <hr />
              <h5 className="mx-3 my-3 text-primary">Answer:</h5>
              <hr />
              <h6 className="mx-3 my-3 text-primary">{item?.answer}</h6>
              <hr />

              <h5 className="mx-3 my-3 text-primary">Feedback:</h5>
              <p className="mx-3 my-3">{item?.feedback}</p>
            </div>
          ))}
        </div>
      </div>
    );

    if (feedbackLoading) {
      return <LoaderOverlay>Loading...</LoaderOverlay>;
    }

    return (
      <Modal
        type="feedback"
        show={modalIsOpen}
        header={header}
        content={content}
      />
    );
  }, [modalIsOpen, messages, feedback, feedbackLoading]);

  const downloadFile = (label, value, type) => {
    const blob = new Blob([value], { type: "text/plain;charset=utf-8" });
    saveAs(blob, label);
  };

  const handleDownloadAll = () => {
    fileList.forEach((file) => downloadFile(file.label, file.value, file.type));

    setTimeout(() => {
      window.open("https://github.com/", "_blank"); // Replace with your desired URL
    }, 1000); // Adjust the delay as needed
  };

  return (
    <div
      // data-aos="fade-up"
      // data-aos-delay="50"
      className="ic-all-question flex flex-col"
    >
      {renderModal()}

      <TestContainer className="w-full">
        {loading && <LoaderOverlay>Loading...</LoaderOverlay>}
        <div className="flex flex-col w-1/2">
          <QuestionContainer className="flex-1">
            <div className="flex space-x-3 my-0 items-center">
              <TimerComponent submitTest={submitTest} />
              <button
                className="bg-red-600 text-white p-2 px-4 rounded-lg mb-3"
                onClick={() => {
                  dispatch(setinterviewMode("interview_exit"));
                  dispatch(setInterviewCompleted(true));
                  localStorage.removeItem("dev_questions");
                  navigate("/interview/role/developer");
                }}
              >
                Exit
              </button>
            </div>
            <QuestionCard>
              {questions[currentQuestionIndex] instanceof Object ? (
                <>
                  <h4>Question {currentQuestionIndex + 1}</h4>
                  <p>{questions[currentQuestionIndex]?.question}</p>
                </>
              ) : (
                <DSAQuestion question={questions[currentQuestionIndex]} />
              )}
              <ButtonContainer className="button-container">
                <Button className="next-button" onClick={moveToNextQuestion}>
                  Next
                </Button>
                {currentQuestionIndex === questions.length - 1 && (
                  <Button className="next-button" onClick={submitTest}>
                    Submit
                  </Button>
                )}
              </ButtonContainer>
            </QuestionCard>
          </QuestionContainer>
          <div className="bg-white mt-4 mr-4 rounded-md shadow-lg">
            <PreFormattedText>{responseData?.evaluation}</PreFormattedText>
            <RealTimeResponseBox
              answers={answers}
              currentQuestionIndex={currentQuestionIndex}
              questions={questions?.filter((e) => e)}
              techStack={techStack}
            />
          </div>
        </div>
        <div className="flex flex-col w-1/2" style={{ height: "560px" }}>
          <CodeEditorContainer style={{ flex: 1, height: "100%" }}>
            <SelectContainer
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              {fileList?.length === 5 && (
                <Button
                  className="rounded-md mr-2 p-2"
                  onClick={handleDownloadAll}
                >
                  Save & Export to github
                </Button>
              )}

              <div className="mr-3">
                <Select
                  options={files?.map((e) => ({
                    ...e,
                    label: e.language,
                    value: e.sign,
                  }))}
                  onChange={(e) => setSelectedFileType(e.value)}
                  styles={customStyles}
                  placeholder="Select Language"
                  // value={selectedFileType}
                />
              </div>
              <div>
                <Select
                  options={themes}
                  onChange={handleChangeTheme}
                  styles={customStyles}
                  placeholder="Select Theme"
                />
              </div>
            </SelectContainer>

            {/* {answers[questions?.[currentQuestionIndex]?.id] ? ( */}
            {monoEditor()}
            {/* ) : null} */}
          </CodeEditorContainer>
        </div>
      </TestContainer>
    </div>
  );
});
/*Use memo to not re render time component each time */
const TimerComponent = memo(({ submitTest }) => {
  const [remainingTime, setRemainingTime] = useState(600); // 10 minutes
  const timerRef = useRef(null);
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = () => {
      setRemainingTime((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timerRef.current);
          timerRef.current = null;
          submitTest();
          return 0;
        }
        return prevTime - 1;
      });
    };
  });

  useEffect(() => {
    if (!timerRef.current) {
      timerRef.current = setInterval(() => savedCallback.current(), 1000);
    }

    return () => clearInterval(timerRef.current);
  }, []);

  return (
    <TimerContainer>
      <TimerIcon>⏰</TimerIcon>
      <Timer>Time Remaining: {formatTime(remainingTime)}</Timer>
    </TimerContainer>
  );
});

export default DeveloperInterview;
