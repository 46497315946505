import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  discord,
  dot,
  facebook,
  insta,
  tiktok,
  twitter,
} from "../../assets/icons/footer-icons";
import "./footer.css";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="nexa-footer z-30">
      <div className="container">
        <div className="top-row">
          <div className="top-row-col-1">
            <div className="brand-logo">
              <img src="/images/footer-logo.png" alt="footer images" />
            </div>
            <p className="get-started text-2xl">Your AI Career Center</p>
            {/*} <div className="input-field-wrapper">
              <input type="text" placeholder="Enter your email here" />
              <button className="arrow-right">
                <img src={arrowRight} alt="icon" />
              </button>
            </div> */}
          </div>
          <div className="top-row-col-2">
            <div className="list support">
              <h2>Support</h2>
              <ul>
                <li
                  onClick={() =>
                    window.open(
                      "https://share.hsforms.com/1OqLkWvR5SHCqlZOUvRD9-Arfy9w"
                    )
                  }
                >
                  Help center
                </li>
                <li>News</li>
                <li>Webinars</li>
                <li onClick={() => navigate("/start-interview")}>Contact us</li>
              </ul>
            </div>
            <div className="list product">
              <h2>Product</h2>
              <ul>
                <li onClick={() => navigate("/faq")}>FAQ</li>
                <li
                  onClick={() =>
                    window.open(
                      "https://docs.google.com/forms/d/1clHBZQhEsblWH8f5x3X_hcCHsJycYJLdr5YdmRA8y4k/viewform",
                      "_blank"
                    )
                  }
                >
                  {" "}
                  Feedback
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end my-3">
          <ul className="nexa-footer-social">
            <li>
              <a href="https://www.tiktok.com/@learnnexa" target="_blank">
                <img src={tiktok} alt="icon" />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/divyn7" target="_blank">
                <img src={twitter} alt="icon" />
              </a>
            </li>
            {/* <li>
              <a
                href="https://www.linkedin.com/company/learnnexa"
                target="_blank"
              >
                <i className="ri-linkedin-fill"></i>
              </a>
            </li> */}
            <li>
              <a href="https://www.instagram.com/learnnexa" target="_blank">
                <img src={facebook} alt="icon" />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/learnnexa" target="_blank">
                <img src={insta} alt="icon" />
              </a>
            </li>
            <li>
              <a href="https://discord.gg/6t7Mq2EJ9p" target="_blank">
                <img src={discord} className="discord_icon" alt="icon" />
              </a>
            </li>
          </ul>
        </div>
        <div className="d-flex justify-content-between gap-3 flex-wrap">
          <p className="text-white fw-medium">
            © 2024 Nexa Inc. Copyright and rights reserved
          </p>
          <div className="footer-terms-privacy">
            <Link to={"/terms-and-condition"} className="text-white">
              Terms and Condtions
            </Link>
            <div>
              <img src={dot} alt="dot" />
            </div>
            <Link to={"/privacy-policy"} className="text-white">
              Privacy Policy
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
