import Repository from "./Repository";
import RefreshAuthRepository from "./RefreshAuthRepository";
const createNewUser = "/es/onboard/create_user";
const INVITED_USER = "/organization/complete_registration";
const loginUser = "/es/login";
const FORGET_PASS = "/es/reset_password";
const VERIFY_EMAIL = "/es/reset_password";
const SSOLOGIN = "/es/authenticate_sso_user";
const SSOINVITATIONLOGIN = "/organization/complete_registration_sso";
const USER_SUB = "/dashboard/get-subscription";
const ONBOARDING = "/onboarding/create";
const GET_ONBOARDING = "/onboarding/read";
const UPDATE_ONBOARDING = "/onboarding/update";
const UPDATE_ONBOARDING_USER = "/customer/info";
const REFRESH_TOKEN = "/es/refresh";
const USER_UPDATE = "/es/onboard/update_user";
const CONTINUE_SINGLE = "/es/onboard/switch_to_individual";
const CANCEL_SUBSCRIPTION = "/es/onboard/cancel_subscription";
const AUTH_WITH_CONTEXT_TOKEN = "/es/login_with_token?token=";
const LOGOUT_USER = "/es/logout";
const GET_CURRENT_USER = "/customer/info";

const authRepository = {
  createNewUser(payload) {
    return Repository.post(`${createNewUser}`, payload);
  },
  registerInvitedUser(payload) {
    return Repository.post(`${INVITED_USER}`, payload);
  },
  authenticateUser(payload) {
    return Repository.post(`${loginUser}`, payload);
  },
  authenticateWithContext(token) {
    return Repository.post(`${AUTH_WITH_CONTEXT_TOKEN}${token}`);
  },
  ssoLogin(token) {
    return Repository.post(`${SSOLOGIN}/${token}`);
  },
  ssoInvitationLogin(payload) {
    return Repository.post(`${SSOINVITATIONLOGIN}`, payload);
  },
  forgetPass(payload) {
    const { token, ...rest } = payload;
    return Repository.post(`${FORGET_PASS}/${token}`, rest);
  },
  verfiyEmail(payload) {
    return Repository.post(`${VERIFY_EMAIL}`, payload);
  },
  getUserBundle() {
    return Repository.get(`${USER_SUB}`);
  },
  refreshToken() {
    return RefreshAuthRepository.post(`${REFRESH_TOKEN}`);
  },
  logoutCurrentUser() {
    return RefreshAuthRepository.put(`${LOGOUT_USER}`);
  },
  onBoarding(payload) {
    console.log("🚀 ~ onBoarding ~ payload:", payload);
    const form = new FormData();
    if (payload.question_slug) {
      form.append("question_slug", payload.question_slug);
    }
    if (payload.answer) {
      form.append("answer", payload.answer);
    }
    if (payload.file) {
      form.append("file", payload.file);
    }
    return Repository.post(`${ONBOARDING}`, form);
  },
  skillsGapAnalysis() {
    return Repository.get("/onboarding/fetch_skill_gap_analysis");
  },
  generateSkillsAnalysis() {
    return Repository.get("/onboarding/generate_skill_gap_analysis");
  },
  udpateOnBoard(payload) {
    const form = new FormData();
    if (payload.question_slug) {
      form.append("question_slug", payload.question_slug);
    }
    if (payload.answer) {
      form.append("answer", payload.answer);
    }
    if (payload.file) {
      form.append("file", payload.file);
    }
    return Repository.put(`${UPDATE_ONBOARDING}`, form);
  },
  updateOnboardingUser(payload) {
    return Repository.put(`${UPDATE_ONBOARDING_USER}`, payload);
  },
  getOnBoard() {
    return Repository.get(`${GET_ONBOARDING}`);
  },
  currentUserInfo() {
    return Repository.get(`${GET_CURRENT_USER}`);
  },
  udpateProfile(payload) {
    return Repository.put(`${USER_UPDATE}`, payload);
  },
  individualUser() {
    return Repository.get(`${CONTINUE_SINGLE}`);
  },
  cancelSubscription() {
    return Repository.post(`${CANCEL_SUBSCRIPTION}`);
  },
};

export default authRepository;
