import React, { useEffect, useState } from "react";
import { MdDelete, MdFileUpload } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../Components/header";
// import { updateprofile } from "../../Redux/DashboardSlice";
import { Link } from "react-router-dom";
import Loader from "../../Components/main-loader";
import { plans } from "../../DummyData/PricingData";
import {
  getCurrentUser,
  updateOnboardingUser,
  updateprofile,
} from "../../Redux/authenticate/authenticate.action";
import { error, success } from "../../Utilities/toast";

const Setting = () => {
  const dispatch = useDispatch();
  const { user, loading } = useSelector((state) => state.authenticate);
  const [currentBundleData, setCurrentBundleData] = useState(null);
  const [profileInfo, setProfileInfo] = useState({
    image: null,
    name: "",
    email: "",
    address: "",
    education: "",
    contact: "",
    employment_status: "",
    linkedIn_profile: "",
  });
  const handleProfileChange = (e) => {
    const { name, value, type, files } = e.target;

    if (name === "contact" && !/^\d*$/.test(value)) {
      return;
    }

    if (type === "file") {
      const file = files[0];
      const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];
      const maxSizeInMB = 2; // 2MB
      const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

      if (file && !validImageTypes.includes(file.type)) {
        error("Please upload a valid image file (jpg, jpeg, or png).");
        return;
      }

      if (file.size > maxSizeInBytes) {
        error(`File size must be less than ${maxSizeInMB}MB.`);
        return;
      }

      setProfileInfo((prev) => ({
        ...prev,
        [name]: file,
      }));
    } else {
      setProfileInfo((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleDeleteImage = () => {
    setProfileInfo((prev) => ({ ...prev, image: "" }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let payload = new FormData();
    Object.entries(profileInfo).forEach(([key, val]) => {
      if (key === "image") {
        if (typeof val === "object") {
          payload.append(key, val);
        }
        return;
      } else {
        if (val) {
          payload.append(key, val);
        }
      }
    });
    dispatch(
      updateOnboardingUser({
        payload,
        onSuccess: (res) => {
          success(res);
        },
      })
    );
  };

  useEffect(() => {
    if (user) {
      setProfileInfo((prev) => ({
        ...prev,
        name: user?.fullName || "",
        email: user?.email || "",
        image: user?.image || "",
        address: user?.address || "",
        linkedIn_profile: user?.linkedIn_profile || "",
        contact: user?.contact || "",
        education: user?.education || "",
        employment_status: user?.employment_status || "",
      }));
    }
  }, [user]);

  const findBundle = (plans, bundleId) => {
    console.log(bundleId, "bundleId");
    let bundle = null;

    for (const planType in plans.individual) {
      bundle = plans.individual[planType].find((item) => item.id === bundleId);
      if (bundle) return bundle;
    }
    for (const planType in plans.organization) {
      bundle = plans.organization[planType].find(
        (item) => item.id === bundleId
      );
      if (bundle) return bundle;
    }

    return bundle;
  };

  useEffect(() => {
    const bundle = findBundle(plans, user?.activeBundle);
    console.log(bundle, "bundlebundle");
    setCurrentBundleData(bundle);
    dispatch(getCurrentUser());
  }, []);

  return (
    <>
      {loading && <Loader />}
      <Header />
      <div
        className="ic-interview-full-body"
        style={{
          backgroundImage: `url(${"/images/interview-banner.png"})`,
          marginTop: "6em",
        }}
      >
        <main
          role="main"
          className="ml-sm-auto col-lg-12 px-4 bg-cover"
          style={{
            backgroundImage: `url(${"/images/interview-banner.png"})`,
          }}
        >
          <div className="pt-3 pb-2 mb-3">
            <h1 className="h2">Settings</h1>
          </div>
          <div className="career_slide-cover">
            <div className="ic-card_container border-0">
              <form onSubmit={handleSubmit}>
                <div className="profile-header">
                  <div className="image-upload-container">
                    {!profileInfo.image && (
                      <>
                        <input
                          accept=".png, .jpg, .jpeg"
                          style={{ display: "none" }}
                          id="image-upload-input"
                          type="file"
                          name="image"
                          onChange={handleProfileChange}
                        />
                        <label htmlFor="image-upload-input">
                          <div className="image-upload-box">
                            <span className="upload-icon text-center">
                              <MdFileUpload className="m-auto" />
                              <p>Upload you Photo</p>
                            </span>
                          </div>
                        </label>
                      </>
                    )}

                    {profileInfo.image && (
                      <div className="image-preview-container">
                        <img
                          src={
                            typeof profileInfo.image === "object"
                              ? URL.createObjectURL(profileInfo.image)
                              : profileInfo.image
                          }
                          alt="Selected"
                          className="image-preview"
                        />
                        <div
                          className="image-overlay"
                          onClick={handleDeleteImage}
                        >
                          <button className="delete-icon">
                            <MdDelete />
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="profile-form_cover">
                  <div className="profile-form">
                    <input
                      type="text"
                      id="username"
                      name="name"
                      className="form-control mt-3"
                      placeholder="Full Name"
                      required
                      value={profileInfo.name}
                      onChange={handleProfileChange}
                    />
                     <input
                      type="email"
                      id="email"
                      name="email"
                      className="form-control mt-3"
                      placeholder="Email"
                      required
                      disabled
                      value={profileInfo.email}
                      onChange={handleProfileChange}
                    />
                    <input
                      type="text"
                      id="contact"
                      name="contact"
                      className="form-control mt-3"
                      placeholder="Contact Number"
                      value={profileInfo.contact}
                      onChange={handleProfileChange}
                    />
                    <input
                      type="text"
                      id="education"
                      className="form-control mt-3"
                      placeholder="Education"
                      name="education"
                      value={profileInfo.education}
                      onChange={handleProfileChange}
                    />
                    <input
                      type="text"
                      id="address"
                      className="form-control mt-3"
                      placeholder="Address"
                      name="address"
                      value={profileInfo.address}
                      onChange={handleProfileChange}
                    />
                    <input
                      type="url"
                      id="linkedIn_profile"
                      className="form-control mt-3"
                      placeholder="LinkedIn Profile URL"
                      name="linkedIn_profile"
                      value={profileInfo.linkedIn_profile}
                      onChange={handleProfileChange}
                    />
                    <select
                      id="employment_status"
                      name="employment_status"
                      value={profileInfo.employment_status}
                      onChange={handleProfileChange}
                      className="form-control mt-3"
                    >
                      <option value="" disabled>
                        Select Employment Status
                      </option>
                      <option value="FullTime">Full-Time</option>
                      <option value="PartTime">Part-Time</option>
                      <option value="Contractor">Contractor</option>
                      <option value="Intern">Intern</option>
                      <option value="Freelancer">Freelancer</option>
                      <option value="Temporary">Temporary</option>
                      <option value="Unemployed">Unemployed</option>
                      <option value="Student">Student</option>
                      <option value="Retired">Retired</option>
                      <option value="OnLeave">OnLeave</option>
                      <option value="Sabbatical">Sabbatical</option>
                      <option value="Probation">Probation</option>
                    </select>

                    <span className="d-flex justify-center align-middle items-center">
                      <b>Current Plan: </b>
                      <p className="ml-1">
                        {currentBundleData
                          ? `You have been subscribed the ${currentBundleData?.name} plan`
                          : user?.organizationName
                          ? `You have been subscribed to organisation plan under
                        organisation ${user?.organizationName}`
                          : "There's no plan subscribed, please subscribe the plan by clicking below"}
                      </p>
                    </span>
                    <div
                      className="flex justify-center items-center w-100 pt-4"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                      }} // Flex container to align buttons side by side
                    >
                      <button
                        type="submit"
                        disabled={loading}
                        style={{
                          display: "inline-block",
                          backgroundColor: "#007bff", // Primary color
                          color: "#ffffff", // Text color
                          padding: "10px 20px", // Adjust padding for button size
                          borderRadius: "30px", // Curved edges
                          textDecoration: "none",
                          fontWeight: "bold", // Bold text
                          fontSize: "14px",
                          width: "120px", // Match width with Billing button
                          height: "40px", // Match height with Billing button
                          transition: "background-color 0.3s ease", // Smooth hover effect
                          cursor: loading ? "not-allowed" : "pointer", // Change cursor when disabled
                          opacity: loading ? 0.6 : 1, // Change opacity when disabled
                        }}
                      >
                        Save
                      </button>

                      <Link
                        to="/pricing"
                        style={{
                          display: "inline-block",
                          backgroundColor: "#007bff", // Primary color
                          color: "#ffffff", // Text color
                          padding: "10px 20px", // Adjust padding for button size
                          borderRadius: "30px", // Curved edges
                          textDecoration: "none", // Remove underline
                          textAlign: "center",
                          fontWeight: "bold", // Bold text
                          transition: "background-color 0.3s ease", // Smooth hover effect
                          width: "140px", // Width for text fit
                          height: "40px", // Match height with Save button
                          lineHeight: "40px", // Center text vertically
                          fontSize: "14px", // Match font size with Save button
                          display: "flex", // Use flexbox for centering
                          alignItems: "center", // Vertically center the text
                          justifyContent: "center", // Horizontally center the text
                        }}
                      >
                        Go to Billing
                      </Link>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default Setting;
