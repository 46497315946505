import ChatBody from "./ChatBody";
import ChatFooter from "./ChatFooter";
import ChatHeader from "./ChatHeader";
import ConversationItem from "./ConversationItem";
import RoundedContainer from "../common/RoundedContainer";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getChat,
  updateActiveConversation,
  updateChatMessages,
  updateConversations,
} from "../../Redux/ChatSlice";
import { ShimmerCategoryList } from "react-shimmer-effects";
import { pageRefresh, socket } from "../../config/socketConfig";
const Chat = () => {
  const dispatch = useDispatch();
  const { connectedChats, activeConversation, chatMessages, userId } =
    useSelector((state) => state.chat);
  const { user } = useSelector((state) => state.authenticate);
  const [isLoading, setIsLoading] = useState(false);
  const [messagesLoading, setMessagesLoading] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchchats, setSearchChats] = useState([]);
  const toggleSearch = () => {
    setSearchActive(!searchActive);
    setSearchValue("");
  };
  useEffect(() => {
    if (searchValue) {
      const filteredChats = connectedChats?.filter((chat) => {
        const lowerCaseChatName = chat?.members[0]?.name.toLowerCase();
        const lowerCaseSearch = searchValue.toLowerCase();
        return lowerCaseChatName.includes(lowerCaseSearch);
      });
      setSearchChats(filteredChats);
    } else {
      setSearchChats(connectedChats);
    }
  }, [searchValue, connectedChats]);
  useEffect(() => {
    if (user && userId) {
      pageRefresh({
        token: localStorage.getItem("auth"),
        encrypted_user_id: userId?.user_id,
      });
    }
  }, [userId]);
  useEffect(() => {
    if (user) {
      setIsLoading(true);
      dispatch(getChat(() => setIsLoading(false)));
      socket.on("get_message", async (msg) => {
        console.log("msg: ", msg);
        dispatch(updateChatMessages(msg?.data));
        dispatch(updateConversations(msg?.data?.updatedConversation));
      });
    }
  }, []);
  return (
    <RoundedContainer className="overflow-hidden" isPadding={false}>
      <div className="grid h-screen grid-cols-12">
        <div
          className={`${
            activeConversation ? "hidden" : "block"
          } col-span-12 md:block md:col-span-6 lg:col-span-4 h-full border-r rounded-tl-3xl rounded-bl-3xl bg-black border-black border-opacity-10 p-4`}
        >
          <div className="flex items-center">
            <h4 className="mx-1 text-white">All Messages</h4>
            <FontAwesomeIcon
              className="ml-auto cursor-pointer text-white"
              icon={searchActive ? faTimes : faSearch}
              onClick={toggleSearch}
            />
          </div>
          {searchActive && (
            <div className="relative">
              <input
                type="text"
                className="border-0 bg-gray-100 w-full mt-4 outline-none p-2 px-10 rounded-lg placeholder:text-slate-500"
                placeholder="Search by name"
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              />
            </div>
          )}
          <div
            className={`   
                  ${
                    !searchActive
                      ? "mt-10 custom_height"
                      : "conversation-container h-minheight overflow-hidden mt-0"
                  }`}
          >
            {isLoading ? (
              <ShimmerCategoryList
                title
                items={2}
                categoryStyle="STYLE_SEVEN"
              />
            ) : searchchats && searchchats?.length == 0 ? (
              <p className="text-center top text-white mt-2">
                No conversation found
              </p>
            ) : (
              searchchats &&
              searchchats?.map((conversation, index) => (
                <ConversationItem
                  conversation={conversation}
                  index={index}
                  setMessagesLoading={setMessagesLoading}
                />
              ))
            )}
          </div>
        </div>
        <div
          className={`${
            activeConversation ? "block" : "hidden"
          } col-span-12 md:block md:col-span-6 lg:col-span-8 relative`}
        >
          {activeConversation !== null ? (
            <div className="w-full flex flex-col h-full">
              <ChatHeader />
              {messagesLoading ? (
                <ShimmerCategoryList
                  title
                  items={3}
                  categoryStyle="STYLE_SEVEN"
                />
              ) : (
                <ChatBody chatMessages={chatMessages} />
              )}
              <ChatFooter />
            </div>
          ) : (
            <div>
              <div className="absolute top-1/2  left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center gap-1">
                <div className="navbar-logo mb-6">
                  <Link to="/">
                    <img src="/images/logo.png" alt="logo" />
                  </Link>
                </div>
                <h2 className="text-xl font-semibold text-gray-700">
                  No Chat Selected
                </h2>
                <p className="text-gray-500">
                  Please select a chat to start messaging.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </RoundedContainer>
  );
};

export default Chat;
