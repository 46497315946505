import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RepositoryFactory } from "../repository/RepositoryFactory";
import { error } from "../Utilities/toast";
// import { error, success } from "../Utilities/toast";

const simulatorProRepo = RepositoryFactory.get("simulatorPro");

export const getPromptById = createAsyncThunk(
  "simulatorPro/getPromptById",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await simulatorProRepo.getPromptById(id);
      return data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const simulatorProBegin = createAsyncThunk(
  "simulatorPro/simulatorProBegin",
  async ({ payload, onSuccess, onError }, { rejectWithValue }) => {
    try {
      const { data } = await simulatorProRepo.simulatorProBegin(payload);
      if (data?.status_code === 200) {
        onSuccess(data.data);
        return data.data;
      }
    } catch (err) {
      onError(err);
      return rejectWithValue(err.message);
    }
  }
);

export const simulatorProSave = createAsyncThunk(
  "simulatorPro/simulatorProSave",
  async ({ payload, onSuccess, onError }, { rejectWithValue }) => {
    try {
      const { data } = await simulatorProRepo.simulatorProSave(payload);
      if (data?.status_code === 200) {
        onSuccess(data);
        return data;
      }
    } catch (err) {
      onError(err);
      // error("")
      return rejectWithValue(err.message);
    }
  }
);

const initialState = {
  isPromptLoading: false,
  gettingSessionId: false,
  isFeedbackLoading: false,
  prompt: "",
  sessionId: null,
  feedbackData: null,
};

const simulatorProSlice = createSlice({
  name: "simulatorPro",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getPromptById.pending, (state) => {
        state.isPromptLoading = true;
      })
      .addCase(getPromptById.fulfilled, (state, action) => {
        state.isPromptLoading = false;
        console.log(action.payload);
        state.prompt = action.payload?.prompt_text;
      })
      .addCase(getPromptById.rejected, (state) => {
        state.isPromptLoading = false;
      })
      .addCase(simulatorProBegin.pending, (state) => {
        state.gettingSessionId = true;
      })
      .addCase(simulatorProBegin.fulfilled, (state, action) => {
        state.gettingSessionId = false;
        state.sessionId = action.payload?.session_id;
      })
      .addCase(simulatorProBegin.rejected, (state) => {
        state.gettingSessionId = false;
      })
      .addCase(simulatorProSave.pending, (state) => {
        state.isFeedbackLoading = true;
      })
      .addCase(simulatorProSave.fulfilled, (state, action) => {
        state.isFeedbackLoading = false;
        state.feedbackData = action.payload?.data;
        console.log("state.feedbackData : ", state.feedbackData);
      })
      .addCase(simulatorProSave.rejected, (state) => {
        state.isFeedbackLoading = false;
      });
  },
});

export default simulatorProSlice.reducer;
