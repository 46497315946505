import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RepositoryFactory } from "../repository/RepositoryFactory";
import { error } from "../Utilities/toast";
import languages from "../config/languages";

const octagonRepo = RepositoryFactory.get("octagon");

export const executeCode = createAsyncThunk(
  "octagone/executeCode",
  async (payload, { dispatch, rejectWithValue }) => {
    dispatch(executeCodeLoader(true));
    try {
      const { data } = await octagonRepo.executeCode(payload);
      if (data) {
        dispatch(executeCodeLoader(false));
        return data;
      }
    } catch (err) {
      dispatch(executeCodeLoader(false));
      error(err.message);
      return rejectWithValue(err);
    }
  },
);

function getFileLanguage(fileName) {
  return fileName.includes(".") && fileName.at(0) !== "."
    ? languages[fileName.split(".").at(-1)]
    : "other";
}

function getFileExtension(fileName) {
  return fileName.includes(".") && fileName.at(0) !== "."
    ? fileName.split(".").at(-1)
    : "txt";
}

// get the reference of directory at path in the state slice,
// path is full path of directory
function getDirFromPath(fileTree, path) {
  if (path === ".") return fileTree;
  let pathArr = path.split("/");
  if (pathArr[0] === ".") pathArr = pathArr.slice(1);
  return pathArr.reduce((acc, dir) => {
    if (dir === "") {
      return acc;
    }
    return acc.childDirs.find((child) => child.label === dir);
  }, fileTree);
}

// get the reference of directory at path in the state slice,
// path is full path of the file
function getFileFromPath(fileTree, path) {
  let dirPath = path.split("/").slice(0, -1).join("/");
  let fileName = path.split("/").at(-1);
  const dir = getDirFromPath(fileTree, dirPath);
  return dir.childFiles.find((file) => file.name === fileName);
}

const octagonSlice = createSlice({
  name: "octagon",
  initialState: {
    isIndustry: false,
    selectedQuestionType: "",
    roleCategoryId: "",
    interviewTrainingSessionId: "",
    loaders: {
      executeCodeLoader: false,
    },
    errors: {
      executeCodeError: null,
    },
    fileType: "js",
    fileList: [],
    fileTree: {
      name: ".",
      path: ".",
      childDirs: [],
      childFiles: [],
    },
    answers: {},
    activeFileKey: null,
    executeCodeResult: null,
  },
  reducers: {
    executeCodeLoader: (state, action) => {
      console.log("executeCodeLoader action:", action);
      state.loaders.executeCodeLoader = action.payload;
    },
    // load files and directory data from the backend into state
    loadDir: (state, action) => {
      console.log("loadDir action:", action);
      const dir = getDirFromPath(state.fileTree, action.payload.path);
      let childFiles = action.payload.files.map((file) => ({
        name: file,
        path: action.payload.path + "/" + file,
        // language: getFileLanguage(file),
        // type: getFileExtension(file),
        label: file,
        saved: true,
        value: null, // use null instead
      }));
      let childDirs = action.payload.dirs.map((dir) => ({
        label: dir,
        path: action.payload.path + "/" + dir,
        childDirs: null,
        childFiles: null,
      }));

      dir.childDirs = childDirs;
      dir.childFiles = childFiles;
    },
    changeTab: (state, action) => {
      console.log("changeTab action:", action);
      state.activeFileKey = action.payload;
    },
    codeChange: (state, action) => {
      console.log("codeChange action:", action);
      state[action.payload.questionId] = action.payload.code;
      state.fileList[parseInt(state.activeFileKey)].value = action.payload.code;
      state.fileList[parseInt(state.activeFileKey)].saved = false;
    },
    // load file content from the backend
    loadFile: (state, action) => {
      console.log("loadFile action:", action);
      const dir = getDirFromPath(state.fileTree, action.payload.path);
      let fileName = action.payload.fileName;
      let node = dir.childFiles.find((file) => file.name === fileName);
      node.value = action.payload.value;

      state.fileList.find((file) => file.path === node.path).value =
        action.payload.value;
    },
    openFileTab: (state, action) => {
      console.log("openFileTab action:", action);
      const file = getFileFromPath(state.fileTree, action.payload.path);
      state.activeFileKey = state.fileList.length;
      let key = state.activeFileKey;
      state.fileList.push({
        key,
        name: file.name,
        label: file.name,
        value: file.value,
        path: file.path,
        saved: true,
        closable: true,
      });
    },
    // create new file
    newFile: (state, action) => {
      console.log("newFile action:", action);
      let fileName = action.payload.name;
      let fileObj = {
        key: state.fileList.length,
        label: fileName,
        type: getFileExtension(fileName),
        value: "",
        path: action.payload.dirPath + "/" + fileName,
        language: getFileLanguage(fileName),
        saved: false,
        closable: true,
      };
      const dir = getDirFromPath(state.fileTree, action.payload.dirPath);
      dir.childFiles.push({
        name: fileName,
        path: action.payload.dirPath + "/" + fileName,
        label: fileName,
        saved: false,
        value: "",
      });
      state.fileList.push(fileObj);
      state.activeFileKey = state.fileList.length - 1;
    },
    fileSavedState: (state, action) => {
      console.log("fileSavedState action:", action);
      // update status of file as saved after it is saved in backend
      state.fileList[action.payload.fileKey].saved = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(executeCode.pending, (state) => {
        state.loaders.executeCodeLoader = true;
      })
      .addCase(executeCode.fulfilled, (state, action) => {
        state.loaders.executeCodeLoader = false;
        state.executeCodeResult = action.payload;
      })
      .addCase(executeCode.rejected, (state, action) => {
        state.loaders.executeCodeLoader = false;
        state.errors.executeCodeError = action.payload;
      });
  },
});

export const {
  changeTab,
  executeCodeLoader,
  codeChange,
  newFile,
  loadDir,
  fileSavedState,
  loadFile,
  openFileTab,
} = octagonSlice.actions;
export default octagonSlice.reducer;
