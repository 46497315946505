import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "../../Components/Resume/DatePicker";
import RichTextEditor from "../RichTextEditor";
import {
  addProject,
  addResumeProjects,
  deleteResumeProjects,
  getResumeProjects,
  removeProject,
  udpateResumeProjects,
  updateProject,
} from "../../Redux/ResumeBuilderSlice";
// import {
//   setActiveField,
//   showPorjectForm,
// } from "../education/EducationFormSlice";
import { error, success } from "../../Utilities/toast";
import { useParams } from "react-router-dom";

const ProjectForm = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { projects } = useSelector((state) => state.ResumeBuilder);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [editorContent, setEditorContent] = useState("");
  const [defaultContent, setDefaultContent] = useState("");
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const [project, setProject] = useState(projects);
  const [updateDocId, setUpdateDocId] = useState(null);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      project_name: "",
      organization: "",
    },
  });

  const handleEditingIndex = (index, id) => {
    setIsFormVisible(true);
    setSelectedIndex(index);
    setUpdateDocId(id);
    const foundObj = project.find((obj, idx) => idx === index);
    reset({
      project_name: foundObj?.project_name,
      organization: foundObj?.organization,
    });
    setSelectedStartDate(new Date(foundObj.start_date));
    setSelectedEndDate(new Date(foundObj.end_date));
    setDefaultContent(foundObj?.additional_information);
  };

  const onCancel = () => {
    setIsFormVisible(false);
    reset({
      project_name: "",
      organization: "",
    });
    setSelectedStartDate(new Date());
    setSelectedEndDate(new Date());
    setEditorContent("");
    setSelectedIndex(-1);
  };

  const handleAddProject = () => {
    setIsFormVisible(true);
  };

  const handleRemoveProject = (payload) => {
    dispatch(
      deleteResumeProjects({
        payload,
        onSuccess: (res) => {
          success(res);
          getProjectsInfo();
        },
      })
    );
  };

  const onSubmit = (data) => {
    const timeZoneOffset = selectedStartDate.getTimezoneOffset() * 60000;
    data.start_date = new Date(selectedStartDate - timeZoneOffset)
      .toISOString()
      .split("T")[0];

    data.end_date = new Date(selectedEndDate - timeZoneOffset)
      .toISOString()
      .split("T")[0];
    data.additional_information = editorContent;
    // data.is_organization_active = "false"

    if (selectedIndex !== -1) {
      const payload = {
        updateDocId,
        data,
      };
      const nowData = new Date();
      const endedAtDate = new Date(payload?.data?.end_date);
      if (payload?.data?.start_date >= payload?.data?.end_date) {
        error("Start date cannot be greater than or equal to end date");
        return;
      }
      if (endedAtDate > nowData) {
        error("End date cannot be greater than current date");
        return;
      }
      dispatch(
        udpateResumeProjects({
          payload,
          onSuccess: (res) => {
            success(res);
            getProjectsInfo();
          },
        })
      );
    } else {
      const payload = data;
      const nowData = new Date();
      const endedAtDate = new Date(payload?.end_date);
      if (payload.start_date >= payload.end_date) {
        error("Start date cannot be greater than or equal to end date");
        return;
      }
      if (endedAtDate > nowData) {
        error("End date cannot be greater than current date");
        return;
      }
      dispatch(
        addResumeProjects({
          payload,
          id,
          onSuccess: (res) => {
            success(res);
            getProjectsInfo();
          },
        })
      );
    }

    setIsFormVisible(false);
    reset({
      project_name: "",
      organization: "",
    });
    setSelectedStartDate(new Date());
    setSelectedEndDate(new Date());
    setEditorContent("");
    setSelectedIndex(-1);
    setDefaultContent("");
  };

  const getProjectsInfo = () => {
    const resumeId = id;
    dispatch(getResumeProjects({ resumeId, onSuccess: () => {} }));
  };

  useEffect(() => {
    if (projects?.length > 0) {
      setProject(projects);
    }
  }, [projects]);

  return (
    <div className="mydetails">
      {isFormVisible && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex mb-1   ">
            <div className="form-group mr-2 w-50 ">
              <label htmlFor="project_name">Project Name</label>
              <input
                type="text"
                id="project_name"
                className="form-control"
                placeholder=""
                {...register("project_name", {
                  required: "Project name is required",
                })}
                // onFocus={() => dispatch(showPorjectForm())}
              />
              {errors.project_name && (
                <span className="text-danger">
                  {errors.project_name.message}
                </span>
              )}
            </div>
            <div className="form-group ml-3  w-50">
              <label htmlFor="organization">Organization</label>
              <input
                type="text"
                id="organization"
                className="form-control"
                placeholder=""
                {...register("organization", {
                  required: "Organization name is required",
                })}
                // onFocus={() => dispatch(showPorjectForm())}
              />
              {errors.organization && (
                <span className="text-danger">
                  {errors.organization.message}
                </span>
              )}
            </div>
          </div>
          <div className="d-flex mb-1 flex-col md:flex-row">
            <div className="form-group mr-2 w-full md:w-50">
              <label htmlFor="startdate">Start Date</label>
              <DatePicker
                selectedDate={selectedStartDate}
                onSelect={setSelectedStartDate}
              />
            </div>
            <div className="form-group ml-0 md:ml-3 w-full md:w-50">
              <label htmlFor="enddate">End Date</label>
              <DatePicker
                selectedDate={selectedEndDate}
                onSelect={setSelectedEndDate}
              />
            </div>
          </div>
          <div className="d-flex   mb-1   ">
            <div className="form-group  w-100 ">
              <label htmlFor="additionalinformation">
                Additional Information
              </label>
              <div className="customized-editor">
                <RichTextEditor
                  setEditorContent={setEditorContent}
                  defaultContent={defaultContent}
                />
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end  py-2 px-2   border-bottom border-primary  ">
            <button
              type="button"
              className="cancel-btn mr-4"
              style={{
                fontSize: "14px",
                fontWeight: "500",
                width: "100px",
                height: "40px",
              }}
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="save-btn"
              style={{
                fontSize: "14px",
                fontWeight: "500",
                width: "100px",
                height: "40px",
              }}
            >
              Save
            </button>
          </div>
        </form>
      )}
      {!isFormVisible && (
        <div className="professionalsummary-item">
          <button
            className="professionalitem-button"
            onClick={handleAddProject}
          >
            <i className="ri-add-circle-line"></i>
            <p className="ml-2">Add Project</p>
          </button>
        </div>
      )}
      {projects &&
        projects.map((project, index) => (
          <div
            key={index}
            className="professionalsummary-item"
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
          >
            <div className="d-flex">
              <p className="mr-2">{project.project_name}</p>
              <p>{project.organization}</p>
            </div>
            {hoveredIndex === index && (
              <div className="professionalitem-actions">
                <button
                  className="professionalitem-button"
                  onClick={() => handleEditingIndex(index, project?.id)}
                >
                  <i className="ri-edit-line"></i>
                </button>
                <button
                  className="professionalitem-button"
                  onClick={() => handleRemoveProject(project?.id)}
                >
                  <i className="ri-delete-bin-line"></i>
                </button>
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default ProjectForm;
