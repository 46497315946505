import React, { useState } from 'react';
import JobListing from '../../Components/Autoapply/JobListing';
import { jobListings as allJobListings } from '../AutoApply/JobData';
import "./style.css";
import FilterComponent from "../../Components/Autoapply/FilterComponent";
import Header from "../../Components/header";

const AutoApply = () => {
  const [filteredJobListings, setFilteredJobListings] = useState(allJobListings);


  
  const handleApplyFilters = (filters) => {
    const filtered = allJobListings.filter((job) => {
      // Simplified regex to detect days
     
      const match = job.postedDate.match(/Posted (\d+)d ago/);
      let daysAgo = match ? parseInt(match[1], 10) : 40; // Assume 40 days if not matched
      // Filtering logic based on selected filters
      if (filters.includes("any-time")) return true;
      if (filters.includes("past-week") && daysAgo <= 7) return true;
      if (filters.includes("past-month") && daysAgo <= 30) return true;
  
      return false;
    });
  
    setFilteredJobListings(filtered);
  };
  return (
    <>
      <Header/>
      <FilterComponent onApplyFilters={handleApplyFilters} />

      <div className="parent-container-wrapper">
        <div className="parent-container" style={{ position: 'relative' }}>
          {filteredJobListings.map((job, index) => (
            <JobListing
              key={index}
              jobTitle={job.jobTitle}
              compensation={job.compensation}
              location={job.location}
              tenure={job.tenure}
              descriptionPoints={job.descriptionPoints}
              companyName={job.companyName}
              companyTagline={job.companyTagline}
              funding={job.funding}
              headcount={job.headcount}
              founded={job.founded}
              headquarters={job.headquarters}
              CompanyDesc={job.CompanyDesc}
              logoUrl={job.logoUrl}
              topInvestors={job.topInvestors}
              ventureBacked={job.ventureBacked}
              postedDate={job.postedDate}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default AutoApply;
