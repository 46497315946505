import React from "react";

const CancelModal = ({ cancelModal, toggleModal, cancelSubscription }) => {
  return (
    <div>
      <div
        class={`fixed z-10 overflow-y-auto top-0 w-full left-0 ${
          !cancelModal ? "hidden" : ""
        }`}
        id="modal"
      >
        <div class="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div class="fixed inset-0 transition-opacity">
            <div class="absolute inset-0 bg-gray-900 opacity-75" />
          </div>
          <span class="hidden sm:inline-block sm:align-middle sm:h-screen">
            &#8203;
          </span>
          <div
            class="inline-block py-4 align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <h1 class="text-2xl font-bold text-center my-2">
              Are you Sure you want to cancel this Subscription?
            </h1>
            <div class="bg-gray-200 px-4 py-3 mt-3 text-right">
              <button
                type="button"
                class="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2"
                onClick={toggleModal}
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={cancelSubscription}
                class="py-2 px-4 bg-blue-500 text-white rounded font-medium hover:bg-blue-700 mr-2 transition duration-500"
              >
                Sure
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancelModal;
