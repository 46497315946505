import React from "react";
import { useDispatch } from "react-redux";
import { removeToken } from "../../Redux/authenticate/authenticate.slicer";

const ContinueIndividualModal = ({ show, setShow, Confirmation }) => {
  const dispatch = useDispatch();
  const handleIndividual = () => {
    Confirmation(true);
  };

  return (
    <div className={`ic-modal ${show ? "show" : ""}`}>
      <div className="ic-modal-dialog">
        <div className="modal-main-content w-100">
          <div className="ic-modal-header">Continue as Individual User</div>

          <div className="ic-modal-body">
            <div className="ic-modal-content">
              You are removed by the organization. Do you want to continue as an
              individual user?
            </div>
            <div className="ic-footer-modal d-flex justify-content-end">
              <button
                onClick={() => {
                  setShow(false);
                  dispatch(removeToken());
                }}
              >
                Cancel
              </button>
              <button onClick={handleIndividual}>
                Continue as individual user
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContinueIndividualModal;
