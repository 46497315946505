import React, { useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";

import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loader from "../../Components/main-loader";
import {
  createInvitedUser,
  authenticateAction,
  ssoInvitationLoginAction,
} from "../../Redux/authenticate/authenticate.action";
import { error } from "../../Utilities/toast";
import "../Login/style.css";

const InviteUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { loading } = useSelector((state) => state.authenticate);
  const [checkoutLoader, setCheckoutLoader] = useState(false);
  const [passwordFieldType, setPasswordFieldType] = useState("password");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [fullname, setFullname] = useState("");
  const query = new URLSearchParams(location.search);
  const token = query.get("token");

  const form = {
    errors: {},
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        await dispatch(authenticateAction(response.access_token))
          .unwrap()
          .then((res) => {
            let payload = {
              invitation_token: token,
              sso_token: response.access_token,
            };
            dispatch(ssoInvitationLoginAction(payload))
              .unwrap()
              .then((ssoResponse) => {
                if (ssoResponse?.isNewUser) {
                  navigate("/onboarding");
                } else {
                  navigate("/home");
                }
              });
          });
      } catch (err) {
        console.log(err);
      }
    },
    onError: (error) => console.log("Login Failed:", error),
    redirectUri: "http://localhost:3001",
  });

  const registerInvitedUser = (e) => {
    e.preventDefault();
    let payload = {
      fullName: fullname,
      password: password,
      token: token,
    };

    if (password == confirmPassword) {
      dispatch(createInvitedUser(payload))
        .unwrap()
        .then((resolvedData) => {
          if (resolvedData?.isNewUser) {
            setCheckoutLoader(true);
            navigate("/onboarding");
          } else {
            navigate("/home");
          }
        })
        .catch((rejectedValueOrSerializedError) => {
          console.error("InviteUser failed", rejectedValueOrSerializedError);
        });
    } else {
      error("Password do not match");
    }
  };

  return (
    <div>
      <div className="ic-login">
        <div className="ic-login-box">
          <Link to="" className="ic-auth-logo">
            <img src="/images/logo.png" alt="logo" style={{ width: "220px" }} />
          </Link>
          <p className="text-center auth-sub-title">
            Nexa, Your AI Career Platform
          </p>

          <h4 className="text-center auth-title">Create your account</h4>

          <div className="auth-social">
            <button onClick={googleLogin} className="ic-google">
              <img src="/images/google.png" alt="" /> Sign in with Google
            </button>
          </div>
          <div className="or">
            <p>Or</p>
          </div>

          <form onSubmit={(e) => registerInvitedUser(e)}>
            <div className="form-group">
              <label htmlFor="fullname">Full Name</label>
              <input
                type="text"
                id="fullname"
                className={`form-control ${
                  form.errors.fullname ? "is-invalid" : ""
                }`}
                placeholder="Enter your full name"
                value={fullname}
                onChange={(e) => setFullname(e.target.value)}
              />
              {form.errors.fullname ? (
                <div className="invalid-feedback">{form.errors.fullname}</div>
              ) : (
                ""
              )}
            </div>

            {/* <div className="form-group">
              <label htmlFor="#">Email Address</label>
              <input
                type="email"
                id="email"
                className={`form-control ${
                  form.errors.email ? "is-invalid" : ""
                }`}
                placeholder="example@nexa.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {form.errors.email ? (
                <div className="invalid-feedback">{form.errors.email}</div>
              ) : (
                ""
              )}
            </div> */}

            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                type={passwordFieldType}
                id="password"
                className="form-control"
                placeholder="Type password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {form.errors.password ? (
                <div className="invalid-feedback">{form.errors.password}</div>
              ) : (
                ""
              )}
            </div>

            <div className="form-group">
              <label htmlFor="confirm-password">Confirm Password</label>
              <input
                type={passwordFieldType}
                id="confirm-password"
                className="form-control"
                placeholder="Type password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>

            <button
              type="submit"
              className="ic-btn w-100"
              disabled={loading}
              style={{ fontSize: "16px", fontWeight: "500" }}
            >
              {loading || checkoutLoader ? <Loader /> : "Sign Up"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default InviteUser;
