import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import DatePicker from "../../Components/Resume/DatePicker";
import {
  addCertification,
  addResumeCertificate,
  deleteResumeCertificate,
  getResumeCertificate,
  removeCertification,
  udpateResumeCertificate,
  updateCertification,
} from "../../Redux/ResumeBuilderSlice";
import { error, success } from "../../Utilities/toast";
import { useParams } from "react-router-dom";

const CertificationForm = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const { certificates } = useSelector((state) => state.ResumeBuilder);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [certificate, setCertificate] = useState(certificates);
  const [selectedStartDate, setSelectedStartDate] = useState(yesterday);
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [updateDocId, setUpdateDocId] = useState(null);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleAddCertification = () => {
    setIsFormVisible(true);
  };

  const handleEditingIndex = (index, id) => {
    setIsFormVisible(true);
    setSelectedIndex(index);
    setUpdateDocId(id);
    const foundObj = certificate.find((obj, idx) => idx === index);
    reset({
      name: foundObj?.name,
      provider: foundObj?.provider,
    });
    setSelectedStartDate(new Date(foundObj.start_date));
    setSelectedEndDate(new Date(foundObj.end_date));
  };

  const onCancel = () => {
    setIsFormVisible(false);
    reset({
      name: "",
      provider: "",
    });
    setSelectedStartDate(yesterday);
    setSelectedEndDate(new Date());
  };

  const onSubmit = (data) => {
    const timeZoneOffset = selectedStartDate.getTimezoneOffset() * 60000;
    data.start_date = new Date(selectedStartDate - timeZoneOffset)
      .toISOString()
      .split("T")[0];

    data.end_date = new Date(selectedEndDate - timeZoneOffset)
      .toISOString()
      .split("T")[0];

    if (new Date(data.start_date) >= new Date(data.end_date)) {
      error("Start date cannot be greater than or equal to end date");
      return;
    }

    if (new Date(data.start_date) > new Date()) {
      error("Start date cannot be greater than Current Date");
      return;
    }

    console.log("formatted dates", data.start_date, data.end_date);
    console.log("formdata", data);

    if (selectedIndex !== -1) {
      const payload = {
        updateDocId,
        data,
      };
      dispatch(
        udpateResumeCertificate({
          payload,
          onSuccess: (res) => {
            success(res);
            getCertificationInfo();
          },
        })
      );
    } else {
      const payload = data;
      dispatch(
        addResumeCertificate({
          payload,
          id,
          onSuccess: (res) => {
            success(res);
            getCertificationInfo();
          },
        })
      );
    }

    setIsFormVisible(false);
    reset({
      name: "",
      provider: "",
    });
    setSelectedStartDate(yesterday);
    setSelectedEndDate(new Date());
    setSelectedIndex(-1);
  };

  const getCertificationInfo = () => {
    const resumeId = id;
    dispatch(getResumeCertificate({ resumeId, onSuccess: () => {} }));
  };

  const handleRemoveCertification = (payload) => {
    dispatch(
      deleteResumeCertificate({
        payload,
        onSuccess: (res) => {
          success(res);
          getCertificationInfo();
        },
      })
    );
  };

  useEffect(() => {
    if (certificates?.length > 0) {
      setCertificate(certificates);
    }
  }, [certificates]);

  return (
    <div className="mydetails">
      {isFormVisible && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex flex-column mb-1">
            <div className="d-flex mb-1">
              <div className="form-group mr-2 w-50 ">
                <label htmlFor="name">Certification</label>
                <input
                  type="text"
                  id="name"
                  className="form-control"
                  placeholder=""
                  {...register("name", {
                    required: "Certification name is required",
                  })}
                />
                {errors.name && (
                  <span className="text-danger">{errors.name.message}</span>
                )}
              </div>
              <div className="form-group ml-3  w-50">
                <label htmlFor="provider">Provider</label>
                <input
                  type="text"
                  id="provider"
                  className="form-control"
                  placeholder=""
                  {...register("provider", {
                    required: "Provider name is required",
                  })}
                />
                {errors.provider && (
                  <span className="text-danger">{errors.provider.message}</span>
                )}
              </div>
            </div>
            <div className="d-flex mb-1 flex-col md:flex-row">
              <div className="form-group mr-2 w-full md:w-50">
                <label htmlFor="startdate">Start Date</label>
                <DatePicker
                  selectedDate={selectedStartDate}
                  onSelect={setSelectedStartDate}
                />
              </div>
              <div className="form-group ml-0 md:ml-3 w-full md:w-50">
                <label htmlFor="enddate">End Date</label>
                <DatePicker
                  selectedDate={selectedEndDate}
                  onSelect={setSelectedEndDate}
                />
              </div>
            </div>
            <div className="d-flex justify-content-end  py-2 px-2   border-bottom border-primary  ">
              <button
                type="button"
                className="cancel-btn mr-4"
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  width: "100px",
                  height: "40px",
                }}
                onClick={onCancel}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="save-btn"
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  width: "100px",
                  height: "40px",
                }}
              >
                Save
              </button>
            </div>
          </div>
        </form>
      )}
      {!isFormVisible && (
        <div className="professionalsummary-item">
          <button
            className="professionalitem-button"
            onClick={handleAddCertification}
          >
            <i className="ri-add-circle-line"></i>
            <p className="ml-2">Add Certification</p>
          </button>
        </div>
      )}
      {certificates &&
        certificates.map((certificate, index) => (
          <div
            key={index}
            className="professionalsummary-item"
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
          >
            <div className="d-flex">
              <p className="mr-2">{certificate.name}</p>
              <p>{certificate.provider}</p>
            </div>
            {hoveredIndex === index && (
              <div className="professionalitem-actions">
                <button
                  className="professionalitem-button"
                  onClick={() => handleEditingIndex(index, certificate?.id)}
                >
                  <i className="ri-edit-line"></i>
                </button>
                <button
                  className="professionalitem-button"
                  onClick={() => handleRemoveCertification(certificate?.id)}
                >
                  <i className="ri-delete-bin-line"></i>
                </button>
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default CertificationForm;
