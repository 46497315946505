import candidates from "./candidates.svg";
import Chart_alt_light from "./Chart_alt_light.svg";
import File_dock_light from "./File_dock_light.svg";
import pie_chart_light from "./pie_chart_light.svg";
import Star_light from "./Star_light.svg";
import Stat from "./Stat.svg";
import Transger_light from "./Transger_light.svg";
import User_alt_light from "./User_alt_light.svg";
import User_scan_light from "./User_scan_light.svg";
import table_settings_light from "./table_settings_light.svg";
import Book_open_light from "./Book_open_light.svg";
import faqs from "./faqs.svg";
import help_center from "./help_center.svg";
import File_dock_search_light from "./File_dock_search_light.svg";
import arrowRight from "./arrow-right.svg";

export {
  candidates,
  Chart_alt_light,
  File_dock_light,
  pie_chart_light,
  Star_light,
  Stat,
  Transger_light,
  User_alt_light,
  User_scan_light,
  table_settings_light,
  Book_open_light,
  faqs,
  help_center,
  File_dock_search_light,
  arrowRight
};
