import { Link } from "react-router-dom";

const Sidebar = () => {

    return (
        <>
            <nav className="sidebar sidebar-offcanvas" id="sidebar">
                <ul className="nav">
                    {/* <li className="nav-item">
                        <Link className="nav-link" to="/dashboard/home">
                            <span className="icon-bg"><i className="mdi mdi-cube menu-icon"></i></span>
                            <span className="menu-title">Dashboard</span>
                        </Link>
                    </li> */}

                    <li className="nav-item sidebar-user-actions">
                        <div className="sidebar-user-menu">
                            <Link to="/dashboard/home" className="nav-link"><i className="mdi mdi-cube menu-icon"></i>
                                <span className="menu-title">Dashboard</span>
                            </Link>
                        </div>
                    </li>
                    <li className="nav-item sidebar-user-actions">
                        <div className="sidebar-user-menu">
                            <Link to="/dashboard/reporting" className="nav-link"><i className="mdi mdi-format-list-bulleted menu-icon"></i>
                                <span className="menu-title">Reporting</span>
                            </Link>
                        </div>
                    </li>

                    <li className="nav-item sidebar-user-actions">
                        <div className="sidebar-user-menu">
                            <Link to="/dashboard/analytics" className="nav-link"><i className="mdi mdi-chart-bar menu-icon"></i>
                                <span className="menu-title">Analytics</span>
                            </Link>
                        </div>
                    </li>
                    
                   
                    

                    <li className="nav-item sidebar-user-actions d-none">
                        <div className="user-details">
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <div className="d-flex align-items-center">
                                        <div className="sidebar-profile-img">
                                            <img src="assets/images/faces/face28.png" alt="image" />
                                        </div>
                                        <div className="sidebar-profile-text">
                                            <p className="mb-1">Henry Klein</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="badge badge-danger">3</div>
                            </div>
                        </div>
                    </li>
                    <li className="nav-item sidebar-user-actions">
                        <div className="sidebar-user-menu">
                            <a href="#" className="nav-link"><i className="mdi mdi-settings menu-icon"></i>
                                <span className="menu-title">Settings</span>
                            </a>
                        </div>
                    </li>
                    <li className="nav-item sidebar-user-actions d-none">
                        <div className="sidebar-user-menu">
                            <a href="#" className="nav-link"><i className="mdi mdi-speedometer menu-icon"></i>
                                <span className="menu-title">Take Tour</span></a>
                        </div>
                    </li>
                    <li className="nav-item sidebar-user-actions">
                        <div className="sidebar-user-menu">
                            <a href="#" className="nav-link"><i className="mdi mdi-logout menu-icon"></i>
                                <span className="menu-title">Log Out</span></a>
                        </div>
                    </li>
                </ul>
            </nav>
        </>
    )
};

export default Sidebar;
