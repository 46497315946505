import React, { useEffect, useState } from "react";
import Header from "../../Components/header";
import AddCardModal from "../Payments/data/AddCardModal";
import AddTicketModal from "./data/AddTicketModal";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Loader from "../../Components/main-loader";
import { success } from "../../Utilities/toast";
import {
  deleteTicket,
  getAllTickets,
  getSingleTicket,
} from "../../Redux/TicketSlice";

const Tickets = () => {
  const dispatch = useDispatch();
  const { tickets, loading } = useSelector((state) => state.tickets);
  const [addModal, setAddModal] = useState(false);
  const [ticketMode, setTicketMode] = useState("create");
  const [singleTaskData, setSingleTaskData] = useState(null);

  const toggleModal = () => {
    setAddModal(!addModal);
  };

  const handleDeleteTicket = (id) => {
    dispatch(
      deleteTicket({
        id,
        onSuccess: () => {
          success("Ticket deleted successfully");
          dispatch(getAllTickets());
        },
      })
    );
  };
  const handleEditTicket = (id) => {
    dispatch(
      getSingleTicket({
        id,
        onSuccess: (res) => {
          setSingleTaskData(res);
          setTicketMode("edit");
          toggleModal();
        },
      })
    );
  };

  useEffect(() => {
    dispatch(getAllTickets());
  }, []);
  return (
    <>
      <Header />
      {loading && <Loader />}
      <div
        className="ic-interview-full-body overflow-hidden"
        style={{
          backgroundImage: `url(${"/images/interview-banner.png"})`,
          marginTop: "6em",
          padding: "1rem",
        }}
      >
        <div className="w-full grid-margin stretch-card my-5">
          <div
            className="card border-rounded-card card-padding-profile2 !overflow-scroll"
            style={{ boxShadow: "10px 10px 40px gray" }}
          >
            <div className="card-body text-center justify-content-between card-body-mobile">
              <div className="mt-3">
                <div className="flex justify-content-between">
                  <p className="text-center text-lg text-dark mb-4">
                    Your Tickets
                  </p>
                  <button
                    className="mx-3 bg-primary px-3 py-1 text-md rounded-md font-medium text-white"
                    onClick={() => {
                      setTicketMode("create");
                      toggleModal();
                    }}
                  >
                    Add New Ticket
                  </button>
                </div>
                <div class="flex flex-col">
                  <div class="overflow-x-auto sm:mx-0.5 lg:mx-0.5">
                    <div class="py-2 inline-block min-w-full">
                      <div class="overflow-hidden">
                        <table class="min-w-full">
                          <thead class="bg-gray-200 border-b">
                            <tr>
                              <th
                                scope="col"
                                class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                              >
                                Title
                              </th>
                              <th
                                scope="col"
                                class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                              >
                                Detail
                              </th>
                              <th
                                scope="col"
                                class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                              >
                                Created At
                              </th>
                              <th
                                scope="col"
                                class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                              >
                                Updated At
                              </th>
                              <th
                                scope="col"
                                class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                              >
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {tickets?.length > 0 ? (
                              tickets?.map((item) => {
                                return (
                                  <tr class="bg-white  text-capitalize border-b text-start transition duration-300 ease-in-out hover:bg-gray-100">
                                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                      {item?.title}
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                      {item?.details}
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                      {moment(item?.created_on).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                      {moment(item?.updated_on).format(
                                        "DD-MM-YYYY"
                                      )}
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                      <div className="flex gap-3">
                                        <button
                                          className="py-2 bg-primary px-3 text-md rounded-lg font-medium text-white"
                                          onClick={() =>
                                            handleEditTicket(item?.id)
                                          }
                                        >
                                          Edit
                                        </button>
                                        <button
                                          className="py-2 bg-danger px-3 text-md rounded-lg font-medium text-white"
                                          onClick={() =>
                                            handleDeleteTicket(item?.id)
                                          }
                                        >
                                          Delete
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr className="bg-white border-b text-start transition duration-300 ease-in-out hover:bg-gray-100">
                                <td
                                  colSpan="6"
                                  className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center"
                                >
                                  No Data Found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddTicketModal
        addModal={addModal}
        toggleModal={toggleModal}
        ticketMode={ticketMode}
        singleTaskData={singleTaskData}
      />
    </>
  );
};

export default Tickets;
