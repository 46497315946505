import React from "react";
import PrivacyPolicyLayout from "../Components/layout/PrivacyPolicyLayout";

const PrivacyPolicy = () => {
  return (
    <PrivacyPolicyLayout>
      <div className="privacy_policy_content">
        <div className="ic_text_content">
          <h6>1. Introduction</h6>
          <p>
            Nexa is not just any career services tool; it is a software platform
            built with your security in mind. We leverage advanced encryption
            and security measures to protect your data at every step of your
            interaction with our services. Whether you are using Nexa for
            interview simulation training or utilizing our AI-driven feedback
            mechanisms, you can be assured that your data is handled with care
            and protected by the latest in security technology. We are committed
            to protecting your privacy and handling your personal information
            transparently and securely. This Privacy Policy outlines how we
            collect, use, store, and share your information when you use our
            services. By accessing or using Nexa, you agree to the practices
            described in this policy.
          </p>
        </div>
        <div className="ic_text_content">
          <h6>2. Consent</h6>
          <p>
            By using Nexa, you consent to the collection, use, and sharing of
            your personal information as described in this Privacy Policy. We
            will obtain your consent for any additional uses or disclosures of
            your information that are not covered in this Policy or required by
            law.
          </p>
        </div>
        <div className="ic_text_content">
          <h6>3. Information Collection</h6>
          <p>
            We collect information about you in various ways when you use our
            service. This includes:
          </p>
          <ul>
            <li>
              <p>
                <span>Personal Information:</span> Such as your name, email
                address, and resume details that you voluntarily provide when
                you register or use our services.
              </p>
            </li>
            <li>
              <p>
                <span>Usage Information:</span> Details of how you use Nexa,
                including interaction data and preferences.
              </p>
            </li>
            <li>
              <p>
                <span>Technical Information:</span> Including your IP address,
                browser type, and device information, collected through cookies
                and similar technologies.
              </p>
            </li>
          </ul>
        </div>
        <div className="ic_text_content">
          <h6>4. Use of Information</h6>
          <p>
            The information we collect is used in several ways, including to:
          </p>
          <ul>
            <li>
              <p>
                Provide and improve our services, including personalizing your
                experience and support.
              </p>
            </li>
            <li>
              <p>
                Communicate with you about your account, our services, and
                updates.
              </p>
            </li>
            <li>
              <p>
                Analyze and understand our audience for business development and
                service improvement. Ensure the security and integrity of our
                service.
              </p>
            </li>
          </ul>
        </div>
        <div className="ic_text_content">
          <h6>5. Information Sharing and Disclosure</h6>
          <p>
            We do not sell your personal information. We may share your
            information with third parties in the following circumstances:
          </p>
          <ul>
            <li>
              <p>
                <span>Service Providers:</span> To assist in providing our
                services, such as hosting, analytics, and customer service.
              </p>
            </li>
            <li>
              <p>
                <span>Legal Requirements:</span> If required by law or if we
                believe that such action is necessary to comply with legal
                processes, we may disclose your information.
              </p>
            </li>
            <li>
              <p>
                <span>Business Transfers:</span> In connection with a merger,
                acquisition, or sale of assets, your information may be
                transferred as part of that transaction.
              </p>
            </li>
          </ul>
        </div>
        <div className="ic_text_content">
          <h6>6. International Data Transfers</h6>
          <p>
            Your information may be transferred to, and maintained on, computers
            located outside of your state, province, country, or other
            governmental jurisdiction where the privacy laws may not be as
            protective as those in your jurisdiction. We will take all steps
            reasonably necessary to ensure that your data is treated securely
            and in accordance with this Privacy Policy.
          </p>
        </div>
        <div className="ic_text_content">
          <h6>7. Cookies and Tracking Technologies</h6>
          <p>
            We use cookies and similar tracking technologies to track activity
            on our service and hold certain information. Cookies are files with
            a small amount of data which may include an anonymous unique
            identifier. You can instruct your browser to refuse all cookies or
            to indicate when a cookie is being sent. However, if you do not
            accept cookies, you may not be able to use some portions of our
            service.
          </p>
        </div>
        <div className="ic_text_content">
          <h6>8. Data Retention</h6>
          <p>
            We retain your personal information only for as long as is necessary
            for the purposes set out in this Privacy Policy. We will retain and
            use your information to the extent necessary to comply with our
            legal obligations (for example, if we are required to retain your
            data to comply with applicable laws), resolve disputes, and enforce
            our legal agreements and policies. Nexa will also retain Usage Data
            for internal analysis purposes. Usage Data is generally retained for
            a shorter period, except when this data is used to strengthen the
            security or to improve the functionality of our Service, or we are
            legally obligated to retain this data for longer time periods.
          </p>
        </div>
        <div className="ic_text_content">
          <h6>9. User Rights and Choices</h6>
          <p>
            You have several rights concerning the personal information we hold
            about you. These rights include the ability to:
          </p>
          <ul>
            <li>
              <p>
                <span>Access:</span> You can request a copy of the personal
                information we hold about you.
              </p>
            </li>
            <li>
              <p>
                <span>Correction:</span> You can request that we correct any
                inaccuracies in the personal information we hold.
              </p>
            </li>
            <li>
              <p>
                <span>Deletion:</span> You can request that we delete your
                personal information from our systems.
              </p>
            </li>
            <li>
              <p>
                <span>Restrict Processing:</span> You have the right to ask us
                to restrict processing your personal information under certain
                circumstances.
              </p>
            </li>
            <li>
              <p>
                <span>Data Portability:</span> You have the right to receive the
                personal information you have provided to us in a structured,
                commonly used, and machine-readable format.
              </p>
            </li>
            <li>
              <p>
                <span>Object:</span> You can object to the processing of your
                personal information in certain circumstances (for example, when
                we don’t have to process the data to meet a contractual or other
                legal requirement).
              </p>
            </li>
          </ul>
        </div>
        <div className="ic_text_content">
          <h6>10. Security Measures</h6>
          <p>
            The security of your personal information is important to us. We
            implement and maintain reasonable, commercially acceptable security
            procedures and practices appropriate to the nature of the
            information we store, in order to protect it from unauthorized
            access, destruction, use, modification, or disclosure. However, no
            method of transmission over the internet, or method of electronic
            storage, is 100% secure; and therefore, we cannot guarantee its
            absolute security.
          </p>
        </div>
        <div className="ic_text_content">
          <h6>11. Children's Privacy</h6>
          <p>
            Our Service does not address anyone under the age of 13
            ("Children"). We do not knowingly collect personally identifiable
            information from children under 13. If you are a parent or guardian
            and you are aware that your Child has provided us with personal
            information, please contact us. If we become aware that we have
            collected personal information from children without verification of
            parental consent, we take steps to remove that information from our
            servers.
          </p>
        </div>
        <div className="ic_text_content">
          <h6>12. Third-Party Services</h6>
          <p>
            Our Service may contain links to other sites that are not operated
            by us. If you click on a third-party link, you will be directed to
            that third party's site. We strongly advise you to review the
            Privacy Policy of every site you visit. We have no control over and
            assume no responsibility for the content, privacy policies, or
            practices of any third-party sites or services.
          </p>
        </div>
        <div className="ic_text_content">
          <h6>13. Updates to the Privacy Policy</h6>
          <p>
            We reserve the right to update or change our Privacy Policy at any
            time. You should check this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page. If we make any material changes to this Privacy
            Policy, we will notify you either through the email address you have
            provided us or by placing a prominent notice on our website.
          </p>
        </div>
        <div className="ic_text_content">
          <h6>14. Contact Information</h6>
          <p>
            If you have any questions about this Privacy Policy or our practices
            regarding your personal information, please contact us at [insert
            contact email or physical address here]. Our dedicated team will
            address your concerns and provide the assistance you need with
            respect to your privacy inquiries.
          </p>
        </div>
        <div className="ic_text_content">
          <h6>15. Compliance with PIPEDA</h6>
          <p>
            As a Canadian-operated service, we adhere to the Personal
            Information Protection and Electronic Documents Act (PIPEDA). This
            means we are committed to protecting your personal information and
            ensuring our collection, use, and disclosure practices are
            transparent, consent-based, and with a purpose that you understand.
            If you wish to inquire about your personal information held by us,
            correct it, or challenge our compliance, please contact our Privacy
            Officer at the provided contact information.
          </p>
        </div>
        <div className="ic_text_content">
          <h6>16. Compliance with GDPR</h6>
          <p>
            For users in the European Economic Area (EEA), Nexa complies with
            the General Data Protection Regulation (GDPR) to ensure the secure
            and lawful processing of personal data. You have the right to
            access, correct, delete, and restrict processing of your data, the
            right to data portability, and the right to object to data
            processing. If you wish to exercise any of these rights or have
            concerns about our data processing practices, please contact us.
          </p>
        </div>
        <div className="ic_text_content">
          <h6>17. Compliance with CCPA</h6>
          <p>
            For residents of California, in line with the California Consumer
            Privacy Act (CCPA), you have the right to know about the personal
            information collected about you, to delete personal information we
            hold, and to opt-out of the sale of personal information. To request
            access to or deletion of your personal information, or to exercise
            any other data rights under California law, please contact us using
            the contact information provided.
          </p>
        </div>
        <div className="ic_text_content">
          <h6>18. Data Breach Notification</h6>
          <p>
            In the unlikely event of a data breach that compromises your
            personal information, we will notify you and any applicable
            regulator when we are legally required to do so, in accordance with
            the privacy laws applicable to your data.
          </p>
        </div>
        <div className="ic_text_content">
          <h6>19. Data Protection Officer</h6>
          <p>
            To ensure the highest level of commitment to your privacy, we have
            appointed a Data Protection Officer (DPO). The DPO is responsible
            for overseeing our data protection strategy and its implementation
            to secure your data. For any questions related to our privacy
            practices or the processing of your personal data, please contact
            our DPO directly at [insert DPO contact information here].
          </p>
        </div>
        <div className="ic_text_content">
          <h6>20. Cross-Border Data Transfers</h6>
          <p>
            Your information, including Personal Data, may be transferred to —
            and maintained on — computers located outside of your state,
            province, country, or other governmental jurisdiction where the data
            protection laws may differ from those of your jurisdiction. We
            ensure that such transfers comply with the legal requirements,
            providing an adequate level of protection to any personal data
            transferred.
          </p>
        </div>
        <div className="ic_text_content">
          <h6>21. Governing Law</h6>
          <p>
            This Privacy Policy is governed by and construed in accordance with
            the laws of Canada, without giving effect to any principles of
            conflicts of law. For users outside of Canada, we commit to
            resolving any privacy concerns in accordance with this Privacy
            Policy and applicable local laws.
          </p>
        </div>
      </div>
    </PrivacyPolicyLayout>
  );
};

export default PrivacyPolicy;
