import authRepository from "./AuthRepository";
import DashboardRepository from "./DashboardRepository";
import profileRepository from "./ProfileRepository";
import ResumeRepository from "./ResumeRepository";
import MyJobsRepository from "./JobRepository";
import paymentRepository from "./PaymentsRepository";
import customerRepository from "./CustomerRepository";
import ChatRepository from "./ChatRepository";
import TaskRepository from "./TaskRepository";
import TicketRepository from "./TicketRepository";
import octagonRepository from "./OctagonRepository";
import SimulatorProRepository from "./SimulatorProRepository";

const repositories = {
  auth: authRepository,
  Dashboard: DashboardRepository,
  profile: profileRepository,
  resume: ResumeRepository,
  job: MyJobsRepository,
  Payment: paymentRepository,
  customer: customerRepository,
  chat: ChatRepository,
  task: TaskRepository,
  Tickets: TicketRepository,
  octagon: octagonRepository,
  simulatorPro: SimulatorProRepository,
};

export const RepositoryFactory = {
  get: (name) => repositories[name],
};
