import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RepositoryFactory } from "../repository/RepositoryFactory";
import { error, success } from "../Utilities/toast";

const ticketRepo = RepositoryFactory.get("Tickets");

// Async actions
export const addNewTicket = createAsyncThunk(
  "ticket/addNewTicket",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await ticketRepo.addNewTicket(payload);
      if (data?.status_code === 200) {
        onSuccess();
        return data;
      }
      throw new Error("Fetch failed");
    } catch (err) {
      error(err.response.data.detail || "Something went wrong");
      return rejectWithValue(err.message);
    }
  }
);

export const getAllTickets = createAsyncThunk(
  "ticket/getAllTickets",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await ticketRepo.getAllTickets();
      if (data?.status_code === 200) {
        return data.data;
      }
      throw new Error("Fetch failed");
    } catch (err) {
      error(err.response.data.detail || "Something went wrong");
      return rejectWithValue(err.message);
    }
  }
);

export const deleteTicket = createAsyncThunk(
  "ticket/deleteTicket",
  async ({ id, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await ticketRepo.deleteTicket(id);
      if (data?.status_code === 204 || data?.status_code === 200) {
        onSuccess();
        return data;
      }
      throw new Error("Fetch failed");
    } catch (err) {
      error(err.response.data.detail || "Something went wrong");
      return rejectWithValue(err.message);
    }
  }
);

export const getSingleTicket = createAsyncThunk(
  "ticket/getSingleTicket",
  async ({ id, onSuccess }, { rejectWithValue }) => {
    try {
      const data = await ticketRepo.getSingleTicket(id);
      if (data?.status === 200) {
        onSuccess(data?.data);
        return data?.data;
      }
      throw new Error("Fetch failed");
    } catch (err) {
      error(err.response.data.detail || "Something went wrong");
      return rejectWithValue(err.message);
    }
  }
);

export const udpateTicket = createAsyncThunk(
  "ticket/udpateTicket",
  async ({ payload, id, onSuccess }, { rejectWithValue }) => {
    try {
      const data = await ticketRepo.udpateTicket(payload, id);
      console.log(data)
      if (data?.status === 200) {
        onSuccess();
        return data.data;
      }
      throw new Error("Fetch failed");
    } catch (err) {
      error(err.response.data.detail || "Something went wrong");
      return rejectWithValue(err.message);
    }
  }
);

const TicketSlice = createSlice({
  name: "ticket",
  initialState: {
    loading: false,
    tickets: [],
    singleTicket: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addNewTicket.pending, (state) => {
        state.loading = true;
      })
      .addCase(addNewTicket.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(addNewTicket.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getAllTickets.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllTickets.fulfilled, (state, action) => {
        state.loading = false;
        state.tickets = action.payload.tickets;
      })
      .addCase(getAllTickets.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteTicket.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteTicket.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteTicket.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getSingleTicket.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSingleTicket.fulfilled, (state, action) => {
        state.loading = false;
        state.singleTicket = action.payload;
      })
      .addCase(getSingleTicket.rejected, (state) => {
        state.loading = false;
      })
      .addCase(udpateTicket.pending, (state) => {
        state.loading = true;
      })
      .addCase(udpateTicket.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(udpateTicket.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default TicketSlice.reducer;
