import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RepositoryFactory } from "../repository/RepositoryFactory";
import { error, success } from "../Utilities/toast";

const dashboardRepo = RepositoryFactory.get("Dashboard");

// Async actions
export const getBlogs = createAsyncThunk(
  "blogs/getBlogs",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await dashboardRepo.getAllBlogs();
      if (data?.status_code === 200) {
        return data.data.data;
      }
      throw new Error("Fetch failed");
    } catch (err) {
      error(err.response.data.detail);
      return rejectWithValue(err.message);
    }
  }
);

const BlogSlice = createSlice({
  name: "blogs",
  initialState: {
    loading: false,
    blogs: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBlogs.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBlogs.fulfilled, (state, action) => {
        state.loading = false;
        state.blogs = action.payload;
      })
      .addCase(getBlogs.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default BlogSlice.reducer;
