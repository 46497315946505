import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RepositoryFactory } from "../repository/RepositoryFactory";
import { error, success } from "../Utilities/toast";

const paymentRepo = RepositoryFactory.get("Payment");

export const getPaymentInvoices = createAsyncThunk(
  "payment/invoices",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await paymentRepo.getInvoices();
      return data.data;
    } catch (err) {
      error(err.response.data.detail);
      return rejectWithValue(err.message);
    }
  }
);

// Async actions
export const getAllPaymentCards = createAsyncThunk(
  "payment/getCards",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await paymentRepo.getAllCards();
      return data.data;
    } catch (err) {
      error(err.response.data.detail);
      return rejectWithValue(err.message);
    }
  }
);

export const addNewPaymentMethod = createAsyncThunk(
  "payment/addNew",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await paymentRepo.addNewCard(payload);
      if (data.status_code === 200) {
        success(data.detail);
        return data.data;
      }
    } catch (err) {
      error(err.response.data.detail);
      return rejectWithValue(err.message);
    }
  }
);
export const setDefaultPaymentCard = createAsyncThunk(
  "payment/updateDeafult",
  async (ID, { rejectWithValue }) => {
    try {
      const { data } = await paymentRepo.updateDefaultCard(ID);
      if (data.status_code === 200) {
        success(data.detail);
        return data.data;
      }
    } catch (err) {
      error(err.response.data.detail);
      return rejectWithValue(err.message);
    }
  }
);
export const deletePaymentCard = createAsyncThunk(
  "payment/deleteCard",
  async (ID, { rejectWithValue }) => {
    try {
      const { data } = await paymentRepo.deleteCard(ID);
      if (data.status_code === 200) {
        success(data.reason);
        return data;
      }
    } catch (err) {
      error(err.response.data.detail);
      return rejectWithValue(err.message);
    }
  }
);

export const getDeclinedError = createAsyncThunk(
  "payment/getDeclinedError",
  async ({ onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await paymentRepo.getDeclinedError();
      if (data?.status_code == 200) {
        onSuccess();
      }
      return data?.data?.declined_payments[0];
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const reActivatePackage = createAsyncThunk(
  "subscription/reActivatePackage",
  async ({ payload, onSuccess }, { rejectWithValue }) => {
    try {
      const { data } = await paymentRepo.reActivatePackage(payload);
      if (data?.status_code == 200) {
        success(data?.detail);
        if (data?.data?.payment_intent_status == "succeeded") {
          onSuccess();
        }
        return data;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// payment slice
const paymentSlice = createSlice({
  name: "payment",
  initialState: {
    paymentLoading: false,
    paymentCards: [],
    declineError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentInvoices.pending, (state) => {
        state.paymentLoading = true;
      })
      .addCase(getPaymentInvoices.fulfilled, (state, action) => {
        state.paymentLoading = false;
        state.paymentInvoices = action.payload;
      })
      .addCase(getPaymentInvoices.rejected, (state) => {
        state.paymentLoading = false;
      })
      .addCase(getAllPaymentCards.pending, (state) => {
        state.paymentLoading = true;
      })
      .addCase(getAllPaymentCards.fulfilled, (state, action) => {
        state.paymentLoading = false;
        state.paymentCards = action.payload;
      })
      .addCase(getAllPaymentCards.rejected, (state) => {
        state.paymentLoading = false;
      })
      .addCase(addNewPaymentMethod.pending, (state) => {
        state.paymentLoading = true;
      })
      .addCase(addNewPaymentMethod.fulfilled, (state, action) => {
        state.paymentLoading = false;
      })
      .addCase(addNewPaymentMethod.rejected, (state) => {
        state.paymentLoading = false;
      })
      .addCase(setDefaultPaymentCard.pending, (state) => {
        state.paymentLoading = true;
      })
      .addCase(setDefaultPaymentCard.fulfilled, (state, action) => {
        state.paymentLoading = false;
      })
      .addCase(setDefaultPaymentCard.rejected, (state) => {
        state.paymentLoading = false;
      })
      .addCase(deletePaymentCard.pending, (state) => {
        state.paymentLoading = true;
      })
      .addCase(deletePaymentCard.fulfilled, (state, action) => {
        state.paymentLoading = false;
      })
      .addCase(deletePaymentCard.rejected, (state) => {
        state.paymentLoading = false;
      })
      .addCase(getDeclinedError.pending, (state) => {
        state.paymentLoading = true;
      })
      .addCase(getDeclinedError.fulfilled, (state, action) => {
        state.paymentLoading = false;
        state.declineError = action.payload;
      })
      .addCase(getDeclinedError.rejected, (state, action) => {
        state.paymentLoading = false;
        state.error = action.error.message;
      })
      .addCase(reActivatePackage.pending, (state) => {
        state.paymentLoading = true;
      })
      .addCase(reActivatePackage.fulfilled, (state, action) => {
        state.paymentLoading = false;
      })
      .addCase(reActivatePackage.rejected, (state, action) => {
        state.paymentLoading = false;
        state.error = action.error.message;
      });
  },
});

export default paymentSlice.reducer;
