import Repository from "./Repository";
const job = "/resume/job_descriptions?resume_id=";
const MyJobsRepository = {
  Add_JOB(jobID,payload) {
    return Repository.post(`${job}${jobID}`,payload);
  },
  GET_JOB(jobID){
    return Repository.get(`${job}${jobID}`);
  },
  UPDATE_JOB(jobID,payload){
    return Repository.put(`${job}${jobID}`,payload);
  },
  DELETE_JOB(jobID){
    return Repository.delete(`${job}${jobID}`);
  }
};

export default MyJobsRepository;