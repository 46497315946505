// ** Toolkit imports
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { persistReducer, persistStore } from "redux-persist";
import rootReducer from "../Redux/rootReducer";

// Configure persist
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["globalState", "authenticate", "dashboard", "Steps"],
};

const rootReducerClear = (state, action) => {
  if (action.type === "RESET") {
    localStorage.clear();
    storage.removeItem("persist:root");
    state = undefined;
  }
  return rootReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducerClear);
// Create the store with persisted reducer
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Export persistor to be used in the application
export const persistor = persistStore(store);
