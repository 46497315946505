import React from "react";

const OctagonCards = ({ card, onCardClick, index }) => {
  return (
    <div
      className="ic-interview-items"
      data-aos="fade-up"
      data-aos-delay="50"
      data-aos-duration={index * 200 + 500}
      onClick={onCardClick}
    >
      {/* <div
        onClick={onCardClick}
        className="flex flex-col items-center justify-center w-full h-full"
      >
        <div className="ic-icons h-32 w-32 flex items-center justify-center mb-4 bg-white rounded-full">
          <img
            src={card.image}
            className="img-fluid h-24 w-24 object-contain"
            alt={card.label}
          />
        </div>

        <p>{card.label}</p>

        <i
          className="ri-arrow-right-line ic-right-arrow"
          style={{ fontSize: "22px", marginTop: 10 }}
          size="30"
        />
      </div> */}

      <div className="ic-icons">
        <img
          src={card.image}
          className="img-fluid h-24 w-24 object-contain"
          alt={card.label}
        />
      </div>
      <p>{card.label}</p>
      <i
        className="ri-arrow-right-line ic-right-arrow"
        style={{ fontSize: "22px", marginTop: 10 }}
        size="30"
      />
    </div>
  );
};

const OctaCatCards = ({ card, onCardClick, index }) => {
  return (
    <div
      // data-aos="fade-up"
      // data-aos-delay="50"
      // data-aos-duration={index * 200 + 500}
      className="ic-interview-items"
      onClick={onCardClick}
      // style={{ width: "90%" }}
    >
      {/* <div
        onClick={onCardClick}
        className="flex flex-col items-center p-6 text-center text-base font-medium text-gray-800 hover:text-white"
      >
        <div className="bg-white rounded-full p-4 mb-4 flex items-center justify-center">
          <img src={card.image} className="w-10 h-10" alt={card.label} />
        </div>
        <p style={{ minHeight: 70 }}>{card.label}</p>

        <i
          className="ri-arrow-right-line ic-right-arrow"
          style={{ fontSize: "22px", marginTop: 10 }}
          size="30"
        />
      </div> */}
      <div className="ic-icons">
        <img src={card.image} className="w-10 h-10" alt={card.label} />
      </div>
      <p>{card.label}</p>
      <i
        className="ri-arrow-right-line ic-right-arrow"
        style={{ fontSize: "22px", marginTop: 10 }}
        size="30"
      />
    </div>
  );
};

export { OctagonCards, OctaCatCards };
