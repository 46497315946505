import React, { useState } from "react";
import { Link } from "react-router-dom";
import founder from "../../assets/media/aboutUs/founder.png";
import cto from "../../assets/media/aboutUs/cto.png";
import { FaArrowRight } from "react-icons/fa6";

const CareerSection = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const handleOnHover = () => {
    setIsOpen(true);
  };
  const handleOutHover = () => {
    setTimeout(() => {
      setIsOpen(false);
    }, 3000);
  };
  const handleOnHover2 = () => {
    setIsOpen2(true);
  };
  const handleOutHover2 = () => {
    setTimeout(() => {
      setIsOpen2(false);
    }, 3000);
  };
  return (
    <div className="aboutUs__career-container">
      <div
        className="aboutUs__career-rectangle-modal"
        style={{
          visibility: isOpen ? "visible" : "hidden",
        }}
      >
        <p className="aboutUs__career-para">
          Divy Nayyar is the visionary founder and CEO of Nexa, bringing over 7
          years of experience as a cybersecurity and data engineer. With a deep
          understanding of the technology landscape, Divy has dedicated his
          career to empowering individuals through innovative career preparation
          tools. Before founding Nexa, Divy established InTech Institute, a
          leading vocational center for technology education. His hands-on
          experience in the industry and direct interaction with students have
          given him unique insights into the challenges and pain points faced by
          job seekers and educationalinstitutions alike.
          <br /> Driven by a passion for making career preparation accessible
          and effective, Divy leverages his technical expertise and
          entrepreneurial spirit to lead Nexa towards transforming the way
          people prepare for and secure their dream jobs.
        </p>
      </div>
      <div
        className="aboutUs__career-rectangle-modal"
        style={{
          visibility: isOpen2 ? "visible" : "hidden",
        }}
      >
        <p className="aboutUs__career-para">
          Prithvi Yendapally is the Co-Founder and Chief Technology Officer of
          Nexa, bringing a wealth of experience and a passion for innovation in
          career and HR tech. Before co-founding Nexa, Prithvi honed his skills
          as an AI Developer at Scale AI, where he worked on cutting-edge
          projects that pushed the boundaries of artificial intelligence.
          <br /> Prithvi holds dual degrees in Computer Science and Business
          from the University of Southern California (USC), a combination that
          has equipped him with both technical prowess and a deep understanding
          of business dynamics. His entrepreneurial spirit led him to build a
          successful freelance staffing and recruiting business, where he gained
          invaluable insights into the intricacies of the hiring process and the
          challenges faced by both job seekers and employers. <br />
          Driven by a passion for creating meaningful career opportunities,
          Prithvi leverages his technical expertise and industry experience to
          lead Nexa’s technological vision and development. His commitment to
          innovation and excellence ensures that Nexa’s platform continually
          evolves to meet the needs of its users, providing them with the tools
          and confidence to achieve their career aspirations.
        </p>
      </div>
      <div className="container">
        <div className="aboutUs__career-content">
          <h2 className="aboutUs__career-heading section-heading ">
            Team and Culture
          </h2>
          <div className="teamSection_box-cover">
            <div className="teamSection_box">
              <div className="teamSection_box-profile_cover">
                <div className="teamSection_box-profile-left">
                  <img
                    src={founder}
                    alt="founder"
                    className="teamSection_box-profile-image"
                  />
                  <h3 className="teamSection_box-profile-heading">
                    Divy Nayyar
                  </h3>
                  <p className="teamSection_box-profile-description">
                    Founder and CEO
                  </p>
                  <button
                    className="teamSection_box-profile-button"
                    onMouseOver={handleOnHover}
                    onMouseOut={handleOutHover}
                  >
                    Watch Bio
                    <FaArrowRight
                      size={20}
                      style={{ marginLeft: "5px", marginTop: "4px" }}
                    />
                  </button>
                </div>
                <div className="teamSection_box-profile-right">
                  <img
                    src={cto}
                    alt="cto"
                    className="teamSection_box-profile-image"
                  />
                  <h3 className="teamSection_box-profile-heading">
                    Prithvi Yendapally
                  </h3>
                  <p className="teamSection_box-profile-description">
                    Co-Founder & CTO
                  </p>
                  <button
                    className="teamSection_box-profile-button"
                    onMouseOver={handleOnHover2}
                    onMouseOut={handleOutHover2}
                  >
                    Watch Bio
                    <FaArrowRight
                      size={20}
                      style={{ marginLeft: "5px", marginTop: "4px" }}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerSection;
