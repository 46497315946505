import React, { useState } from "react";

const FilterComponent = ({ onApplyFilters }) => {
  const [activeFilter, setActiveFilter] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState([]);

  const toggleFilter = (filter) => {
    setActiveFilter(activeFilter === filter ? null : filter);
  };

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    setSelectedFilters((prevFilters) =>
      checked ? [...prevFilters, value] : prevFilters.filter((v) => v !== value)
    );
  };

  const handleApply = () => {
    onApplyFilters(selectedFilters);
    setActiveFilter(null);
    setSelectedFilters([]);
  };

  return (<>
    <div className="fillter"></div>
    <div className="wrappertop">
<div className="filter-component bg-transparent hide-mob">

<div className="flex h-full w-full items-center justify-center space-x-6 custom-max-width">



              <div className="shrink-1">
                <div className="relative">
                  <div className="py-[10px] px-[16px] cursor-default rounded-[8px] flex items-center border border-black bg-transparent">
                    <span className="text-sm font-semibold whitespace-nowrap max-w-[150px] text-ellipsis overflow-hidden">
                      Network Engineer
                    </span>
                    <svg
                      width="17"
                      height="16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-1"
                    >
                      <path
                        d="m4.5 5.334-.715.781L8.5 10.83l4.714-4.714-.714-.781-4 4-4-4Z"
                        fill="#4D4D4D"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="shrink-1">
                <div className="relative">
                  <div className="py-[10px] px-[16px] cursor-default rounded-[8px] flex items-center border border-black bg-[#FAFFEB]">
                    <span className="text-sm font-semibold whitespace-nowrap max-w-[150px] text-ellipsis overflow-hidden">
                      Internship
                    </span>
                    <svg
                      width="17"
                      height="16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-1"
                    >
                      <path
                        d="m4.5 5.334-.715.781L8.5 10.83l4.714-4.714-.714-.781-4 4-4-4Z"
                        fill="#4D4D4D"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="shrink-1 relative w-[200px]">
                <div
                  className={`py-[10px] px-[16px] cursor-pointer rounded-[8px] flex items-center justify-between border border-black ${
                    activeFilter === "networkEngineer"
                      ? "bg-[#DCFF64]"
                      : "bg-[#FAFFEB]"
                  }`}
                  onClick={() => toggleFilter("networkEngineer")}
                >
                  <span className="text-sm font-semibold whitespace-nowrap text-ellipsis overflow-hidden">
                    Date Posted
                  </span>
                  <svg
                    width="17"
                    height="16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={`ml-1 ${
                      activeFilter === "networkEngineer" ? "rotate-180" : ""
                    }`}
                  >
                    <path
                      d="m4.5 5.334-.715.781L8.5 10.83l4.714-4.714-.714-.781-4 4-4-4Z"
                      fill="#4D4D4D"
                    ></path>
                  </svg>
                </div>
                {activeFilter === "networkEngineer" && (
                  <div className="absolute rounded-[12px] border border-[#cccccc] mt-2 left-0 bg-white shadow-lg w-full p-4 z-[1000]">
                    <ul className="mb-3 space-y-2 flex flex-col items-start">
                      <li className="flex h-[28px] flex-row items-center space-x-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          id="any-time"
                          value="any-time"
                          onChange={handleCheckboxChange}
                        />
                        <label
                          htmlFor="any-time"
                          className="text-sm whitespace-nowrap"
                        >
                          Any time
                        </label>
                      </li>
                      <li className="flex h-[28px] flex-row items-center space-x-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          id="past-month"
                          value="past-month"
                          onChange={handleCheckboxChange}
                        />
                        <label
                          htmlFor="past-month"
                          className="text-sm whitespace-nowrap"
                        >
                          Past month
                        </label>
                      </li>
                      <li className="flex h-[28px] flex-row items-center space-x-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          id="past-week"
                          value="past-week"
                          onChange={handleCheckboxChange}
                        />
                        <label
                          htmlFor="past-week"
                          className="text-sm whitespace-nowrap"
                        >
                          Past week
                        </label>
                      </li>
                    </ul>
                    <button
                      className="bg-black w-full py-1 rounded-[6px] text-[#f9f9f9] font-semibold"
                      onClick={handleApply}
                    >
                      Apply
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          </div>
          </>
  );
};

export default FilterComponent;
