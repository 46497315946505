import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SkillsGapConvertion } from "./SkillsGapConvertion";
import { getSkillsAnalysis } from "../Redux/authenticate/authenticate.action";

const TypingEffect = ({ speed = 50, storeFetchValue }) => {
  const dispatch = useDispatch();
  const { skillsAnalysis } = useSelector((state) => state.authenticate);
  const [displayedText, setDisplayedText] = useState([]);
  const [handleTyper, setHandleTyper] = useState(true);
  const [index, setIndex] = useState(0);
  const [textArray, setTextArray] = useState([]);

  useEffect(() => {
    if (skillsAnalysis) {
      const convertedText = SkillsGapConvertion(skillsAnalysis?.analysis ?? "");
      setTextArray(convertedText);
      setDisplayedText([]);
      setIndex(0);
    }
  }, [skillsAnalysis]);

  useEffect(() => {
    if (index < textArray.length) {
      const timeout = setTimeout(() => {
        setDisplayedText((prev) => [...prev, textArray[index]]);
        setIndex((prev) => prev + 1);
      }, speed);

      return () => clearTimeout(timeout);
    }
  }, [index, textArray, speed]);

  useEffect(() => {
    dispatch(
      getSkillsAnalysis({
        onSuccess: () => {
          setHandleTyper(false);
        },
      })
    );
  }, []);

  useEffect(() => {
    setHandleTyper(storeFetchValue);
  }, [storeFetchValue]);

  return (
    <div className="skillsgap">
      <div
        dangerouslySetInnerHTML={{
          __html: handleTyper
            ? displayedText.join("")
            : skillsAnalysis?.analysis,
        }}
      ></div>
    </div>
  );
};

export default TypingEffect;
