
const UsersComponent = () => {
   
    const fetchedData = {
        totalInterviewTaken:0,
        interviewsFeedback:0,
        interviewSeesionTaken:0,
        interviewMutualFeedback:0
    };
     /*Remove this from comment when you get a real API
    const fetchData = fetch("backend/userDashboard");*/
    return (
        <>
          <div className="content-wrapper">

<div className="d-xl-flex justify-content-between align-items-start">
    <h2 className="text-dark font-weight-bold mb-2"> Overview Users </h2>
    
</div>
<div className="row">
    <div className="col-md-12">
       
        <div className="tab-content tab-transparent-content">
            <div className="tab-pane fade show active" >
                <div className="row">
                    <div className="col-xl-3 col-lg-6 col-sm-6 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body text-center">
                                <h5 className="mb-2 text-dark font-weight-normal">Total Interviews Taken</h5>
                                <h2 className="mb-4 text-dark font-weight-bold">{fetchedData.totalInterviewTaken}</h2>
                               
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-sm-6 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body text-center">
                                <h5 className="mb-2 text-dark font-weight-normal">Interviews Feedback</h5>
                                <h2 className="mb-4 text-dark font-weight-bold">{fetchedData.interviewsFeedback}</h2>
                               
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3  col-lg-6 col-sm-6 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body text-center">
                                <h5 className="mb-2 text-dark font-weight-normal">Interview Session Taken</h5>
                                <h2 className="mb-4 text-dark font-weight-bold">{fetchedData.interviewSeesionTaken}</h2>
                               
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-6 col-sm-6 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body text-center">
                                <h5 className="mb-2 text-dark font-weight-normal">Interviews & mutual Feedback</h5>
                                <h2 className="mb-4 text-dark font-weight-bold">{fetchedData.interviewMutualFeedback}</h2>
                               
                            </div>
                        </div>
                    </div>
                </div>
                

                {/*  */}
            </div>
        </div>
    </div>
</div>
</div>
        </>
    )
};

export default UsersComponent;
