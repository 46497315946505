import { createContext, useReducer, useState } from "react";
import reducer from "./reducer/sidebarReducer";
import PropTypes from "prop-types";

const initialState = {
  activeLinkIdx: 1,
};

export const MobileSidebarContext = createContext({});
export const MobileSideMenuProvider = ({ children }) => {
  const [activeLinkIdx, setActiveLinkIdx] = useState(1);
  const [isMobileSidebarActive, setIsMobileSidebarActive] = useState(false);

  return (
    <MobileSidebarContext.Provider
      value={{
        activeLinkIdx,
        setActiveLinkIdx,
        isMobileSidebarActive,
        setIsMobileSidebarActive,
      }}
    >
      {children}
    </MobileSidebarContext.Provider>
  );
};

MobileSideMenuProvider.propTypes = {
  children: PropTypes.node,
};
