import React from "react";
import PrivacyPolicyLayout from "../Components/layout/PrivacyPolicyLayout";

const TermsConditions = () => {
  return (
    <PrivacyPolicyLayout>
      {" "}
      <div className="privacy_policy_content">
        <div className="ic_text_content">
          <h6>1. Introduction</h6>
          <p>
            Welcome to Nexa ("Nexa", "we", "us", or "our"), an innovative AI
            career services tool designed to enhance your performance in job
            interviews through simulation training. Our platform leverages your
            resume and job descriptions to offer a personalized training
            experience. These Terms and Conditions govern your access to and use
            of Nexa's website, services, and applications (collectively, the
            "Service"). By accessing or using the Service, you agree to be bound
            by these Terms and Conditions and our Privacy Policy.
          </p>
        </div>
        <div className="ic_text_content">
          <h6>2. Acceptance of Terms</h6>
          <p>
            By registering for, accessing, or using any part of the Service, you
            agree to these Terms and Conditions and all other operating rules,
            policies, and procedures that may be published from time to time on
            the Service by us, each of which is incorporated by reference and
            each of which may be updated from time to time without notice to
            you. If you do not agree to these Terms, you must not access or use
            the Service.
          </p>
        </div>
        <div className="ic_text_content">
          <h6>3. Modification of Terms</h6>
          <p>
            We reserve the right, at our sole discretion, to modify or replace
            these Terms and Conditions at any time. If a revision is material,
            we will provide at least 30 days' notice prior to any new terms
            taking effect. What constitutes a material change will be determined
            at our sole discretion. By continuing to access or use our Service
            after those revisions become effective, you agree to be bound by the
            revised terms. If you do not agree to the new terms, you are no
            longer authorized to use the Service.
          </p>
        </div>
        <div className="ic_text_content">
          <h6>4. Account Registration and Use</h6>
          <p>
            To access certain features of the Service, you must register for an
            account. When registering, you agree to provide accurate, current,
            and complete information as requested by any forms on the Service
            ("Registration Data"). You further agree to maintain and promptly
            update your Registration Data and any other information you provide
            to us to keep it accurate, current, and complete. You are
            responsible for maintaining the confidentiality of your account and
            password, including but not limited to the restriction of access to
            your computer and/or account. You agree to accept responsibility for
            any and all activities or actions that occur under your account
            and/or password, whether your password is with our Service or a
            third-party service. You must notify us immediately upon becoming
            aware of any breach of security or unauthorized use of your account.
          </p>
        </div>
        <div className="ic_text_content">
          <h6>5. Nexa Services Description</h6>
          <p>
            Nexa provides an AI-powered career services tool that offers
            personalized interview simulation training. Utilizing advanced
            algorithms, Nexa analyses your resume and job descriptions to tailor
            training sessions aimed at improving your interview skills. Our
            services are designed to help you better understand interview
            dynamics, refine your responses, and ultimately enhance your
            performance in real-world job interviews. Please note that while we
            strive to provide valuable insights and training, your success in
            any job interview is not guaranteed.
          </p>
        </div>
        <div className="ic_text_content">
          <h6>6. User Responsibilities</h6>
          <p>
            As a user of Nexa, you are responsible for your own actions in
            connection with the Service. This includes ensuring the accuracy,
            legality, and propriety of all information and content you provide
            on the platform. You agree not to use the Service in any manner that
            violates these Terms and Conditions or any local, state, national,
            or international law or regulation. Additionally, you are
            responsible for maintaining the confidentiality of your account
            information and for all activities that occur under your account.
          </p>
        </div>
        <div className="ic_text_content">
          <h6>7. Privacy Policy</h6>
          <p>
            Your privacy is important to us. Our Privacy Policy explains how we
            collect, use, and share information about you when you use Nexa. By
            using the Service, you agree to our collection, use, and sharing of
            your information as outlined in the Privacy Policy, which is
            incorporated into these Terms and Conditions by reference. Please
            read the Privacy Policy carefully to understand our practices
            regarding your information.
          </p>
        </div>
        <div className="ic_text_content">
          <h6>8. Intellectual Property Rights</h6>
          <p>
            All rights, title, and interest in and to the Service (excluding
            content provided by users) are and will remain the exclusive
            property of Nexa and its licensors. The Service, its original
            content, features, and functionality are protected by copyright,
            trademark, and other laws of Canada, the United States, and foreign
            countries. Our trademarks and trade dress may not be used in
            connection with any product or service without the prior written
            consent of Nexa. You are granted a limited, non-exclusive,
            non-transferable license to access and use the Service strictly in
            accordance with these Terms.
          </p>
        </div>
        <div className="ic_text_content">
          <h6>9. User Content</h6>
          <p>
            You may be able to upload, store, publish, display, and distribute
            information, text, photos, videos, or other material on or through
            the Service ("User Content"). By posting User Content, you grant
            Nexa a non-exclusive, worldwide, royalty-free, perpetual, and
            sublicensable license to use, reproduce, modify, perform, display,
            distribute, and otherwise disclose to third parties any such
            material for any purpose. You represent and warrant that: (i) you
            own or control all rights in and to the User Content and have the
            right to grant the license granted above, and (ii) that the User
            Content does not violate the privacy, publicity, copyrights, or
            other rights of any third party.
          </p>
        </div>
        <div className="ic_text_content">
          <h6>10. Prohibited Conduct</h6>
          <p>
            You agree not to engage in any of the following prohibited
            activities: (i) copying, distributing, or disclosing any part of the
            Service in any medium, including without limitation by any automated
            or non-automated "scraping"; (ii) using any automated system,
            including without limitation "robots," "spiders," "offline readers,"
            etc., to access the Service in a manner that sends more request
            messages to the Nexa servers than a human can reasonably produce in
            the same period by using a conventional online web browser; (iii)
            transmitting spam, chain letters, or other unsolicited email; (iv)
            attempting to interfere with, compromise the system integrity or
            security, or decipher any transmissions to or from the servers
            running the Service; (v) taking any action that imposes, or may
            impose at our sole discretion an unreasonable or disproportionately
            large load on our infrastructure; (vi) uploading invalid data,
            viruses, worms, or other software agents through the Service; (vii)
            collecting or harvesting any personally identifiable information,
            including account names, from the Service; (viii) using the Service
            for any commercial solicitation purposes; (ix) impersonating another
            person or otherwise misrepresenting your affiliation with a person
            or entity, conducting fraud, hiding, or attempting to hide your
            identity; (x) interfering with the proper working of the Service;
            (xi) accessing any content on the Service through any technology or
            means other than those provided or authorized by the Service; or
            (xii) bypassing the measures we may use to prevent or restrict
            access to the Service, including without limitation features that
            prevent or restrict use or copying of any content or enforce
            limitations on use of the Service or the content therein.
          </p>
        </div>
        <div className="ic_text_content">
          <h6>11. Service Availability and Modifications</h6>
          <p>
            We are continually evolving and improving the Service. We may add,
            alter, or remove functionality from a Service it provides to you at
            any time without prior notice. We may also limit, suspend, or
            discontinue a Service provided to you without prior notice. We may
            remove content from the Service at any time in our sole discretion,
            although we will endeavor to notify you before we do so if it
            materially impacts you and if practicable under the circumstances.
          </p>
        </div>
        <div className="ic_text_content">
          <h6>12. Termination</h6>
          <p>
            We may terminate or suspend your account and bar access to the
            Service immediately, without prior notice or liability, under our
            sole discretion, for any reason whatsoever and without limitation,
            including but not limited to a breach of the Terms. If you wish to
            terminate your account, you may simply discontinue using the
            Service. All provisions of the Terms which by their nature should
            survive termination shall survive termination, including, without
            limitation, ownership provisions, warranty disclaimers, indemnity,
            and limitations of liability.
          </p>
        </div>
        <div className="ic_text_content">
          <h6>13. Disclaimer of Warranties</h6>
          <p>
            The Service is provided on an "AS IS" and "AS AVAILABLE" basis. Use
            of the Service is at your own risk. To the maximum extent permitted
            by applicable law, the Service is provided without warranties of any
            kind, whether express or implied, including, but not limited to,
            implied warranties of merchantability, fitness for a particular
            purpose, or non-infringement. Without limiting the foregoing, Nexa,
            its subsidiaries, and its licensors do not warrant that the content
            is accurate, reliable, or correct; that the Service will meet your
            requirements; that the Service will be available at any particular
            time or location, uninterrupted or secure; that any defects or
            errors will be corrected; or that the Service is free of viruses or
            other harmful components. Any content downloaded or otherwise
            obtained through the use of the Service is downloaded at your own
            risk and you will be solely responsible for any damage to your
            computer system or mobile device or loss of data that results from
            such download or your use of the Service.
          </p>
        </div>
        <div className="ic_text_content">
          <h6>14. Limitation of Liability</h6>
          <p>
            To the maximum extent permitted by applicable law, in no event shall
            Nexa, its affiliates, agents, directors, employees, suppliers, or
            licensors be liable for any indirect, punitive, incidental, special,
            consequential, or exemplary damages, including without limitation
            damages for loss of profits, goodwill, use, data, or other
            intangible losses, arising out of or relating to the use of, or
            inability to use, this Service. Under no circumstances will Nexa be
            responsible for any damage, loss, or injury resulting from hacking,
            tampering, or other unauthorized access or use of the Service or
            your account or the information contained therein.
          </p>
        </div>
        <div className="ic_text_content">
          <h6>15. Governing Law</h6>
          <p>
            These Terms shall be governed and construed in accordance with the
            laws of the State of California, United States, without regard to
            its conflict of law provisions. Our failure to enforce any right or
            provision of these Terms will not be considered a waiver of those
            rights. If any provision of these Terms is held to be invalid or
            unenforceable by a court, the remaining provisions of these Terms
            will remain in effect. These Terms constitute the entire agreement
            between us regarding our Service and supersede and replace any prior
            agreements we might have had between us regarding the Service.
          </p>
        </div>
        <div className="ic_text_content">
          <h6>16. Contact Information</h6>
          <p>
            If you have any questions about these Terms, please contact us at
            [support email].
          </p>
        </div>
      </div>
    </PrivacyPolicyLayout>
  );
};

export default TermsConditions;
