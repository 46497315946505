import React from "react";
const TopBar = () => {
  return (
    <div class="mb-4 flex w-full flex-col px-[20px] sm:px-0">
      <div class="flex flex-row items-center text-base font-semibold text-[#4d4d4d]">
        <div class="mb-3 flex w-full flex-col-reverse items-center justify-between sm:flex-row">
          <div class="flex relative w-full flex-row items-center text-sm sm:text-base">
            <div class="absolute w-full border-b border-b-[#D9D9D9] -bottom-[2px] sm:-bottom-[12px]"></div>
            <div class="w-full flex items-center justify-center relative">
              <div class="relative mr-2 whitespace-nowrap mb-[10px] cursor-pointer pr-[24px] sm:mb-auto sm:px-5 sm:pr-5 text-black">
                Job Matches
                <div class="absolute translate-x-[100%] -top-[12px] sm:-top-3 right-[28px] sm:right-[28px] flex items-center justify-center rounded-full bg-[#DCFF64] px-[4px] py-0 sm:py-[2px] text-[9px] text-[#4d4d4d] sm:text-xs">
                  578
                </div>
              </div>
              <div class="absolute -bottom-[2px] sm:-bottom-[12px] left-0 w-full border-b border-b-black "></div>
            </div>
            <svg
              width="16"
              height="16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="mb-[10px] shrink-0 fill-current text-[#8c8c8c] sm:mb-auto shrink-0"
            >
              <path
                d="M5.155 1.995a.5.5 0 0 0-.336.864l5.297 5.14-5.297 5.142a.5.5 0 1 0 .697.718l5.666-5.5a.5.5 0 0 0 0-.718l-5.666-5.5a.5.5 0 0 0-.361-.146Z"
                fill="#8C8C8C"
              ></path>
            </svg>
            <div class="w-full flex items-center justify-center relative">
              <div class="relative mb-[10px] whitespace-nowrap pl-[8px] pr-[18px] sm:mr-2 sm:mb-auto sm:px-5 sm:pr-5 cursor-default text-[#8c8c8c]">
                Applying
              </div>
            </div>
            <svg
              width="16"
              height="16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="mb-[10px] fill-current text-[#8c8c8c] sm:mb-auto shrink-0"
            >
              <path
                d="M5.155 1.995a.5.5 0 0 0-.336.864l5.297 5.14-5.297 5.142a.5.5 0 1 0 .697.718l5.666-5.5a.5.5 0 0 0 0-.718l-5.666-5.5a.5.5 0 0 0-.361-.146Z"
                fill="#8C8C8C"
              ></path>
            </svg>
            <div class="w-full flex items-center justify-center relative">
              <div class="relative mr-2 mb-[10px] pl-[8px] sm:mb-auto sm:px-5 sm:pr-5 cursor-default text-[#8c8c8c]">
                Applied
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
