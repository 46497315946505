import React, { useRef, useState, useEffect } from "react";
import Header from "../../Components/header";

const ComingSoon = () => {
  const [timer, setTimer] = useState({
    days: '20',
    hours: '22',
    minutes: '32',
    seconds: '45'
  });
  const Ref = useRef(null);

  useEffect(() => {
    clearTimer(getDeadTime());
    return (() => {
      if (Ref.current) clearInterval(Ref.current);
    })
  }, []);

  const startTimer = (e) => {
    const t = Date.parse(e) - Date.parse(new Date());

    var dd = Math.floor(t / (1000 * 60 * 60 * 24));
    var hh = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var mm = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
    var ss = Math.floor((t % (1000 * 60)) / 1000);

    var days = dd < 10 ? "0" + dd : dd;
    var hours = hh < 10 ? "0" + hh : hh;
    var minutes = mm < 10 ? "0" + mm : mm;
    var seconds = ss < 10 ? "0" + ss : ss;

    setTimer({ days, hours, minutes, seconds });
  }

  const clearTimer = (e) => {
    setTimer({
      days: '20',
      hours: '22',
      minutes: '32',
      seconds: '45'
    });

    if (Ref.current) clearInterval(Ref.current);
    Ref.current = setInterval(() => {
      startTimer(e);
    }, 1000);
  };

  const getDeadTime = () => {
    let deadline = new Date("Apr 26, 2024 00:00:00");

    deadline.setSeconds(deadline.getSeconds() + 60);
    return deadline;
  };

  return (
    <div>
      <Header />

      <div className="fxt-template" style={{ backgroundImage: `url(${'/images/ic-banner.jpg'})` }}>
        <div className="fxt-main-content">

          <div className="fxt-sub-title">Hey Guys !</div>
          <h1 className="fxt-main-title">We're coming soon</h1>
          <p className="fxt-paragraph">Perfect and awesome template to present your future product  service. Hooking audience attention in the opener.</p>
          <div className="fxt-countdown">
            <div className="countdown-section">
              <div>
                <div className="countdown-number">
                  {timer.days}
                </div>
                <div className="countdown-unit">
                  Days
                </div>
              </div>
            </div>
            <div className="countdown-section">
              <div>
                <div className="countdown-number">
                  {timer.hours}
                </div>
                <div className="countdown-unit">
                  Hours
                </div>
              </div>
            </div>
            <div className="countdown-section">
              <div>
                <div className="countdown-number">
                  {timer.minutes}
                </div>
                <div className="countdown-unit">
                  Minutes
                </div>
              </div>
            </div>
            <div className="countdown-section">
              <div>
                <div className="countdown-number">
                  {timer.seconds}
                </div>
                <div className="countdown-unit">
                  Seconds
                </div>
              </div>
            </div>
          </div>

          <div className="fxt-socials">
            <ul>
              <li className="fxt-facebook">
                <a href="https://www.tiktok.com/@learnnexa" target="_blank" title="Tiktok"><i className="ri-tiktok-fill"></i></a>
              </li>
              <li className="fxt-instagram">
                <a href="https://www.instagram.com/learnnexa" target="_blank" title="instagram"><i className="ri-instagram-line"></i></a>
              </li>
              <li className="fxt-linkedin">
                <a href="https://www.linkedin.com/company/learnnexa" target="_blank" title="linkedin"><i className="ri-linkedin-fill"></i></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ComingSoon;
