import React from "react";

import Footer from "../footer";
import "../../Css/dashboard.css";
import "./layout.css";
import SidebarProfile from "../dashboardComponents/layout/userSidebar";
import { SidebarProvider } from "../../context/sidebarContext";
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const UserLayout = ({ children }) => {
  const { user, auth } = useSelector((state) => state.authenticate);
  console.log("useruser", user, auth);
  const navigate = useNavigate();

  if (!user) {
    console.log("==!!!!!=going");
    navigate("/");
  }

  return (
    <>
      <SidebarProvider>
        <div className="app bg-white">
          <SidebarProfile />
          <div className="main-content p-0">{children}</div>
        </div>
      </SidebarProvider>
      <Footer />
    </>
  );
};

export default UserLayout;
