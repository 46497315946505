import React from "react";
import SkillsButton from "./SkillsButton";

const Category = ({ name, onDelete, onEdit, onAddSkill }) => {
  return (
    <div className="category">
      <p className="category-name ">{name}</p>
      {name == "Top Skills" ? null : (
        <>
          <SkillsButton onClick={onAddSkill}>
            <i className="ri-add-circle-line" style={{ fontSize: "14px" }}></i>
            <p>Add Skills</p>
          </SkillsButton>
          <SkillsButton onClick={onDelete}>
            <i
              className="ri-close-circle-line"
              style={{ fontSize: "14px" }}
            ></i>
            <p>Delete Category</p>
          </SkillsButton>
          <SkillsButton onClick={onEdit}>
            <i className="ri-edit-line" style={{ fontSize: "14px" }}></i>
            <p>Edit Category</p>
          </SkillsButton>
        </>
      )}
    </div>
  );
};

export default Category;
