import axios from "axios";
import { toast } from "react-toastify";
const baseDomain = process.env.REACT_APP_API_URL;
const baseURL = `${baseDomain}`;
let axiosObj;
axiosObj = axios.create({
  baseURL,
  headers: {
    "Cache-Control": "no-cache",
  },
});

axiosObj.interceptors.request.use((config) => {
  const serializedUid = localStorage.getItem("auth");
  config.headers.Authorization = `Bearer ${serializedUid}`;
  return config;
});

axiosObj.interceptors.response.use(
  (response) => response,
  async (err) => {
    const originalRequest = err.config;

    if (err.response) {
      if (err.response.status === 401 || err.response.status === 403) {
        try {
          const serializedRefreshToken = localStorage.getItem("refreshAuth");
          if (serializedRefreshToken !== null) {
            let refreshAxiosObj = axios.create({
              baseURL,
              headers: {
                "Cache-Control": "no-cache",
                Authorization: `Bearer ${serializedRefreshToken}`,
              },
            });
            const { data } = await refreshAxiosObj.post("/es/refresh");
            localStorage.setItem("auth", data.accessToken);
            localStorage.setItem("refreshAuth", data.refreshToken);

            originalRequest.headers.Authorization = `Bearer ${data.accessToken}`;

            return axiosObj(originalRequest); // Retry the original request
          } else {
            toast.error("Your session expired. Please log in again.");
            setTimeout(() => {
              localStorage.clear();
              window.location.href = "/login";
            }, 1500);
          }
        } catch (refreshError) {
          toast.error("Your session expired. Please log in again.");
          setTimeout(() => {
            localStorage.clear();
            window.location.href = "/login";
          }, 1500);
        }
      }
    } else {
      console.error("An unexpected error occurred:", err);
      toast.error("An unexpected error occurred. Please try again.");
      return Promise.reject(err);
    }
  }
);

export default axiosObj;
