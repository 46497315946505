import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import OpenAI from "openai";

const MILLISECONDS_PER_SECOND = 1000;

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true,
});

// indexedDB.js
export const openDB = (dbName, version, upgradeCallback) => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName, version);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      upgradeCallback(db);
    };

    request.onsuccess = (event) => {
      resolve(event.target.result);
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
};

export const addData = (db, storeName, data) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(storeName, "readwrite");
    const store = transaction.objectStore(storeName);
    const request = store.put(data);

    request.onsuccess = () => {
      resolve(request.result);
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
};

export const getData = (db, storeName, id) => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(storeName, "readonly");
    const store = transaction.objectStore(storeName);
    const request = store.get(id);

    request.onsuccess = (event) => {
      resolve(event.target.result);
    };

    request.onerror = (event) => {
      reject(event.target.error);
    };
  });
};

// indexedDB.js
export const appendMessageToDB = async (dbName, storeName, newMessage) => {
  const db = await openDB(dbName, 1, (db) => {
    if (!db.objectStoreNames.contains(storeName)) {
      db.createObjectStore(storeName, { keyPath: "id", autoIncrement: true });
    }
  });

  const existingMessages = await getData(db, storeName, 1);
  const updatedMessages = existingMessages
    ? [...existingMessages.messages, newMessage]
    : [newMessage];

  await addData(db, storeName, { id: 1, messages: updatedMessages });
};

const chatResponseDataStreamHandler = async (
  opts,
  onIncomingChunk,
  onCloseStream
) => {
  try {
    console.log(opts);
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}${opts.url}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
        body: JSON.stringify({ prompt: opts?.prompt }),
        signal: opts?.signal,
      }
    );

    // If the response isn't OK (non-2XX HTTP code) report the HTTP status and description.
    if (!response.ok) {
      throw new Error(
        `Network response was not ok: ${response.status} - ${response.statusText}`
      );
    }

    // A response body should always exist, if there isn't one something has gone wrong.
    if (!response.body) {
      throw new Error("No body included in POST response object");
    }

    let content = "";
    let role = "";

    if (response.body) {
      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          break;
        }
        const str = decoder.decode(value);
        try {
          // Adjusting for SSE format by stripping 'data: ' prefix and trimming any remaining whitespace
          const newChunk = JSON.parse(str);
          // setMessages((prevMessages) => [...prevMessages, newMessage.message]);
          content = `${content}${newChunk.message}`;
          onIncomingChunk(newChunk.message);
        } catch (error) {
          try {
            console.log(str);
            content = str
              .split("}{")
              .map((s, index) => {
                console.log(s, s[0] === "{", s.at(-1) === "}");
                let msg = "";
                if (s === "") return "";
                else if (s[0] === "{") {
                  console.log(s, msg);
                  msg = s + "}";
                } else if (s.at(-1) === "}") msg = "{" + s;
                else msg = "{" + s + "}";
                console.log(msg);
                return JSON.parse(msg)?.message;
              })
              .join("");
            console.log(content);
            onIncomingChunk(content);
          } catch (err) {
            console.error("Error parsing message:", err);
          }
        }
      }
      const storeChats =
        opts?.storeChats === undefined ? true : opts.storeChats;
      if (content && storeChats) {
        await appendMessageToDB(opts?.chatDB || "chatDB", "messages", {
          role: "user",
          content: opts.prompt,
        });
        await appendMessageToDB(opts?.chatDB || "chatDB", "messages", {
          role: "bot",
          content: content,
        });
      }
      onCloseStream(0);

      return { content };
    }
  } catch (error) {
    if (error.name === "AbortError") {
      // Fetch was aborted
      console.log("Fetch aborted");
    } else {
      console.error("Fetch error:", error);
    }
  }
};

// Utility method for updating the last item in a list.
const updateLastItem = (msgFn) => (currentMessages) =>
  currentMessages.map((msg, i) => {
    if (currentMessages.length - 1 === i) {
      return msgFn(msg);
    }
    return msg;
  });
// Utility method for transforming a chat message that may or may not be decorated with metadata
// to a fully-fledged chat message with metadata.
const createChatMessage = ({ content, role, ...restOfParams }) => ({
  content,
  role,
  timestamp: restOfParams.timestamp ?? Date.now(),
  meta: {
    loading: false,
    responseTime: "",
    chunks: [],
    ...restOfParams.meta,
  },
});

export const useChatLogs = ({
  storeChats = true,
  chatDB = "chatDB",
  url = "/nexavoyce/stream-response",
}) => {
  const [messages, _setMessages] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [controller, setController] = React.useState(null);

  // Abort an in-progress streaming response
  const abortResponse = () => {
    if (controller) {
      controller.abort();
      setController(null);
    }
  };
  // forcefully set messages even if a request is ongoing
  const forceSetMessages = (messages) => {
    abortResponse();
    _setMessages(messages);
  };
  // Reset the messages list as long as a response isn't being loaded.
  const resetMessages = () => {
    if (!loading) {
      _setMessages([]);
    }
  };

  // Overwrites all existing messages with the list of messages passed to it.
  const setMessages = (newMessages) => {
    if (!loading) {
      _setMessages(newMessages.map(createChatMessage));
    }
  };

  const uploadFile = async (file, query) => {
    // const formData = new FormData();
    // formData.append("file", file);

    // Assuming you have an endpoint to process the file and query

    setLoading(true);

    let newMessages = [];

    newMessages = newMessages.concat({
      role: "user",
      content: `${file?.name}, ${query}`,
    });

    const updatedMessages = [
      ...messages,
      ...newMessages.map(createChatMessage),
      createChatMessage({
        content: "",
        role: "",
        timestamp: 0,
        meta: { loading: true },
      }),
    ];

    // Set the updated message list.
    _setMessages(updatedMessages);

    try {
      const assistant = await openai.beta.assistants.create({
        name: "Financial Analyst Assistant",
        instructions:
          "You are an expert analyst. Use you knowledge base to answer questions about audited statements.",
        model: "gpt-4o",
        tools: [{ type: "file_search" }],
      });

      const aapl10k = await openai.files.create({
        file: file,
        purpose: "assistants",
      });

      const thread = await openai.beta.threads.create({
        messages: [
          {
            role: "user",
            content: query,
            // Attach the new file to the message.
            attachments: [
              { file_id: aapl10k.id, tools: [{ type: "file_search" }] },
            ],
          },
        ],
      });

      console.log(thread.tool_resources?.file_search);

      const run = await openai.beta.threads.runs.createAndPoll(thread.id, {
        assistant_id: assistant.id,
      });

      const messages = await openai.beta.threads.messages.list(thread.id, {
        run_id: run.id,
      });

      const message = messages.data.pop();
      if (message.content[0].type === "text") {
        const { text } = message.content[0];
        const { annotations } = text;
        const citations = [];

        let index = 0;
        for (let annotation of annotations) {
          text.value = text.value.replace(annotation.text, "[" + index + "]");
          const { file_citation } = annotation;
          if (file_citation) {
            const citedFile = await openai.files.retrieve(
              file_citation.file_id
            );
            citations.push("[" + index + "]" + citedFile.filename);
          }
          index++;
        }

        console.log(text.value);

        handleNewData(text.value);

        // if (content && storeChats) {
        await appendMessageToDB(chatDB || "chatDB", "messages", {
          role: "user",
          content: `${file?.name}, ${query}`,
        });
        await appendMessageToDB(chatDB || "chatDB", "messages", {
          role: "bot",
          content: text.value,
        });
        // }

        console.log(citations.join("\n"));
        closeStream(0);
        setLoading(false);
      }

      // const fileContent = await file.text();
      // const prompt = `Query: ${query}\nFile Content:\n${fileContent}`;

      // const response = await axios.post(
      //   "https://api.openai.com/v1/chat/completions",
      //   {
      //     model: "gpt-4-turbo", // or use 'gpt-3.5-turbo'
      //     messages: [
      //       { role: "system", content: "You are a helpful assistant." },
      //       { role: "user", content: prompt },
      //     ],
      //     // max_tokens: 150,
      //   },
      //   {
      //     headers: {
      //       Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
      //       "Content-Type": "application/json",
      //     },
      //   }
      // );

      // console.log("response>>>>", response);

      // setResponse(response.data.choices[0].text);
    } catch (error) {
      console.error("Error querying OpenAI:", error);
      setLoading(false);
    }
  };

  // When new data comes in, add the incremental chunk of data to the last message.
  const handleNewData = (chunkContent, chunkRole) => {
    _setMessages(
      updateLastItem((msg) => ({
        content: `${msg.content}${chunkContent}`,
        // role: `${msg.role}${chunkRole}`,
        timestamp: 0,
        meta: {
          ...msg.meta,
          chunks: [
            ...msg.meta.chunks,
            {
              content: chunkContent,
              // role: chunkRole,
              timestamp: Date.now(),
            },
          ],
        },
      }))
    );
  };

  // Handles what happens when the stream of a given completion is finished.
  const closeStream = (beforeTimestamp) => {
    // Determine the final timestamp, and calculate the number of seconds the full request took.
    const afterTimestamp = Date.now();
    const diffInSeconds =
      (afterTimestamp - beforeTimestamp) / MILLISECONDS_PER_SECOND;
    const formattedDiff = diffInSeconds.toFixed(2) + " sec.";

    // Update the messages list, specifically update the last message entry with the final
    // details of the full request/response.
    _setMessages(
      updateLastItem((msg) => ({
        ...msg,
        timestamp: afterTimestamp,
        meta: {
          ...msg.meta,
          loading: false,
          responseTime: formattedDiff,
        },
      }))
    );
  };

  const submitPrompt = React.useCallback(
    async (prompt) => {
      console.log("DEBOUNCE");

      let newMessages = [];
      // if (system_prompt)
      //   newMessages = newMessages.concat({
      //     role: "system",
      //     content: system_prompt,
      //   });
      newMessages = newMessages.concat({ role: "user", content: prompt });
      // Don't let two streaming calls occur at the same time. If the last message in the list has
      // a `loading` state set to true, we know there is a request in progress.
      if (messages[messages.length - 1]?.meta?.loading) {
        console.log("DEBOUNCE");
        return;
      }

      // If the array is empty or there are no new messages submited, do not make a request.
      if (!newMessages || newMessages.length < 1) {
        return;
      }
      console.log("DEBOUNCE");

      setLoading(true);

      // Update the messages list with the new message as well as a placeholder for the next message
      // that will be returned from the API.
      const updatedMessages = [
        ...messages,
        ...newMessages.map(createChatMessage),
        createChatMessage({
          content: "",
          role: "",
          timestamp: 0,
          meta: { loading: true },
        }),
      ];

      // Set the updated message list.
      _setMessages(updatedMessages);

      // Create a controller that can abort the entire request.
      const newController = new AbortController();
      const signal = newController.signal;
      setController(newController);

      try {
        // Wait for all the results to be streamed back to the client before proceeding.
        await chatResponseDataStreamHandler(
          {
            signal,
            prompt,
            chatDB: chatDB || "chatDB",
            storeChats: storeChats || true,
            url,
          },
          // The handleNewData function will be called as new data is received.
          handleNewData,
          // The closeStream function be called when the message stream has been completed.
          closeStream
        );
      } catch (err) {
        if (signal.aborted) {
          console.error(`Request aborted`, err);
        } else {
          console.error(`Error during chat response streaming`, err);
        }
      } finally {
        // Remove the AbortController now the response has completed.
        setController(null);
        // Set the loading state to false
        setLoading(false);
      }
    },
    [messages]
  );

  // useEffect(() => {
  //   const storeMessagesInDB = async () => {
  //     const db = await openDB("chatDB", 1, (db) => {
  //       if (!db.objectStoreNames.contains("messages")) {
  //         db.createObjectStore("messages", {
  //           keyPath: "id",
  //           autoIncrement: true,
  //         });
  //       }
  //     });
  //     await addData(db, "messages", { id: 1, messages });
  //   };

  //   storeMessagesInDB();
  // }, [messages]);

  const getFeedback = async (content) => {
    const params = {
      messages: [{ role: "user", content }],
      model: "gpt-4o",
      response_format: { type: "json_object" },
    };
    const chatCompletion = await openai.chat.completions.create(params);
    return chatCompletion?.choices[0]?.message?.content;
  };

  return {
    messages,
    loading,
    submitPrompt,
    abortResponse,
    resetMessages,
    setMessages,
    forceSetMessages,
    uploadFile,
    getFeedback,
  };
};

export default useChatLogs;
