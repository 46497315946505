// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-sidebar {
  position: relative;
  z-index: 10;
}
.back_button {
  width: 40px;
  cursor: pointer;
  position: absolute;
  z-index: 10;
  right: -31%;
}
.app .header {
  width: calc(100% - 260px);
  right: 0;
  left: unset;
}

@media (max-width: 1200px) {
  .app .header {
    width: calc(100% - 72px);
  }
}

.main-content {
  background-color: var(--clr-secondary);
  flex: 1 1;
  padding: 32px;
}

@media screen and (max-width: 1400px) {
  .main-content {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 1200px) {
  .main-content {
    padding-right: 16px;
    padding-left: 16px;
  }
  .back_button {
    right: -138%;
  }
  .app .header {
    width: 100%;
  }
  .back_button {
    right: -80%;
    margin-top: 10px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/LayoutNoFooter/layout.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;AACb;AACA;EACE,WAAW;EACX,eAAe;EACf,kBAAkB;EAClB,WAAW;EACX,WAAW;AACb;AACA;EACE,yBAAyB;EACzB,QAAQ;EACR,WAAW;AACb;;AAEA;EACE;IACE,wBAAwB;EAC1B;AACF;;AAEA;EACE,sCAAsC;EACtC,SAAO;EACP,aAAa;AACf;;AAEA;EACE;IACE,kBAAkB;IAClB,mBAAmB;EACrB;AACF;;AAEA;EACE;IACE,mBAAmB;IACnB,kBAAkB;EACpB;EACA;IACE,YAAY;EACd;EAIA;IACE,WAAW;EACb;EACA;IACE,WAAW;IACX,gBAAgB;EAClB;AATF","sourcesContent":[".dashboard-sidebar {\n  position: relative;\n  z-index: 10;\n}\n.back_button {\n  width: 40px;\n  cursor: pointer;\n  position: absolute;\n  z-index: 10;\n  right: -31%;\n}\n.app .header {\n  width: calc(100% - 260px);\n  right: 0;\n  left: unset;\n}\n\n@media (max-width: 1200px) {\n  .app .header {\n    width: calc(100% - 72px);\n  }\n}\n\n.main-content {\n  background-color: var(--clr-secondary);\n  flex: 1;\n  padding: 32px;\n}\n\n@media screen and (max-width: 1400px) {\n  .main-content {\n    padding-left: 20px;\n    padding-right: 20px;\n  }\n}\n\n@media screen and (max-width: 1200px) {\n  .main-content {\n    padding-right: 16px;\n    padding-left: 16px;\n  }\n  .back_button {\n    right: -138%;\n  }\n}\n\n@media screen and (max-width: 1200px) {\n  .app .header {\n    width: 100%;\n  }\n  .back_button {\n    right: -80%;\n    margin-top: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
