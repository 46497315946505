import React from "react";
import { Link } from "react-router-dom";
import Header from "../../Components/header";
import Footer from "../../Components/footer";

const HowItWork = () => {
  return (
    <div>
      <Header />

      <div className="ic-work" id="work" style={{ paddingTop: "150px" }}>
        <div className="container">
          <div className="ic-heading text-center">
            <h2 className="">Here's How Interview Training Center Works</h2>
          </div>
          <div className="ic-work-process">
            <h3>Test your responses to interview questions.</h3>
            <div className="ic-work-main-process">
              <div className="ic-work-process-left">
                <p className="support-text text-color">
                  Get comfortable answering questions from industry experts.
                  Just talk and your answers are transcribed in real time.
                </p>
                <img
                  src="/images/work-process-1.png"
                  className="img-fluid"
                  alt="work process 1"
                />
              </div>
              <div className="ic-work-process-right">
                <img
                  src="/images/question-img.png"
                  className="img-fluid"
                  alt="how work"
                />
              </div>
            </div>
          </div>
          <div className="ic-insights-about">
            <div className="ic-insights-about-left">
              <h3 className="primary">Get insights about your answers.</h3>
              <p className="support-text text-color">
                Get comfortable answering questions from industry experts. Just
                talk and your answers are transcribed in real time.
              </p>
              <img
                src="/images/insights-img.png"
                className="img-fluid"
                alt="insights images"
              />
            </div>
            <div className="ic-insights-about-right">
              <img
                src="/images/insights-workflow.png"
                className="img-fluid"
                alt="workflow"
              />
            </div>
          </div>
          <div className="ic-keep-getting-main">
            <div className="ic-keep-getting-left">
              <img
                src="/images/keep-getting.png"
                className="img-fluid"
                alt="keep getting"
              />
            </div>
            <div className="ic-keep-getting-right">
              <h3 className="primary">Keep getting better.</h3>
              <p className="support-text text-color">
                Apply the knowledge you've gained and dive right back into
                another round. Continue gaining confidence in an area devoid of
                criticism.
              </p>
              <img
                src="/images/keep-going.png"
                className="img-fluid"
                alt="keep going"
              />
            </div>
          </div>
          <div className="ic-heading text-center">
            <h2 className="">Here's How Interview Simulator Works</h2>
          </div>
          <div className="ic-work-process ic-interview-anywhere">
            <div className="ic-work-main-process">
              <div className="ic-work-process-left">
                <h3>Simulate Realistic Interview Anywhere.</h3>
                <p className="support-text text-color">
                  Take unlimited mock interviews whenever you want, wherever you
                  want simple press a button and start interviewing to success
                </p>
                <ul className="simulators-it">
                  <li>
                    <div>
                      <i className="ri-graduation-cap-fill"></i>
                    </div>
                    <p>
                      Practice answering realistic interview questions anytime,
                      anywhere
                    </p>
                  </li>
                  <li>
                    <div>
                      <i className="ri-briefcase-3-fill"></i>
                    </div>
                    <p>
                      Get instant feedback on your skill level, grammar, and
                      communication strengths and weaknesses
                    </p>
                  </li>
                  <li>
                    <div>
                      <i className="ri-shield-star-fill"></i>
                    </div>
                    <p>
                      Real world questions with practical material from
                      technical to human behaviour
                    </p>
                  </li>
                </ul>
              </div>
              <div className="ic-work-process-right">
                <img
                  src="/images/mock-interviw-card.png"
                  className="img-fluid mt-0"
                  alt="how work"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default HowItWork;
