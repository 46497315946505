import React from "react";
import getInTouchLeft from "../../assets/media/aboutUs/about-us-get-left.png";
import getInTouchRight from "../../assets/media/aboutUs/about-us-get-right.png";

const GetInTouchSection = () => {
  return (
    <div className="aboutUs__touch-container">
      <div className="container">
        <div className="aboutUs__touch-rectangle">
          <div className="abouUs__touch-content">
            <img
              src={getInTouchLeft}
              className="abountUs__touch-left-vector"
              alt=""
            />

            <h4 className="abouUs__touch-content-heading">Get in Touch</h4>
            <p className="abouUs__touch-content-description">
              Join leading institutions that trust Nexa to enhance their
              curriculum and candidate job outcomes.
            </p>
            <img
              src={getInTouchRight}
              className="abountUs__touch-right-vector"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetInTouchSection;
