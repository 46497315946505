import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentUser,
  getUserSubscription,
} from "../../Redux/authenticate/authenticate.action";
import landingVideo from "../../assets/media/reallanding.mp4";
import Header from "../../Components/header";
import "./DashBoard.css";

const DashboardHome = () => {
  const { user } = useSelector((state) => state.authenticate);
  const dispatch = useDispatch();
  useEffect(() => {
    if (user) {
      dispatch(getUserSubscription());
      dispatch(getCurrentUser());
    }
  }, []);
  console.log(user);
  return (
    <div
      style={{
        backgroundImage: `url(${"/images/interview-banner.png"})`,
        backgroundSize: "cover",
        height: "100vh",
        position: "relative",
      }}
    >
      <Header />
      <div className="ic-interviews overflow-y-scroll h-screen">
        <div className="container ">
          <div className="ic-interview-container">
            <div className="dashboard-home-text text-2xl">
              <h1 className="text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl font-bold">
                <strong>Land your Dream Role</strong>
              </h1>
            </div>
            <div className="w-full flex justify-center ">
              <video
                src={landingVideo}
                controls
                className="w-full sm:w-3/4 md:w-2/3 lg:w-3/4 xl:w-2/3 rounded-lg mt-20 xl:mt-12"
              />
            </div>
          </div>
        </div>
        {/*} <TaskList /> */}
      </div>
    </div>
  );
};

export default DashboardHome;
