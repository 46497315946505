
const AnalyticsComponent = () => {

    return (
        <>
         <h6>Analytics</h6>
        </>
    )
};

export default AnalyticsComponent;
