import { Link } from "react-router-dom";

const Navbar = () => {

    return (
        <>
            <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row" style={{background: '#f0f1f6'}}>
                <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
                    <Link className="navbar-brand brand-logo" to="/dashboard/home"><img src="/images/logo.png" alt="logo" /></Link>
                    <Link className="navbar-brand brand-logo-mini" to="/dashboard/home"><img src="/images/logo.png" alt="logo" /></Link>
                </div>
                
            </nav>
        </>
    )
};

export default Navbar;
