import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Header from "../../Components/header/index";
import CircularLoader from "../../Components/loader";
import octoStack from "../../DummyData/octoStack.json";
import useChatLogs from "../../hook/chatStream";
import { setinterviewMode } from "../../Redux/GlobalStates";
import Interview from "./octaInterview";

function CustomInput() {
  const dispatch = useDispatch();
  const [grade, setGrade] = useState("");
  const [topic, setTopic] = useState("");
  const [difficulty, setDifficulty] = useState("");
  const [timeRequired, setTimeRequired] = useState("");
  const [standards, setStandards] = useState("");
  const [progress, setProgress] = useState(0);
  const [animateCard, setAnimateCard] = useState(false);
  const [question, setQuestion] = useState(null);
  const [loading, setLoading] = useState(false);

  const { getFeedback } = useChatLogs({
    storeChats: false,
    url: "/dashboard/evaluate",
  });

  useEffect(() => {
    let filledFields = 0;
    if (grade) filledFields++;
    if (topic) filledFields++;
    if (difficulty) filledFields++;
    if (timeRequired) filledFields++;
    if (standards) filledFields++;
    setProgress((filledFields / 5) * 100);
  }, [grade, topic, difficulty, timeRequired, standards]);

  useEffect(() => {
    setAnimateCard(true);
  }, []);

  useEffect(() => {
    if (progress === 100) {
      setTimeout(() => {
        setProgress(100);
      }, 300); // Slight delay to simulate smooth loading
    }
  }, [progress]);

  const handleGenerate = async () => {
    try {
      setLoading(true);

      let summary = `Design a coding project for me, where technical focus will be ${grade} & the coding language should be ${topic}. The difficult level of the project should be ${difficulty}. ${
        standards ? `The project should be compatible with ${standards}` : ""
      }  Please follow the below structure\n ${JSON.stringify(
        octoStack.technical_frontend
      )}.Please return result in json format with array named "results"`;

      const resposne = await getFeedback(summary);

      if (resposne) {
        let results = JSON.parse(resposne);

        if (results?.results) {
          setQuestion(results?.results);
        }
        if (results?.summary) {
          setQuestion(results?.summary);
        }
        if (results?.responses) {
          setQuestion(results?.responses);
        }
      }

      dispatch(setinterviewMode("interview_start_developer"));

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Update body styles when the component mounts
    document.body.style.overflow = "hidden"; // Example: Disable scrolling

    // Clean up styles when the component unmounts
    return () => {
      document.body.style.overflow = ""; // Reset scrolling
    };
  }, []);

  if (loading) {
    return (
      <CircularLoader
        title="Nexa is hard at work creating your coding project, give us a minute"
        icon={true}
      />
    );
  }

  return (
    <div
      style={{
        backgroundImage: `url(${"/images/interview-banner.png"})`,
        backgroundSize: "cover",
        position: "relative",
        height: "100vh",
        overflow: "hidden",
      }}
      className={question ? "" : "ic-interviews"}
    >
      <Header />
      {!question && (
        <div style={styles.container}>
          <div style={styles.progressBarContainer}>
            <div
              style={{
                ...styles.progressBar,
                width: `${progress}%`,
              }}
            ></div>
          </div>
          <div
            style={{
              ...styles.card,
              opacity: animateCard ? 1 : 0,
              transform: animateCard ? "translateY(0)" : "translateY(20px)",
              overflowY: "scroll",
            }}
          >
            <h2 style={styles.title}>Design your Coding Project</h2>

            <div style={styles.inputGroup}>
              <label style={styles.label}>
                Technical Focus <span style={styles.important}>*</span>
              </label>
              <select
                value={grade}
                onChange={(e) => setGrade(e.target.value)}
                style={styles.select}
              >
                <option value="">Select...</option>
                <option value="1">Frontend</option>
                <option value="2">Backend</option>
                <option value="3">Fullstack</option>
                <option value="4">Mobile</option>
                {/* Add more options as needed */}
              </select>
            </div>

            <div style={styles.inputGroup}>
              <label style={styles.label}>
                Coding Language Focus <span style={styles.important}>*</span>
              </label>
              <input
                type="text"
                value={topic}
                onChange={(e) => setTopic(e.target.value)}
                placeholder="Enter coding language"
                style={styles.input}
              />
            </div>

            <div style={styles.inputGroup}>
              <label style={styles.label}>
                Difficulty Level <span style={styles.important}>*</span>
              </label>
              <select
                value={difficulty}
                onChange={(e) => setDifficulty(e.target.value)}
                style={styles.select}
              >
                <option value="">Select...</option>
                <option value="1">Easy</option>
                <option value="2">Medium</option>
                <option value="3">Hard</option>
                {/* Add more options as needed */}
              </select>
            </div>

            <div style={styles.inputGroup}>
              <label style={styles.label}>Time Required</label>
              <select
                value={timeRequired}
                onChange={(e) => setTimeRequired(e.target.value)}
                style={styles.select}
              >
                <option value="">Select...</option>
                <option value="1">Under 24 hours</option>
                <option value="2">1-3 Days</option>
                <option value="3">4+ Days</option>
                {/* Add more options as needed */}
              </select>
            </div>

            <div style={styles.inputGroup}>
              <label style={styles.label}>Software Industry Sector</label>
              <input
                type="text"
                value={standards}
                onChange={(e) => setStandards(e.target.value)}
                placeholder="Add a standard"
                style={styles.input}
              />
            </div>

            <button
              disabled={!grade || !topic || !difficulty}
              style={styles.button}
              onClick={handleGenerate}
              className={
                !grade || !topic || !difficulty ? `bg-gray-400` : "bg-primary"
              }
            >
              Generate
            </button>
          </div>
        </div>
      )}

      {question && <Interview techStack={question} />}
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    // justifyContent: "center",
    // alignItems: "center",
    minHeight: "100vh",
    // backgroundColor: "#f0f2f5",
    // paddingTop: "100px",
    boxSizing: "border-box",
  },
  progressBarContainer: {
    width: "100%",
    height: "8px",
    backgroundColor: "#e0e0e0",
    borderRadius: "4px",
    marginBottom: "20px",
    marginTop: "-20px",
    position: "fixed",
    zIndex: 100,
  },
  progressBar: {
    height: "100%",
    backgroundColor: "#0077cc",
    borderRadius: "4px",
    transition: "width 0.3s ease",
  },
  card: {
    opacity: 0,
    transform: "translateY(20px)",
    transition: "opacity 0.5s ease-out, transform 0.5s ease-out",
    padding: "20px",
    maxWidth: "600px",
    width: "90%",
    margin: "20px auto",
    fontFamily: "Arial, sans-serif",
    backgroundColor: "white",
    borderRadius: "15px",
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
    boxSizing: "border-box",
    height: "500px", // Set a fixed height or max-height
    maxHeight: "80vh", // Optional: limits height based on viewport height
    overflowY: "auto", // Change to 'auto' to show scrollbar only when needed
    display: "flex",
    flexDirection: "column",
  },
  title: {
    textAlign: "center",
    color: "#0077cc",
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "20px",
  },
  inputGroup: {
    marginBottom: "15px",
  },
  label: {
    display: "block",
    marginBottom: "5px",
    fontWeight: "bold",
  },
  select: {
    width: "100%",
    padding: "10px",
    borderRadius: "5px",
    border: "1px solid #ccc",
  },
  input: {
    width: "100%",
    padding: "10px",
    borderRadius: "5px",
    border: "1px solid #ccc",
  },
  button: {
    // backgroundColor: "#0077cc",
    color: "white",
    padding: "10px 20px",
    border: "none",
    borderRadius: "5px",
    width: "100%",
    fontSize: "16px",
    fontWeight: "bold",
  },
  important: {
    color: "red",
  },
};

export default CustomInput;
