// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-home-text {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 575.98px) {
  .dashboard-home-text h1 {
    margin-top: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Pages/DashboardHome/DashBoard.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".dashboard-home-text {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n@media only screen and (max-width: 575.98px) {\n  .dashboard-home-text h1 {\n    margin-top: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
