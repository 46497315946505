import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../Components/header";
import octoStack from "../../DummyData/octoStack.json";
import { OctaCatCards, OctagonCards } from "./octagonCards";

const Octagon = () => {
  const navigate = useNavigate();
  const { category, subcategory } = useParams();

  const cards = [
    {
      label: "Coding Dojo",
      delay: 300,
      image: "/images/icon.png",
      link: "/codingdojo",
    },
    {
      label: "Sales Simulations",
      delay: 500,
      image: "/images/icon.png",
      link: "/salesoptions",
    },
    {
      label: "Customer Hospitality",
      delay: 700,
      image: "/images/icon.png",
      link: "/hospitality",
    },
    {
      label: "Salary Negotiation",
      delay: 900,
      image: "/images/icon.png",
      link: "/management-communications",
    },
  ];

  useEffect(() => {
    // Update body styles when the component mounts
    document.body.style.overflow = "hidden"; // Example: Disable scrolling

    // Clean up styles when the component unmounts
    return () => {
      document.body.style.overflow = ""; // Reset scrolling
    };
  }, []);

  return (
    <div className="h-screen flex flex-col pb-20 md:pb-0">
      <Header />
      <div className="flex-1 overflow-y-scroll pb-2">
        <div className="ic-interviews voyce py-0 h-full">
          <div className="container pt-32">
            <h1 className="text-center text-bold text-2xl mb-8">
              Enter Nexa's Octagon & Face Real World Scenarios
            </h1>

            {/* Initial Options */}
            {!category && !subcategory && (
              <div className="ic-interview-card-container">
                {cards.map((card, index) => (
                  <OctagonCards
                    index={index}
                    key={index}
                    card={card}
                    onCardClick={() =>
                      navigate(
                        { pathname: `/octagon${card.link}` },
                        { replace: true }
                      )
                    }
                  />
                ))}
              </div>
            )}

            {/* Category Options */}
            {category && !subcategory && (
              <div className="ic-interview-card-container">
                {octoStack[category].map((card, index) => (
                  <OctaCatCards
                    index={index}
                    key={index}
                    card={card}
                    onCardClick={() =>
                      navigate(
                        { pathname: `/octagon/${category}${card.link}` },
                        { replace: true }
                      )
                    }
                  />
                ))}
              </div>
            )}

            {/* Subcategory  Options */}
            {category && subcategory && (
              <div className="ic-interview-card-container flex flex-wrap justify-center">
                {octoStack["techstacks"].map((card, index) => (
                  <OctagonCards
                    index={index}
                    key={index}
                    card={card}
                    onCardClick={() =>
                      navigate(
                        `/intervieworcustom/${category}/${subcategory}${card.link}`
                      )
                    }
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Octagon;
