import React, { useEffect, useState } from "react";
import JobListing from "../../Components/Autoapply/JobListing";
import { jobListings as allJobListings } from "../AutoApply/JobData";
import "./style.css";
import FilterComponent from "../../Components/Autoapply/FilterComponent";
import Header from "../../Components/header";
import { getResume } from "../../Redux/ResumeBuilderSlice";
import { useDispatch } from "react-redux";

const AutoApply = () => {
  const dispatch = useDispatch();
  const [filteredJobListings, setFilteredJobListings] =
    useState(allJobListings);
  const [activeTab, setActiveTab] = useState("Jobs");
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleApplyFilters = (filters) => {
    const filtered = allJobListings.filter((job) => {
      const match = job.postedDate.match(/Posted (\d+)d ago/);
      let daysAgo = match ? parseInt(match[1], 10) : 40;
      if (filters.includes("any-time")) return true;
      if (filters.includes("past-week") && daysAgo <= 7) return true;
      if (filters.includes("past-month") && daysAgo <= 30) return true;

      return false;
    });

    setFilteredJobListings(filtered);
  };
  useEffect(() => {
    dispatch(getResume({ onSuccess: () => {} }));
  }, []);
  return (
    <div className="bg-[#f5f5f5]">
      <Header />
      <FilterComponent onApplyFilters={handleApplyFilters} />
      <div className="flex justify-content-between items-center bg-[#f5f5f5] nexaIqTab-container border-b">
        <h3 className="text-bold text-capitalize text-xl">Nexa {activeTab}</h3>
        <ul className="list-reset flex border-b">
          <li className="-mb-px mr-1">
            <a
              className={`border-b inline-block py-2 px-4 font-semibold  ${
                activeTab === "Jobs"
                  ? " border-l border-t border-r rounded-t  text-primary"
                  : "text-dark"
              }`}
              href="#"
              onClick={() => handleTabClick("Jobs")}
            >
              Jobs
            </a>
          </li>
          <li className="mr-1">
            <a
              className={`inline-block py-2 px-4 font-semibold ${
                activeTab === "Applied Jobs"
                  ? " border-l border-t border-r rounded-t text-primary"
                  : "text-dark"
              }`}
              href="#"
              onClick={() => handleTabClick("Applied Jobs")}
            >
              Applied Jobs
            </a>
          </li>
        </ul>
      </div>
      <div className="parent-container-wrapper">
        <div className="parent-container" style={{ position: "relative" }}>
          {filteredJobListings.map((job, index) => (
            <JobListing
              key={index}
              jobTitle={job.jobTitle}
              compensation={job.compensation}
              location={job.location}
              tenure={job.tenure}
              descriptionPoints={job.descriptionPoints}
              companyName={job.companyName}
              companyTagline={job.companyTagline}
              funding={job.funding}
              headcount={job.headcount}
              founded={job.founded}
              headquarters={job.headquarters}
              CompanyDesc={job.CompanyDesc}
              logoUrl={job.logoUrl}
              topInvestors={job.topInvestors}
              ventureBacked={job.ventureBacked}
              postedDate={job.postedDate}
              badge={job.badge}
              jobProvider={job.jobProvider}
              activeTab={activeTab}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AutoApply;
