import { createAsyncThunk } from "@reduxjs/toolkit";
import { error, success } from "../../Utilities/toast";
import { RepositoryFactory } from "../../repository/RepositoryFactory";
import { AuthenticateApi } from "./api";
import { addUserSocket } from "../../config/socketActions";
import { toast } from "react-toastify";
const authRepo = RepositoryFactory.get("auth");
console.log("🚀 ~ authRepo:", authRepo);

export const authenticateAction = createAsyncThunk(
  "authenticate",
  async (payload) => {
    console.log(payload);
    const { data } = await AuthenticateApi(payload);
    console.log(data, "google");
    if (data) {
      let ssoPayload = {
        displayName: data?.name,
        email: data.email,
        picture: data.picture,
        uid: payload,
      };
      return ssoPayload;
    }
    // console.log(ssoPayload, "google");
  }
);

export const ssoLoginAction = createAsyncThunk("ssoLogin", async (token) => {
  const { data } = await authRepo.ssoLogin(token);
  console.log(data, "ssoLogin");
  if (data?.status_code === 200) {
    success("Logged in successfully");
    return data;
  }
});
export const ssoInvitationLoginAction = createAsyncThunk(
  "ssoInvitationLogin",
  async (payload) => {
    const { data } = await authRepo.ssoInvitationLogin(payload);
    if (data?.status_code === 200) {
      return data;
    }
  }
);

export const checkSessionAction = createAsyncThunk("checkSession", async () => {
  const payload = localStorage.getItem("auth");
  const { data } = await AuthenticateApi(payload);
  return data;
});

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await authRepo.authenticateUser(payload);
      // addUserSocket(data.accessToken);
      if (data?.status_code === 200) {
        if (!data?.data && data?.detail == "User is revoked by organization") {
          return { revoked: true, ...data };
        } else {
          success("Logged in successfully");
          return data;
        }
      }
      throw new Error("Failed to login");
    } catch (err) {
      error(err.response?.data?.detail || "Invalid Email or Password");
      return rejectWithValue(err.message);
    }
  }
);
export const delegateAccess = createAsyncThunk(
  "auth/delegateAccess",
  async (token, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await authRepo.authenticateWithContext(token);
      // addUserSocket(data.accessToken);
      if (data?.status_code === 200) {
        if (!data?.data && data?.detail == "User is revoked by organization") {
          return { revoked: true, ...data };
        } else {
          success("Logged in successfully");
          return data;
        }
      }
      throw new Error("Failed to login");
    } catch (err) {
      error("Access denied! please try again later");
      return rejectWithValue(err.message);
    }
  }
);

export const createUser = createAsyncThunk(
  "auth/createUser",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await authRepo.createNewUser(payload);
      if (data.status_code === 200) {
        success("User Created successfully");
        return data;
      }
      // throw new Error("Failed to create user");
    } catch (err) {
      const errorMessage =
        err.response?.data?.detail ||
        (err.response?.data?.messages?.email &&
          "Please enter a valid email that matches the pattern example@example.com") ||
        err.response?.data?.key;
      error(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
export const createInvitedUser = createAsyncThunk(
  "auth/invitedUser",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await authRepo.registerInvitedUser(payload);
      if (data.status_code === 200) {
        success("User Created successfully");
        return data;
      }
      throw new Error("Failed to create user");
    } catch (err) {
      console.log({ err });
      error(err.response.data.detail);
      return rejectWithValue(err.message);
    }
  }
);

export const forgetPassword = createAsyncThunk(
  "auth/forgetPassword",
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await authRepo.forgetPass(payload);
      // if (data.details === "Reset email sent successfully") {
      return data;
      // }
      // throw new Error("Failed to reset password");
    } catch (err) {
      error(err.response?.data?.message);
      return rejectWithValue(err.message);
    }
  }
);

export const sendVerificationEmail = createAsyncThunk(
  "auth/sendVerificationEmail",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await authRepo.verfiyEmail(payload);
      if (response?.status === 200) {
        return response.data.details;
      }
      throw new Error("Verification failed");
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);
export const getUserSubscription = createAsyncThunk(
  "auth/userSubscripition",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await authRepo.getUserBundle();
      if (data.status_code === 200) {
        return data;
      }
      throw new Error("Failed to fetch pricing bundles");
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const onBoardingAction = createAsyncThunk(
  "auth/onBoardingAction",
  async (payload, { rejectWithValue, dispatch }) => {
    console.log(payload, "payload");
    try {
      const { data } = await authRepo.onBoarding(payload);
      if (data.status_code === 200) {
        return data;
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const updateOnboarding = createAsyncThunk(
  "auth/updateOnBoard",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await authRepo.udpateOnBoard(payload);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const updateOnboardingUser = createAsyncThunk(
  "auth/updateOnboardingUser",
  async ({ payload, onSuccess }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await authRepo.updateOnboardingUser(payload);
      await dispatch(getCurrentUser());
      onSuccess(data?.detail);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const getOnBoardingAction = createAsyncThunk(
  "auth/getOnBoard",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await authRepo.getOnBoard();
      if (data.entries.length > 0) {
        return data.entries;
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const getCurrentUser = createAsyncThunk(
  "auth/getCurrentUser",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await authRepo.currentUserInfo();
      return data.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const getSkillsAnalysis = createAsyncThunk(
  "auth/skillsAnalysis",
  async ({ onSuccess }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await authRepo.skillsGapAnalysis();
      onSuccess();
      console.log(data, "skills analysis");
      return data.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const generateSkillsGapAnalysis = createAsyncThunk(
  "auth/generateSkillsGapAnalysis",
  async ({ onSuccess }, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await authRepo.generateSkillsAnalysis();
      onSuccess();
      console.log(data, "skills analysis");
      return data.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const refreshAuthToken = createAsyncThunk(
  "auth/refreshAuthToken",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await authRepo.refreshToken();
      if (data.status_code === 200) {
        return data;
      }
      throw new Error("Failed to fetch pricing bundles");
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const logoutUser = createAsyncThunk(
  "auth/logoutUser",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await authRepo.logoutCurrentUser();
      if (data.status_code === 200) {
        dispatch({ type: "RESET" });
        return data;
      }
      throw new Error("Failed to fetch pricing bundles");
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateprofile = createAsyncThunk(
  "auth/updateprofile",
  async ({ payload, onSuccess }, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await authRepo.udpateProfile(payload);
      if (data) {
        onSuccess();
        return data;
      } else {
        throw new Error("Failed to update simulations");
      }
    } catch (err) {
      error(err.response?.data?.detail);
      return rejectWithValue(err.message);
    }
  }
);
export const continueIndividualAction = createAsyncThunk(
  "auth/individualContinue",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await authRepo.individualUser();
      if (data.status_code === 200) {
        success(data?.detail);
        return data;
      }
    } catch (err) {
      error(err.response?.data?.detail);
      return rejectWithValue(err.message);
    }
  }
);
export const cancelUserSubscription = createAsyncThunk(
  "auth/cancelSubscrtiption",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await authRepo.cancelSubscription();
      console.log(data, "susbcription cancel");
      if (data.status_code === 200) {
        success(data?.detail);
        return data;
      }
    } catch (err) {
      error(err.response?.data?.detail);
      return rejectWithValue(err.message);
    }
  }
);
