import React from "react";

const ProgressBar = ({ completed }) => {
  let fillerStyles = {
    height: '100%',
    width: `${completed}%`,
    borderRadius: 'inherit',
    textAlign: 'right',
  }

  return (
    <div className="progressBar progress">
      <div style={fillerStyles} className="progress-bar" />
    </div>
  )
}

export default ProgressBar;
