import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import "./TextEditor.css";
import Header from "../../Components/header";
import MyDetails from "../../features/profile/profileForm";
import Frozendetails from "../../features/profile/profilefrozenform";
import { useDispatch, useSelector } from "react-redux";
import ProfessionalSummary from "../../features/professionalsummary/professionalSummaryForm";
import CVTemplate from "../../Components/Resume/CVTemplate";
import ResumeHeader from "../../Components/Resume/ResumeHeader";
import WorkExperienceForm from "../../features/workexperience/workExperienceForm";
import EducationForm from "../../features/education/EducationForm";
import Skills from "../../features/Skills/Skills";
import CompanyInfoForm from "../../features/companyinformation/CompanyInfoForm";
import {
  addStep,
  selectStep,
  selectStep1,
  setStep1,
  setActivePreview,
} from "../../features/companyinformation/StepsSlice";
import Guidance from "../../Components/Resume/Guidance";
import ProjectForm from "../../features/projects/ProjectForm";
import CertificationForm from "../../features/Certifications/CertificationForm";
import PublicationForm from "../../features/publications/PublicationForm";
import AiGeneration from "../../Components/Resume/AiGeneration";
import { getSingleResume } from "../../Redux/ResumeBuilderSlice";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../Components/main-loader";
import InterestForm from "../../features/Interests/InterestForm";
import LanguagesForm from "../../features/Languages/LanguagesForm";
import SoftSkills from "../../features/companyinformation/SoftSkills/SoftSkills";
import Preview from "../../Components/Resume/Preview";
import CircularLoader from "../../Components/loader";
import Footer from "../../Components/footer";
import chevronDoubleRightIcon from "../../assets/icons/chevronDoubleRight.svg";
import {
  PersonalSummaryIcon,
  ProfessionalSummaryIcon,
  OtherIcon,
  EducationIcon,
  WorkexperienceIcon,
  TopSkillsIcon,
  CertificateIcon,
  PersonalSumIcon,
} from "../../Components/Resume/svgimages";
import ReactModal from "react-modal";
import ResumeInfo from "./information";
const backgroundStyle = {
  backgroundImage: 'url("/images/background.svg")',
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  width: "100vw",
  height: "auto",
  // border: "2px solid red"
};
// const PreviewIcon = () => (
//   <svg
//     width="20"
//     height="14"
//     viewBox="0 0 20 14"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//   >
//     <path
//       d="M10.0019 0C6.04545 0 2.42681 2.32944 0.0751426 6.3904C0.0259316 6.47473 0 6.57061 0 6.66825C0 6.76588 0.0259316 6.86176 0.0751426 6.94609C2.42681 11.0071 6.04545 13.3365 10.0019 13.3365C13.9584 13.3365 17.5771 11.0071 19.9287 6.94609C19.9779 6.86176 20.0039 6.76588 20.0039 6.66825C20.0039 6.57061 19.9779 6.47473 19.9287 6.3904C17.5771 2.32944 13.9584 0 10.0019 0ZM10.0019 12.2251C6.54334 12.2251 3.35147 10.2046 1.20208 6.66825C3.35147 3.13185 6.53667 1.11137 10.0019 1.11137C13.4672 1.11137 16.6524 3.13185 18.8018 6.66825C16.6524 10.2046 13.4605 12.2251 10.0019 12.2251ZM10.0019 2.77844C9.23261 2.77844 8.48055 3.00657 7.84087 3.43399C7.2012 3.86141 6.70263 4.46891 6.40822 5.17968C6.11381 5.89045 6.03678 6.67256 6.18687 7.42711C6.33696 8.18166 6.70743 8.87476 7.25143 9.41876C7.79543 9.96276 8.48852 10.3332 9.24307 10.4833C9.99762 10.6334 10.7797 10.5564 11.4905 10.262C12.2013 9.96755 12.8088 9.46898 13.2362 8.82931C13.6636 8.18963 13.8917 7.43758 13.8917 6.66825C13.8906 5.63697 13.4804 4.64826 12.7511 3.91903C12.0219 3.18981 11.0332 2.77961 10.0019 2.77844ZM10.0019 9.44668C9.45242 9.44668 8.91523 9.28373 8.45832 8.97843C8.00141 8.67313 7.64529 8.2392 7.435 7.73151C7.22471 7.22381 7.16968 6.66516 7.27689 6.1262C7.3841 5.58724 7.64872 5.09217 8.03729 4.70359C8.42586 4.31502 8.92093 4.0504 9.45989 3.9432C9.99886 3.83599 10.5575 3.89101 11.0652 4.10131C11.5729 4.3116 12.0068 4.66772 12.3121 5.12463C12.6174 5.58154 12.7804 6.11872 12.7804 6.66825C12.7792 7.40477 12.4861 8.1108 11.9653 8.6316C11.4445 9.1524 10.7385 9.44551 10.0019 9.44668Z"
//       fill="white"
//     />
//   </svg>
// );
const ResumeBuilder = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const {
    isAILoading,
    isLoading,
    profile,
    professionalSummaries,
    educations,
    categories,
    certificates,
    publications,
    projects,
    workExperience,
    interests,
    softskills,
    languages,
    job,
  } = useSelector((state) => state.ResumeBuilder);
  const step = useSelector(selectStep);
  const activeField = useSelector((state) => state?.educationForm?.activeField);
  const { activePreview } = useSelector((state) => state?.Steps);
  // const profile = useSelector(selectProfile);
  // const professionalSummaries = useSelector(selectSummaries);
  // const workExperience = useSelector(selectExperiences);
  // const educations = useSelector(selectEducations);
  // const categories = useSelector(selectCategories);
  // const projects = useSelector(selectProjects);
  // const publications = useSelector(selectPublications);
  // const certificates = useSelector(selectCertifications);
  // : [],
  // const [contactInfoShow, setContactInfoShow] = useState(false);
  // const [professionalsummaryShow, setProfessionalsummaryShow] = useState(false);
  // const [workexperienceShow, setWorkexperienceShow] = useState(false);
  // const [educationShow, setEducationShow] = useState(false);
  // const [certificationShow, setCertificationShow] = useState(false);
  // const [awardsShow, setAwardsShow] = useState(false);

  // const [projectsShow, setProjectsShow] = useState(false);
  // const [publicationsShow, setPublicationsShow] = useState(false);
  // const [InterestsShow, setInterestsShow] = useState(false);
  // const [languageShow, setLanguageShow] = useState(false);
  // const [skillsShow, setSkillsShow] = useState(false);
  // const [softskillShow, setsoftskillShow] = useState(false);
  // const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // const [showPreview, setShowPreview] = useState(true);
  const [otherCareer, setOtherCareer] = useState(false);
  const [collapse, setCollapse] = useState("");
  const [careerCollapse, setCareerCollapse] = useState("");
  const [tempRef, settempRef] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const toggleCollapse = (value) => {
    if (value !== collapse) {
      setCollapse(value);
      setCareerCollapse("");
      setOtherCareer(false);
    } else {
      setCollapse("");
    }
  };
  const toggleCareerCollapse = (value) => {
    if (value !== careerCollapse) {
      setCareerCollapse(value);
    } else {
      setCareerCollapse("");
    }
  };
  // const handleResumeGenerated = () => {
  //   setShowPreview(false);
  // };

  // const isMobile = windowWidth < 768;
  // const handlePreview = () => {
  //   const payload = {
  //     preview: true,
  //     step: 0,
  //     step1: false,
  //   };
  //   dispatch(setActivePreview(payload));
  // };

  const handlePreview2 = () => {
    const payload = {
      preview: false,
      step: 1,
      step1: true,
    };
    dispatch(setActivePreview(payload));
  };
  // const [showsetp2, setShowStep2] = useState(false);

  // console.log(activePreview, "activePreview");

  // console.log(
  //   "hdshasdha s",
  //   profile,
  //   professionalSummaries,
  //   workExperience,
  //   educations,
  //   categories
  // );

  // function toggleContactInfo() {
  //   setContactInfoShow(!contactInfoShow);
  // }

  // function toggleProfessional() {
  //   setProfessionalsummaryShow(!professionalsummaryShow);
  // }

  // function toggleWorkExperience() {
  //   setWorkexperienceShow(!workexperienceShow);
  // }

  // function toggleEducation() {
  //   setEducationShow(!educationShow);
  // }

  // function toggleCertification() {
  //   setCertificationShow(!certificationShow);
  // }
  function toggleOtherCareers() {
    setOtherCareer(!otherCareer);
    setCollapse("");
  }

  // function toggleAwards() {
  //   setAwardsShow(!awardsShow);
  // }

  // function toggleProjects() {
  //   setProjectsShow(!projectsShow);
  // }

  // function togglePublications() {
  //   setPublicationsShow(!publicationsShow);
  // }

  // function toggleLanguages() {
  //   setLanguageShow(!languageShow);
  // }
  // function toggleInterests() {
  //   setInterestsShow(!InterestsShow);
  // }

  // function toggleSkills() {
  //   setSkillsShow(!skillsShow);
  // }

  // function toggleSoftSkills() {
  //   setsoftskillShow(!softskillShow);
  // }
  const checkDisable = () => {
    if (
      !(
        profile &&
        professionalSummaries?.length > 0 &&
        workExperience?.length > 0 &&
        educations?.length > 0 &&
        projects?.length > 0 &&
        publications?.length > 0 &&
        certificates?.length > 0
      )
    ) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    if (id) {
      const resumeId = id;
      dispatch(getSingleResume({ resumeId, onSuccess: () => {} }));
    }
  }, [id]);

  useEffect(() => {
    toggleCollapse("contactInfoShow");
    // const handleResize = () => setWindowWidth(window.innerWidth);

    // window.addEventListener("resize", handleResize);

    // return () => window.removeEventListener("resize", handleResize);
    // const otherSkillsToggler =
    //   projects?.length > 0 ||
    //   publications?.length > 0 ||
    //   interests?.interests?.length > 0 ||
    //   softskills?.skills?.length > 0 ||
    //   languages?.languages?.length > 0;

    // if (otherSkillsToggler) {
    //   console.log({ otherSkillsToggler });
    //   setOtherCareer(true);
    // }
  }, []);

  const renderResumeInfo = () => {
    return (
      <div
        style={{
          border: collapse === "resumeInfo" ? "1px solid #2A68DC" : "none",
          borderRadius: "8px",
          overflow: "hidden",
          width: "94%",
          margin: "auto",
        }}
      >
        <div
          className="d-flex justify-content-between  py-1 px-2"
          style={{
            backgroundColor: "white",
          }}
        >
          <div
            style={{
              display: "flex",
            }}
          >
            <div style={{ marginTop: "5px", marginRight: "10px" }}>
              <PersonalSumIcon></PersonalSumIcon>
            </div>
            <h3>Resume Information</h3>
          </div>
          <button
            className="toggle_button text-center d-flex align-items-center justify-content-center"
            onClick={() => toggleCollapse("resumeInfo")}
          >
            <i
              className={`ri-arrow-${
                collapse === "resumeInfo" ? "up" : "down"
              }-s-line`}
              style={{
                fontSize: "26px",
                color: collapse === "contactInfoShow" ? "#2A68DC" : "inherit",
              }}
            />
          </button>
        </div>
        {collapse === "resumeInfo" && <ResumeInfo />}
      </div>
    );
  };
  return (
    <div style={backgroundStyle}>
      <Header />
      {isLoading && <Loader />}

      {/* {!isAILoading && (
        <> */}
      <div className="resume-back  ">
        <button
          onClick={() => setModalIsOpen(true)}
          className={` z-50 mt-28 ml-2 bg-white rounded-full size-10 p-1 transition-all duration-500  rotate-180`}
        >
          <img src={chevronDoubleRightIcon} alt="" />
        </button>
      </div>
      <ResumeHeader targetId="pdf-generate" handlePreview2={handlePreview2} />

      <div className="resume">
        {step === 1 && (
          <>
            <div className="resume-work">
              {renderResumeInfo()}
              <div className="details">
                <div
                  className="button-group d-flex justify-content-end py-1 px-2"
                  style={{ marginBottom: "10px" }}
                ></div>
                <div
                  style={{
                    border:
                      collapse === "contactInfoShow"
                        ? "1px solid #2A68DC"
                        : "none",
                    borderRadius: "8px",
                    overflow: "hidden",
                  }}
                >
                  <div
                    className="d-flex justify-content-between  py-1 px-2   "
                    style={{
                      backgroundColor: "white",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <div style={{ marginTop: "5px", marginRight: "10px" }}>
                        <PersonalSumIcon></PersonalSumIcon>
                      </div>
                      <h3> Personal Information</h3>
                      <span className="text-red-600 mt-1">*</span>
                    </div>
                    <button
                      className="toggle_button text-center d-flex align-items-center justify-content-center"
                      onClick={() => toggleCollapse("contactInfoShow")}
                    >
                      <i
                        className={`ri-arrow-${
                          collapse === "contactInfoShow" ? "up" : "down"
                        }-s-line`}
                        style={{
                          fontSize: "26px",
                          color:
                            collapse === "contactInfoShow"
                              ? "#2A68DC"
                              : "inherit",
                        }}
                      />
                    </button>
                  </div>

                  {collapse === "contactInfoShow" && <MyDetails />}
                </div>
              </div>
              <div
                className="professionalsummary"
                style={{
                  border:
                    collapse === "professionalsummary"
                      ? "1px solid #2A68DC"
                      : "none",
                  borderRadius: "8px",
                  overflow: "hidden",
                }}
              >
                <div
                  className="d-flex justify-content-between  py-1 px-2   "
                  style={{ backgroundColor: "white" }}
                >
                  <div className="d-flex">
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <div style={{ marginTop: "5px", marginRight: "10px" }}>
                        <ProfessionalSummaryIcon></ProfessionalSummaryIcon>
                      </div>
                      <h3 className="ml-2">Professional Summary</h3>
                      <span className="text-red-600 mt-1">*</span>
                    </div>
                  </div>
                  <button
                    className="toggle_button text-center d-flex align-items-center justify-content-center"
                    onClick={() => toggleCollapse("professionalsummary")} // Toggle visibility on click
                  >
                    <i
                      className={`ri-arrow-${
                        collapse === "professionalsummary" ? "up" : "down"
                      }-s-line`}
                      style={{ fontSize: "26px" }}
                    />
                  </button>
                </div>
                {collapse === "professionalsummary" && <ProfessionalSummary />}
              </div>
              <div
                className="certification"
                style={{
                  border:
                    collapse === "certification" ? "1px solid #2A68DC" : "none",
                  borderRadius: "8px",
                }}
              >
                <div
                  className="d-flex justify-content-between  py-1 px-2  "
                  style={{ backgroundColor: "white" }}
                >
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <div style={{ marginTop: "5px", marginRight: "10px" }}>
                      <CertificateIcon></CertificateIcon>
                    </div>
                    <h3>Certification</h3>
                    <span className="text-red-600 mt-1">*</span>
                  </div>
                  <button
                    className="toggle_button text-center d-flex align-items-center justify-content-center"
                    onClick={() => toggleCollapse("certification")}
                  >
                    <i
                      className={`ri-arrow-${
                        collapse === "certification" ? "up" : "down"
                      }-s-line`}
                      style={{ fontSize: "26px" }}
                    />
                  </button>
                </div>

                {collapse === "certification" && <CertificationForm />}
              </div>
              <div
                className="skills"
                style={{
                  border: collapse === "skills" ? "1px solid #2A68DC" : "none",
                  borderRadius: "8px",
                  overflow: "hidden",
                }}
              >
                <div
                  className="d-flex justify-content-between  py-1 px-2  "
                  style={{ backgroundColor: "white" }}
                >
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <div style={{ marginTop: "5px", marginRight: "10px" }}>
                      <TopSkillsIcon></TopSkillsIcon>
                    </div>
                    <h3>Top Skills</h3>
                  </div>
                  <button
                    className="toggle_button text-center d-flex align-items-center justify-content-center"
                    onClick={() => toggleCollapse("skills")}
                  >
                    <i
                      className={`ri-arrow-${
                        collapse === "skills" ? "up" : "down"
                      }-s-line`}
                      style={{ fontSize: "26px" }}
                    />
                  </button>
                </div>

                {collapse === "skills" && <Skills />}
              </div>
              <div
                className="workexperience"
                style={{
                  border:
                    collapse === "workexperience"
                      ? "1px solid #2A68DC"
                      : "none",
                  borderRadius: "8px",
                }}
              >
                <div
                  className="d-flex justify-content-between  py-1 px-2  "
                  style={{ backgroundColor: "white" }}
                >
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <div style={{ marginTop: "5px", marginRight: "10px" }}>
                      <WorkexperienceIcon></WorkexperienceIcon>
                    </div>
                    <h3>Work Experience</h3>
                    <span className="text-red-600 mt-1">*</span>
                  </div>
                  <button
                    className="toggle_button text-center d-flex align-items-center justify-content-center"
                    onClick={() => toggleCollapse("workexperience")} // Toggle visibility on click
                  >
                    <i
                      className={`ri-arrow-${
                        collapse === "workexperience" ? "up" : "down"
                      }-s-line`}
                      style={{ fontSize: "26px" }}
                    />
                  </button>
                </div>

                {collapse === "workexperience" && <WorkExperienceForm />}
              </div>
              <div
                className="education"
                style={{
                  border:
                    collapse === "education" ? "1px solid #2A68DC" : "none",
                  borderRadius: "8px",
                  overflow: "hidden",
                }}
              >
                <div
                  className="d-flex justify-content-between  py-1 px-2  "
                  style={{ backgroundColor: "white" }}
                >
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <div style={{ marginTop: "5px", marginRight: "10px" }}>
                      <EducationIcon></EducationIcon>
                    </div>
                    <h3>Education</h3>
                    <span className="text-red-600 mt-1">*</span>
                  </div>
                  <button
                    className="toggle_button text-center d-flex align-items-center justify-content-center"
                    onClick={() => toggleCollapse("education")}
                  >
                    <i
                      className={`ri-arrow-${
                        collapse === "education" ? "up" : "down"
                      }-s-line`}
                      style={{ fontSize: "26px" }}
                    />
                  </button>
                </div>

                {collapse === "education" && <EducationForm />}
              </div>
              <div className="projects">
                <div
                  className="d-flex justify-content-between  py-1 px-2  "
                  style={{ backgroundColor: "white" }}
                >
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <div style={{ marginTop: "5px", marginRight: "10px" }}>
                      <OtherIcon></OtherIcon>
                    </div>
                    <h3>Other Career related Skills and Information</h3>
                  </div>
                  <button
                    className="toggle_button text-center d-flex align-items-center justify-content-center"
                    onClick={toggleOtherCareers}
                  >
                    <i
                      className={`ri-arrow-${
                        otherCareer ? "up" : "down"
                      }-s-line`}
                      style={{ fontSize: "26px" }}
                    />
                  </button>
                </div>
              </div>
              {otherCareer && (
                <>
                  <div
                    className="projects"
                    style={{
                      border:
                        careerCollapse === "projects"
                          ? "1px solid #2A68DC"
                          : "none",
                      borderRadius: "8px",
                      overflow: "hidden",
                    }}
                  >
                    <div
                      className="d-flex justify-content-between  py-1 px-2  "
                      style={{ backgroundColor: "white" }}
                    >
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <h3>Projects</h3>
                        <span className="text-red-600 mt-1">*</span>
                      </div>
                      <button
                        className="toggle_button text-center d-flex align-items-center justify-content-center"
                        onClick={() => toggleCareerCollapse("projects")}
                      >
                        <i
                          className={`ri-arrow-${
                            careerCollapse === "projects" ? "up" : "down"
                          }-s-line`}
                          style={{ fontSize: "26px" }}
                        />
                      </button>
                    </div>

                    {careerCollapse === "projects" && <ProjectForm />}
                  </div>
                  <div
                    className="publications"
                    style={{
                      border:
                        careerCollapse === "publications"
                          ? "1px solid #2A68DC"
                          : "none",
                      borderRadius: "8px",
                    }}
                  >
                    <div
                      className="d-flex justify-content-between  py-1 px-2  "
                      style={{ backgroundColor: "white" }}
                    >
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <h3>Publications</h3>
                        <span className="text-red-600 mt-1">*</span>
                      </div>
                      <button
                        className="toggle_button text-center d-flex align-items-center justify-content-center"
                        onClick={() => toggleCareerCollapse("publications")}
                      >
                        <i
                          className={`ri-arrow-${
                            careerCollapse === "publications" ? "up" : "down"
                          }-s-line`}
                          style={{ fontSize: "26px" }}
                        />
                      </button>
                    </div>

                    {careerCollapse === "publications" && <PublicationForm />}
                  </div>
                  <div
                    className="publications"
                    style={{
                      border:
                        careerCollapse === "Interests"
                          ? "1px solid #2A68DC"
                          : "none",
                      borderRadius: "8px",
                      overflow: "hidden",
                    }}
                  >
                    <div
                      className="d-flex justify-content-between  py-1 px-2 "
                      style={{ backgroundColor: "white" }}
                    >
                      <h3>Interests</h3>
                      <button
                        className="toggle_button text-center d-flex align-items-center justify-content-center"
                        onClick={() => toggleCareerCollapse("Interests")}
                      >
                        <i
                          className={`ri-arrow-${
                            careerCollapse === "Interests" ? "up" : "down"
                          }-s-line`}
                          style={{ fontSize: "26px" }}
                        />
                      </button>
                    </div>

                    {careerCollapse === "Interests" && <InterestForm />}
                  </div>
                  <div
                    className="skills"
                    style={{
                      border:
                        careerCollapse === "skill"
                          ? "1px solid #2A68DC"
                          : "none",
                      borderRadius: "8px",
                      overflow: "hidden",
                    }}
                  >
                    <div
                      className="d-flex justify-content-between  py-1 px-2  "
                      style={{ backgroundColor: "white" }}
                    >
                      <h3>Soft Skills</h3>
                      <button
                        className="toggle_button text-center d-flex align-items-center justify-content-center"
                        onClick={() => toggleCareerCollapse("skill")}
                      >
                        <i
                          className={`ri-arrow-${
                            careerCollapse === "skill" ? "up" : "down"
                          }-s-line`}
                          style={{ fontSize: "26px" }}
                        />
                      </button>
                    </div>

                    {careerCollapse === "skill" && <SoftSkills />}
                  </div>
                  <div
                    className="publications"
                    style={{
                      border:
                        careerCollapse === "language"
                          ? "1px solid #2A68DC"
                          : "none",
                      borderRadius: "8px",
                      overflow: "hidden",
                    }}
                  >
                    <div
                      className="d-flex justify-content-between  py-1 px-2  "
                      style={{ backgroundColor: "white" }}
                    >
                      <h3>Languages</h3>
                      <button
                        className="toggle_button text-center d-flex align-items-center justify-content-center"
                        onClick={() => toggleCareerCollapse("language")}
                      >
                        <i
                          className={`ri-arrow-${
                            careerCollapse === "language" ? "up" : "down"
                          }-s-line`}
                          style={{ fontSize: "26px" }}
                        />
                      </button>
                    </div>

                    {careerCollapse === "language" && <LanguagesForm />}
                  </div>
                </>
              )}

              <div className="d-flex justify-content-end  py-1 px-4">
                <button
                  className={` ${
                    checkDisable()
                      ? "cursor-not-allowed save-btn-disabled"
                      : "save-btn"
                  }`}
                  style={{
                    fontWeight: "500",
                    width: "130px",
                    height: "50px",
                  }}
                  disabled={checkDisable()}
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                    dispatch(addStep(2));
                    dispatch(setStep1(true));
                  }}
                >
                  <span
                    style={{
                      fontSize: "20px",
                      fontWeight: "500",
                    }}
                  >
                    Next
                  </span>
                  <i
                    className="ri-arrow-right-line ml-2"
                    style={{
                      fontSize: "20px",
                      fontWeight: "500",
                    }}
                  />
                </button>
              </div>
            </div>

            {!activeField && (
              <div id="pdf-generate">
                <div className="cv-main-container">
                  <CVTemplate tempRef={tempRef} settempRef={settempRef} />
                </div>
              </div>
            )}

            {activeField && step === 1 && <Guidance />}
          </>
        )}

        {step === 2 && (
          <>
            <div className="companyinformation">
              <CompanyInfoForm />
            </div>
            <div id="pdf-generate">
              <div className="cv-main-container">
                <CVTemplate tempRef={tempRef} blured={true} />
              </div>
            </div>
          </>
        )}

        {step >= 3 && (
          <AiGeneration tempRef={tempRef} settempRef={settempRef} />
        )}
        {activePreview && <Preview />}
      </div>
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            padding: "20px",
            maxWidth: "500px",
            width: "90%",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          },
        }}
      >
        <h2>Confirmation Box</h2>
        <hr />
        <div className="modal-body">
          <div className="">
            <p className="text-center">
              You are about to exit the resume module, please make sure your
              work is saved before exiting back to home
            </p>
            <div className="flex align-items-center justify-content-center mt-2">
              <button
                className="bg-green-600 px-4 py-2 rounded text-white"
                onClick={() => setModalIsOpen(false)}
              >
                Cancel
              </button>
              <button
                className="bg-red-600 px-4 py-2 rounded mx-2 text-white"
                onClick={() => navigation("/resumes")}
              >
                Exit
              </button>
            </div>
          </div>
        </div>
      </ReactModal>
      <Footer />
      {/* </>
      )} */}
    </div>
  );
};

export default ResumeBuilder;
/* <button
    className="resume-header-button"
    onClick={handlePreview}
    style={{
      backgroundColor: 'white',
      color: '#2B6BE2',
    }}
  >
    <i className="ri-file-3-line" style={{ fontSize: "18px" }}></i>
    <p>Preview</p>
  </button>*/
