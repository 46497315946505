import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentInvoices } from "../../../Redux/PaymentsSlice";
import Loader from "../../../Components/main-loader";

const History = () => {
  const disptach = useDispatch();
  const { paymentInvoices, paymentLoading } = useSelector(
    (state) => state.paymentMethods
  );

  useEffect(() => {
    disptach(getPaymentInvoices());
  }, []);

  function convertTimeStamp(timestamp) {
    const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Months are zero-indexed
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    return `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")} ${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  }

  if (paymentLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className="mt-3">
        <p className="text-center text-lg text-dark mb-4">
          Your Billing History
        </p>
        <div class="flex flex-col">
          <div class="overflow-x-auto sm:mx-0.5 lg:mx-0.5">
            <div class="py-2 inline-block min-w-full">
              <div class="overflow-hidden">
                <table class="min-w-full">
                  <thead class="bg-gray-200 border-b">
                    <tr>
                      <th
                        scope="col"
                        class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                      >
                        Period Start
                      </th>
                      <th
                        scope="col"
                        class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                      >
                        Period End
                      </th>
                      <th
                        scope="col"
                        class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                      >
                        Invoice Date
                      </th>
                      <th
                        scope="col"
                        class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                      >
                        Amount
                      </th>
                      <th
                        scope="col"
                        class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {paymentInvoices?.length > 0 ? (
                      paymentInvoices?.map((item) => {
                        return (
                          <tr class="bg-white  text-capitalize border-b text-start transition duration-300 ease-in-out hover:bg-gray-100">
                            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              {convertTimeStamp(item?.period_start)}
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              {convertTimeStamp(item?.period_end)}
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              {convertTimeStamp(item?.invoice_created)}
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              {item?.currency == "usd" ? "$" : item.currency}{" "}
                              {item?.total}
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              {item?.status}
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              <button
                                className="py-2 bg-primary px-3 text-md rounded-lg font-medium text-white"
                                onClick={() =>
                                  window.open(item?.invoice_pdf, "_blank")
                                }
                              >
                                Download
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="bg-white border-b text-start transition duration-300 ease-in-out hover:bg-gray-100">
                        <td
                          colSpan="6"
                          className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 text-center"
                        >
                          No Data Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default History;
