import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSingleChatAction } from "../../Redux/ChatSlice";

const Contact = React.memo(({ item, isSelected }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authenticate);

  return (
    <div
      onClick={() => dispatch(getSingleChatAction(item.id))}
      className={`cursor-pointer flex items-center gap-3 hover:bg-gray-300 rounded-[100px] ${
        isSelected && "bg-gray-300"
      }`}
    >
      <div className="min-w-10 min-h-10 w-10 h-10 rounded-full bg-gray-500 sm:flex hidden justify-center items-center text-white font-semibold text-xl truncate">
        {item.name.split("")[0] || user?.organizationName.split("")[0]}
      </div>
      <h2>{item.name || user?.organizationName}</h2>
    </div>
  );
});

export default Contact;
