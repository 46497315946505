import Repository from "./Repository";
const DASHBOARD_EVALUATE = "/dashboard/evaluate";
const JOB_ROLES = "/dashboard/job-roles";
const Hide_JOB_ROLE = "/dashboard/hide-job-role";
const Show_JOB_ROLE = "/dashboard/show-job-role";
const GENERATE_QUESTIONS = "/dashboard/interview-training/questions";
const SUBMIT_ANSWERS = "/dashboard/interview-training/response";
const GET_INTERVIEW_RESPONSE = "/dashboard/interview-training/feedback";
const GET_SIMULATION_RESPONSE = "/dashboard/interview-simulation/feedback";
const SIMULATIONS = "/dashboard/interview-simulator/begin";
const SIMULATIONS_NEXT = "/dashboard/interview-simulator/next";
const UPDATE_SIMULATION = "/dashboard/interview-simulator/update-answer";
const PRICINGS = "/es/pricing/product_pricing";
const CHECK_OUT = "/es/onboard/checkout";
const UPDATE_SUBCRIPTION = "/es/onboard/subscription/modify";
const BLOGS = "/admin/blogs/list";
const INTERVIEW_TRAINING_IDE = "/dashboard/interview-training/ide";
const INTERVIEW_FEEDBACK = "/dashboard/interview-training/feedback";

const dashboardRepository = {
  setDashboardEvaluate(payload) {
    return Repository.post(`${DASHBOARD_EVALUATE}`, payload);
  },
  getAllRoles(payload) {
    return Repository.get(`${JOB_ROLES}?type=${payload}`);
  },
  hideJobRole(payload) {
    return Repository.get(`${Hide_JOB_ROLE}?job_role_id=${payload}`);
  },
  showJobRole(payload) {
    return Repository.get(`${Show_JOB_ROLE}?job_role_id=${payload}`);
  },
  generateQuestions(payload) {
    console.log(payload, "payload");
    return Repository.post(`${GENERATE_QUESTIONS}`, payload);
  },
  submitAnswers(payload) {
    console.log(payload, "payload");
    return Repository.post(`${SUBMIT_ANSWERS}`, payload);
  },
  getInterviewResponse(id) {
    return Repository.get(
      `${GET_INTERVIEW_RESPONSE}?interviewTrainingSessionId=${id}`
    );
  },
  getSimultorResponse(id) {
    return Repository.get(
      `${GET_SIMULATION_RESPONSE}?interviewSimulationSessionId=${id}`
    );
  },
  startSimulations(payload) {
    const formdata = new FormData();
    if (payload.resume_file) {
      formdata.append("resumeFile", payload.resume_file);
    }
    if (payload.resumeId) {
      formdata.append("resumeId", payload.resumeId);
    }
    formdata.append("jobDescription", payload.jobDescription);
    return Repository.post(`${SIMULATIONS}`, formdata);
  },
  nextSimulations(payload) {
    console.log(payload, "payload");
    return Repository.post(`${SIMULATIONS_NEXT}`, payload);
  },
  updateSimulation(payload) {
    console.log(payload, "payload");
    return Repository.put(`${UPDATE_SIMULATION}`, payload);
  },
  getPricing() {
    return Repository.get(`${PRICINGS}`);
  },
  stripeCheckout(id) {
    return Repository.post(`${CHECK_OUT}?bundlePlanId=${id}`);
  },
  updateSubscription(id) {
    return Repository.post(`${UPDATE_SUBCRIPTION}?new_plan_id=${id}`);
  },
  getAllBlogs() {
    return Repository.get(`${BLOGS}`);
  },
  interviewTrainingIde(payload) {
    return Repository.post(`${INTERVIEW_TRAINING_IDE}`, payload);
  },
  interviewFeedback(id) {
    return Repository.get(
      `${INTERVIEW_FEEDBACK}?interviewTrainingSessionId=${id}`
    );
  },
};

export default dashboardRepository;
