import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { delegateAccess } from "../Redux/authenticate/authenticate.action";

const DelegateAccess = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  useEffect(() => {
    dispatch(delegateAccess(token))
      .unwrap()
      .then((res) => {
        navigate("/home");
      })
      .catch((err) => {
        console.log(err);
        navigate("/login");
      });
  }, []);

  return (
    <div className="h-screen flex justify-center items-center flex-col gap-3 bg-gray-300">
      <div className="navbar-logo mb-6">
        <div>
          <img src="/images/logo.png" alt="logo" />
        </div>
      </div>
      <h2 className="text-xl font-semibold text-gray-700">Welcome!</h2>
      <p className="text-gray-500">
        Please wait a moment! We will redirect you soon...
      </p>
      <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900"></div>
    </div>
  );
};

export default DelegateAccess;
