import DirectoryItem from "./DirectoryItem";
import FileItem from "./FileItem";

export default function FileSystem({ files, dirs }) {
  if (files === null || dirs === null) {
    return <div>Loading...</div>;
  }
  return (
    <nav className="w-60 overflow-x-scroll bg-white shadow-lg px-2 h-full">
      {dirs.map((dir) => (
        <DirectoryItem {...dir} />
      ))}
      {files.map((file) => (
        <FileItem {...file} />
      ))}
    </nav>
  );
}
