import nexaLogo from "../../assets/icons/logo.jpeg";
import googleLogo from "../../assets/icons/googleIcon.webp";
export const jobListings = [
  {
    jobTitle: "Senior Network Engineer",
    compensation: "$207k - $312k",
    location: "Santa Clara",
    tenure: "Senior",
    descriptionPoints: [
      "Design, architect, and implement complex data center network solutions using VXLAN and BGP protocols.",
      "Optimize network performance through continuous monitoring, tuning, and capacity planning.",
      "Bachelor's degree in Computer Science, Information Technology, or related field.",
      "12+ years of experience in network engineering roles, with a focus on supporting data center environments.",
    ],
    companyName: "Pure Storage",
    companyTagline: "Where Savings Meets Sustainability",
    funding: "$956.2M",
    headcount: "5000+",
    founded: "2009",
    headquarters: "Mountain View, CA",
    CompanyDesc:
      "Pure Storage helps innovators uncomplicate data storage, forever. Our data solutions enable SaaS companies, cloud service providers, and enterprise and public sector customers to deliver real-time, secure data to power their mission-critical production, DevOps, and modern analytics environments in a multi-cloud environment.",
    logoUrl: "https://assets.harmonic.ai/company_62ac3e2d1c2a074b100c8fc5357736e1c9efb8ab1ef7e4707294d91b2836248d.png",
    topInvestors: true,
    ventureBacked: true,
    postedDate: "Posted 7d ago",
    badge: "Applied",
    jobProvider: googleLogo,
  },
  {
    jobTitle: "Network Engineer",
    compensation: "N/A",
    location: "Brisbane City + 4",
    tenure: "Mid Level",
    descriptionPoints: [
      "Design, implement, and maintain clients' network infrastructure.",
      "Responsible for the clients' network infrastructure.",
      "Bachelor's degree in Computer Science, Information Technology, or a related field.",
      "Minimum of 5 years of experience as a Network Engineer.",
      "Expertise in implementing and managing large-scale ICT networks, including switching and routing.",
    ],
    companyName: "Zone IT Solutions",
    companyTagline: "Your IT Talent Partner Globally",
    funding: "$0",
    headcount: "63",
    founded: "2012",
    headquarters: "Docklands, Victoria",
    CompanyDesc:
      "Zone IT Solutions is a leading IT Recruitment agency delivering talent across digital, ERP, Data, and integration technologies for Australia, New Zealand, India, and Singapore. We offer flexible, efficient, and collaborative solutions to any organisation that requires IT experts. Our customers range from small/mid-size enterprises to large, multi-national corporations. Our agile, agnostic, and flexible solutions will help you source the IT Expertise you need with a creative approach to solutions. Our delivery offices are in Melbourne, Sydney, Ballarat, Singapore, and India. To be considered for new opportunities, please send your resume to careers@zoneitsolutions.com. Also, follow us on LinkedIn for upcoming opportunities. Zone IT Solutions is an equal opportunity employer, and our recruitment process focuses on essential skills and abilities. We welcome applicants from various backgrounds, including Aboriginal and Torres Strait Islander peoples, culturally and linguistically diverse (CALD) backgrounds, and people with disabilities.",
    logoUrl: "https://media.licdn.com/dms/image/C510BAQH5I-xubihJtg/company-logo_200_200/0/1631380042774?e=2147483647&v=beta&t=-aeC9pHWBNfOvCycV5WXIY1iNcLrB2zyc0415sUflck",
    topInvestors: false,
    ventureBacked: false,
    postedDate: "Posted 30d+ ago",
    badge: "Waiting for interview",
    jobProvider: nexaLogo,
  },
  {
    jobTitle: "Network Engineer",
    compensation: "N/A",
    location: "Singapore",
    tenure: "Mid Level",
    descriptionPoints: [
      "Help support and expand the networking environment at Schonfeld.",
      "Participate in the implementation, operations and troubleshooting of our global network infrastructure.",
      "At minimum, 3 years of experience in a network engineering role focusing on enterprise network design and administration.",
      "Expert level knowledge with routing and switching in a Cisco/Arista environment.",
    ],
    companyName: "Schonfeld",
    companyTagline: "Global multi-manager platform.",
    funding: "$0",
    headcount: "Unknown",
    founded: "Unknown",
    headquarters: "Remote",
    CompanyDesc:
      "A global multi-manager platform maximizing portfolio managers' value through autonomy, flexibility, and support.",
    logoUrl: "https://media.licdn.com/dms/image/D4E0BAQGfZK4Gj6CCjg/company-logo_200_200/0/1686248853121?e=2147483647&v=beta&t=-dtLWaClPzHqXzQ5Ul89NFDd90z8wf3ZfGUwOq0AYXY",
    topInvestors: true,
    ventureBacked: false,
    postedDate: "Posted 10d ago",
    badge: "Unqualified",
    jobProvider: nexaLogo,
  },
  {
    jobTitle: "Carrier Network Engineer",
    compensation: "N/A",
    location: "London",
    tenure: "Senior",
    descriptionPoints: [
      "Taking engineering accountability for this ingenious start-up's Next Generation ISP/Carrier network throughout its evolution.",
      "Handling day to day Network Management tasks such as Troubleshooting Network Issues.",
      "Significant network engineering experience to at least CCNP/JNCP level.",
      "Striving for CIIE, within a carrier/ISP environment.",
      "Collaborative engagement with Network Operations and Network Architecture teams to report progress and technical requirements.",
    ],
    companyName: "J BANDY CONSULTING LTD",
    companyTagline: "Empowering Tomorrow's Tech Talent Today",
    funding: "$0",
    headcount: "3",
    founded: "2015",
    headquarters: "Street, Somerset",
    CompanyDesc:
      "The company specializes in recruiting next-gen technology talent, with a focus on Next Gen Telecoms, DevOps, Cloud, Cyber, Product Evolution, AI & ML, Data Science, and Service Delivery. They also offer bespoke recruitment solutions and performance improvement plans to help businesses and individuals perform better.",
    logoUrl: "https://assets.harmonic.ai/company_53ec67d617f1adcb53667ea89bbef7021b6a886c2b47b2bd76552d2fc19cdc03.png",
    topInvestors: false,
    ventureBacked: true,
    postedDate: "Posted 25d ago",
    badge: "Qualified",
    jobProvider: googleLogo,
  },
  {
    jobTitle: "Network Engineer",
    compensation: "N/A",
    location: "Remote",
    tenure: "Mid Level",
    descriptionPoints: [
      "Design and implement a modern secure high-observable, high-reliability wired/wireless network for a next-generation transit system.",
      "Maintain and optimize network performance by monitoring network availability, throughput, and latency.",
      "Degree in Electrical Engineering, Computer Engineering, or Computer Science.",
      "Cisco CCNA certification required, Cisco CCNP desired.",
      "Hands-on experience as a Network/Wireless Network Engineer.",
    ],
    companyName: "Glydways",
    companyTagline: "Sustainable mobility for a better world.",
    funding: "$40.5M",
    headcount: "70",
    founded: "2016",
    headquarters: "South San Francisco, California",
    CompanyDesc:
      "Designs, manufactures, installs, and operates affordable autonomous transportation for low, medium, and extremely high capacity needs that is environmentally net-negative in terms of greenhouse gas production (GHG) and inherently profitable.",
    logoUrl: "https://assets.harmonic.ai/company_2080f140cb65932e02a4e0fae67c8a0135b5c650592507512a61d584a26525f6.png",
    topInvestors: true,
    ventureBacked: true,
    postedDate: "Posted 30d+ ago",
    badge: "Hired",
    jobProvider: googleLogo,
  },
];
