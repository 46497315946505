import React from "react";
import "../onboarding.css";
import { Link } from "react-router-dom";

const Terms2 = ({ handleNext }) => {
  return (
    <div className=" position-relative pt-2">
      <div className="header headers">
        <div className="container">
          <div className="ic-navbar-heads">
            <div className="ic-logos">
              <Link to="/">
                <img src="/images/logo.png" alt="" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="container text-center mt-[30vh]">
        <div>
          {termsData.map((term, index) => (
            <div className="pb-4 text-center">
              <p className=" heading mb-2"> {term.heading}</p>

              <p className="para ml-4">{term.para}</p>
            </div>
          ))}
        </div >
        <div className="btn-agree">
        <button className="ic-btn "  onClick={handleNext}>
          I'm Ready
        </button>
        </div>
      </div>
    </div>
  );
};

export default Terms2;

const termsData = [
  {
    heading: "Welcome to Nexa. You're about to embark on a Career journey like no other",
    para: "The following questions will personalize this journey for you.",
  }
];
