import React from "react";
import { useDispatch, useSelector } from "react-redux";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import {
  addStep,
  selectStep,
  selectStep1,
  setActivePreview,
} from "../../features/companyinformation/StepsSlice";
import { FaArrowRight } from "react-icons/fa";
// import {
//   setActiveField,
//   setActivePreview,
// } from "../../features/education/EducationFormSlice";
import { useState, useEffect } from "react";
import {
  MagicWandIcon,
  CreateIcon,
  backgroundImage,
  BuildIcon,
} from "./svgimages";
const ResumeHeaderButton = ({ children, onClick, active, disabled }) => {
  return (
    <button
      className={`resume-header-button ${
        active ? "resume-header-button-active" : ""
      }`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

const ResumeHeader = ({ targetId, handlePreview, handlePreview2 }) => {
  const step = useSelector(selectStep);
  const step1 = useSelector(selectStep1);
  const dispatch = useDispatch();
  const activeField = useSelector((state) => state?.educationForm?.activeField);
  const { activePreview } = useSelector((state) => state?.Steps);
  // const isediting = useSelector((state) => state?.educationForm?.isediting);
  const isediting = true;
  const [windowsize, setwindowsize] = useState(window.innerWidth);
  const [isPreview, setIsPreview] = useState(activePreview);
  console.log(windowsize, "windowsize>>>>>>>>>>>>>>>>>>>");
  const [activeButton, setActiveButton] = useState(null);
  const handleButtonClick = (buttonName, step) => {
    setActiveButton(buttonName);
    dispatch(addStep(step));
  };

  const PreviewIcon = () => (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0019 0C6.04545 0 2.42681 2.32944 0.0751426 6.3904C0.0259316 6.47473 0 6.57061 0 6.66825C0 6.76588 0.0259316 6.86176 0.0751426 6.94609C2.42681 11.0071 6.04545 13.3365 10.0019 13.3365C13.9584 13.3365 17.5771 11.0071 19.9287 6.94609C19.9779 6.86176 20.0039 6.76588 20.0039 6.66825C20.0039 6.57061 19.9779 6.47473 19.9287 6.3904C17.5771 2.32944 13.9584 0 10.0019 0ZM10.0019 12.2251C6.54334 12.2251 3.35147 10.2046 1.20208 6.66825C3.35147 3.13185 6.53667 1.11137 10.0019 1.11137C13.4672 1.11137 16.6524 3.13185 18.8018 6.66825C16.6524 10.2046 13.4605 12.2251 10.0019 12.2251ZM10.0019 2.77844C9.23261 2.77844 8.48055 3.00657 7.84087 3.43399C7.2012 3.86141 6.70263 4.46891 6.40822 5.17968C6.11381 5.89045 6.03678 6.67256 6.18687 7.42711C6.33696 8.18166 6.70743 8.87476 7.25143 9.41876C7.79543 9.96276 8.48852 10.3332 9.24307 10.4833C9.99762 10.6334 10.7797 10.5564 11.4905 10.262C12.2013 9.96755 12.8088 9.46898 13.2362 8.82931C13.6636 8.18963 13.8917 7.43758 13.8917 6.66825C13.8906 5.63697 13.4804 4.64826 12.7511 3.91903C12.0219 3.18981 11.0332 2.77961 10.0019 2.77844ZM10.0019 9.44668C9.45242 9.44668 8.91523 9.28373 8.45832 8.97843C8.00141 8.67313 7.64529 8.2392 7.435 7.73151C7.22471 7.22381 7.16968 6.66516 7.27689 6.1262C7.3841 5.58724 7.64872 5.09217 8.03729 4.70359C8.42586 4.31502 8.92093 4.0504 9.45989 3.9432C9.99886 3.83599 10.5575 3.89101 11.0652 4.10131C11.5729 4.3116 12.0068 4.66772 12.3121 5.12463C12.6174 5.58154 12.7804 6.11872 12.7804 6.66825C12.7792 7.40477 12.4861 8.1108 11.9653 8.6316C11.4445 9.1524 10.7385 9.44551 10.0019 9.44668Z"
        fill="white"
      />
    </svg>
  );
  const PreviewMobileIcon = () => (
    <svg
      width="30"
      height="24"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0019 0C6.04545 0 2.42681 2.32944 0.0751426 6.3904C0.0259316 6.47473 0 6.57061 0 6.66825C0 6.76588 0.0259316 6.86176 0.0751426 6.94609C2.42681 11.0071 6.04545 13.3365 10.0019 13.3365C13.9584 13.3365 17.5771 11.0071 19.9287 6.94609C19.9779 6.86176 20.0039 6.76588 20.0039 6.66825C20.0039 6.57061 19.9779 6.47473 19.9287 6.3904C17.5771 2.32944 13.9584 0 10.0019 0ZM10.0019 12.2251C6.54334 12.2251 3.35147 10.2046 1.20208 6.66825C3.35147 3.13185 6.53667 1.11137 10.0019 1.11137C13.4672 1.11137 16.6524 3.13185 18.8018 6.66825C16.6524 10.2046 13.4605 12.2251 10.0019 12.2251ZM10.0019 2.77844C9.23261 2.77844 8.48055 3.00657 7.84087 3.43399C7.2012 3.86141 6.70263 4.46891 6.40822 5.17968C6.11381 5.89045 6.03678 6.67256 6.18687 7.42711C6.33696 8.18166 6.70743 8.87476 7.25143 9.41876C7.79543 9.96276 8.48852 10.3332 9.24307 10.4833C9.99762 10.6334 10.7797 10.5564 11.4905 10.262C12.2013 9.96755 12.8088 9.46898 13.2362 8.82931C13.6636 8.18963 13.8917 7.43758 13.8917 6.66825C13.8906 5.63697 13.4804 4.64826 12.7511 3.91903C12.0219 3.18981 11.0332 2.77961 10.0019 2.77844ZM10.0019 9.44668C9.45242 9.44668 8.91523 9.28373 8.45832 8.97843C8.00141 8.67313 7.64529 8.2392 7.435 7.73151C7.22471 7.22381 7.16968 6.66516 7.27689 6.1262C7.3841 5.58724 7.64872 5.09217 8.03729 4.70359C8.42586 4.31502 8.92093 4.0504 9.45989 3.9432C9.99886 3.83599 10.5575 3.89101 11.0652 4.10131C11.5729 4.3116 12.0068 4.66772 12.3121 5.12463C12.6174 5.58154 12.7804 6.11872 12.7804 6.66825C12.7792 7.40477 12.4861 8.1108 11.9653 8.6316C11.4445 9.1524 10.7385 9.44551 10.0019 9.44668Z"
        fill="white"
      />
    </svg>
  );

  const handlePreviewResume = () => {
    const payload = {
      preview: true,
      step: 0,
      step1: false,
    };
    dispatch(setActivePreview(payload));
  };

  useEffect(() => {
    setIsPreview(activePreview);
  }, [activePreview]);
  useEffect(() => {
    const handleResize = () => {
      setwindowsize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window]);

  const options = {
    method: "open",
    resolution: Resolution.HIGH,
    page: {
      margin: Margin.SMALL,
      format: "letter",
      orientation: "landscape",
    },
    canvas: {
      mimeType: "image/png",
      qualityRatio: 1,
    },
    overrides: {
      pdf: {
        compress: true,
      },
      canvas: {
        useCORS: true,
      },
    },
  };

  const getTargetElement = (id) => document.getElementById(id);

  const handleGeneratePDF = () => {
    generatePDF(() => getTargetElement(targetId), options);
  };
  if (windowsize < 768) {
    return (
      <div
        className="resume-header"
        style={{
          backgroundColor: "white",
          marginBottom: "30px",
          marginTop: "8rem",
        }}
      >
        <div
          className="resume-stepssection"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div style={{ display: "flex", gap: "10px" }}>
            <ResumeHeaderButton
              active={step === 1}
              // onClick={() => {
              //   dispatch(addStep(1));
              // }}
              disabled={isPreview}
            >
              <BuildIcon active={step === 1}></BuildIcon>
            </ResumeHeaderButton>

            <ResumeHeaderButton
              active={step === 2}
              // onClick={() => {
              //   dispatch(addStep(2));
              // }}
              disabled={isPreview}
            >
              <MagicWandIcon active={step === 2}></MagicWandIcon>
            </ResumeHeaderButton>

            <ResumeHeaderButton
              active={step === 3}
              // onClick={() => {
              //   dispatch(addStep(3));
              // }}
              disabled={isPreview}
            >
              <CreateIcon active={step === 3}></CreateIcon>
            </ResumeHeaderButton>
          </div>
          {activePreview ? (
            <button
              className="resume-header-button"
              onClick={handlePreview2}
              style={{
                backgroundColor: "white",
                color: "#2B6BE2",
                marginLeft: "auto",
              }}
            >
              <i className="ri-pencil-line" style={{ fontSize: "18px" }}></i>
            </button>
          ) : (
            <button
              className="resume-header-button"
              onClick={handlePreviewResume}
              style={{
                backgroundColor: "#2B6BE2",
                color: "white",
                borderRadius: "50%",
                padding: "20px 12px",
                border: "none",
              }}
            >
              <PreviewMobileIcon/>
            </button>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div
        className="resume-header mt-20"
        style={{
          backgroundColor: "white",
          marginBottom: "30px",
          marginTop: "8rem",
        }}
      >
        <div
          className="resume-stepssection"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <ResumeHeaderButton
              active={step === 1}
              // onClick={() => {
              //   dispatch(addStep(1));
              // }}
              disabled={true}
            >
              <BuildIcon active={step === 1}></BuildIcon>
              <p>Build</p>
            </ResumeHeaderButton>
            <FaArrowRight style={{ color: "#2B6BE2", margin: "0 10px" }} />
            <ResumeHeaderButton
              active={step === 2}
              // onClick={() => {
              //   dispatch(addStep(2));
              // }}
              disabled={true}
            >
              <MagicWandIcon active={step === 2}></MagicWandIcon>
              <p>Enhance</p>
            </ResumeHeaderButton>
            <FaArrowRight style={{ color: "#2B6BE2", margin: "0 10px" }} />
            <ResumeHeaderButton
              active={step === 3}
              // onClick={() => {
              //   dispatch(addStep(3));
              // }}
              disabled={true}
            >
              <CreateIcon active={step === 3}></CreateIcon>
              <p>Create</p>
            </ResumeHeaderButton>
          </div>
          {activePreview ? (
            <button
              className="resume-header-button"
              onClick={handlePreview2}
              style={{
                backgroundColor: "white",
                color: "#2B6BE2",
                marginLeft: "auto",
              }}
            >
              <i className="ri-pencil-line" style={{ fontSize: "18px" }}></i>
              <p>Edit Details</p>
            </button>
          ) : (
            <button
              className="resume-header-button"
              onClick={handlePreviewResume}
              style={{
                backgroundColor: "#2B6BE2",
                color: "white",
                borderRadius: "50px",
                padding: "10px 20px",
                border: "none",
              }}
            >
              <PreviewIcon />
              <p style={{ margin: "0" }}>Preview</p>
            </button>
          )}
        </div>
      </div>
    );
  }
};

export default ResumeHeader;
