import React from "react";
import CVTemplate from "../../Components/Resume/CVTemplate";
// import "./cv.css";

const CV = () => {
  return (
    <div className="cv-main-container">
      <CVTemplate />;
    </div>
  );
};

export default CV;
