import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import SelectComponent from "../../Components/Resume/Select";
import DatePicker from "../../Components/Resume/DatePicker";
import {
  addResumeCompany,
  addResumeExperience,
  addResumeRoles,
  addWorkExperience,
  deleteResumeCompaney,
  deleteResumeRole,
  // deleteResumeExperience,
  getResumeExperience,
  getSingleResume,
  removeWorkExperience,
  udpateResumeCompaney,
  udpateResumeExperience,
  udpateResumeRoles,
  updateWorkExperience,
} from "../../Redux/ResumeBuilderSlice";
// import { setActiveField } from "../education/EducationFormSlice";
import { error, success } from "../../Utilities/toast";
import { useParams } from "react-router-dom";
import { IoMdAdd } from "react-icons/io";

const WorkExperienceForm = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { workExperience } = useSelector((state) => state.ResumeBuilder);
  console.log(workExperience, "workExperienceworkExperience");
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [updateDocId, setUpdateDocId] = useState(null);
  const [storeRole, setStoreRole] = useState(null);
  const [activeComId, setActiveComId] = useState(null);
  const [editActiveComId, setEditActiveComId] = useState(null);
  console.log(storeRole, "storeRolestoreRolestoreRolestoreRole");
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const handleAddWork = () => {
    setStoreRole(null);
    setIsFormVisible(true);
  };

  const handleEditingIndex = (index, role, id) => {
    console.log(index, role, id, "indexindex");
    setStoreRole(role);
    setIsFormVisible(true);
    setSelectedIndex(index);
    setUpdateDocId(id);
    if (role == "companey") {
      const foundObj = workExperience.find((obj, idx) => idx === index);
      console.log(foundObj, "foundObjfoundObjbyhamza");
      setEditActiveComId(foundObj?.company?.id);
      setValue("companyName", foundObj?.company?.name);
      setValue("companyDescription", foundObj?.company?.description);

      // setValue({
      //   companyName: foundObj?.company?.name,
      //   companyDescription: foundObj?.company?.description,
      // });
    } else if (role == "role") {
      const foundObj = workExperience.find((obj, idx) => idx === index);
      const newObjArray = foundObj.roles.filter((role) => role.id === id);
      const newObj = newObjArray[0];
      setEditActiveComId(newObj?.id);
      setValue("companyPosition", "Ahsan IQbal");
      reset({
        companyPosition: newObj?.name,
        companyLocation: newObj?.location,
        checked: !newObj?.ended_at ? true : false,
        startDate: newObj?.started_at,
        endDate: newObj?.ended_at,
      });
      setSelectedType(newObj?.type);
      setSelectedStartDate(new Date(newObj?.started_at));
      setSelectedEndDate(new Date(newObj?.ended_at));
    }
  };

  const onCancel = () => {
    setIsFormVisible(false);
    setSelectedIndex(-1);
    reset({
      companyName: "",
      companyDescription: "",
      companyPosition: "",
      companyLocation: "",
      checked: "",
      startDate: "",
      endDate: "",
    });
    setSelectedType("");
    setSelectedStartDate(new Date());
    setSelectedEndDate(new Date());
    setSelectedIndex(-1);
  };

  const onSubmit = (data) => {
    console.log(data, "databyworkexperience");
    data.jobType = selectedType;
    const timeZoneOffset = selectedStartDate.getTimezoneOffset() * 60000;

    data.startDate = new Date(selectedStartDate - timeZoneOffset)
      .toISOString()
      .split("T")[0];

    data.endDate = new Date(selectedEndDate - timeZoneOffset)
      .toISOString()
      .split("T")[0];
    if (selectedIndex !== -1) {
      if (storeRole == "companey") {
        const companies = {
          name: data?.companyName,
          description: data?.companyDescription,
        };
        console.log(companies, "companiescompaniescompanies");
        const roleId = editActiveComId;
        console.log(roleId, "roleIdbyhamzIjaz");
        dispatch(
          udpateResumeCompaney({
            companies,
            roleId,
            onSuccess: (res) => {
              success(res);
              getExperienceInfo();
            },
          })
        );
      }

      if (storeRole == "role") {
        const roles = {
          name: data?.companyPosition,
          location: data?.companyLocation,
          type: selectedType,
          started_at: data?.startDate,
          ended_at: data?.checked == true ? null : data?.endDate,
        };
        const roleId = editActiveComId;
        console.log(roles, "rolesrolesrolesrolesrolesroles");
        const nowData = new Date();
        const endedAtDate = new Date(roles?.ended_at);
        if (roles?.started_at >= roles?.ended_at) {
          error("Start date cannot be greater than or equal to end date");
          return;
        }
        if (endedAtDate > nowData) {
          error("End date cannot be greater than current date");
          return;
        }
        dispatch(
          udpateResumeRoles({
            roles,
            roleId,
            onSuccess: (res) => {
              success(res);
              getExperienceInfo();
            },
          })
        );
      }

      // const payload = {
      //   updateDocId,
      //   data,
      // };
      // dispatch();
      // udpateResumeExperience({
      //   payload,
      //   onSuccess: (res) => {
      //     success(res);
      //     getExperienceInfo();
      //   },
      // })
    } else {
      if (storeRole == null) {
        const companies = {
          name: data?.companyName,
          description: data?.companyDescription,
        };
        const role = {
          name: data?.companyPosition,
          started_at: data?.startDate,
          ended_at: data?.checked == true ? null : data?.endDate,
          location: data?.companyLocation,
          type: data?.jobType,
        };
        const nowData = new Date();
        const endedAtDate = new Date(role?.ended_at);
        if (role?.started_at >= role?.ended_at) {
          error("Start date cannot be greater than or equal to end date");
          return;
        }
        if (endedAtDate > nowData) {
          error("End date cannot be greater than current date");
          return;
        }
        dispatch(
          addResumeCompany({
            companies,
            id,
            onSuccess: (res, roleId) => {
              success(res);
              if (roleId) {
                dispatch(
                  addResumeRoles({
                    role,
                    roleId,
                    onSuccess: (res) => {
                      success(res);
                      getExperienceInfo();
                    },
                  })
                );
              }
              getExperienceInfo();
            },
          })
        );
      } else if (storeRole == "role") {
        const role = {
          name: data?.companyPosition,
          started_at: data?.startDate,
          ended_at: data?.checked == true ? null : data?.endDate,
          location: data?.companyLocation,
          type: data?.jobType,
        };
        const roleId = activeComId;
        const nowData = new Date();
        const endedAtDate = new Date(role?.ended_at);
        if (role?.started_at >= role?.ended_at) {
          error("Start date cannot be greater than or equal to end date");
          return;
        }
        if (endedAtDate > nowData) {
          error("End date cannot be greater than current date");
          return;
        }
        dispatch(
          addResumeRoles({
            role,
            roleId,
            onSuccess: (res) => {
              success(res);
              getExperienceInfo();
            },
          })
        );
        console.log(role, "addRoleaddRole");
      }
      // const payload = data;
    }

    setIsFormVisible(false);
    reset({
      companyName: "",
      companyDescription: "",
      companyPosition: "",
      companyLocation: "",
      checked: "",
      startDate: "",
      endDate: "",
    });
    setSelectedType("");
    setSelectedStartDate(new Date());
    setSelectedEndDate(new Date());
    setSelectedIndex(-1);
  };

  const handleRemoveRoles = (payload) => {
    dispatch(
      deleteResumeRole({
        payload,
        onSuccess: (res) => {
          success(res);
          getExperienceInfo();
        },
      })
    );
  };

  const handleRemoveCompaney = (payload) => {
    dispatch(
      deleteResumeCompaney({
        payload,
        onSuccess: (res) => {
          success(res);
          getExperienceInfo();
        },
      })
    );
  };

  const getExperienceInfo = () => {
    const resumeId = id;
    dispatch(getSingleResume({ resumeId, onSuccess: () => {} }));
  };

  const handleAddRole = (role, comId) => {
    console.log(comId, "comIdcomIdcomIdcomId");
    setActiveComId(comId);
    setStoreRole(role);
    setIsFormVisible(true);
  };

  return (
    <div className="mydetails">
      {isFormVisible && (
        <form onSubmit={handleSubmit(onSubmit)}>
          {(storeRole === "companey" || storeRole === null) && (
            <>
              <div className="d-flex flex-column mb-1">
                <div className="form-group w-100">
                  <label htmlFor="companyName">Company Name</label>
                  <input
                    type="text"
                    id="companyName"
                    className="form-control"
                    placeholder="Facebook"
                    {...register("companyName", {
                      required: "Company Name is required",
                    })}
                    //  onFocus={() => dispatch(setActiveField("work"))}
                  />
                  {errors.companyName && (
                    <p className="text-danger">{errors.companyName.message}</p>
                  )}
                </div>
                <div className="form-group w-100">
                  <label htmlFor="companyDescription">
                    Company Description
                  </label>
                  <textarea
                    id="companyDescription"
                    className="workcontrol"
                    style={{ height: "150px" }}
                    {...register("companyDescription", {
                      required: "Company Description is required",
                    })}
                    //  onFocus={() => dispatch(setActiveField("work"))}
                  />
                  {errors.companyDescription && (
                    <p className="text-danger">
                      {errors.companyDescription.message}
                    </p>
                  )}
                </div>
              </div>
            </>
          )}
          {(storeRole === "role" || storeRole === null) && (
            <>
              <div className="form-group w-100">
                <label htmlFor="companyPosition">Position</label>
                <input
                  type="text"
                  id="companyPosition"
                  className="form-control"
                  {...register("companyPosition", {
                    required: "Company Position is required",
                  })}
                  //  onFocus={() => dispatch(setActiveField("work"))}
                />
                {errors.companyPosition && (
                  <p className="text-danger">
                    {errors.companyPosition.message}
                  </p>
                )}
              </div>
              <div className="d-flex mb-1">
                <div className="form-group mr-2 w-50">
                  <label htmlFor="location">Location</label>
                  <input
                    type="text"
                    id="location"
                    className="form-control"
                    {...register("companyLocation", {
                      required: "Company Location is required",
                    })}
                    //  onFocus={() => dispatch(setActiveField("work"))}
                  />
                  {errors.companyLocation && (
                    <p className="text-danger">
                      {errors.companyLocation.message}
                    </p>
                  )}
                </div>
                <div className="form-group ml-3 w-50">
                  <label htmlFor="companyType">Type</label>
                  <SelectComponent
                    onSelect={setSelectedType}
                    selectedValue={selectedType}
                  />
                </div>
              </div>
              <div className="d-flex mb-1 flex-col md:flex-row">
                <div className="form-group mr-2 w-full md:w-50">
                  <label htmlFor="startDate">Start Date</label>
                  <DatePicker
                    selectedDate={selectedStartDate}
                    onSelect={setSelectedStartDate}
                  />
                </div>
                <div className="form-group ml-0 md:ml-3 w-full md:w-50">
                  <label htmlFor="endDate">End Date</label>
                  <DatePicker
                    selectedDate={selectedEndDate}
                    onSelect={setSelectedEndDate}
                  />
                </div>
              </div>
              <div className="d-flex mb-1">
                <div className="form-check ml-1">
                  <input
                    className="custom-check-input"
                    type="checkbox"
                    id="stillworking"
                    {...register("checked")}
                    //  onFocus={() => dispatch(setActiveField("work"))}
                  />
                  <label className="form-check-label" htmlFor="stillworking">
                    I am currently in this position
                  </label>
                </div>
              </div>
            </>
          )}
          <div className="d-flex justify-content-end py-2 px-2 border-bottom border-primary">
            <button
              type="button"
              className="cancel-btn mr-4"
              style={{
                fontSize: "14px",
                fontWeight: "500",
                width: "100px",
                height: "40px",
              }}
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="save-btn"
              style={{
                fontSize: "14px",
                fontWeight: "500",
                width: "100px",
                height: "40px",
              }}
            >
              Save
            </button>
          </div>
        </form>
      )}
      {!isFormVisible && (
        <div className="professionalsummary-item">
          <button className="professionalitem-button" onClick={handleAddWork}>
            <i className="ri-add-circle-line"></i>
            <span className="ml-2">Add Work Experience</span>
          </button>
        </div>
      )}
      {workExperience &&
        workExperience?.map((experience, index) => (
          <div>
            <div className="professionalsummary-companey">
              <div className="d-flex flex-col mt-2">
                <h4 className="bold">{experience?.company?.name}</h4>
                <p>{experience?.company?.description}</p>
              </div>
              <div className="professionalitem-actions">
                <button
                  className="professionalitem-button"
                  onClick={() => handleAddRole("role", experience?.company?.id)}
                >
                  <IoMdAdd size={25} />
                </button>
                <button
                  className="professionalitem-button"
                  onClick={() =>
                    handleEditingIndex(
                      index,
                      "companey",
                      experience?.company?.id
                    )
                  }
                >
                  <i className="ri-edit-line"></i>
                </button>
                <button
                  className="professionalitem-button"
                  onClick={() => handleRemoveCompaney(experience?.company?.id)}
                >
                  <i className="ri-delete-bin-line"></i>
                </button>
              </div>
            </div>
            {experience?.roles?.map((item, keey) => (
              <div
                key={keey}
                className="professionalsummary-item"
                onMouseEnter={() => setHoveredIndex(item?.id)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                <div className="d-flex">
                  <p className="mr-2">{item?.name}</p>
                  <p>{item?.location}</p>
                </div>
                {hoveredIndex === item?.id && (
                  <div className="professionalitem-actions">
                    <button
                      className="professionalitem-button"
                      // onClick={() => handleEditingIndex(index, experience?.id)}
                      onClick={() =>
                        handleEditingIndex(index, "role", item?.id)
                      }
                    >
                      <i className="ri-edit-line"></i>
                    </button>
                    <button
                      className="professionalitem-button"
                      onClick={() => handleRemoveRoles(item?.id)}
                    >
                      <i className="ri-delete-bin-line"></i>
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
        ))}
    </div>
  );
};

export default WorkExperienceForm;
