import React, { Fragment } from "react";
import star from "../../assets/media/Landing/star.png";
import bulb from "../../assets/media/Landing/bulb.png";

const TopPriority = () => {
  const priorityData = [
    {
      title: "Enterprise-Grade Security",
      description:
        " “Nexa safeguards your information with advanced, enterprise-grade security systems designed to protect against threats and ensure data integrity. Our comprehensive security approach encompasses the latest in encryption, secure network architectures, and continuous system monitoring to guard your sensitive data around the clock.",
    },
    {
      title: "Single Sign-On (SSO) Integration",
      description:
        " “Simplify and secure your login process with Nexa’s Single Sign-On (SSO) capabilities. By integrating with leading SSO providers, we make accessing Nexa seamless and safe, reducing the risk of password theft and unauthorized access.",
    },
    {
      title: "Custom Data Retention Policies",
      description:
        " We understand the importance of data management compliance. Nexa supports customizable data retention policies, allowing your institution to align with internal policies and regulatory requirements. Control how long data is retained to meet your specific legal and operational needs.",
    },
    {
      title: "Regular Security Audits and Compliance",
      description:
        "Nexa is committed to maintaining the highest standards of data protection. We regularly undergo independent security audits and are compliant with major international standards, including GDPR and CCPA. Trust in our commitment to not just meet, but exceed, industry security standards.",
    },
  ];

  return (
    <div className="section text-center">
      <div className="gradient-right"></div>
      <h1 className="section-heading color-dark">
        Your Data’s Security and Privacy{" "}
        <span>
          <br />
          Our Top Priority
        </span>
      </h1>

      <div className="container my-5 px-0">
        <div className="row justify-content-center">
          {priorityData.map((data, index) => {
            return (
              <Fragment key={index}>
                {index == 0 || index == 3 ? (
                  <div className="col col-10 col-sm-12 col-lg-7 mt-4">
                    <div className="priority-card priority-card-blue">
                      <div className="row ">
                        <div className="col col-12 col-md-8 ">
                          <p className="priority-card-title">{data?.title}</p>
                          <p className="priority-card-text">
                            {data?.description}
                          </p>
                        </div>
                        <div className="col d-none d-md-block col-4 position-relative">
                          <img src={star} className="priority-card-img" />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="col col-10 col-sm-12 col-lg-5 mt-4">
                    <div className="priority-card priority-card-dark">
                      <div className="row">
                        <div className="col col-12 col-md-9">
                          <p className="priority-card-title">{data?.title}</p>
                          <p className="priority-card-text">
                            {data?.description}
                          </p>
                        </div>
                        <div className="col d-none d-md-block col-3 position-relative">
                          <img src={bulb} className="priority-card-img-dark" />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TopPriority;
