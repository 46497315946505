import React, { useState } from "react";
import Header from "../../Components/header";

const Faq = () => {
  const [active, setActive] = useState(null);

  const questions = [
    {
      title: "Why should I choose Nexa for interview preparation?",
      answer:
        "Nexa stands out due to its advanced AI-driven approach to interview preparation. Unlike traditional methods, Nexa offers a personalized experience by simulating real-life interview scenarios based on your specific job application and resume. This tailored approach helps you prepare more effectively by focusing on areas that are directly relevant to your desired role, ensuring you re well-equipped to handle the specific challenges and questions of actual interviews.",
    },
    {
      title:
        "How does preparing for interviews with Nexa give me an edge in the job market?",
      answer:
        "In todays highly competitive job market, thorough preparation can make a significant difference. Nexa helps you gain this edge by providing detailed feedback on your responses, guiding you to improve in areas that are crucial for the role you re applying for. By simulating real interview environments, Nexa helps build your confidence, sharpens your responses, and equips you with the skills to stand out among other candidates.",
    },
    {
      title:
        "Why is interview preparation more important now than ever before?",
      answer:
        "The job market has become increasingly competitive, with many qualified candidates vying for the same positions. Effective interview preparation is crucial because it not only helps you present your qualifications and experience effectively but also demonstrates your commitment and enthusiasm for the role. Nexa’s comprehensive preparation tools ensure that you are not only ready to answer typical interview questions but also prepared for more dynamic and challenging scenarios, giving you a significant advantage in securing your desired job.",
    },
    {
      title: "What is Nexa’s Interview Training Center Tool?",
      answer:
        "Our free tool provides interview training by randomizing 5 questions from our database. It's designed to help users get accustomed to answering interview questions and provides feedback on their responses.",
    },
    {
      title:
        "How does the Interview Simulation tool differ from the Interview Training Center Tool?",
      answer:
        "The Interview Simulation tool offers a more advanced and personalized experience. It conducts dynamic AI-powered interviews using your CV and job description (JD) to generate questions. This tool is designed to simulate a real interview scenario with personalized feedback based on your responses.",
    },
    {
      title: "What technology powers the Interview Simulation tool?",
      answer:
        "The tool uses a Retrieval-Augmented Generation (RAG) pipeline, integrating custom large language models for question generation and text-to-speech technology for a realistic interview experience.",
    },
    {
      title:
        "How does the Interview Simulation tool use my resume and job description?",
      answer:
        "Your resume and the job description you upload are used by the AI to create a tailored interview experience. The system generates questions relevant to your profile and the job, providing specific feedback to help you prepare better.",
    },
    {
      title: "What is Nexa SmartScreen in the Enterprise section?",
      answer:
        "Nexa SmartScreen is an AI-powered candidate screener designed to efficiently process numerous applications. It screens candidates against criteria provided by the company, ensuring only the most suitable candidates are shortlisted, thereby saving time and effort in recruitment.",
    },
    {
      title: "How does Nexa SmartScreen improve the recruitment process?",
      answer:
        "By using AI to screen candidates, Nexa SmartScreen identifies the best matches for a role more accurately than traditional screening processes. It focuses on finding true fits for the role, considering detailed criteria that typical recruiters might not be able to due to knowledge gaps.",
    },
    {
      title: "What is Nexa Career Connect?",
      answer:
        "This marketplace is a platform where users can connect with experts in their desired career fields. For example, a cybersecurity student can consult with a professional cybersecurity analyst to gain insights into the role, industry, and company culture.",
    },
    {
      title: "How can I benefit from the Career Coaching Marketplace?",
      answer:
        "Users can gain valuable, real-world insights and advice from experienced professionals in their field of interest. It's particularly useful for understanding specific roles, industries, and preparing for career transitions.",
    },
    {
      title: "Does Nexa store user data and inputs?",
      answer:
        "Yes, we keep records of all user data and inputs to continuously improve our AI's accuracy and effectiveness. You can request to have yours removed at any time.",
    },
    {
      title: "How is user privacy and data security handled by Nexa?",
      answer:
        "We prioritize user privacy and data security. All data collected is handled in compliance with relevant data protection laws and regulations, ensuring your information is secure and used solely for enhancing your experience with Nexa.",
    },
  ];

  return (
    <div>
      <Header />

      <div
        className="ic-faq"
        style={{ backgroundImage: `url(${"/images/interview-banner.png"})` }}
      >
        <div className="container">
          <div className="col-xxl-6 col-xl-7 col-lg-8 m-auto">
            <div className="faq-header mt-4 md:!mt-0">
              <h1>Frequently Asked Questions</h1>
            </div>
            <div className="ic-according">
              {questions.map((question, index) => (
                <div
                  className={`ic-according-item ${
                    active === index ? "active" : ""
                  }`}
                  key={question.title}
                >
                  <button
                    className={`${active === index ? "active" : ""}`}
                    onClick={() => setActive(active === index ? null : index)}
                  >
                    <span className="flex mr-5 md:!mr-0">{question.title}</span>
                  </button>
                  <div className={`body ${active === index ? "active" : ""}`}>
                    {active === index ? <p>{question.answer}</p> : ""}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
