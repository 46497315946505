import React, { useRef,useState } from "react";
import { useSelector } from "react-redux";
// import { selectCategories } from "../../features/Skills/SkillsSlice";
// import { selectPublications } from "../../features/publications/PublicationSlice";
import moment from "moment";
const CVTemplate = ({ tempRef, blured = false }) => {
  const containerRef = useRef(null);
  // const { profile } = useSelector((state) => state.profileForm);
  const {
    professionalSummaries,
    workExperience,
    educations,
    projects,
    certificates,
    profile,
    categories,
    publications,
    interests,
    softskills,
    languages,
  } = useSelector((state) => state.ResumeBuilder);
  console.log(
    workExperience,
    professionalSummaries,
    "professionalSummariesprofessionalSummaries"
  );

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };
  console.log({ projects });

  return (
    <div
      className={`cv-child-container h-auto ${blured ? 'blured' : ''}`}
      ref={tempRef}
    >
      {/* Render profile data */}
      {profile && (
        <header className="cv-header">
          <p className="cv-name text-uppercase">
            {profile?.first_name} {profile?.last_name}
          </p>
          <p className="cv-email  text-center">
            {profile?.email && `${profile.email}`}
            {profile?.email &&
              (profile?.address ||
                profile?.city ||
                profile?.phone ||
                profile?.linked_in ||
                profile?.website) &&
              " | "}

            {(profile?.address || profile?.city) &&
              `${profile?.address ?? ""}${
                profile?.address && profile?.city ? ", " : ""
              }${profile?.city ?? ""}`}
            {(profile?.address || profile?.city) &&
              (profile?.phone || profile?.linked_in || profile?.website) &&
              " | "}

            {profile?.phone && `${profile.phone}`}
            {profile?.phone &&
              (profile?.linked_in || profile?.website) &&
              " | "}

            {profile?.linked_in && `${profile.linked_in}`}
            {profile?.linked_in && profile?.website && " | "}

            {profile?.website && `${profile.website}`}
          </p>
        </header>
      )}
      {professionalSummaries && professionalSummaries.length > 0 && (
        <section className="cv-professional_summary">
          <h2 className="cv-section-title">Professional Summary</h2>
          {professionalSummaries?.map((item, index) => (
            <div
              key={index}
              className="cv-professional_summary-item"
              ref={containerRef}
            >
              <p dangerouslySetInnerHTML={{ __html: item?.text }} />
            </div>
          ))}
        </section>
      )}
      {certificates && certificates?.length > 0 && (
        <section className="cv-certifications">
          <h2 className="cv-section-title">Certifications</h2>

          {certificates.map((certification, index) => (
            <div
              className="cv-certification-item d-flex justify-content-between align-items-center"
              key={index}
            >
              <div>
                <h3 className="cv-item-title mb-0">{certification.name}</h3>
                <p className="cv-item-description text-capitalize">
                  {certification?.provider}
                </p>
              </div>
              <p className="cv-item-date">
                {formatDate(certification?.start_date)} -{" "}
                {formatDate(certification?.end_date)}
              </p>
            </div>
          ))}

          {/* Add more certification items as needed */}
        </section>
      )}
      {categories && categories?.find((item) => item.name == "Top Skills") && (
        <section className="cv-skills">
          <h2 className="cv-section-title">Top Skills</h2>
          {categories?.map((category, index) => (
            <React.Fragment key={index}>
              {category.name == "Top Skills" ? (
                <>
                  <ul className="cv-skills-list">
                    {category?.skills &&
                      category?.skills.map((skill, index) => (
                        <li className="cv-skill_top" key={index}>
                          <span className="font-bold text-lg">•</span>{" "}
                          {skill?.name}
                        </li>
                      ))}
                  </ul>
                </>
              ) : (
                <>
                  <ul className="cv-skills-list">
                    {category?.skills &&
                      category?.skills.map((skill, index) => (
                        <li className="cv-skill" key={index}>
                          {skill?.name}
                        </li>
                      ))}
                  </ul>
                </>
              )}
            </React.Fragment>
          ))}
        </section>
      )}
      {workExperience && workExperience?.length > 0 && (
        <section className="cv-professional_summary">
          <h2 className="cv-section-title">Work Experiences</h2>
          {workExperience?.map((experience, index) => (
            <div
              key={index}
              className="cv-professional_summary-item d-flex justify-content-between align-items-center"
            >
              <div className="text-capitalize">
                <h3 className="cv-item-title mb-0">
                  {experience?.company?.name}
                </h3>
                {experience?.roles?.map((item, index) => (
                  <>
                    <p className="cv-item-date">{item?.name}</p>
                  </>
                ))}
              </div>
              <div className="text-capitalize">
                {experience?.roles?.map((item, index) => (
                  <>
                    <p className="cv-item-date">
                      {formatDate(item?.started_at)}
                      {item?.ended_at == null
                        ? " - present"
                        : `${""} - ${formatDate(item?.ended_at)}`}
                    </p>
                  </>
                ))}
              </div>
            </div>
          ))}
        </section>
      )}
      {educations && educations?.length > 0 && (
        <section className="cv-professional_summary">
          <h2 className="cv-section-title">Educations</h2>
          {educations.map((education, index) => (
            <div
              key={index}
              className="cv-professional_summary-item d-flex align-items-center justify-content-between"
            >
              <div>
                <h3>{education.school_name}</h3>
                <p className="cv-item-date">
                  {education.degree && education.degree + " Degree " + " | "}
                  {education?.field && education?.field}
                </p>
              </div>
              <div>
                <p className="cv-item-date text-capitalize">
                  {education?.ended_at == null
                    ? " present"
                    : `${formatDate(education?.ended_at)}`}
                </p>
              </div>
            </div>
          ))}
        </section>
      )}

      {projects && projects?.length > 0 && (
        <section className="cv-projects">
          <h2 className="cv-section-title">Projects</h2>
          {projects?.map((pro, index) => (
            <div className="cv-project-item" key={index}>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h3 className="cv-item-title mb-0">{pro?.project_name}</h3>
                  <p className="cv-item-date">{pro?.organization}</p>
                </div>
                <p className="cv-item-date">
                  {formatDate(pro?.start_date)} - {formatDate(pro?.end_date)}
                </p>
              </div>
              <p
                className="cv-item-date px-2"
                dangerouslySetInnerHTML={{
                  __html: pro?.additional_information,
                }}
              />
            </div>
          ))}
        </section>
      )}

      {publications && publications?.length > 0 && (
        <section className="cv-certifications">
          <h2 className="cv-section-title">Publications</h2>

          {publications.map((publication, index) => {
            // const timestamp = publication?.date;
            // console.log(timestamp, "timestamp>>>>>>>>>>>>>>>");
            // const formattedDateTime = moment(timestamp).format("DD/MM/YYYY ");
            // console.log(formattedDateTime, "formattedDateTime>>>>>>>>>>>");
            return (
              <div
                className="cv-publication-item d-flex justify-content-between align-items-center"
                key={index}
              >
                <div>
                  <h3 className="cv-item-title mb-0">{publication.title}</h3>
                  <p className="cv-item-date">{publication.publisher}</p>
                </div>
                <p className="cv-item-description">
                  {formatDate(publication?.date)}
                </p>
              </div>
            );
          })}

          {/* Add more certification items as needed */}
        </section>
      )}
      {interests?.interests?.length > 0 && (
        <section className="cv-interests">
          <h2 className="cv-section-title">Interests</h2>
          <ul className="cv-skills-list d-flex justify-content-start">
            {interests?.interests &&
              interests?.interests.map((interest, index) => (
                <li className="cv-skill_top" key={index}>
                  <span className="font-bold text-lg">•</span> {interest}
                </li>
              ))}
          </ul>
        </section>
      )}
      {softskills?.skills?.length > 0 && (
        <section className="cv-languages">
          <h2 className="cv-section-title">Soft Skills</h2>
          <ul className="cv-skills-list d-flex justify-content-start">
            {softskills?.skills &&
              softskills?.skills?.map((skill, index) => (
                <li className="cv-skill_top" key={index}>
                  <span className="font-bold text-lg">•</span> {skill}
                </li>
              ))}
          </ul>
        </section>
      )}
      {languages?.languages?.length > 0 && (
        <section className="cv-languages">
          <h2 className="cv-section-title">Languages</h2>
          <ul className="cv-skills-list d-flex justify-content-start">
            {languages?.languages &&
              languages?.languages.map((language, index) => (
                <li className="cv-skill_top" key={index}>
                  <span className="font-bold text-lg">•</span> {language}
                </li>
              ))}
          </ul>
        </section>
      )}

      {/* Add more sections as needed */}
    </div>
  );
};

export default CVTemplate;
