import React from "react";
import { IoCameraOutline } from "react-icons/io5";

const ProfileInfo = ({ profileInfo, handleProfileChange, errors }) => {
  console.log(errors);
  return (
    <div className="ic-heading py-3">
      <h2 className="font-semibold text-xl mb-3">
        Update your profile information
      </h2>
      <div className="flex items-center flex-col mb-3">
        <label
          className="relative min-w-16 min-h-16 w-16 h-16 flex rounded-full overflow-hidden bg-gray-200 justify-center items-center"
          htmlFor="profileImage"
        >
          {profileInfo.image ? (
            <img
              src={
                typeof profileInfo.image === "object"
                  ? URL.createObjectURL(profileInfo.image)
                  : profileInfo.image
              }
              alt="profile"
              className="w-full h-full object-cover"
            />
          ) : (
            <IoCameraOutline size={24} />
          )}
        </label>
        <label htmlFor="profileImage mt-2">Upload Profile Image</label>
        {errors.image && <p className="text-red-500">{errors.image}</p>}
        <input
          id="profileImage"
          name="image"
          onChange={handleProfileChange}
          type="file"
          className="hidden"
          accept=".jpg, .jpeg, .png"
        />
      </div>
      {/* <div className="form-group">
        <label htmlFor="name">Name:</label>
        <input
          type="text"
          id="name"
          name="name"
          value={profileInfo.name}
          onChange={handleProfileChange}
          className="form-control"
        />
        {errors.name && <p className="text-red-500">{errors.name}</p>}
      </div> */}
      <div className="form-group">
        <label htmlFor="address">Address:</label>
        <input
          type="text"
          id="address"
          name="address"
          value={profileInfo.address}
          onChange={handleProfileChange}
          className="form-control"
        />
        {errors.address && <p className="text-red-500">{errors.address}</p>}
      </div>
      <div className="form-group">
        <label htmlFor="education">Education:</label>
        <input
          type="text"
          id="education"
          name="education"
          value={profileInfo.education}
          onChange={handleProfileChange}
          className="form-control"
        />
        {errors.education && <p className="text-red-500">{errors.education}</p>}
      </div>
      <div className="form-group">
        <label htmlFor="contact">Phone Number:</label>
        <input
          type="text"
          id="contact"
          name="contact"
          value={profileInfo.contact}
          onChange={handleProfileChange}
          className="form-control"
        />
        {errors.contact && <p className="text-red-500">{errors.contact}</p>}
      </div>

      <div className="form-group">
        <label htmlFor="linkedIn_profile">LinkedIn Profile:</label>
        <input
          type="text"
          id="linkedIn_profile"
          name="linkedIn_profile"
          value={profileInfo.linkedIn_profile}
          onChange={handleProfileChange}
          className="form-control"
        />
        {errors.linkedIn_profile && (
          <p className="text-red-500">{errors.linkedIn_profile}</p>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="employment_status">Employment Status:</label>
        <select
          id="employment_status"
          name="employment_status"
          value={profileInfo.employment_status}
          onChange={handleProfileChange}
          className="form-control"
        >
          <option value="" disabled>
            Select Employment Status
          </option>
          <option value="FullTime">Full-Time</option>
          <option value="PartTime">Part-Time</option>
          <option value="Contractor">Contractor</option>
          <option value="Intern">Intern</option>
          <option value="Freelancer">Freelancer</option>
          <option value="Temporary">Temporary</option>
          <option value="Unemployed">Unemployed</option>
          <option value="Student">Student</option>
          <option value="Retired">Retired</option>
          <option value="OnLeave">OnLeave</option>
          <option value="Sabbatical">Sabbatical</option>
          <option value="Probation">Probation</option>
        </select>
        {errors.employment_status && (
          <p className="text-red-500">{errors.employment_status}</p>
        )}
      </div>
    </div>
  );
};

export default ProfileInfo;
