import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Header from "../header";
import Footer from "../footer";

const PrivacyPolicyLayout = ({ children }) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <section
        className="ic_privacy_policy"
        style={{ backgroundImage: "url('/images/interview-banner.png')" }}
      >
        <div className="container py-5">
          <div className="privacy_main">
            <aside className="privacy_policy_sidebar">
              <ul>
                <li>
                  <Link
                    to="/terms-and-condition"
                    className={
                      location.pathname === "/terms-and-condition"
                        ? "active"
                        : ""
                    }
                  >
                    Terms & Conditions
                  </Link>
                </li>
                <li>
                  <Link
                    to="/privacy-policy"
                    className={
                      location.pathname === "/privacy-policy" ? "active" : ""
                    }
                  >
                    Privacy & Policy
                  </Link>
                </li>
                <li>
                  <Link
                    to="/cookie-policy"
                    className={
                      location.pathname === "/cookie-policy" ? "active" : ""
                    }
                  >
                    Cookie Policy
                  </Link>
                </li>
              </ul>
            </aside>
            {children}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default PrivacyPolicyLayout;
