import React from "react";
import { useNavigate } from "react-router-dom";

const EndingScreen = ({ setEndScreen, mainRoute }) => {
  const navigate = useNavigate();
  const handlePracticeModal = () => {
    if (mainRoute === "/interview/role") {
      navigate(mainRoute);
    } else {
      window.location.reload();
    }
    setEndScreen(false);
  };
  return (
    <div className="ic-question-start bg-white shadow border-0">
      <h3 className="congratulation-text">
        <img src="/images/hurrah.png" alt="" height={50} width={50} /> Congrats,
        you did it! Let's review.
      </h3>
      <div className="ic-exam-information">
        <p>
          For further information about your responses, use the insight buttons.
          Consider what you said from the interviewer's point of view. Decide
          what you want to get better at, then go back and practice it.
        </p>
        <div className="ic-congratulation-btn">
          <button className="ic-btn-outline" onClick={handlePracticeModal}>
            Practice again <i className="ri-restart-line"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default EndingScreen;
