import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Loader from "../../../Components/main-loader";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Tabs from "./Tabs";
import { handleJobRoleStatus } from "../../../Redux/DashboardSlice";

const Roles = ({
  roles,
  selectedRole,
  setRoleCategoryId,
  setTechStack,
  setTechStackIcon,
  activeTab,
  setActiveTab,
  tabs,
}) => {
  const { isLoading } = useSelector((state) => state.dashboard);
  const location = useLocation();
  const dispatch = useDispatch();
  setTechStack && setTechStack(null);

  const getUrl = (name) => {
    let url = "/interview/role/";
    if (selectedRole && selectedRole.name) {
      url += selectedRole.name.replace(" ", "-").toLowerCase();
    }
    if (!name || typeof name !== "string") return "";
    return name.replace(" ", "-").toLowerCase();
  };

  const getRoleCard = (id, name, icon, index) => {
    setTechStackIcon(icon);

    return (
      <div className="ic-interview-items relative">
        <Link
          onClick={() => setRoleCategoryId(id)}
          key={index}
          to={`${getUrl(name)}`}
          className="w-full h-full flex flex-col justify-center items-center gap-2"
          // data-aos="fade-up"
          // data-aos-delay="50"
          // data-aos-duration={index * 200 + 500}
        >
          <div className="ic-icons">
            <img src={icon} className="img-fluid" alt={name} />
          </div>
          <p>{name}</p>
          <i
            className="ri-arrow-right-line ic-right-arrow"
            style={{ fontSize: "22px" }}
            size="30"
          />
        </Link>
        {location.pathname === "/interview/role/" && (
          <button
            title={
              activeTab === "visible"
                ? "Mark as Archived"
                : "Mark as Recomended"
            }
            onClick={() => dispatch(handleJobRoleStatus({ id, activeTab }))}
            className="absolute top-2 right-2 z-20 p-2 border rounded-full hover:bg-gray-200"
          >
            {activeTab === "visible" ? (
              <FaEye color="#007BFF" />
            ) : (
              <FaEyeSlash color="#007BFF" />
            )}
          </button>
        )}
      </div>
    );
  };

  return (
    <div className="ic-interviews">
      <div className="container">
        <div className="ic-interview-container">
          <h1 className="text-center text-capitalize text-bold">
            Which Career are you interested in practicing?
          </h1>
          {location.pathname === "/interview/role/" && (
            <Tabs
              tabs={tabs}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          )}
          {isLoading ? (
            <Loader />
          ) : (
            <div className="ic-interview-card-container">
              {selectedRole ? (
                selectedRole?.categories
                  ?.slice()
                  .sort((a, b) => {
                    if (a.name < b.name) {
                      return -1;
                    }
                    if (a.name > b.name) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((item, index) =>
                    getRoleCard(
                      item.id,
                      item.name,
                      selectedRole.iconUrl,
                      index
                      // setRoleCategoryId
                    )
                  )
              ) : Array.isArray(roles) && roles.length > 0 ? (
                roles
                  ?.slice()
                  .sort((a, b) => {
                    if (a.name < b.name) {
                      return -1;
                    }
                    if (a.name > b.name) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((item, index) =>
                    getRoleCard(
                      item.id,
                      item.name,
                      item.iconUrl,
                      index
                      // setRoleCategoryId
                    )
                  )
              ) : (
                <h2 className="text-xl font-semibold text-gray-600">
                  There are no roles available in your{" "}
                  {activeTab === "visible" ? "Recommended Tab" : "Archived Tab"}
                </h2>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Roles;
