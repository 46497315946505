import React, { useState, useEffect } from "react";
import { error } from "./toast";

const Timer = ({
  isRunning,
  setIsRunning,
  seconds,
  setSeconds,
  setEndSession,
}) => {
  let minutes = 5 * 60;
  useEffect(() => {
    let timerId;
    if (isRunning && seconds > 0) {
      timerId = setInterval(() => {
        setSeconds((prev) => prev - 1);
      }, 1000);
    } else if (seconds === 0) {
      setEndSession(true);
    }
    return () => clearInterval(timerId);
  }, [isRunning, seconds]);

  const formatTime = () => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  const getProgressBarColor = () => {
    const percentage = (seconds / minutes) * 100;

    if (percentage > 80) {
      // Dark Green to Light Green
      const greenValue =
        128 + Math.floor((255 - 128) * ((percentage - 80) / 20));
      return `rgb(0, ${greenValue}, 0)`;
    } else if (percentage > 60) {
      // Light Green to Light Orange
      const greenValue =
        255 - Math.floor((255 - 165) * ((80 - percentage) / 20));
      return `rgb(255, ${greenValue}, 0)`;
    } else if (percentage > 40) {
      // Light Orange to Orange
      const redValue = 255 - Math.floor((255 - 255) * ((60 - percentage) / 20));
      const greenValue =
        165 - Math.floor((165 - 69) * ((60 - percentage) / 20));
      return `rgb(${redValue}, ${greenValue}, 0)`;
    } else if (percentage > 20) {
      // Orange to Light Red
      const redValue = 255;
      const greenValue = 69 - Math.floor((69 - 0) * ((40 - percentage) / 20));
      return `rgb(${redValue}, ${greenValue}, 0)`;
    } else {
      // Light Red to Dark Red
      const redValue = 255;
      const greenValue = 128 - Math.floor((69 - 0) * ((40 - percentage) / 20));
      return `rgb(${redValue}, 0, 0)`;
    }
  };
  const progressBarWidth = `${(seconds / minutes) * 100}%`;

  return (
    <div className="timer-box">
      <div className="timer-text">{formatTime()}</div>
      <div className="progress-bar-container">
        <div
          className="progress-bar"
          style={{
            width: progressBarWidth,
            backgroundColor: getProgressBarColor(),
          }}
        ></div>
      </div>

      {/* {isRunning ? (
        <div
          onClick={() => setIsRunning(false)}
          style={{
            cursor: "pointer",
          }}
        >
          <i className="ri-pause-mini-fill"></i>
        </div>
      ) : (
        <div
          onClick={() => setIsRunning(true)}
          style={{
            cursor: "pointer",
          }}
        >
          <i className="ri-play-mini-fill"></i>
        </div>
      )} */}
    </div>
  );
};

export default Timer;
