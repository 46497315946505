import { GoogleOAuthProvider } from "@react-oauth/google";
import posthog from "posthog-js";
import React from "react";
import ReactDOM from "react-dom/client";
import { pdfjs } from "react-pdf";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import "./assets/CSS/index.css";
import AppRoutes from "./AuthGuardLayout/AppRoutes";
import HooksWrapper from "./hookWrapper";
import reportWebVitals from "./reportWebVitals";
import { persistor, store } from "./store/store";
//pdfjs.GlobalWorkerOptions.workerSrc = new URL(
// 'pdfjs-dist/build/pdf.worker.min.mjs',
//  import.meta.url,
//).toString();
//post hog: API Key: phx_etrgPmlFkyEzlQnO2k1aF9NhZF2ICXZqeapIOh420tgxLOi
//Initialize posthog

posthog.init("phx_etrgPmlFkyEzlQnO2k1aF9NhZF2ICXZqeapIOh420tgxLOi", {
  api_host: "https://app.posthog.com",
});

posthog.init(process.env.REACT_APP_POSTHOG_KEY, {
  api_host: "https://us.i.posthog.com",
  autocapture: true,
  debug: true,
  // person_profiles: "identified_only", // or 'always' to create profiles for anonymous users as well
});

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`,
  import.meta.url
).toString();
//
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <HooksWrapper>
          {/* <React.StrictMode> */}
          <BrowserRouter>
            <AppRoutes />
          </BrowserRouter>
          {/* </React.StrictMode> */}
        </HooksWrapper>
      </PersistGate>
    </Provider>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analyticdfdsf endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
