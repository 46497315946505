import Repository from "./Repository";
const FeedbackList = "/analytics/interview_simulation_sessions_list";
const GET_SIMULATION_FEEDBACK = "dashboard/interview-simulation/feedback";
const GET_TRAINING_FEEDBACK = "/dashboard/interview-training/feedback";
const TRAININGSESSION = "analytics/interview_training_sessions_list";
const MyInterviewRepository = {
  InterviewSimulationsData(payload) {
    return Repository.get(`${FeedbackList}?page=${payload}&limit=5`);
  },
  GET_feebacks(payload) {
    if (payload.type == "simulation") {
      return Repository.get(
        `${GET_SIMULATION_FEEDBACK}?interviewSimulationSessionId=${payload.id}`
      );
    } else {
      return Repository.get(
        `${GET_TRAINING_FEEDBACK}?interviewTrainingSessionId=${payload.id}`
      );
    }
  },
  GET_training_sessions(payload) {
    return Repository.get(`${TRAININGSESSION}?page=${payload}&limit=5`);
  },
};

export default MyInterviewRepository;
