// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  .curved-edges {
    border-radius: 20px; /* Adjust this value to make the edges more or less curved */
    background-color: white; /* Ensure the background color is set */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for better visual effect */
    padding: 10px; /* Optional: Add some padding for better spacing */
  }
  .Outside {
    background-color: white;
  }`, "",{"version":3,"sources":["webpack://./src/Components/header/Popupmenu.css"],"names":[],"mappings":";EACE;IACE,mBAAmB,EAAE,4DAA4D;IACjF,uBAAuB,EAAE,uCAAuC;IAChE,wCAAwC,EAAE,oDAAoD;IAC9F,aAAa,EAAE,kDAAkD;EACnE;EACA;IACE,uBAAuB;EACzB","sourcesContent":["\n  .curved-edges {\n    border-radius: 20px; /* Adjust this value to make the edges more or less curved */\n    background-color: white; /* Ensure the background color is set */\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for better visual effect */\n    padding: 10px; /* Optional: Add some padding for better spacing */\n  }\n  .Outside {\n    background-color: white;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
