import React, { useEffect, useState } from "react";
import Header from "../../Components/header";
import Footer from "../../Components/footer";

const StartInterview = () => {


  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          portalId: "46093892",
          formId: "224c07e7-fdcc-4a63-8b71-fc2f254d6096",
          target: "#hubspotForm",
        });
      }
    });
  }, []);

  return (
    <div className="interveiw">
      <Header />
      <div
        className="ic-banner"
        style={{ backgroundImage: `url(${"/images/ic-banner.jpg"})` }}
      >
        <div
          className="container-fluid position-relative px-5 py-4 "
          style={{ maxWidth: "auto" }}
        >
          <div className="row">
            <div className="col-md-6 mt-0 py-4 ml-40px px-6 text-black interview-text">
              <h1>
                <b>
                  Nexa streamlines career management, upskilling, and coaching
                  throughout your organization
                </b>
              </h1>
              <br />
              <p className="support-text mt-2">
                Contact us{" "}
                <u>
                  <b>today</b>
                </u>{" "}
                to unlock:
                <br />
                <ul
                  className="mt-1 mx-2"
                  style={{ listStyleType: "disc", marginLeft: "80px" }}
                >
                  <br />
                  <li>Advanced Analytics and Reporting</li>
                  <br />
                  <li>AI-Driven Career Preparation Tool Suite</li>
                  <br />
                  <li>Tailored Career Pathways and Skill Gap Analysis</li>
                  <br />
                  <li>Unlimited Nexa seats across your organization</li>
                  <br />
                  <li>Enterprise grade SSO and security</li>
                  <br />
                  <li>Enterprise Dashboard & SmartScreen Vetting</li>
                  <br />
                  <li>Custom onboarding & 24/7 support</li>
                </ul>
              </p>
            </div>
            <div className="mt-0 px-4 py-2 col-md-6">
              <div className="row">
                <div className="col-md-11 col-sm-4 bg-light shadow-2xl start-interview-form">
                  <div>
                    <div id="hubspotForm"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default StartInterview;
