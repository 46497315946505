import React from "react";
import { Select } from "antd";
import FormInput from "./FormInput";
import SubmitButton from "./SubmitButton";
import {
  experienceOptions,
  jobTypeOptions,
  locationOptions,
  positionOptions,
} from "./constants";
import { MdDelete, MdFileUpload } from "react-icons/md";

const { Option } = Select;

const JobSearchCriteria = ({
  jobSearchCriteria,
  setJobSearchCriteria,
  handleSubmit,
}) => {
  const handleDeleteImage = () => {
    setJobSearchCriteria((prev) => ({ ...prev, image: "" }));
  };
  return (
    <form className="space-y-4" onSubmit={handleSubmit}>
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Positions
        </label>
        <Select
          mode="multiple"
          placeholder="Select Positions"
          onChange={(value) =>
            setJobSearchCriteria((prev) => ({
              ...prev,
              positions: value,
            }))
          }
          className="w-full mt-1"
        >
          {positionOptions.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Locations
        </label>
        <Select
          mode="multiple"
          placeholder="Select Locations"
          onChange={(value) =>
            setJobSearchCriteria((prev) => ({
              ...prev,
              locations: value,
            }))
          }
          className="w-full mt-1"
        >
          {locationOptions.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Experience Levels
        </label>
        <Select
          mode="multiple"
          placeholder="Select Experience Levels"
          onChange={(value) =>
            setJobSearchCriteria((prev) => ({
              ...prev,
              experience: value,
            }))
          }
          className="w-full mt-1"
        >
          {experienceOptions.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      </div>

      <FormInput
        label="Distance (km)"
        type="number"
        placeholder="Enter distance in km"
        value={jobSearchCriteria.distance}
        onChange={(e) =>
          setJobSearchCriteria((prev) => ({
            ...prev,
            distance: e.target.value,
          }))
        }
        name="distance"
      />
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Job Types
        </label>
        <Select
          mode="multiple"
          placeholder="Select Job Types"
          onChange={(value) =>
            setJobSearchCriteria((prev) => ({
              ...prev,
              jobTypes: value,
            }))
          }
          className="w-full mt-1"
        >
          {jobTypeOptions.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      </div>
      <FormInput
        label="Company"
        type="text"
        placeholder="company.."
        value={jobSearchCriteria.company}
        onChange={(e) =>
          setJobSearchCriteria((prev) => ({
            ...prev,
            company: e.target.value,
          }))
        }
        name="distance"
      />
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Upload Resume
        </label>
        <div className="image-upload-container mt-3">
          {!jobSearchCriteria.image && (
            <>
              <input
                accept=".png, .jpg, .jpeg"
                style={{ display: "none" }}
                id="image-upload-input"
                type="file"
                name="image"
                onChange={(e) =>
                  setJobSearchCriteria((prev) => ({
                    ...prev,
                    image: e.target.files[0],
                  }))
                }
              />
              <label htmlFor="image-upload-input">
                <div className="image-upload-box">
                  <span className="upload-icon text-center">
                    <MdFileUpload className="m-auto" />
                    <p>Upload you Photo</p>
                  </span>
                </div>
              </label>
            </>
          )}

          {jobSearchCriteria.image && (
            <div className="image-preview-container">
              <img
                src={
                  typeof jobSearchCriteria.image === "object"
                    ? URL.createObjectURL(jobSearchCriteria.image)
                    : jobSearchCriteria.image
                }
                alt="Selected"
                className="image-preview"
              />
              <div className="image-overlay" onClick={handleDeleteImage}>
                <button className="delete-icon">
                  <MdDelete />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex items-center gap-1">
        <input
          name="remoteJob"
          id="remoteJob"
          type="checkbox"
          className="w-4 h-4"
          checked={jobSearchCriteria.remoteJob}
          onChange={(e) =>
            setJobSearchCriteria((prev) => ({
              ...prev,
              remoteJob: e.target.checked,
            }))
          }
        />
        <label
          htmlFor="remoteJob"
          className="text-sm font-medium text-gray-700"
        >
          Are you looking for a remote job?
        </label>
      </div>

      <SubmitButton />
    </form>
  );
};

export default JobSearchCriteria;
