import React, { useState } from 'react';
import { useSpring, animated } from '@react-spring/web';
import Header from '../../../Components/header';
import { Link } from 'react-router-dom';

const Salesoptions = () => {
  const [state, setState] = useState();

  const error = null;
  const uploadFile = () => {};
  const submitPrompt = () => {};
  const messages = [];

  const handleIconChange = (message) => {
    console.log(message);
  };

  const cards = [
    { text: "How do I enhance my LinkedIn Profile", label: "Cold Calls", delay: 300, image: "/images/icon.png", link: "/sales/coldcall" },
    { text: "Create my career journey", label: "Warm Calls", delay: 500, image: "/images/icon.png", link: "/sales/warmcall" },
    { text: "What are some Effective Strategies for finding job openings in my field?", label: "Gatekeeper Calls", delay: 700, image: "/images/icon.png", link: "/hospitality"  },
    { text: "What are the best practices for filling out online job applications?", label: "Discovery Calls", delay: 900, image: "/images/icon.png", link: "/job-applications"  },
  ];

  const animationProps = useSpring({
    from: { opacity: 0, transform: 'translateY(20px)' },
    to: { opacity: 1, transform: 'translateY(0px)' },
    config: { duration: 500 },
    delay: 200,
  });

  return (
    <div className="h-screen flex flex-col">
      <Header />
      <div className="flex-1 overflow-hidden">
        <div className="ic-interviews voyce py-0 h-full">
          <div className="container pt-32">
            <h1 className="text-center text-bold">Supercharge your Sales Skills with Real Life Scenarios</h1>
            {messages.length === 0 ? (
              <div className="ic-interview-card-container">
                {cards.map((card, index) => (
                  <animated.div
                    key={index}
                    style={{ ...animationProps, transitionDelay: `${card.delay}ms` }}
                    className="ic-interview-items"
                    onClick={() => handleIconChange(card.text)}
                  >
                    <div className="ic-icons">
                      <img src={card.image} className="img-fluid" alt={card.label} />
                    </div>
                    <Link to={card.link} className="text-center voyce_P-lineheight">{card.label}</Link>
                    <i className="size-30" style={{ fontSize: "22px" }}></i>
                  </animated.div>
                ))}
              </div>
            ) : (
              <div className="h-[720px] w-full lg:w-[900px] mx-auto">
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Salesoptions;
