import diners from "../../../assets/media/cardLogos/diners.png";
import visa from "../../../assets/media/cardLogos/visa.png";
import mastercard from "../../../assets/media/cardLogos/mastercard.png";
import amex from "../../../assets/media/cardLogos/amex.png";
import unionpay from "../../../assets/media/cardLogos/unionpay.png";
import discover from "../../../assets/media/cardLogos/discover.png";

export const cardBrands = [
  {
    brand: "diners",
    img: diners,
  },
  {
    brand: "visa",
    img: visa,
  },
  {
    brand: "mastercard",
    img: mastercard,
  },
  {
    brand: "amex",
    img: amex,
  },
  {
    brand: "unionpay",
    img: unionpay,
  },
  {
    brand: "discover",
    img: discover,
  },
];
