import styled from "styled-components";

export const TestContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
`;

export const QuestionContainer = styled.div`
  flex: 1;
  margin-right: 20px;
`;

export const QuestionCard = styled.div`
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 8px;
  padding: 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  padding: 0px 0px;
`;

export const CodeEditorContainer = styled.div``;

export const TimerContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const TimerIcon = styled.span`
  margin-right: 10px;
  font-size: 20px;
`;

export const Timer = styled.span`
  font-size: 20px;
  font-weight: bold;
`;

export const SelectContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  & > div {
    width: 30%;
  }
`;

export const LoaderOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    255,
    255,
    255,
    0.8
  ); /* Semi-transparent white background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's above other content */
`;

export const Loader = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.3); /* Light border */
  border-top: 4px solid #3498db; /* Blue border on top */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite; /* Rotate animation */

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const Button = styled.button`
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

export const PreFormattedText = styled.pre`
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: "Courier New", Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  color: #333;
`;

export const ApiResponseContainer = styled.div`
  // margin-top: 1px;
  // padding: 16px;

  // border-radius: 8px;
  // border: ;
`;

export const ApiResponseHeader = styled.h6`
  color: #007bff;
  // margin-bottom: 10px;
`;

export const ApiResponseContent = styled.pre`
  // background-color: rgb(142 137 137 / 17%);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 8px;
  overflow: auto;
`;
