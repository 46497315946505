import React, { Fragment } from "react";
import Marquee from "react-fast-marquee";
import { useNavigate } from "react-router-dom";
import ISO from "../../assets/media/Landing/ISO.svg";
import SOC from "../../assets/media/Landing/SOC.svg";
import arrowBlue from "../../assets/media/Landing/arrowBlue.svg";
import arrowWhite from "../../assets/media/Landing/arrowWhite.svg";

const Badges = () => {
  const navigate = useNavigate();
  const images = [ISO, SOC, ISO, SOC, ISO, SOC];

  const handleRedirect = () => {
    window.location.href = "https://org.trynexa.com/authentication/sign-up";
  };

  return (
    <div className="section text-center">
      <div className="gradient-right"></div>
      <h1 className="section-heading color-dark">Compliance Is Our Priority</h1>
      <div className="container-fluid px-5 my-5">
        <Marquee delay={0} loop={0} autoFill={true} className="mt-4">
          {images?.map((item, i) => {
            return (
              <Fragment key={i}>
                {" "}
                <div className="mx-5">
                  <img src={item} alt="ISO" />
                </div>
              </Fragment>
            );
          })}
        </Marquee>

        <div className="hero-counter-card badges_elevate">
          <div className="row flex-column flex-xl-row justify-content-between align-items-center my-5">
            <div className="col col-12 col-xl-5">
              <h1 className="elevate-heading mb-3">
                elevate your training program with nexa
              </h1>
              <p className="elevate-subheading">
                Join leading career prep institutions that trust nexa to enhance
                their curriculum & job outcomes
              </p>
            </div>
            <div className="col col-12 col-xl-5 d-flex flex-column mt-3 mt-xl-0 flex-lg-row">
              <button className="elevate-btn elevate-btn-white mt-3 mt-lg-0 mr-3">
                <div onClick={handleRedirect}>
                  <p>Start a free institutional trial</p>
                  <img src={arrowBlue} />
                </div>
              </button>
              <button className="elevate-btn  mt-3 mt-lg-0  mr-3">
                <a
                  href="https://tally.so/r/3yDKdg"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div>
                    <p>Claim Your Free Career Services Improvement Report</p>
                    <img src={arrowWhite} alt="Arrow Icon" />
                  </div>
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Badges;
