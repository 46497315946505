import axios from "axios";
const baseDomain = process.env.REACT_APP_API_URL;
const baseURL = `${baseDomain}`;
let axiosObj;
axiosObj = axios.create({
  baseURL,
  headers: {
    "Cache-Control": "no-cache",
  },
});

axiosObj.interceptors.request.use((config) => {
  const serializedUid = localStorage.getItem("refreshAuth");
  config.headers.Authorization = `Bearer ${serializedUid}`;
  return config;
});

export default axiosObj;
