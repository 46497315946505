import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewTicket,
  getAllTickets,
  udpateTicket,
} from "../../../Redux/TicketSlice";
import { success } from "../../../Utilities/toast";

const AddTicketModal = ({
  addModal,
  toggleModal,
  ticketMode,
  singleTaskData,
}) => {
  console.log(ticketMode, "ticketMode");
  const dispatch = useDispatch();
  const [ticketTitle, setTicketTitle] = useState(singleTaskData?.title || "");
  const [ticketDetail, setTicketDetail] = useState(
    singleTaskData?.details || ""
  );
  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      title: ticketTitle,
      details: ticketDetail,
    };
    if (ticketMode === "edit") {
      dispatch(
        udpateTicket({
          payload,
          id: singleTaskData?.id,
          onSuccess: () => {
            success("Ticket updated successfully");
            dispatch(getAllTickets());
          },
        })
      );
    } else {
      dispatch(
        addNewTicket({
          payload,
          onSuccess: () => {
            success("Ticket created successfully");
            dispatch(getAllTickets());
          },
        })
      );
    }

    toggleModal();
  };

  useEffect(() => {
    if (ticketMode === "create") {
      setTicketTitle("");
      setTicketDetail("");
    }
  }, [toggleModal]);

  useEffect(() => {
    if (ticketMode === "edit") {
      setTicketTitle(singleTaskData?.title);
      setTicketDetail(singleTaskData?.details);
    }
  }, [singleTaskData]);

  return (
    <div>
      <div
        class={`fixed z-10 overflow-y-auto top-0 w-full left-0 ${
          !addModal ? "hidden" : ""
        }`}
        id="modal"
      >
        <div class="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div class="fixed inset-0 transition-opacity">
            <div class="absolute inset-0 bg-gray-900 opacity-75" />
          </div>
          <span class="hidden sm:inline-block sm:align-middle sm:h-screen">
            &#8203;
          </span>
          <div
            class="inline-block py-4 align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <h1 class="text-2xl font-bold text-center my-2">
              {ticketMode == "create" ? "Add New Ticket" : "Edit Ticket"}
            </h1>
            <form onSubmit={handleSubmit}>
              <div className="modal-body2">
                <label className="modal-label2">
                  <span>Ticket Title:</span>
                  <input
                    type="text"
                    value={ticketTitle}
                    onChange={(e) => setTicketTitle(e.target.value)}
                    required
                    className="modal-input"
                  />
                </label>
              </div>
              <div className="modal-body2">
                <label className="modal-label2">
                  <span>Ticket Detail:</span>
                  <input
                    type="text"
                    value={ticketDetail}
                    onChange={(e) => setTicketDetail(e.target.value)}
                    required
                    className="modal-input"
                  />
                </label>
              </div>
              <div class="px-4 py-3 mt-3 text-right">
                <button
                  type="button"
                  class="py-2 px-4 bg-gray-500 text-white rounded hover:bg-gray-700 mr-2"
                  onClick={toggleModal}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  class="py-2 px-4 bg-blue-500 text-white rounded font-medium hover:bg-blue-700 mr-2 transition duration-500"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTicketModal;
