import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { checkSessionAction } from "../Redux/authenticate/authenticate.action";

const useCheckSession = () => {
  const dispatch = useDispatch();
  console.log("==========useCheckSession================")
  useEffect(() => {
    dispatch(checkSessionAction());
  }, [dispatch]);
};

export default useCheckSession;
